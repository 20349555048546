"use client";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import classNames from "../class_names";
const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverAnchor = PopoverPrimitive.Anchor;
/**
 * Displays rich content in a portal, triggered by a button.
 * @see https://radix-ui.com/primitives/docs/components/popover
 *
 * @example
 * <Popover>
 *  <PopoverTrigger as="button">Trigger</PopoverTrigger>
 * <PopoverContent>Content</PopoverContent>
 * </Popover>
 *
 * If your content can scroll to a larger than the screen then its a good idea
 * to wrap your content in a scroll area component.
 * Ensure you mark it as a modal.
 *  @example
 * <Popover modal={true}>
 *  <PopoverTrigger as="button">Trigger</PopoverTrigger>
 *  <PopoverContent>
 *   <ScrollArea>
 *   <div>Content</div>
 *   </ScrollArea>
 *  </PopoverContent>
 * </Popover>
 */
const PopoverContent = React.forwardRef((_a, ref) => {
    var { className, align = "center", sideOffset = 4 } = _a, props = __rest(_a, ["className", "align", "sideOffset"]);
    return (_jsx(PopoverPrimitive.Portal, { children: _jsx(PopoverPrimitive.Content, Object.assign({ ref: ref, align: align, sideOffset: sideOffset, className: classNames("z-50 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none font-['Lato']", "data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2", className), onWheel: (e) => e.stopPropagation(), onTouchMove: (e) => e.stopPropagation() }, props)) }));
});
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor };
