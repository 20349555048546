/**
 * This is according to the relative timestamp UX guidance.
 * Ref: https://www.figma.com/design/mOBO3EGpl2vnsboMPXx3a4/Wiser-CRM?node-id=822-20377&node-type=frame&t=dWlw85qhXRiSL1xl-0
 *
 * @param timeDiffInSeconds
 * @returns
 */
export const getRefreshLabel = (timeDiffInSeconds) => {
    const SECONDS_IN_A_MINUTE = 60;
    const SECONDS_IN_AN_HOUR = 60 * SECONDS_IN_A_MINUTE;
    const SECONDS_IN_A_DAY = 24 * SECONDS_IN_AN_HOUR;
    const SECONDS_IN_A_WEEK = 7 * SECONDS_IN_A_DAY;
    const SECONDS_IN_A_MONTH = 4 * SECONDS_IN_A_WEEK;
    if (timeDiffInSeconds < 10) {
        return "Refreshed just now";
    }
    if (timeDiffInSeconds < SECONDS_IN_A_MINUTE) {
        return "Refreshed a few seconds ago";
    }
    if (timeDiffInSeconds < SECONDS_IN_AN_HOUR) {
        const minutes = Math.floor(timeDiffInSeconds / 60);
        return `Refreshed ${minutes} ${minutes <= 1 ? "minute" : "minutes"} ago`;
    }
    if (timeDiffInSeconds < SECONDS_IN_A_DAY) {
        const hours = Math.floor(timeDiffInSeconds / SECONDS_IN_AN_HOUR);
        return `Refreshed ${hours} ${hours <= 1 ? "hour" : "hours"} ago`;
    }
    if (timeDiffInSeconds < SECONDS_IN_A_WEEK) {
        const days = Math.floor(timeDiffInSeconds / SECONDS_IN_A_DAY);
        return `Refreshed ${days} ${days <= 1 ? "day" : "days"} ago`;
    }
    if (timeDiffInSeconds < SECONDS_IN_A_MONTH) {
        const weeks = Math.floor(timeDiffInSeconds / SECONDS_IN_A_WEEK);
        return `Refreshed ${weeks} ${weeks <= 1 ? "week" : "weeks"} ago`;
    }
    if (timeDiffInSeconds < 12 * SECONDS_IN_A_MONTH) {
        const months = Math.floor(timeDiffInSeconds / SECONDS_IN_A_MONTH);
        return `Refreshed ${months} ${months <= 1 ? "month" : "months"} ago`;
    }
    return "Refreshed over a year ago";
};
