import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDataTable } from "../table_hooks/use_data_table";
import { DataTable } from "../components/data_table";
import { useNavigate } from "react-router-dom";
import { DEFAULT_TABLE_LAYOUT } from "../settings/constants";
import { DataTableToolbarV2 } from "../components/data_table_toolbar_v2";
import { useFilterChange, useFilterConfig, useInitialFilter, } from "../table_hooks/use_filters";
import { useSortRuleConfig, useInitialSortRules, useSortRulesChange, } from "../table_hooks/use_sort";
import { Drawer, DrawerContent, DrawerTitle, } from "../components/drawer_dialog";
import { useQuery, useQueryClient, } from "react-query";
import { getAppIntegrationData } from "../common/endpoints";
import { getColumnOrder, useMatchingLayout, } from "../components/data_table_toolbar_hooks";
import { exportTableToCSV } from "../table_helpers";
import { useEffect, useMemo, useState } from "react";
import { FIELDS, getColumns, SORTABLE_FIELDS } from "./user.helpers";
import UserForm from "./user_form";
export function UserTable(data) {
    // Feature flags for showcasing some additional features. Feel free to remove them.
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const users = data.users;
    const total = data.total;
    const { data: appIntegrationData } = useQuery({
        queryKey: ["appIntegrationData", data.tenant.id],
        queryFn: getAppIntegrationData,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const [selectedUser, setSelectedUser] = useState();
    // Memoize the columns so they don't re-render on every render
    const columns = useMemo(() => getColumns(setSelectedUser), []);
    const matchingLayout = useMatchingLayout(data.layouts, data.searchParams);
    const [columnOrder, setColumnOrder] = useState(getColumnOrder(columns, matchingLayout));
    const { filterConfig, wiserBackendQueryConverter } = useFilterConfig(FIELDS);
    const { sortRuleConfig, wiserBackendSortRuleConverter } = useSortRuleConfig(SORTABLE_FIELDS);
    const initialFilter = useInitialFilter(data.searchParams, wiserBackendQueryConverter);
    const initialSortRules = useInitialSortRules(data.searchParams, wiserBackendSortRuleConverter);
    const handleFilterChange = useFilterChange(navigate, data.searchParams);
    const handleSortRulesChange = useSortRulesChange(navigate, data.searchParams);
    const { table } = useDataTable({
        data: users,
        columns,
        initialState: {
            columnPinning: { right: ["actions"], left: ["select", "name"] },
            columnVisibility: {
                precall_notification_duration: false,
                precall_email_enabled: false,
                precall_slack_enabled: false,
                custom_bot_name: false,
                no_live_bot: false,
                timezone: false,
                total_internal_meetings: false,
            },
        },
        columnOrder,
        onColumnOrderChange: setColumnOrder,
        enableAdvancedFilter: true,
        enableAdvancedSorting: true,
        // For remembering the previous row selection on page change
        getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    });
    // Special condition to handle table export.
    // TODO: Find a better way to achieve this, since pagination is no longer a table state
    // setting table.setPageSize() no longer works.
    useEffect(() => {
        if (data.searchParams.has("pageSize") &&
            data.searchParams.get("pageSize") === "1000") {
            exportTableToCSV(table, {
                filename: "calls",
                excludeColumns: ["select", "actions"],
            });
        }
    }, []);
    const renderNewToolBar = () => {
        return (_jsx(DataTableToolbarV2, { table: table, columnIdMap: {}, layouts: data.layouts, filterConfig: filterConfig, sortRuleConfig: sortRuleConfig, defaultTableLayout: DEFAULT_TABLE_LAYOUT, onFiltersChange: handleFilterChange, onSortRulesChange: handleSortRulesChange, filterPanelTitle: "Show me users", sortRuleBuilderPanelTitle: "Sort users by", initialFilters: initialFilter, initialSortRules: initialSortRules, showSearchBar: true, onSearchQueryChange: data.setSearchQuery, matchingLayout: matchingLayout }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(DataTable, Object.assign({ table: table, columnOrder: columnOrder, setColumnOrder: setColumnOrder, fetchNextPage: data.fetchNextPage, total: total, totalFetched: users.length, isFetching: data.isFetching }, { children: renderNewToolBar() })), _jsx(Drawer, Object.assign({ open: !!selectedUser, onOpenChange: () => setSelectedUser(undefined) }, { children: _jsxs(DrawerContent, Object.assign({ className: "sm:max-w-none sm:w-5/6" }, { children: [_jsx(DrawerTitle, Object.assign({ className: "text-wds-black" }, { children: "User Details" })), _jsx(UserForm, { user: selectedUser })] })) }))] }));
}
