import { useEffect, useRef, useState } from "react";
const useAutoScroll = (dependencies) => {
    const [autoScroll, setAutoScroll] = useState(true);
    const scrollContainerRef = useRef(null);
    // Scroll to bottom when new entries are added and autoScroll is true
    useEffect(() => {
        if (autoScroll && scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollHeight;
        }
    }, dependencies);
    const handleScroll = () => {
        if (!scrollContainerRef.current) {
            return;
        }
        // enable auto scroll when user scrolls to bottom, & disable auto scroll if user scrolls up
        const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
            setAutoScroll(true);
        }
        else {
            setAutoScroll(false);
        }
    };
    return { scrollContainerRef, handleScroll };
};
export default useAutoScroll;
