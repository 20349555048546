var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import * as HoverCardPrimitive from "@radix-ui/react-hover-card";
import classNames from "../class_names";
const HoverCard = HoverCardPrimitive.Root;
const HoverCardTrigger = HoverCardPrimitive.Trigger;
/**
 * For sighted users to preview content available behind a link or any item.
 * @example
 * ```
 * <HoverCard>
 *   <HoverCardTrigger>Hover</HoverCardTrigger>
 *   <HoverCardContent>Content</HoverCardContent>
 * </HoverCard>
 * ```
 * You can change the side with `side` prop in `HoverCardContent`
 *
 * @see https://react.dev/reference/react/forwardRef for more documentaiton on forwardRef
 * Essentially this allows forwarding a ref through multiple components.
 * For example
 * @example
 * ```
 * <HoverCard>
 *   <HoverCardTrigger>Hover</HoverCardTrigger>
 *   <HoverCardContent ref={someRef}>Content</HoverCardContent>
 * </HoverCard>
 * ```
 * This allows you to pass `someRef` which is actually parsed properly by
 * HoverCardPrimitive.Content, why would this be required?
 * Consider this example
 * ```
 * const hoverCardRef = React.useRef(null);
 *
 *  const handleFocus = () => {
 *    if (hoverCardRef.current) {
 *      hoverCardRef.current.focus(); // Assuming HoverCardPrimitive.Content can receive focus
 *    }
 *  };
 *
 *  return (
 *    <div>
 *      <button onClick={handleFocus}>Focus Hover Card</button>
 *      <HoverCardContent ref={hoverCardRef} className="my-hover-card">
 *        Hover card content goes here.
 *     </HoverCardContent>
 *    </div>
 *  );
 *  ```
 * This allows me to pass this ref to hadle the focus event.
 *
 * @param {Object} props - The props that will be forwarded to HoverCardPrimitive.Content.
 * @param {string} [props.className] - Additional classes for custom styling.
 * @param {string} [props.align="center"] - The alignment of the hover card content.
 * @param {number} [props.sideOffset=4] - The offset for the hover card content from the side.
 * @param {string} [props.side] - The side from where this needs to hover in.
 * @param {React.Ref} ref - The ref to be forwarded to HoverCardPrimitive.Content.
 *
 * @returns {React.ReactElement} The rendered HoverCardContent component.
 */
const HoverCardContent = React.forwardRef((_a, ref) => {
    var { className, align = "center", sideOffset = 4 } = _a, props = __rest(_a, ["className", "align", "sideOffset"]);
    return (_jsx(HoverCardPrimitive.Content, Object.assign({ ref: ref, align: align, sideOffset: sideOffset, className: classNames("z-50 w-64 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2", className) }, props)));
});
HoverCardContent.displayName = HoverCardPrimitive.Content.displayName;
export { HoverCard, HoverCardTrigger, HoverCardContent };
