import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getActiveTenant, getCrmAccount } from "../../common/endpoints";
import { CenterScreen } from "../../settings/styled_components";
import { SettingsLoader } from "../../common/loaders";
import { AccountPageHeader } from "./account_page_header";
import { useMemo } from "react";
import { AccountBlocks } from "./account_blocks";
import { useAccountResearchBlocks } from "./use_account_research_blocks";
import { ACCOUNT_SOURCES_AND_WEB_DATA, PAST_CALLS_CHAT_SOURCE, } from "../../live_cards_chat_bot/chat_source_menu";
import { ChatBox } from "../../home/chat_box_v3";
export const AccountPage = ({ accId }) => {
    // We get the accId in the case this is loaded from the calls table
    const { accountIdParam } = useParams();
    const accountId = accId ? Number(accId) : Number(accountIdParam);
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const { data: account, isLoading: accountLoading } = useQuery({
        queryKey: ["crmAccount", accountId],
        queryFn: () => getCrmAccount(accountId, /* includeNonCrmAccounts= */ true),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const { allResearchBlocks, accountResearchBlockOutputs, accountBlocks: accountBlocksToRender, scrollToBlock: scrollToBlockHandler, staticBlocksCount, blocksRefCallback, createNewResearchBlock, updateResearchBlock, generateResearchBlockOutput, } = useAccountResearchBlocks({
        account,
    });
    const existingResearchBlockIds = useMemo(() => {
        var _a;
        return ((_a = accountResearchBlockOutputs === null || accountResearchBlockOutputs === void 0 ? void 0 : accountResearchBlockOutputs.map((output) => output.research_block_id)) !== null && _a !== void 0 ? _a : []);
    }, [accountResearchBlockOutputs]);
    if (accountLoading || !account || !activeTenant) {
        return (_jsx(CenterScreen, { children: _jsx(SettingsLoader, {}) }));
    }
    return (_jsx("div", Object.assign({ className: "flex flex-row w-full h-[calc(100vh - 3.125rem)] lg:h-screen bg-neutral-100 overflow-hidden font-['Lato']" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col w-full items-start grow p-1 pb-0" }, { children: [_jsx(AccountPageHeader, { tenant: activeTenant, account: account, accountBlocksToRender: accountBlocksToRender, scrollToBlockHandler: scrollToBlockHandler, allResearchBlocks: allResearchBlocks !== null && allResearchBlocks !== void 0 ? allResearchBlocks : [], existingResearchBlockIds: existingResearchBlockIds, createNewResearchBlock: createNewResearchBlock, updateResearchBlock: updateResearchBlock, generateResearchBlockOutput: generateResearchBlockOutput }), _jsxs("div", Object.assign({ className: "flex flex-col w-full py-4 px-8 gap-4 items-start overflow-y-auto h-lvh" }, { children: [_jsx(ChatBox, { accountId: accountId, defaultChatSource: ACCOUNT_SOURCES_AND_WEB_DATA, chatSources: [ACCOUNT_SOURCES_AND_WEB_DATA, PAST_CALLS_CHAT_SOURCE] }), _jsx(AccountBlocks, { accountBlocksToRender: accountBlocksToRender, blocksRefCallback: blocksRefCallback, staticBlocksCount: staticBlocksCount })] }))] })) })));
};
