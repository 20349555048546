import { useEffect, useState } from "react";
/**
 * `usePagination` Custom Hook
 *
 * This hook is designed to encapsulate pagination logic and state management. When invoked,
 * it provides stateful values and functions to manage page-based data fetching or rendering.
 *
 * Intended Usage:
 *
 * 1. Initialization:
 *    const paginationControl = usePagination(initialPage, pageSize);
 *    - `initialPage`: Optional. The starting page number, default is 1.
 *    - `pageSize`: Optional. The number of items per page, default is 10.
 *
 * 2. Values:
 *    - `paginationControl.pageSize`: The current size of each page.
 *    - `paginationControl.currentPage`: The current active page number.
 *    - `paginationControl.totalRows`: The total number of items across all pages.
 *
 * 3. Methods:
 *    - `paginationControl.goToNextPage()`: Increment the current page number.
 *    - `paginationControl.goToPreviousPage()`: Decrement the current page number.
 *    - `paginationControl.setTotalRows(count)`: Set the total number of items. Useful
 *        after data fetching to adjust the pagination controls based on the total data size.
 *
 * Note:
 * The hook includes an effect that checks if the current page becomes empty (e.g., due to
 * data deletion or filter changes) and automatically moves the user to the previous page
 * if needed. This ensures a smooth user experience by preventing them from landing on empty pages.
 *
 * Example:
 *
 * const { currentPage, setTotalRows } = usePagination();
 *
 * useEffect(() => {
 *   // Assume fetchData is a function that fetches data based on current page and page size.
 *   const data = fetchData(currentPage);
 *   setTotalRows(data.total); // setTotalRows MUST be called for this hook to correctly work
 * }, [currentPage]);
 *
 */
const usePagination = (initialPage = 1, pageSize = 10) => {
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [totalRows, setTotalRows] = useState(0);
    const goToNextPage = () => setCurrentPage((currentPage) => currentPage + 1);
    const goToPreviousPage = () => setCurrentPage((currentPage) => currentPage - 1);
    useEffect(() => {
        // Check if the current page is empty after data updates and go to the previous page if necessary
        const isCurrentPageEmpty = Math.ceil(totalRows / pageSize) < currentPage;
        if (isCurrentPageEmpty && currentPage > 1) {
            goToPreviousPage();
        }
    }, [totalRows, pageSize, currentPage]);
    return {
        pageSize,
        currentPage,
        totalRows,
        goToNextPage,
        goToPreviousPage,
        setTotalRows,
    };
};
export default usePagination;
