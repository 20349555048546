"use client";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import classNames from "../class_names";
import { CheckIcon } from "@heroicons/react/24/solid";
/**
 * @example
 * ```
 * <Checkbox id="terms2" />
 * ```
 * @example
 * ```
 * <Checkbox id="terms2" disabled />
 * ```
 * @example
 * ```
 * <Checkbox id="terms2" checked />
 * ```
 */
const Checkbox = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx(CheckboxPrimitive.Root, Object.assign({ ref: ref, className: classNames("peer h-4 w-4 p-[1px] rounded-sm border-[1px] border-wds-black", "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2", "disabled:cursor-not-allowed disabled:opacity-50", "data-[state=checked]:text-white", className) }, props, { children: _jsx(CheckboxPrimitive.Indicator, Object.assign({ className: classNames("flex items-center justify-center rounded-sm bg-primary") }, { children: _jsx(CheckIcon, { className: "h-full w-full" }) })) })));
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;
export { Checkbox };
