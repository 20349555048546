var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InformationCircleIcon, XCircleIcon, } from "@heroicons/react/24/outline";
import WaitingSpinner from "../waiting_spinner";
import LiveCardTextEditor from "../live_card_rich_text";
import BaseModal from "../base_modal";
import { useEffect, useState } from "react";
const CallPrepOutput = (props) => {
    const { callPrepOutput, isExecuting, isEditing, setIsEditing, updateCallPrepOutput, } = props;
    // copying props to state to mantain local edited content, it is discarded & reset if user cancels editing
    const [localCallPrepOutput, setLocalCallPrepOutput] = useState(callPrepOutput);
    useEffect(() => {
        setLocalCallPrepOutput(callPrepOutput);
    }, [callPrepOutput]);
    const renderCallPrepOutput = () => {
        if (isExecuting) {
            return (_jsx("div", Object.assign({ className: "px-2 justify-start items-start gap-2 text-neutral-500 inline-flex" }, { children: _jsx(WaitingSpinner, { text: "Content is being generated..." }) })));
        }
        if (!callPrepOutput) {
            return (_jsxs("div", Object.assign({ className: "px-2 justify-start items-start gap-2 inline-flex" }, { children: [_jsx(InformationCircleIcon, { className: "w-5 h-5 text-blue-600" }), _jsx("div", Object.assign({ className: "grow shrink basis-0 rounded-lg justify-start items-start flex" }, { children: _jsxs("div", Object.assign({ className: "grow shrink basis-0 text-neutral-500 leading-snug" }, { children: ["Pre-call prep block not yet generated, please click on \"Generate block content\" to generate this ", _jsx("br", {})] })) }))] })));
        }
        if (callPrepOutput.errorMessage) {
            return (_jsxs("div", Object.assign({ className: "px-2 justify-start items-start gap-2 inline-flex" }, { children: [_jsx(XCircleIcon, { className: "w-5 h-5 relative text-red-600" }), _jsx("div", Object.assign({ className: "grow shrink basis-0 rounded-lg justify-start items-start flex" }, { children: _jsxs("div", Object.assign({ className: "grow shrink basis-0 text-neutral-500 leading-snug" }, { children: ["There was an error generating this block's content. ", _jsx("br", {}), "Error: ", callPrepOutput.errorMessage] })) }))] })));
        }
        if (callPrepOutput.html) {
            return (_jsx("div", { className: `self-stretch px-4 flex-col justify-start items-start flex ${callPrepOutput.html.includes("ql-ui") ? "ql-editor" : ""} link-styles`, dangerouslySetInnerHTML: { __html: callPrepOutput.html } }));
        }
        return null;
    };
    return (_jsxs(_Fragment, { children: [renderCallPrepOutput(), isEditing && localCallPrepOutput && (_jsx(BaseModal, Object.assign({ title: "Edit pre call prep", description: "", fields: [], open: isEditing, setOpen: (open) => {
                    if (!open) {
                        setIsEditing(false);
                    }
                }, submitCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                    // close the modal
                    setIsEditing(false);
                    yield updateCallPrepOutput(localCallPrepOutput);
                }), submitText: "Save" }, { children: _jsx(LiveCardTextEditor, { content: (localCallPrepOutput === null || localCallPrepOutput === void 0 ? void 0 : localCallPrepOutput.html) || "", setContent: (html) => {
                        setLocalCallPrepOutput(Object.assign(Object.assign({}, localCallPrepOutput), { html }));
                    } }) })))] }));
};
export default CallPrepOutput;
