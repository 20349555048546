export function getEpochTimes(startDate, endDate) {
    // Set start date to midnight
    startDate.setHours(0, 0, 0, 0);
    let startEpoch = getEpochTime(startDate);
    // Set end date to 11:59:59 PM
    endDate.setHours(23, 59, 59, 999);
    let endEpoch = getEpochTime(endDate);
    return { startEpoch, endEpoch };
}
export function getEpochTime(date) {
    return Math.floor(date.getTime() / 1000);
}
export function formatSecsToHMS(secs) {
    let hours = Math.floor(secs / 3600);
    let minutes = Math.floor((secs % 3600) / 60);
    let seconds = Math.floor(secs % 60);
    let formattedTime = "";
    if (hours > 0) {
        formattedTime += hours + ":";
    }
    // Left pad, so we get something like
    // 00:01 instead of 0:1
    if (minutes < 10) {
        formattedTime += "0";
    }
    formattedTime += minutes + ":";
    if (seconds < 10) {
        formattedTime += "0";
    }
    formattedTime += seconds;
    return formattedTime;
}
