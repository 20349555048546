import { jsx as _jsx } from "react/jsx-runtime";
import CarouselItem from "./carousel_item";
import React, { useCallback, useEffect, useState } from "react";
const CardsCarousel = ({ allCards, addCardToChat }) => {
    const [cardsQueue, setCardsQueue] = useState([]);
    useEffect(() => {
        const queueMap = new Map(cardsQueue.map((card) => [card.intent, card]));
        // Flag to check if the queue has been updated
        let queueUpdated = false;
        // Iterate over allCards to find and add new cards
        const updatedQueue = allCards.reduce((acc, card) => {
            // allCards contains already-removed cards, so we need to filter them out
            if (card.removed)
                return acc;
            if (!queueMap.has(card.intent)) {
                acc.push(card);
                queueUpdated = true;
            }
            return acc;
        }, [...cardsQueue]);
        // Queue hasn't been updated
        if (!queueUpdated)
            return;
        // Only update the state if the queue has actually been updated
        setCardsQueue(updatedQueue);
    }, [allCards]);
    // Card is removed from the queue after processing.  We may
    // see it again later, and showing it is desired.
    const removeCardFromQueue = useCallback((id) => {
        setCardsQueue((currentQueue) => {
            return currentQueue.filter((item) => item.intent !== id);
        });
    }, []);
    // add card to render in chat list
    const handleClick = useCallback((card) => {
        addCardToChat(card);
    }, [addCardToChat]);
    if (!allCards.length) {
        return null;
    }
    return (_jsx("div", Object.assign({ className: "grid grid-flow-row-dense gap-2 overflow-auto h-[120px]", style: { gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))" } }, { children: cardsQueue.map((card, index) => (_jsx("div", { children: _jsx(CarouselItem, { startDismissing: card.removing || false, name: card.name, onClick: () => handleClick(card), id: card.intent, animationDuration: 3, removed: card.removed || false, onRemove: () => removeCardFromQueue(card.intent) }) }, card.intent))) })));
};
export default React.memo(CardsCarousel);
