var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BaseModal from "./base_modal";
import { useEffect, useState } from "react";
import { CameraIcon } from "@heroicons/react/24/outline";
import Badge from "./common/badge";
// The ContactView operates in three disinct modes:
// 1. Show a contact picker from which you can click on a contact.
// 2. Show detailed view of that contact.
// 3. A mode where we skip step 1 jumping directly to step 2 with
//    a particular contact.
export default function ContactView(props) {
    const [showContactDialog, setShowContactDialog] = useState(false);
    const [singleContactInView, setSingleContactInView] = useState();
    // This method is used to bypass the Contact Picker, and
    // instead show the dialog right away.
    const showSingleContact = (contact) => __awaiter(this, void 0, void 0, function* () {
        setSingleContactInView(contact);
        setShowContactDialog(true);
    });
    useEffect(() => {
        if (props.singleContactInView) {
            showSingleContact(props.singleContactInView);
        }
    }, [props.singleContactInView]);
    const getBackgroundColorForRole = (role_name) => {
        switch (role_name) {
            case "Customer":
                return "bg-green-100";
            case "Salesperson":
                return "bg-blue-100";
            default:
                return "bg-amber-100";
        }
    };
    return (_jsxs("div", Object.assign({ className: "grid grid-cols-1 gap-4 sm:grid-cols-2" }, { children: [props.contacts.map((contact) => (_jsxs("div", Object.assign({ className: "relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400", onClick: (event) => {
                    event.stopPropagation();
                    setSingleContactInView(contact);
                    setShowContactDialog(true);
                } }, { children: [contact.profile_pic ? (_jsx("div", Object.assign({ className: "flex-shrink-0" }, { children: _jsx("img", { className: "h-10 w-10 rounded-full", src: contact.profile_pic, alt: "" }) }))) : (_jsx(CameraIcon, { className: "h-6 w-6", "aria-hidden": "true" })), _jsxs("div", Object.assign({ className: "min-w-0 flex-1 group" }, { children: [_jsx("span", { className: "absolute inset-0", "aria-hidden": "true" }), _jsx("p", Object.assign({ className: "text-base font-bold text-gray-900" }, { children: contact.full_name })), _jsxs("div", Object.assign({ className: "inline-flex flex-row gap-2" }, { children: [_jsx("p", Object.assign({ className: "text-base font-medium text-blue-500" }, { children: contact.email })), _jsx(Badge, { text: contact.role_name, classes: getBackgroundColorForRole(contact.role_name) })] })), _jsx("p", Object.assign({ className: "truncate text-sm text-gray-700" }, { children: contact.occupation })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-400" }, { children: contact.headline })), _jsx("div", Object.assign({ className: "hidden group-hover:block" }, { children: _jsx("p", {}) }))] }))] }), contact.email))), singleContactInView && (_jsxs(BaseModal, Object.assign({ title: "View Contact", description: "", open: showContactDialog, setOpen: (open) => {
                    if (!open)
                        setSingleContactInView(undefined);
                    setShowContactDialog(open);
                }, 
                // This is a view-only modal, so no submit callback or edit fields.
                submitCallback: (_) => {
                    setSingleContactInView(undefined);
                    setShowContactDialog(false);
                }, fields: [], submitText: "OK" }, { children: [singleContactInView.profile_pic ? (_jsx("img", { className: "h-10 w-10 rounded-full", src: singleContactInView.profile_pic, alt: "" })) : (_jsx(CameraIcon, { className: "h-6 w-6", "aria-hidden": "true" })), _jsx("a", Object.assign({ href: singleContactInView.linkedin_profile_url, target: "_blank", className: "font-bold text-gray-900 hover:underline my-4" }, { children: singleContactInView.full_name })), _jsx("p", Object.assign({ className: "text-base font-medium text-blue-500 hover:underline" }, { children: singleContactInView.email })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: singleContactInView.headline })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: singleContactInView.summary })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: singleContactInView.occupation })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: singleContactInView.city +
                            " " +
                            (singleContactInView.state
                                ? ", " + singleContactInView.state
                                : "") +
                            (singleContactInView.country_full_name
                                ? ", " + singleContactInView.country_full_name
                                : "") })), singleContactInView.experiences &&
                        singleContactInView.experiences.length > 0 && (_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xl font-medium text-gray-900 my-4" }, { children: "Experiences" })), singleContactInView.experiences.map((experience, index) => {
                                return (_jsxs("div", Object.assign({ className: "relative flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 mx-3 my-3" }, { children: [_jsx("a", Object.assign({ href: experience.company_linkedin_profile_url, target: "_blank", className: "font-medium text-blue-600 dark:text-blue-500 hover:underline" }, { children: experience.company })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-900" }, { children: experience.title })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: experience.description })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: experience.start_date + " - " + experience.end_date })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: experience.location }))] }), index));
                            })] })), singleContactInView.education &&
                        singleContactInView.education.length > 0 && (_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xl font-medium text-gray-900 my-4" }, { children: "Education" })), singleContactInView.education.map((education, index) => {
                                return (_jsxs("div", Object.assign({ className: "relative flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-6 py-5 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 mx-3 my-3" }, { children: [_jsx("p", Object.assign({ className: "text-sm font-medium text-gray-700" }, { children: education.school })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: education.field_of_study })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: education.degree_name })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: education.description })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: education.start_date + " - " + education.end_date }))] }), index));
                            })] })), singleContactInView.accomplishments_projects &&
                        singleContactInView.accomplishments_projects.length > 0 && (_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xl font-medium text-gray-900 my-4" }, { children: "Accomplishments" })), singleContactInView.accomplishments_projects.map((accomplishment, index) => {
                                return (_jsxs("div", Object.assign({ className: "relative flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-6 py-5 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 mx-3 my-3" }, { children: [_jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: accomplishment.title })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: accomplishment.description })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: accomplishment.start_date +
                                                " - " +
                                                accomplishment.end_date }))] }), index));
                            })] })), singleContactInView.volunteer_work &&
                        singleContactInView.volunteer_work.length > 0 && (_jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xl font-medium text-gray-900 my-4" }, { children: "Volunteer Work" })), singleContactInView.volunteer_work.map((volunteer_work, index) => {
                                return (_jsxs("div", Object.assign({ className: "relative flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-6 py-5 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 mx-3 my-3" }, { children: [_jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: volunteer_work.title })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: volunteer_work.description })), _jsx("p", Object.assign({ className: "text-sm font-medium text-gray-500" }, { children: volunteer_work.start_date +
                                                " - " +
                                                volunteer_work.end_date }))] }), index));
                            })] }))] })))] })));
}
ContactView.defaultProps = {
    contacts: [],
    singleContactInView: null,
};
