import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
const DoCrash = () => {
    useEffect(() => {
        const x = null;
        x.find(1);
    }, []);
    return _jsx("div", { children: "DoCrash" });
};
export default DoCrash;
