// This function is used to initialize and return the Paragon SDK.
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useRef, useState } from "react";
export default function useParagonGlobal() {
    const mountedParagon = useRef(false);
    const [paragonReady, setParagonReady] = useState(false);
    const initParagon = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        // @ts-ignore
        if (window.paragon) {
            setParagonReady(true);
        }
    }), []);
    useEffect(() => {
        if (typeof window !== "undefined" &&
            !paragonReady &&
            !mountedParagon.current) {
            // @ts-ignore
            if (window.paragon) {
                initParagon();
            }
            else {
                mountedParagon.current = true;
                const paragonSrc = document.createElement("script");
                paragonSrc.src = "https://cdn.useparagon.com/latest/sdk/index.js";
                paragonSrc.onload = initParagon;
                document.body.appendChild(paragonSrc);
            }
        }
    }, [paragonReady, initParagon]);
    // @ts-ignore
    if (paragonReady && window.paragon) {
        // @ts-ignore
        return window.paragon;
    }
    return undefined;
}
