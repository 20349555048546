import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { debounce } from "../common/debounce";
import classNames from "../class_names";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import useIntersectionObserver from "../common/intesection_observer";
import { LoadingSelectItems } from "../common/loaders";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Command, CommandGroup, CommandItem, CommandList } from "./command";
/**
 * Asynchronously loads items for a select combobox.
 *
 * Provides a searchable dropdown experience without requiring all items upfront.
 *
 * **Usage:** Similar to `SearchableDropdown`, but accepts an async `dataFetcher` callback.
 *
 * **dataFetcher:**
 * - Accepts optional pagination parameters (e.g., cursor).
 * - Returns a Promise resolving to an array of {@link SelectOption} objects and an optional cursor.
 *
 * **Example:** See `CallCrmAccountModal` for usage.
 *
 * @param dataFetcher - Async function to fetch select options.
 */
export const AsyncSearchSelect = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const [searchQuery, setSearchQuery] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    // We use debouncedSearchQuery to refetch new select options instead
    // of search query to reduce the number of API calls when user types,
    // and not being reactive on every key-stroke by the user.
    // Why not make the searchQuery state debounced? Because then it makes the user
    // feel that their key-strokes are not being recorded
    const debouncedQuery = debounce(searchQuery, 400);
    const { data: itemsFetchResponse, isLoading: itemsLoading, fetchNextPage, hasNextPage, isFetchingNextPage, } = useInfiniteQuery(["fetchSelectItems", debouncedQuery, (_a = props.id) !== null && _a !== void 0 ? _a : ""], ({ pageParam = null }) => props.dataFetcher(debouncedQuery !== null && debouncedQuery !== void 0 ? debouncedQuery : "", pageParam), {
        getNextPageParam: (prevPage, pages) => prevPage.next_cursor || null,
    });
    const selectItems = useMemo(() => {
        if (!itemsFetchResponse || !itemsFetchResponse.pages) {
            return [];
        }
        return itemsFetchResponse.pages.flatMap((page) => page.results);
    }, [itemsFetchResponse]);
    const loadMoreRef = useIntersectionObserver(() => {
        if (hasNextPage && fetchNextPage) {
            fetchNextPage();
        }
    }, { threshold: 1.0 });
    const sections = props.separator
        ? props.separator(selectItems)
        : [{ options: selectItems }];
    return (_jsxs(Popover, Object.assign({ open: isPopoverOpen, onOpenChange: setIsPopoverOpen }, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsxs("div", Object.assign({ className: classNames("flex px-3 w-full h-8 items-center gap-2 hover:cursor-pointer", "border border-wds-gray-3 rounded-lg justify-between self-strech bg-white", (_b = props.buttonClasses) !== null && _b !== void 0 ? _b : "") }, { children: [_jsx("input", { className: "text-black p-1 w-full text-sm font-medium border-none bg-transparent focus:ring-0 focus:outline-none placeholder:text-wds-gray-4 rounded", placeholder: (_c = props.placeholder) !== null && _c !== void 0 ? _c : "", onChange: (e) => {
                                setSearchQuery(e.target.value);
                            }, value: (_e = searchQuery !== null && searchQuery !== void 0 ? searchQuery : (_d = props.selectedOption) === null || _d === void 0 ? void 0 : _d.label) !== null && _e !== void 0 ? _e : "", onBeforeInput: () => setIsPopoverOpen(true), autoFocus: isPopoverOpen }), _jsx(ChevronDownIcon, { className: "w-5 h-5 shrink-0 text-wds-gray-6" })] })) })), _jsx(PopoverContent, Object.assign({ align: "start", side: "bottom", className: classNames("w-full p-0 bg-white shadow-md rounded-md max-h-60 overflow-y-auto", (_f = props.className) !== null && _f !== void 0 ? _f : ""), 
                // Focus should stay on the input text box.
                onOpenAutoFocus: (e) => e.preventDefault() }, { children: _jsx(Command, { children: _jsx(CommandList, { children: itemsLoading ? (_jsx("div", Object.assign({ className: "w-[var(--radix-popover-trigger-width)]" }, { children: _jsx(LoadingSelectItems, {}) }))) : !selectItems || selectItems.length === 0 ? (_jsx("div", Object.assign({ className: "p-2 text-wds-gray-6" }, { children: "Nothing found." }))) : (_jsx(_Fragment, { children: sections.map((section, sectionIndex) => (_jsxs(CommandGroup, Object.assign({ heading: section.title }, { children: [section.options.map((option) => {
                                        var _a;
                                        const isSelected = ((_a = props.selectedOption) === null || _a === void 0 ? void 0 : _a.value) === option.value;
                                        return (_jsxs(CommandItem, Object.assign({ className: "flex w-full items-center justify-between px-4 py-2 cursor-pointer", onSelect: () => {
                                                props.onSelect(option);
                                                setSearchQuery(null);
                                                setIsPopoverOpen(false);
                                            } }, { children: [props.itemRenderer
                                                    ? props.itemRenderer(option, searchQuery !== null && searchQuery !== void 0 ? searchQuery : "")
                                                    : option.label, isSelected && (_jsx(CheckIcon, { className: "w-4 h-4 text-wds-blue-4 shrink-0" }))] }), option.value));
                                    }), isFetchingNextPage && _jsx(LoadingSelectItems, {}), !isFetchingNextPage && hasNextPage && (_jsx("div", { ref: loadMoreRef, style: { height: "1px" } }))] }), sectionIndex))) })) }) }) }))] })));
};
