import { useState } from "react";
const useFileUploader = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };
    const handleUploadClick = (onFileUpload) => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                onFileUpload(content);
            };
            reader.readAsText(selectedFile);
        }
    };
    return {
        handleFileChange,
        handleUploadClick,
    };
};
export default useFileUploader;
