import { useMemo } from "react";
export function useMatchingLayout(layouts, searchParams, enableLayoutManagement = true) {
    return useMemo(() => {
        if (!enableLayoutManagement || !layouts) {
            return undefined;
        }
        const queryString = decodeURIComponent(searchParams.toString());
        const matchingLayouts = layouts.filter((layout) => layout.query_string === queryString);
        if (matchingLayouts.length) {
            // If more than 1 layout matches the query string, return the default layout or the first layout
            const defaultLayout = matchingLayouts.find((layout) => layout.is_default);
            return defaultLayout !== null && defaultLayout !== void 0 ? defaultLayout : matchingLayouts[0];
        }
        else if (searchParams.has("default")) {
            return layouts.find((layout) => layout.is_default);
        }
    }, [enableLayoutManagement, layouts, searchParams]);
}
export function getColumnOrder(columns, matchingLayout) {
    return useMemo(() => {
        if (!matchingLayout ||
            !matchingLayout.column_order ||
            matchingLayout.column_order.length === 0) {
            return columns.map((c) => c.id);
        }
        // Add the missing columns to the end of the matching layout columnOrder
        const missingColumns = columns.filter((column) => !matchingLayout.column_order.includes(column.id));
        return [
            ...matchingLayout.column_order,
            ...missingColumns.map((c) => c.id),
        ];
    }, [columns, matchingLayout]);
}
