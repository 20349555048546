import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate, useParams } from "react-router-dom";
import * as endpoints from "../common/endpoints";
import { useQuery } from "react-query";
import { CenterScreen } from "../settings/styled_components";
import WaitingSpinner from "../waiting_spinner";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
const CallRedirect = ({ redirectPage }) => {
    const { google_calendar_id } = useParams();
    const navigate = useNavigate();
    if (!google_calendar_id) {
        // Navigate to homepage if no calendar event present
        navigate("/");
        return null;
    }
    const { data: call_id_data, isError: callError, isLoading: callLoading, } = useQuery({
        queryKey: ["google_calendar_id", google_calendar_id],
        queryFn: () => endpoints.getLiveCallIdByGoogleCalendarId(google_calendar_id),
    });
    const handleGoHome = () => {
        navigate("/");
    };
    const reSyncCalls = () => {
        navigate("/");
    };
    const renderError = () => {
        return (_jsx("div", Object.assign({ className: "flex items-center justify-center min-h-screen bg-gray-100" }, { children: _jsxs("div", Object.assign({ className: "text-center" }, { children: [_jsx("div", Object.assign({ className: "mb-4" }, { children: _jsx(ExclamationCircleIcon, { className: "w-12 h-12 text-red-500 mx-auto" }) })), _jsx("p", Object.assign({ className: "text-xl text-gray-700 mb-4" }, { children: (call_id_data === null || call_id_data === void 0 ? void 0 : call_id_data.message) ||
                            "Error loading call ID. This call may not have been synced into Wiser yet. Please retry." })), _jsx("button", Object.assign({ onClick: handleGoHome, className: "m-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" }, { children: "Go Back Home" })), _jsx("button", Object.assign({ onClick: reSyncCalls, disabled: (call_id_data === null || call_id_data === void 0 ? void 0 : call_id_data.message) != null, className: `m-2 px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-opacity-50 ${(call_id_data === null || call_id_data === void 0 ? void 0 : call_id_data.message) != null
                            ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                            : "bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500"}` }, { children: "Retry" }))] })) })));
    };
    if (callError) {
        return renderError();
    }
    if (callLoading) {
        return (_jsx(CenterScreen, { children: _jsx(WaitingSpinner, { text: "Redirecting to call..." }) }));
    }
    if (call_id_data && call_id_data.call_id) {
        navigate(`/${redirectPage}/${call_id_data.call_id}`);
    }
    return renderError();
};
export default CallRedirect;
