var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import BaseTable2 from "./base_table2";
import { createBrick, deletePreCallPrepBlock, getAllBricks, setBrickLinksToTarget, setBrickOrdering, updateBrick, } from "./common/endpoints";
import { useMutation, useQuery, useQueryClient } from "react-query";
import CreateOrEditBriefing from "./pre_call_prep/create_or_edit_briefing";
import { Button } from "./components/button";
import ItemDetailsDrawer from "./components/item_details_drawer";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, } from "./components/modal_dialog";
import { Separator } from "./components/separator";
import { NoteLoaderFrame } from "./common/loaders";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Tabs, TabsList, TabsTrigger } from "./components/tabs";
import classNames from "./class_names";
import BrickPreviews from "./bricks/brick_previews";
import { TabsContent } from "@radix-ui/react-tabs";
import useFeatureFlagEnabled from "./use_feature_flag_enabled";
const TYPE_TO_DELIVERY_TARGETS = new Map([
    [
        "ACCOUNT",
        [
            {
                id: "AccountUI",
                name: "Account Page",
            },
            {
                id: "AccountSummary",
                name: "Account Summary (only latest will be used)",
            },
        ],
    ],
    [
        "CALL",
        [
            {
                id: "Slack",
                name: "Slack",
            },
            {
                id: "Email",
                name: "Email",
            },
        ],
    ],
    ["CALL_OVERVIEW", []],
]);
const BrickTable = () => {
    var _a, _b;
    const queryClient = useQueryClient();
    const { data: allBricksResponse, isLoading: bricksLoading, isError: bricksError, } = useQuery(["all_bricks"], getAllBricks, {
        refetchOnWindowFocus: false,
    });
    const allBricks = allBricksResponse === null || allBricksResponse === void 0 ? void 0 : allBricksResponse.bricks;
    const brickLinks = allBricksResponse === null || allBricksResponse === void 0 ? void 0 : allBricksResponse.brick_links;
    const linkAvailability = allBricksResponse === null || allBricksResponse === void 0 ? void 0 : allBricksResponse.link_availability;
    const [selectedBrickId, setSelectedBrickId] = useState(null);
    const [creatingBrick, setCreatingBrick] = useState(false);
    const [newBrickPrompt, setNewBrickPrompt] = useState("");
    const [newBrickName, setNewBrickName] = useState("");
    const [selectedDataSources, setSelectedDataSources] = useState(new Set());
    const [selectedDeliveryTargets, setSelectedDeliveryTargets] = useState(new Set());
    const [selectedSourceBricks, setSelectedSourceBricks] = useState([]);
    const brickPreviewsEnabled = useFeatureFlagEnabled("BRICK_PREVIEWS");
    useEffect(() => {
        if (brickLinks !== undefined && selectedBrickId !== null) {
            setSelectedSourceBricks(brickLinks
                .filter((link) => link.target_brick_id === selectedBrickId)
                .map((link) => allBricks === null || allBricks === void 0 ? void 0 : allBricks.find((brick) => brick.id === link.source_brick_id))
                .filter((brick) => brick !== undefined));
        }
    }, [brickLinks, selectedBrickId]);
    const availableSourceBricks = useMemo(() => {
        if (linkAvailability === undefined || allBricks === undefined) {
            return [];
        }
        const available = linkAvailability.find((availabilityForTarget) => {
            return availabilityForTarget.target_brick_id === selectedBrickId;
        });
        return allBricks
            .map((brick) => brick.id)
            .filter((brickId) => {
            return (available === undefined ||
                !available.unavailable_source_brick_ids.includes(brickId));
        });
    }, [linkAvailability, allBricks, selectedBrickId]);
    const tabs = [
        { label: "Account Prompts", value: "ACCOUNT" },
        { label: "Call Prompts", value: "CALL" },
        { label: "Call Overview", value: "CALL_OVERVIEW" },
    ];
    const [selectedType, setSelectedType] = useState(tabs[0].value);
    useEffect(() => {
        setSelectedBrickId(null);
        setCreatingBrick(false);
    }, [selectedType]);
    const currentTypeBricks = useMemo(() => {
        if (selectedType === null || allBricks === undefined) {
            return [];
        }
        return allBricks.filter((brick) => brick.research_block_type === selectedType);
    }, [selectedType, allBricks]);
    const clearBrickDetails = () => {
        setSelectedBrickId(null);
        setNewBrickName("");
        setNewBrickPrompt("");
        setSelectedDataSources(new Set());
        setSelectedDeliveryTargets(new Set());
        setSelectedSourceBricks([]);
    };
    // UNCOMMENT FOR TESTING
    // useEffect(() => {
    //   if (currentTypeBricks.length > 0) {
    //     setSelectedBrickId(currentTypeBricks[0].id);
    //     setCreatingBrick(true);
    //   }
    // }, [currentTypeBricks]);
    const promptDetails = useMemo(() => {
        if (selectedBrickId === null || allBricks === undefined) {
            clearBrickDetails();
            return [];
        }
        const selectedBrickList = allBricks.filter((brick) => brick.id === selectedBrickId);
        if (selectedBrickList.length === 0) {
            clearBrickDetails();
            return [];
        }
        const selectedBrick = selectedBrickList[0];
        setNewBrickName(selectedBrick.name);
        setNewBrickPrompt(selectedBrick.prompt);
        setSelectedDataSources(new Set(selectedBrick.data_sources));
        setSelectedDeliveryTargets(new Set(selectedBrick.delivery_targets));
        // Note, the links are handled in a separate useEffect, since they depend
        // on brickLinks rather than allBricks.
        return [
            { title: "Prompt Name", value: selectedBrick.name },
            { title: "Prompt Type", value: selectedBrick.research_block_type },
            { title: "Prompt Text", value: selectedBrick.prompt },
            { title: "Deliver To", value: selectedBrick.delivery_targets.join(", ") },
        ];
    }, [selectedBrickId, allBricks]);
    const [isSaving, setIsSaving] = useState(false);
    const [previewOrEditTabSelection, setPreviewOrEditTabSelection] = useState("create_or_edit");
    const createBrickMutation = useMutation({
        mutationFn: createBrick,
        onSuccess: (updatedBrick) => __awaiter(void 0, void 0, void 0, function* () {
            const prevBricks = allBricks !== null && allBricks !== void 0 ? allBricks : [];
            queryClient.setQueryData(["all_bricks"], {
                bricks: [...prevBricks, updatedBrick],
                brick_links: brickLinks,
                link_availability: linkAvailability,
            });
            yield setBrickLinksMutation.mutateAsync({
                sourceBrickIds: selectedSourceBricks.map((brick) => brick.id),
                targetBrickId: updatedBrick.id,
            });
        }),
    });
    const updateBrickMutation = useMutation({
        mutationFn: updateBrick,
        onSuccess: (updatedBrick) => __awaiter(void 0, void 0, void 0, function* () {
            queryClient.setQueryData(["all_bricks"], {
                bricks: allBricks === null || allBricks === void 0 ? void 0 : allBricks.map((b) => b.id === updatedBrick.id ? updatedBrick : b),
                brick_links: brickLinks,
                link_availability: linkAvailability,
            });
            yield setBrickLinksMutation.mutateAsync({
                sourceBrickIds: selectedSourceBricks.map((brick) => brick.id),
                targetBrickId: updatedBrick.id,
            });
        }),
    });
    const allBricksRef = useRef(allBricks);
    useEffect(() => {
        allBricksRef.current = allBricks;
    }, [allBricks]);
    const setBrickLinksMutation = useMutation({
        mutationFn: setBrickLinksToTarget,
        onSuccess: (response) => {
            var _a;
            queryClient.setQueryData(["all_bricks"], {
                bricks: (_a = allBricksRef.current) !== null && _a !== void 0 ? _a : [],
                brick_links: response.brick_links,
                link_availability: response.link_availability,
            });
        },
    });
    if (bricksLoading || bricksError || allBricks === undefined) {
        return _jsx(NoteLoaderFrame, {});
    }
    const brickTableColumns = [
        { header: "Prompt Name", accessorKey: "name" },
        { header: "Prompt Type", accessorKey: "research_block_type" },
    ];
    const selectedTypeLabel = (_b = (_a = tabs.find((tab) => tab.value === selectedType)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : "Custom Prompts";
    return (_jsxs("div", Object.assign({ className: classNames("flex flex-col", promptDetails.length === 0 ? "w-full" : "w-3/4") }, { children: [_jsx("div", Object.assign({ className: "text-[24px] p-4 font-medium leading-8" }, { children: "Custom Prompt Editor" })), _jsx(Tabs, Object.assign({ defaultValue: tabs[0].value, onValueChange: (value) => {
                    setSelectedType(value);
                }, className: "border-b border-wds-gray-3" }, { children: _jsx(TabsList, Object.assign({ className: "mx-4 border-none" }, { children: tabs.map((tab) => (_jsx(TabsTrigger, Object.assign({ value: tab.value, className: "outline-none px-4 py-2 justify-start items-center flex rounded-none rounded-tl-lg rounded-tr-lg border-blue-600 data-[state=active]:border-b-2 data-[state=active]:bg-white" }, { children: tab.label }), tab.value))) })) })), _jsx(BaseTable2, { title: selectedTypeLabel, rows: currentTypeBricks, columns: brickTableColumns, addCallback: () => {
                    setCreatingBrick(true);
                }, expandCallback: (row) => {
                    setSelectedBrickId(row.id);
                }, reorderRows: (rows) => {
                    for (let i = 0; i < rows.length; i++) {
                        console.log("REORDER: ", rows[i].name);
                    }
                    setBrickOrdering(rows.map((brick) => brick.id)).then((bricks) => {
                        queryClient.setQueryData(["all_bricks"], {
                            bricks,
                            brick_links: brickLinks,
                            link_availability: linkAvailability,
                        });
                    });
                } }), _jsx(ItemDetailsDrawer, { title: "Prompt", details: promptDetails, isOpen: promptDetails.length > 0, onClose: () => {
                    setSelectedBrickId(null);
                }, deleteCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (selectedBrickId === null) {
                        return;
                    }
                    yield deletePreCallPrepBlock(selectedBrickId);
                    setSelectedBrickId(null);
                    queryClient.invalidateQueries("all_bricks");
                }), editCallback: () => {
                    setCreatingBrick(true);
                } }), _jsx(Dialog, Object.assign({ open: creatingBrick, onOpenChange: setCreatingBrick }, { children: _jsx(DialogContent, Object.assign({ className: "sm:w-full sm:max-w-xl lg:max-w-6xl" }, { children: _jsxs(DialogHeader, { children: [_jsx(DialogTitle, { children: "Create New Prompt" }), _jsxs(DialogDescription, { children: ["This will be used to help extract information at the", " ", selectedTypeLabel, " level."] }), _jsxs(Tabs, Object.assign({ defaultValue: "create_or_edit", className: "w-full border-wds-gray-3", onValueChange: setPreviewOrEditTabSelection, value: previewOrEditTabSelection }, { children: [brickPreviewsEnabled && (_jsx("div", Object.assign({ className: "border-b border-wds-gray-3 w-full mb-2" }, { children: _jsx(TabsList, Object.assign({ className: "border-none" }, { children: ["create_or_edit", "preview"].map((tab) => (_jsx(TabsTrigger, Object.assign({ value: tab, className: "outline-none px-4 py-2 justify-start items-center flex rounded-none rounded-tl-lg rounded-tr-lg border-blue-600 data-[state=active]:border-b-2 data-[state=active]:bg-white" }, { children: tab === "create_or_edit"
                                                    ? "Create/Edit"
                                                    : "Preview Changes" }), tab))) })) }))), _jsx(TabsContent, Object.assign({ value: "create_or_edit" }, { children: _jsx(CreateOrEditBriefing, { researchBlockType: selectedType, name: newBrickName, setName: setNewBrickName, prompt: newBrickPrompt, setPrompt: setNewBrickPrompt, selectedDataSources: selectedDataSources, deliveryTargets: selectedDeliveryTargets, setSelectedDataSources: setSelectedDataSources, setDeliveryTargets: setSelectedDeliveryTargets, potentialSourceBricks: currentTypeBricks.filter((brick) => availableSourceBricks.includes(brick.id)), selectedSourceBricks: selectedSourceBricks, selectSourceBricks: setSelectedSourceBricks, deliveryTargetOptions: TYPE_TO_DELIVERY_TARGETS.get(selectedType) }) })), _jsx(TabsContent, Object.assign({ value: "preview" }, { children: _jsx(BrickPreviews, { newPrompt: newBrickPrompt, brickId: selectedBrickId, brickName: newBrickName }) }))] })), _jsx(Separator, { orientation: "horizontal" }), _jsx(DialogFooter, Object.assign({ className: "sm:justify-end" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2" }, { children: [_jsx(Button, Object.assign({ type: "button", variant: "ghost", onClick: () => setCreatingBrick(false) }, { children: "Cancel" })), brickPreviewsEnabled && (_jsx(Button, Object.assign({ type: "button", variant: "light", onClick: () => setPreviewOrEditTabSelection("preview") }, { children: "Preview Output" }))), _jsx(Button, Object.assign({ type: "button", variant: "default", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                if (selectedBrickId) {
                                                    yield updateBrickMutation.mutateAsync({
                                                        id: selectedBrickId,
                                                        name: newBrickName,
                                                        dataSources: [...selectedDataSources],
                                                        prompt: newBrickPrompt,
                                                        deliveryTargets: [...selectedDeliveryTargets],
                                                    });
                                                }
                                                else {
                                                    const resp = yield createBrickMutation.mutateAsync({
                                                        name: newBrickName,
                                                        dataSources: [...selectedDataSources],
                                                        prompt: newBrickPrompt,
                                                        deliveryTargets: [...selectedDeliveryTargets],
                                                        researchBlockType: selectedType,
                                                    });
                                                }
                                                setCreatingBrick(false);
                                            }), disabled: newBrickName === "" || newBrickPrompt === "" }, { children: isSaving ? (_jsx(ArrowPathIcon, { className: "h-3.5 w-3.5 animate-spin" })) : ("Save") }))] })) }))] }) })) }))] })));
};
export default BrickTable;
