import { useState, useEffect, useRef } from "react";
function floatTo16BitPCM(x) {
    const maxVal = Math.max(-1, Math.min(1, x));
    return maxVal < 0 ? maxVal * 32768 : maxVal * 32767;
}
export var StreamSelector;
(function (StreamSelector) {
    // Set to "USER" to record from the local mic
    // or set to "DESKTOP" to record from a remote
    // desktop stream on another tab
    StreamSelector[StreamSelector["User"] = 0] = "User";
    StreamSelector[StreamSelector["Desktop"] = 1] = "Desktop";
})(StreamSelector || (StreamSelector = {}));
const useVoiceRecording = (streamSelector, streamSocket) => {
    // Logs with timing information to the console
    const [callStartTime, setCallStartTime] = useState(new Date().getTime());
    function logTimed(message) {
        const currentTime = new Date().getTime();
        const time = currentTime - callStartTime;
        console.log(`${StreamSelector[streamSelector]}: ${time}: ${message}`);
    }
    // This is the time that the current audio chunk started recording.
    const chunkStartTime = useRef(0);
    // This is the variable bound to the UI Switch that toggles recording on and off
    const [uiRecordingToggle, setUiRecordingToggle] = useState(false);
    const mediaStream = useRef(undefined);
    const pcmRecorder = useRef(undefined);
    const audioContext = useRef(undefined);
    let sendPcmData = (floatBuffer) => {
        var _a;
        if (!!!audioContext.current) {
            console.error("Audio context not initialized");
            return;
        }
        const chunkArrivalTime = Math.max(Date.now(), chunkStartTime.current + 1);
        const numSamples = floatBuffer.length;
        const buffer = new ArrayBuffer(numSamples * 2); // 1 channel * 2 bytes (16-bit) per sample
        const view = new DataView(buffer);
        for (let i = 0; i < numSamples; i++) {
            // Convert float samples to 16-bit integer values
            const leftSample = floatTo16BitPCM(floatBuffer[i]);
            view.setInt16(i * 2, leftSample, true); // little endian
        }
        const blob = new Blob([buffer], { type: "audio/pcm" }); // Create a Blob from the ArrayBuffer
        streamSocket.send_audio(blob, (_a = audioContext.current) === null || _a === void 0 ? void 0 : _a.sampleRate, chunkStartTime.current, chunkArrivalTime, StreamSelector[streamSelector]);
        // Reset the start time for the next chunk of sound if there is one.
        chunkStartTime.current = chunkArrivalTime + 1;
    };
    const shutDownMedia = () => {
        var _a;
        if (mediaStream.current) {
            console.log("Stopping media stream");
            mediaStream.current.getTracks().forEach((track) => {
                console.log("Stopping track " + track.id);
                track.stop();
            });
            if (pcmRecorder.current) {
                (_a = pcmRecorder.current) === null || _a === void 0 ? void 0 : _a.disconnect();
            }
            pcmRecorder.current = undefined;
            mediaStream.current = undefined;
            if (audioContext.current) {
                audioContext.current.suspend();
                audioContext.current.close();
                audioContext.current = undefined;
            }
        }
    };
    useEffect(() => {
        logTimed("Initialize pcm recorder on record button toggle.");
        if (!!!uiRecordingToggle)
            return;
        if (!!!navigator.mediaDevices) {
            logTimed("getUserMedia not supported.");
            return;
        }
        function selectStream(stream) {
            // Ref: https://developer.mozilla.org/en-US/docs/Web/API/AudioContext
            const audioCtx = new AudioContext();
            audioContext.current = audioCtx;
            let source = audioCtx.createMediaStreamSource(stream);
            // Because the audio stream processing is done in a separate thread,
            // in a secure environment, we have to pass it by file instead of importing
            // it directly.
            const workletUrl = STATIC_URL + "frontend/pcm_recorder.js";
            audioCtx.audioWorklet
                .addModule(workletUrl)
                .then(() => {
                const options = {
                    numberOfInputs: 1,
                    numberOfOutputs: 1,
                    processorOptions: {
                        sampleRate: audioCtx.sampleRate,
                    },
                };
                pcmRecorder.current = new AudioWorkletNode(audioCtx, "pcm-recorder", options);
                pcmRecorder.current.port.onmessage = (event) => {
                    const { buffer } = event.data;
                    sendPcmData(buffer);
                };
                source.connect(pcmRecorder.current);
            })
                .catch((err) => {
                console.error("Error loading pcm recorder worklet: " + err);
            });
            chunkStartTime.current = Date.now();
        }
        switch (streamSelector) {
            case StreamSelector.User:
                navigator.mediaDevices
                    .getUserMedia({ audio: true, video: false })
                    .then((userStream) => {
                    selectStream(userStream);
                    mediaStream.current = userStream;
                })
                    .catch((err) => {
                    console.error(`The following getUserMedia error occurred: ${err}`);
                });
                break;
            case StreamSelector.Desktop:
                // Note that to capture audio from another tab we also need to be
                // capturing video.
                // Ref: https://stackoverflow.com/questions/58644808/how-to-get-a-media-stream-of-the-speakers-output-to-transfer-it-over-the-networ
                navigator.mediaDevices
                    .getDisplayMedia({ video: true, audio: true })
                    .then((desktopStream) => {
                    // Stops and removes the video track to enhance the performance
                    const videoTrack = desktopStream.getVideoTracks()[0];
                    videoTrack.stop();
                    desktopStream.removeTrack(videoTrack);
                    selectStream(desktopStream);
                    mediaStream.current = desktopStream;
                })
                    .catch((err) => {
                    console.error(`The following getDisplayMedia error occurred: ${err}`);
                    // If the tab sharing failed, reset the toggle back to unset state.
                    setUiRecordingToggle(false);
                });
                break;
        }
        // Cleanup closure
        return shutDownMedia;
    }, [uiRecordingToggle, streamSelector]);
    return [uiRecordingToggle, setUiRecordingToggle];
};
export default useVoiceRecording;
