import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTableSkeleton } from "../components/data_table_skeleton";
import { CallsTable } from "./calls_table";
import { convertNewSearchParamsIntoFilterQuery } from "./convert_search_params_to_filter_query";
import { useInfiniteQuery, useQuery } from "react-query";
import { getActiveTenant, getCallsForTableView, getCrmFields, getPlaybookItemGroups, } from "../common/endpoints";
import { useMemo, useState } from "react";
import { DEFAULT_TABLE_LAYOUT } from "../settings/constants";
import { debounce } from "../common/debounce";
import { useCustomSearchParams } from "../crm/use_memoised_search_params";
import { CallsChatModal } from "./calls_chat_modal";
import React from "react";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import { getConvertedJsonQuery } from "../common/query_builder/json_query_converter";
export default function IndexPage() {
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedQuery = debounce(searchQuery, 400);
    const { searchParams, layouts, isLoading: layoutsLoading, } = useCustomSearchParams("calls", DEFAULT_TABLE_LAYOUT);
    const filterQuery = convertNewSearchParamsIntoFilterQuery(searchParams, "CallExtended", debouncedQuery);
    const enableChatWithCallsTable = useFeatureFlagEnabled("CHAT_WITH_CALLS_TABLE");
    const [visibleColumnNames, setVisibleColumnNames] = React.useState();
    const pageSize = parseInt(searchParams.get("pageSize") || "50") || 50;
    const { data, fetchNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery(["callsTable", filterQuery, pageSize], ({ pageParam = 0 }) => getCallsForTableView(filterQuery, pageParam + 1, pageSize), {
        getNextPageParam: (_lastGroup, groups) => groups.length,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
    //flatten the array of arrays from the useInfiniteQuery hook
    const calls = useMemo(() => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.flatMap((page) => page.data)) !== null && _b !== void 0 ? _b : []; }, [data]);
    const { data: playbookItemGroups } = useQuery("getPlaybookItemGroups", getPlaybookItemGroups, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
    const { data: crmFields, isLoading: crmFieldsLoading } = useQuery("getCrmFields", getCrmFields, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
    const { total, wiser_joined_count } = useMemo(() => {
        var _a;
        if (((_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.length) && data.pages.length > 0) {
            return {
                total: data.pages[0].total,
                wiser_joined_count: data.pages[0].wiser_joined_count,
            };
        }
        return { total: 0, wiser_joined_count: 0 };
    }, [data === null || data === void 0 ? void 0 : data.pages]);
    let chatWithFilterQuery = useMemo(() => filterQuery, [filterQuery]);
    return (_jsxs("div", Object.assign({ className: "gap-2 p-4 bg-wds-gray-1 h-screen" }, { children: [_jsx("div", Object.assign({ className: "w-60 text-black text-3xl p-2" }, { children: "Calls" })), !data ||
                isLoading ||
                !playbookItemGroups ||
                layoutsLoading ||
                !activeTenant ||
                !layouts ||
                !crmFields ||
                crmFieldsLoading ||
                layouts.length === 0 ? (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true })) : (_jsx(CallsTable, { calls: calls, total: total, wiser_joined_count: wiser_joined_count, playbookItemGroups: playbookItemGroups, layouts: layouts, searchQuery: searchQuery, setSearchQuery: setSearchQuery, onVisibleColumnsChange: setVisibleColumnNames, tenant: activeTenant, searchParams: searchParams, filterQuery: filterQuery, fetchNextPage: fetchNextPage, isFetching: isFetchingNextPage, crmFields: crmFields })), enableChatWithCallsTable && (_jsx(CallsChatModal
            // TODO: the design here will be to communicate the TableLayout identifier,
            // and then use new backend API's to get the data for the chat.
            , { 
                // TODO: the design here will be to communicate the TableLayout identifier,
                // and then use new backend API's to get the data for the chat.
                filterQuery: getConvertedJsonQuery(chatWithFilterQuery), visibleColumnNames: visibleColumnNames }))] })));
}
