import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PencilIcon } from "@heroicons/react/24/solid";
import RightDrawer from "../right_drawer";
import { Separator } from "./separator";
import { Button } from "./button";
const ItemDetailsDrawer = (props) => {
    const { title, details, isOpen, onClose, deleteCallback } = props;
    return (_jsxs(RightDrawer, Object.assign({ title: title, isOpen: isOpen, onClose: onClose, deleteCallback: deleteCallback }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between items-center w-full" }, { children: [_jsx("div", Object.assign({ className: "text-black text-base font-semibold" }, { children: "Details" })), _jsx("div", { children: props.editCallback && (_jsxs(Button, Object.assign({ variant: "secondary", onClick: props.editCallback, className: "mr-2 flex flex-row space-x-2" }, { children: [_jsx("span", { children: "Edit" }), _jsx(PencilIcon, { className: "h-4 w-4" })] }))) })] })), _jsx(Separator, {}), _jsx("div", Object.assign({ className: "flex flex-col space-y-4" }, { children: details.map((detail, index) => (_jsxs("div", Object.assign({ className: "flex flex-col space-y-1" }, { children: [_jsx("div", Object.assign({ className: "text-wds-gray-5 text-sm font-medium" }, { children: detail.title })), _jsx("div", Object.assign({ className: "text-wds-black text-sm font-normal leading-tight" }, { children: detail.value }))] }), index))) }))] })));
};
export default ItemDetailsDrawer;
