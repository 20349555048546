import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/VolumeSlider.tsx
import { SpeakerWaveIcon, SpeakerXMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import classNames from "./class_names";
const VolumeSlider = ({ volume, setVolume, disabled, }) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleVolumeChange = (event) => {
        setVolume(Number(event.target.value));
    };
    return (_jsxs("div", Object.assign({ className: classNames("flex relative items-center rounded-l-2xl p-1", disabled ? "" : "hover:bg-wds-blue-2"), onMouseEnter: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false) }, { children: [_jsx("button", Object.assign({ className: classNames("rounded-full focus:outline-none items-center", disabled ? "" : "hover:bg-wds-blue-2"), onClick: () => setVolume(volume === 0 ? 100 : 0), disabled: disabled }, { children: volume === 0 ? (_jsx(SpeakerXMarkIcon, { className: "stroke-wds-gray-5 text-black h-6 w-6" })) : (_jsx(SpeakerWaveIcon, { className: "stroke-wds-gray-5 text-black h-6 w-6" })) })), isHovered && !disabled && (_jsx("div", Object.assign({ className: "absolute ml-6 w-24 h-full bg-wds-blue-2 rounded-r-2xl p-2 flex items-center justify-center" }, { children: _jsx("input", { type: "range", min: "0", max: "100", value: volume, onChange: handleVolumeChange, className: "w-full h-2 bg-gray-400 rounded-lg appearance-none" }) })))] })));
};
export default VolumeSlider;
