import { jsx as _jsx } from "react/jsx-runtime";
import { ChevronDownIcon, ChevronUpIcon, ChevronLeftIcon, ChevronRightIcon, } from "@heroicons/react/24/outline";
const ChevronIcon = ({ direction, hoverCircle, staticCircle, iconCustomStyle, classNames, toggle, }) => {
    let Icon;
    switch (direction) {
        case "up":
            Icon = ChevronUpIcon;
            break;
        case "down":
            Icon = ChevronDownIcon;
            break;
        case "left":
            Icon = ChevronLeftIcon;
            break;
        case "right":
            Icon = ChevronRightIcon;
            break;
        default:
            Icon = ChevronDownIcon;
    }
    const hoverClass = hoverCircle ? "hover:bg-blue-100" : "";
    const staticCircleClass = staticCircle ? "bg-blue-100" : "";
    return (_jsx("div", Object.assign({ className: `flex justify-center items-center rounded-full cursor-pointer ${hoverClass} ${staticCircleClass}`, onClick: toggle }, { children: _jsx(Icon, { className: `w-5 h-5 p-2 box-content ${classNames}`, style: Object.assign({ color: "initial" }, iconCustomStyle) }) })));
};
export default ChevronIcon;
