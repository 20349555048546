var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as endpoints from "../common/endpoints";
import { PostCallOutputTarget } from "../types";
export const fetchSalesforceIntegrationData = () => __awaiter(void 0, void 0, void 0, function* () {
    const sfdcFields = yield endpoints.getSfdcFieldsForPostCall();
    return {
        name: PostCallOutputTarget.SALESFORCE,
        sfdcFields,
    };
});
export const fetchWiserCrmIntegrationData = () => __awaiter(void 0, void 0, void 0, function* () {
    const crmFields = yield endpoints.getCrmFields();
    return {
        name: PostCallOutputTarget.WISER_CRM,
        crmFields: {
            Account: crmFields
                .filter((field) => field.applicable_objects.includes("Account"))
                .map((field) => {
                return {
                    id: field.id,
                    label: field.name,
                    schema: field.content_type_definition,
                };
            }),
            Contact: crmFields
                .filter((field) => field.applicable_objects.includes("Contact"))
                .map((field) => {
                return {
                    id: field.id,
                    label: field.name,
                    schema: field.content_type_definition,
                };
            }),
            Opportunity: crmFields
                .filter((field) => field.applicable_objects.includes("Opportunity"))
                .map((field) => {
                return {
                    id: field.id,
                    label: field.name,
                    schema: field.content_type_definition,
                };
            }),
        },
    };
});
export const fetchHubspotIntegrationData = () => __awaiter(void 0, void 0, void 0, function* () {
    const crmFields = yield endpoints.getHubspotFieldsForPostCall();
    return {
        name: PostCallOutputTarget.HUBSPOT,
        crmFields,
    };
});
