import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import classNames from "../class_names";
import { Switch } from "@headlessui/react";
import Dropdown from "../common/dropdown";
import MultiSelectDropdown from "../common/multi_select_dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const TextFieldEditor = ({ value, postCallOutputId, onValueEdit, handleKeyDown, fieldSchema, }) => {
    const newValueTextAreaRef = useRef(null);
    useEffect(() => {
        const adjustNewValueTextareaHeight = () => {
            if (newValueTextAreaRef.current) {
                newValueTextAreaRef.current.style.height = `${Math.max(newValueTextAreaRef.current.scrollHeight, 48)}px`;
            }
        };
        adjustNewValueTextareaHeight();
    }, [value]);
    const lengthLimitExceeded = value.length > fieldSchema.restrictions.length;
    return (_jsxs("div", Object.assign({ className: "flex flex-col justify-end gap-1 grow shrink-0" }, { children: [_jsx("textarea", { ref: newValueTextAreaRef, id: `${postCallOutputId}`, name: `${postCallOutputId}`, value: value, className: classNames("grow px-2 py-1 rounded-lg w-full bg-gray-100 focus:outline-none focus:ring-0 border border-solid resize-none", lengthLimitExceeded
                    ? "border-red-500 focus:border-red-500"
                    : "border-black focus:border-black", "font-normal text-sm"), onChange: (e) => {
                    const formattedContent = e.target.value.replace(/\n/g, "\n");
                    onValueEdit(formattedContent);
                }, onKeyDown: handleKeyDown }), _jsxs("div", Object.assign({ className: classNames("flex items-end self-stretch", lengthLimitExceeded ? "justify-between" : "justify-end") }, { children: [lengthLimitExceeded && (_jsx("span", Object.assign({ className: "place-self-start text-xs font-medium text-red-500" }, { children: "Exceeded maximum characters" }))), _jsx("span", Object.assign({ className: classNames("self-end text-xs font-medium", lengthLimitExceeded ? "text-red-500" : "text-gray-500") }, { children: `${value.length}/${fieldSchema.restrictions.length}` }))] }))] })));
};
const NumberFieldEditor = ({ value, postCallOutputId, onValueEdit, handleKeyDown, fieldSchema, }) => {
    var _a;
    // Check if number of digits before the decimal point has exceeded the precision
    const precisionExceeded = value.split(".")[0].length > fieldSchema.restrictions.precision;
    const scaleExceeded = ((_a = value.split(".")[1]) === null || _a === void 0 ? void 0 : _a.length) > fieldSchema.restrictions.scale;
    return (_jsxs("div", Object.assign({ className: "flex flex-col justify-end gap-1 grow shrink-0 w-[24rem]" }, { children: [_jsx("input", { type: "number", id: `${postCallOutputId}`, name: `${postCallOutputId}`, value: value, className: classNames("px-2 py-1 rounded-lg bg-gray-100 focus:outline-none focus:ring-0 border border-solid", "resize-none", precisionExceeded || scaleExceeded
                    ? "border-red-500"
                    : "border-black"), onChange: (e) => {
                    const formattedContent = e.target.value.replace(/\n/g, "\n");
                    onValueEdit(formattedContent);
                }, onKeyDown: handleKeyDown }), _jsx("div", Object.assign({ className: "flex items-start self-stretch" }, { children: precisionExceeded ? (_jsx("span", Object.assign({ className: "place-self-start text-xs font-medium text-red-500" }, { children: "Exceeded maximum number of digits before the decimal point" }))) : scaleExceeded ? (_jsx("span", Object.assign({ className: "place-self-start text-xs font-medium text-red-500" }, { children: "Exceeded maximum number of digits after the decimal point" }))) : null }))] })));
};
const BooleanFieldEditor = ({ value, postCallOutputId, onValueEdit, }) => {
    const checked = !!value && value.toLowerCase() === "true";
    return (_jsxs("label", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx(Switch, Object.assign({ id: `${postCallOutputId}`, checked: checked, onChange: (newValue) => onValueEdit(newValue ? "true" : "false"), className: classNames(checked ? "bg-wds-blue-3" : "bg-gray-200", "relative inline-flex items-center h-6 rounded-full w-11 transition-colors") }, { children: _jsx("span", { className: `${checked ? "translate-x-6" : "translate-x-1"} inline-block w-4 h-4 transform bg-white rounded-full transition-transform` }) })), _jsx("span", Object.assign({ className: classNames(checked ? "font-bold" : "") }, { children: checked ? "True" : "False" }))] })));
};
const SingleSelectFieldEditor = ({ value, postCallOutputId, onValueEdit, handleKeyDown, fieldSchema, }) => {
    return (_jsx("div", Object.assign({ className: "flex flex-col justify-end gap-1 grow shrink-0", onKeyDown: handleKeyDown }, { children: _jsx(Dropdown, { options: fieldSchema.restrictions.picklist_values.map((option) => ({
                value: option,
                label: option,
            })), defaultSelected: value, onOptionSelect: onValueEdit, buttonClasses: "bg-white border border-solid border-gray-300 rounded-lg" }, `${postCallOutputId}`) })));
};
const MultiSelectFieldEditor = ({ value, postCallOutputId, onValueEdit, handleKeyDown, fieldSchema, }) => {
    const selectedOptions = value.split(";");
    return (_jsx("div", Object.assign({ className: "flex flex-col justify-end gap-1 grow shrink-0", onKeyDown: handleKeyDown }, { children: _jsx(MultiSelectDropdown, { options: fieldSchema.restrictions.picklist_values, selectedOptions: selectedOptions, setSelectedOptions: (selectedOptions) => onValueEdit(selectedOptions.join(";")) }, `${postCallOutputId}`) })));
};
const DateFieldEditor = ({ value, postCallOutputId, onValueEdit, }) => {
    const currentValueAsDate = value && !isNaN(parseInt(value)) ? new Date(parseInt(value)) : new Date();
    return (_jsx("div", Object.assign({ className: "flex flex-col justify-end gap-1" }, { children: _jsx(DatePicker, { id: `${postCallOutputId}`, selected: currentValueAsDate, onChange: (date) => onValueEdit(date.getTime().toString()), className: "px-2 py-1 rounded-lg bg-gray-100 focus:outline-none focus:ring-0 border border-solid resize-none font-normal text-sm" }) })));
};
const FieldValueEditor = ({ postCallOutputId, value, fieldSchema, onValueEdit, handleKeyDown, }) => {
    switch (fieldSchema.type) {
        case "Number":
        case "Currency":
            return (_jsx(NumberFieldEditor, { value: value, postCallOutputId: postCallOutputId, onValueEdit: onValueEdit, handleKeyDown: handleKeyDown, fieldSchema: fieldSchema }));
        case "Boolean":
            return (_jsx(BooleanFieldEditor, { value: value, postCallOutputId: postCallOutputId, onValueEdit: onValueEdit }));
        case "Single select":
            return (_jsx(SingleSelectFieldEditor, { value: value, postCallOutputId: postCallOutputId, onValueEdit: onValueEdit, handleKeyDown: handleKeyDown, fieldSchema: fieldSchema }));
        case "Multi select":
            return (_jsx(MultiSelectFieldEditor, { value: value, postCallOutputId: postCallOutputId, onValueEdit: onValueEdit, handleKeyDown: handleKeyDown, fieldSchema: fieldSchema }));
        case "Date":
            return (_jsx(DateFieldEditor, { value: value, postCallOutputId: postCallOutputId, onValueEdit: onValueEdit }));
        default:
            return (_jsx(TextFieldEditor, { value: value, postCallOutputId: postCallOutputId, onValueEdit: onValueEdit, handleKeyDown: handleKeyDown, fieldSchema: fieldSchema }));
    }
};
export default FieldValueEditor;
