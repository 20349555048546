import LogRocket from "logrocket";
export const initLogrocket = () => {
    const loggedInEmail = localStorage.getItem("user_email");
    if (!loggedInEmail) {
        return;
    }
    const userIdentity = {
        email: loggedInEmail,
        tenantId: Number(localStorage.getItem("home_tenant_id")),
    };
    const host = window.location.host;
    if (host === "app.getwiser.io") {
        LogRocket.init("i8zpzc/wiser-prod");
        LogRocket.identify(loggedInEmail, userIdentity);
    }
};
