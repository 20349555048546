import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// This file defines an EditTrigger component, which gives a text box and a list of current triggers.
// It can be used for both KeywordTrigger and VerbalTrigger. Note that these items are currently
// immutable and associated with a single playbook item -- they are too simple to be worth sharing
// and easier to just delete/recreate than to add the complexity of changing text and retaining the
// same id.
import { useEffect, useRef, useState } from "react";
import { getCookie } from "./cookies";
import axios from "axios";
import { showErrorModal } from "./error_modal";
import classNames from "./class_names";
export default function EditTrigger(props) {
    const [newText, setNewText] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [rejectedSuggestions, setRejectedSuggestions] = useState([]);
    const currentIntentIdRef = useRef(props.intentId);
    useEffect(() => {
        currentIntentIdRef.current = props.intentId;
    }, [props.intentId]);
    // NOTE: I deliberated for a while on where to put these RPC's and where to store the actual list.
    // I landed on RPC's in here, but state stored above, because this achieves:
    // 1) Reusability of this component, both for questions and keywords, but also across creation and edit pages.
    // 2) Ability to seed the trigger list from outside, so that one RPC can get all our keywords, questions, goal
    //    text, etc; otherwise, we might end up doing one RPC per component (gross, slow, visible latency)
    // 3) One source of truth; if the parent is doing an initial fetch, we shouldn't keep a second copy of the
    //    trigger list in here.
    let submitTrigger = (text) => {
        axios
            .post(props.add_trigger_url, {
            intent_id: props.intentId,
            text: text,
        }, {
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((res) => {
            let triggerId = res.data.id;
            props.setTriggers(props.triggers.concat([{ text: text, id: triggerId }]));
        })
            .catch((err) => {
            showErrorModal(err);
        });
    };
    let addTrigger = () => {
        submitTrigger(newText);
        setNewText("");
    };
    let removeTrigger = (id) => {
        axios
            .post(props.remove_trigger_url, {
            id: id,
        }, {
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((res) => {
            props.setTriggers(props.triggers.filter((trigger) => trigger.id != id));
        })
            .catch((err) => {
            showErrorModal(err);
        });
    };
    let getSuggestions = () => {
        if (!props.suggest_url)
            return;
        if (props.intentId <= 0)
            return;
        axios
            .post(props.suggest_url, {
            intent_id: props.intentId,
            rejected_suggestions: rejectedSuggestions,
        }, {
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((res) => {
            // These can be slow RPC's, so we need to avoid stale updates.
            if (currentIntentIdRef.current != props.intentId)
                return;
            setSuggestions(res.data.suggestions);
        });
    };
    useEffect(() => {
        if (props.disabled)
            return;
        getSuggestions();
    }, []);
    useEffect(() => {
        if (props.disabled)
            return;
        getSuggestions();
    }, [rejectedSuggestions]);
    let rejectSuggestion = (suggestion) => {
        setRejectedSuggestions(rejectedSuggestions.concat([suggestion]));
        setSuggestions(suggestions.filter((s) => s != suggestion));
    };
    let acceptSuggestion = (suggestion) => {
        submitTrigger(suggestion);
        setSuggestions(suggestions.filter((s) => s != suggestion));
    };
    const keyDownHandler = (e) => {
        if (e.key == "Enter") {
            addTrigger();
        }
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx("label", Object.assign({ htmlFor: props.title, className: "block text-sm font-medium leading-6 text-gray-900" }, { children: props.title })), _jsxs("div", Object.assign({ className: "flex flex-row mt-2" }, { children: [_jsx("input", { id: props.title, name: props.title, type: "text", value: newText, onKeyDown: keyDownHandler, onChange: (e) => setNewText(e.target.value), className: "flex block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6", placeholder: props.placeholder, disabled: props.disabled }), _jsx("button", Object.assign({ type: "button", onClick: addTrigger, disabled: props.disabled, className: classNames("flex inline-flex w-24 justify-center rounded-md ml-2 px-3 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2", props.disabled
                            ? "bg-wds-gray-4"
                            : "bg-indigo-600 hover:bg-indigo-500 shadow-sm") }, { children: "Add" }))] })), _jsxs("div", Object.assign({ className: "mt-2" }, { children: [suggestions.map((suggestion, index) => (_jsx("div", Object.assign({ className: "inline-flex p-1" }, { children: _jsxs("span", Object.assign({ className: "inline-flex items-center gap-x-0.5 rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700" }, { children: [_jsx("b", Object.assign({ className: "mr-1" }, { children: "SUGGESTION:" })), suggestion, _jsx("button", Object.assign({ type: "button", className: "group relative ml-2 h-5 w-6 rounded-sm hover:bg-yellow-600/20", onClick: () => {
                                        acceptSuggestion(suggestion);
                                    } }, { children: "Add" })), _jsxs("button", Object.assign({ type: "button", className: "group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-yellow-600/20", onClick: () => {
                                        rejectSuggestion(suggestion);
                                    } }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Remove" })), _jsx("svg", Object.assign({ viewBox: "0 0 14 14", className: "h-3.5 w-3.5 stroke-yellow-800/50 group-hover:stroke-yellow-800/75" }, { children: _jsx("path", { d: "M4 4l6 6m0-6l-6 6" }) })), _jsx("span", { className: "absolute -inset-1" })] }))] })) }), suggestion))), props.triggers.map((trigger, index) => (_jsx("div", Object.assign({ className: "inline-flex p-1" }, { children: _jsxs("span", Object.assign({ className: "inline-flex items-center gap-x-0.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700" }, { children: [trigger.text, _jsxs("button", Object.assign({ type: "button", className: "group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-blue-600/20", onClick: () => removeTrigger(trigger.id) }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Remove" })), _jsx("svg", Object.assign({ viewBox: "0 0 14 14", className: "h-3.5 w-3.5 stroke-blue-800/50 group-hover:stroke-blue-800/75" }, { children: _jsx("path", { d: "M4 4l6 6m0-6l-6 6" }) })), _jsx("span", { className: "absolute -inset-1" })] }))] })) }), trigger.id)))] }))] })));
}
