import { useEffect, useRef } from "react";
/**
 * A custom hook that sets up an IntersectionObserver to call a callback function
 * when the observed element comes into view.
 *
 * @param {() => void} callback - The function to call when the observed element is in view.
 * @param {IntersectionObserverInit} [options] - Optional settings for the IntersectionObserver.
 * @returns {MutableRefObject<HTMLDivElement | null>} - A ref to be attached to the element to be observed.
 *
 * @example
 * // In a component:
 * const loadMoreRef = useIntersectionObserver(
 *   () => {
 *     if (hasNextPage) fetchNextPage();
 *   },
 *   { threshold: 1.0 }
 * );
 *
 * return (
 *   <div>
 *     {data.map(item => (
 *       <div key={item.id}>{item.name}</div>
 *     ))}
 *     <div ref={loadMoreRef} style={{ height: '1px' }} />
 *   </div>
 * );
 */
const useIntersectionObserver = (callback, options) => {
    const observerRef = useRef(null);
    const elementRef = useRef(null);
    useEffect(() => {
        observerRef.current = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                callback();
            }
        }, options);
        if (elementRef.current) {
            observerRef.current.observe(elementRef.current);
        }
        return () => {
            if (observerRef.current && elementRef.current) {
                observerRef.current.unobserve(elementRef.current);
            }
        };
    }, [callback, options]);
    return elementRef;
};
export default useIntersectionObserver;
