import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useId } from "react";
const WiserSparklesIcon = (props) => {
    const id = useId();
    const gradientId0 = `paint0_radial_${id}`;
    const gradientId1 = `paint1_radial_${id}`;
    if (props.disabled) {
        return (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", fill: "none" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.00156 1.60001C4.44339 1.60001 4.80156 1.95818 4.80156 2.40001V3.20001H5.60156C6.04339 3.20001 6.40156 3.55818 6.40156 4.00001C6.40156 4.44183 6.04339 4.80001 5.60156 4.80001H4.80156V5.60001C4.80156 6.04183 4.44339 6.40001 4.00156 6.40001C3.55973 6.40001 3.20156 6.04183 3.20156 5.60001V4.80001H2.40156C1.95973 4.80001 1.60156 4.44183 1.60156 4.00001C1.60156 3.55818 1.95973 3.20001 2.40156 3.20001H3.20156V2.40001C3.20156 1.95818 3.55973 1.60001 4.00156 1.60001ZM4.00156 9.60001C4.44339 9.60001 4.80156 9.95818 4.80156 10.4V11.2H5.60156C6.04339 11.2 6.40156 11.5582 6.40156 12C6.40156 12.4418 6.04339 12.8 5.60156 12.8H4.80156V13.6C4.80156 14.0418 4.44339 14.4 4.00156 14.4C3.55973 14.4 3.20156 14.0418 3.20156 13.6V12.8H2.40156C1.95973 12.8 1.60156 12.4418 1.60156 12C1.60156 11.5582 1.95973 11.2 2.40156 11.2H3.20156V10.4C3.20156 9.95818 3.55973 9.60001 4.00156 9.60001Z", fill: "#B1B5BA" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.60152 1.60001C9.96451 1.60001 10.282 1.84439 10.3749 2.19529L11.3183 5.75915L14.0013 7.30706C14.2489 7.44993 14.4015 7.7141 14.4015 8.00001C14.4015 8.28591 14.2489 8.55008 14.0013 8.69295L11.3183 10.2409L10.3749 13.8047C10.282 14.1556 9.96451 14.4 9.60152 14.4C9.23853 14.4 8.92104 14.1556 8.82816 13.8047L7.88478 10.2409L5.20178 8.69295C4.95414 8.55008 4.80156 8.28591 4.80156 8.00001C4.80156 7.7141 4.95414 7.44993 5.20178 7.30706L7.88478 5.75915L8.82816 2.19529C8.92104 1.84439 9.23853 1.60001 9.60152 1.60001Z", fill: "#B1B5BA" })] })));
    }
    return (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5 2C5.55228 2 6 2.44772 6 3V4H7C7.55228 4 8 4.44772 8 5C8 5.55228 7.55228 6 7 6H6V7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7V6H3C2.44772 6 2 5.55228 2 5C2 4.44772 2.44772 4 3 4H4V3C4 2.44772 4.44772 2 5 2ZM5 12C5.55228 12 6 12.4477 6 13V14H7C7.55228 14 8 14.4477 8 15C8 15.5523 7.55228 16 7 16H6V17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17V16H3C2.44772 16 2 15.5523 2 15C2 14.4477 2.44772 14 3 14H4V13C4 12.4477 4.44772 12 5 12Z", fill: `url(#${gradientId0})` }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.9999 2C12.4537 2 12.8505 2.30548 12.9667 2.74411L14.1459 7.19893L17.4997 9.13381C17.8092 9.31241 17.9999 9.64262 17.9999 10C17.9999 10.3574 17.8092 10.6876 17.4997 10.8662L14.1459 12.8011L12.9667 17.2559C12.8505 17.6945 12.4537 18 11.9999 18C11.5462 18 11.1493 17.6945 11.0332 17.2559L9.85402 12.8011L6.50027 10.8662C6.19072 10.6876 6 10.3574 6 10C6 9.64262 6.19072 9.31241 6.50027 9.13382L9.85402 7.19893L11.0332 2.74411C11.1493 2.30548 11.5462 2 11.9999 2Z", fill: `url(#${gradientId1})` }), _jsxs("defs", { children: [_jsxs("radialGradient", Object.assign({ id: gradientId0, cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(17.9999 2) rotate(135) scale(22.6274)" }, { children: [_jsx("stop", { stopColor: "#C026D3" }), _jsx("stop", { offset: "1", stopColor: "#0171F5" })] })), _jsxs("radialGradient", Object.assign({ id: gradientId1, cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(17.9999 2) rotate(135) scale(22.6274)" }, { children: [_jsx("stop", { stopColor: "#C026D3" }), _jsx("stop", { offset: "1", stopColor: "#0171F5" })] }))] })] })));
};
export default WiserSparklesIcon;
