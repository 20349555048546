import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ size: [] }],
        [{ font: [] }],
        [{ align: ["right", "center", "justify"] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        [{ color: ["red", "#785412"] }],
        [{ background: ["red", "#785412"] }],
    ],
};
/**
 * LiveCardTextEditor is a React component that initializes and renders a Quill text editor.
 *
 * Props:
 * - content: Initial HTML content to populate the editor.
 * - setContent: Callback function to update parent component with the editor's content.
 *
 * The component uses refs to maintain direct references to both the Quill instance and its DOM container.
 * When the component mounts, it creates a new Quill instance, sets its initial content, and establishes
 * an event listener for changes in the editor content. The provided setContent function is called upon any
 * content change. On component unmount, event listeners are removed for cleanup.
 */
export default function LiveCardTextEditor(props) {
    const quillRef = useRef(null);
    const quillContainerRef = useRef(null);
    useEffect(() => {
        if (quillContainerRef.current) {
            quillRef.current = new Quill(quillContainerRef.current, {
                theme: "snow",
                modules: modules,
            });
            quillRef.current.clipboard.dangerouslyPasteHTML(
            // props.content is coming from our backend & should be already pretty safe, we sanitize it just to be extra safe
            DOMPurify.sanitize(props.content.replace(/<br\s*\/?>/gi, "")));
            const handleChange = () => {
                if (quillRef.current) {
                    const html = quillRef.current.root.innerHTML;
                    props.setContent(html);
                }
            };
            quillRef.current.on("text-change", handleChange);
            return () => {
                if (quillRef.current) {
                    quillRef.current.off("text-change", handleChange);
                    quillRef.current = null;
                }
            };
        }
    }, []);
    useEffect(() => {
        if (quillRef.current) {
            // We must always compare dom-purified to dom-purified content,
            // otherwise we will get false positives and the cursor will reset
            // to the beginning while editing.
            const current_html = DOMPurify.sanitize(quillRef.current.root.innerHTML.replace(/<br\s*\/?>/gi, ""));
            const new_html = DOMPurify.sanitize(props.content.replace(/<br\s*\/?>/gi, ""));
            // This is necessary as every time the content is edited, it will trigger an update,
            // which moves the cursor to the beginning.
            if (current_html === new_html) {
                return;
            }
            quillRef.current.clipboard.dangerouslyPasteHTML(new_html);
        }
    }, [props.content]);
    return _jsx("div", { ref: quillContainerRef });
}
