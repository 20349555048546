var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createColumnHelper } from "@tanstack/react-table";
import { useDataTable } from "../table_hooks/use_data_table";
import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import CrmFieldValue from "./crm_field_value";
import { DataTable } from "../components/data_table";
import { DataTableToolbarV2 } from "../components/data_table_toolbar_v2";
import { getFilterFieldType, getQueryFieldForCrmField, } from "./common/crm_field_utils";
import { WiserBackendQueryConverter } from "../common/query_builder/json_query_converter";
import { WiserBackendSortRuleConverter } from "../common/sort_rule_builder/backend_order_by_clause_converter";
import { CrmNewRow } from "./crm_new_row";
import { useFilterChange, useInitialFilter } from "../table_hooks/use_filters";
import { useInitialSortRules, useSortRulesChange, } from "../table_hooks/use_sort";
import { getColumnOrder, useMatchingLayout, } from "../components/data_table_toolbar_hooks";
export const CrmTableV2 = (crmTableProps) => {
    const searchParams = crmTableProps.searchParams;
    const navigate = useNavigate();
    const columnHelper = createColumnHelper();
    // Memoizing the column defs to avoid re-rendering
    const columnDefs = useMemo(() => {
        var _a;
        return [
            ...crmTableProps.defaultColumns,
            ...((_a = crmTableProps.crmFields) !== null && _a !== void 0 ? _a : [])
                .filter((field) => field.applicable_objects.includes(crmTableProps.crmObject))
                .sort((a, _) => (a.nullable ? 1 : -1))
                .map((field) => columnHelper.accessor((row) => {
                var _a, _b;
                return (_b = (_a = row.crm_data.find((crmFieldValue) => crmFieldValue.crm_field.id === field.id)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : "";
            }, {
                id: `${field.id}`,
                header: () => _jsx("span", Object.assign({ className: "w-max" }, { children: field.name })),
                meta: {
                    fieldId: field.id,
                    fieldSchema: field.content_type_definition,
                    type: field.content_type_definition.type,
                    columnInfo: {
                        columnType: field.column_type,
                        sourcePlaybookItemId: field.source_playbook_item_id,
                    },
                    required: !field.nullable,
                },
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: field.id, crmFieldSchema: field.content_type_definition, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                            // TODO: Render a updation spinner in the UI.
                            yield crmTableProps.onCrmFieldValueChange(parseInt(props.row.id), field, newValue);
                        }), crmFieldValueStatus: 
                        // For CRM fields, the status is a no-op since we render the CRM value only.
                        // For enrichment columns, we want to render the computation status as some
                        // background job might still be processing it.
                        field.column_type === "ENRICHMENT"
                            ? (_a = props.row.original.crm_data.find((crmFieldValue) => crmFieldValue.crm_field.id === field.id)) === null || _a === void 0 ? void 0 : _a.status
                            : undefined }));
                },
            })),
        ];
    }, [crmTableProps.defaultColumns, crmTableProps.crmFields]);
    const matchingLayout = useMatchingLayout(crmTableProps.layouts, crmTableProps.searchParams);
    const [columnOrder, setColumnOrder] = useState(getColumnOrder(columnDefs, matchingLayout));
    const filterFields = useMemo(() => {
        var _a;
        return [
            ...crmTableProps.defaultFilterFields,
            ...((_a = crmTableProps.crmFields) !== null && _a !== void 0 ? _a : [])
                .filter((field) => field.applicable_objects.includes(crmTableProps.crmObject))
                .map((crmField) => {
                return {
                    id: `${crmField.id}`,
                    name: crmField.name,
                    type: getFilterFieldType(crmField.content_type_definition),
                    meta: {
                        model: `Crm${crmTableProps.crmObject}FieldValue`,
                        relatedModelFieldName: `${crmTableProps.crmObject.toLowerCase()}_id`,
                        proxyFieldKey: "crm_field_id",
                        proxyFieldValue: getQueryFieldForCrmField(crmField.content_type_definition),
                    },
                };
            }),
        ];
    }, [crmTableProps.defaultFilterFields, crmTableProps.crmFields]);
    const filterConfig = useMemo(() => {
        return {
            fields: filterFields,
            maxSubConditions: 5,
            referenceObjectFetcherMap: crmTableProps.referenceObjectFetcherMap,
        };
    }, [filterFields]);
    const sortRuleConfig = useMemo(() => {
        return {
            // Reference fields are not sortable by default.
            fields: filterFields.filter((field) => field.type !== "reference"),
            maxConditions: 3,
        };
    }, [filterFields]);
    const wiserBackendQueryConverter = useMemo(() => {
        return new WiserBackendQueryConverter(filterConfig);
    }, [filterConfig]);
    const wiserBackendSortRuleConverter = useMemo(() => {
        return new WiserBackendSortRuleConverter(sortRuleConfig);
    }, [sortRuleConfig]);
    const initialFilter = useInitialFilter(searchParams, wiserBackendQueryConverter);
    const initialSortRules = useInitialSortRules(searchParams, wiserBackendSortRuleConverter);
    const onFilterChange = useFilterChange(navigate, searchParams);
    const onSortRulesChange = useSortRulesChange(navigate, searchParams);
    const { table } = useDataTable({
        data: crmTableProps.tableRows,
        columns: columnDefs,
        initialState: {
            columnPinning: { left: ["row-select", "name"] },
            columnVisibility: Object.assign(Object.assign({}, Object.fromEntries(columnDefs.map((col) => [col.id, false]))), Object.fromEntries(Object.keys(crmTableProps.defaultTableLayout.columns).map((col) => [
                col,
                true,
            ]))),
        },
        columnOrder,
        onColumnOrderChange: setColumnOrder,
        enableAdvancedFilter: true,
        enableAdvancedSorting: true,
        // For remembering the previous row selection on page change
        getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    });
    const columnIdMap = useMemo(() => {
        return crmTableProps.crmFields.reduce((acc, crmField) => {
            acc[crmField.id] = crmField.name;
            return acc;
        }, {});
    }, [crmTableProps.crmFields]);
    const tableFooter = () => {
        if (!table
            .getAllFlatColumns()
            .filter((col) => { var _a; return !!((_a = col.columnDef.meta) === null || _a === void 0 ? void 0 : _a.required); })
            .every((col) => col.getIsVisible())) {
            return null;
        }
        return (_jsx(CrmNewRow, { crmTableInfo: {
                crmObject: crmTableProps.crmObject,
                columns: table.getVisibleFlatColumns(),
                tableState: table.getState(),
                columnObjectsFetcher: crmTableProps.columnObjectsFetcher,
            }, onNewRowCreate: crmTableProps.onNewRowCreate }));
    };
    return (_jsx("div", Object.assign({ className: "gap-2 p-4 bg-wds-gray-1 h-screen" }, { children: _jsx(DataTable, Object.assign({ table: table, footer: tableFooter(), columnOrder: columnOrder, setColumnOrder: setColumnOrder, fetchNextPage: crmTableProps.fetchNextPage, total: crmTableProps.totalRows, totalFetched: crmTableProps.tableRows.length, isFetching: crmTableProps.isFetching }, { children: _jsx(DataTableToolbarV2, { table: table, columnIdMap: columnIdMap, filterConfig: filterConfig, filterPanelTitle: `Show all ${crmTableProps.crmObject}`, onFiltersChange: onFilterChange, initialFilters: initialFilter, sortRuleConfig: sortRuleConfig, sortRuleBuilderPanelTitle: `Sort ${crmTableProps.crmObject} where`, onSortRulesChange: onSortRulesChange, initialSortRules: initialSortRules, layouts: crmTableProps.layouts, defaultTableLayout: crmTableProps.defaultTableLayout, showSearchBar: true, onSearchQueryChange: crmTableProps.onSearchQueryChange }) })) })));
};
