import tw from "tailwind-styled-components";
export const AccountBlockCard = tw.div `
    flex
    p-6
    flex-col
    items-start
    gap-6
    self-stretch
    rounded-2xl
    border
    border-wds-gray-3
    bg-white
    scroll-mt-4
    w-full
`;
