import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import { Button } from "../../components/button";
import { ChevronDownIcon, ChevronLeftIcon, } from "@heroicons/react/24/outline";
import { getCrmAccountDescription, getCrmAccountName, } from "../../common/call_account_utils";
import Dropdown from "../../common/dropdown";
import { Separator } from "../../components/separator";
import { useState } from "react";
import AddBriefingBlockModal from "../../pre_call_prep/add_briefing_block_modal";
import { CreateOrEditAccountResearchBlockModal } from "./create_or_edit_account_research_block_modal";
import { useQuery } from "react-query";
import { getAccountDescription, getAppIntegrationData, } from "../../common/endpoints";
import { getConnectedCrmLogo } from "../../common/crm_logo";
import { getExternalCrmObjectLink } from "../common/external_crm_link";
const getCompanyLogoFromWebsiteUrl = (websiteUrl) => {
    /**
     * A little trick to get the logo of any company from their website URL.
     * Note the https:// is added to the URL if it is not present because it only works with https:// URLs.
     */
    if (!websiteUrl.startsWith("http")) {
        if (websiteUrl.startsWith("www.")) {
            websiteUrl = `https://${websiteUrl}`;
        }
        else {
            websiteUrl = `https://www.${websiteUrl}`;
        }
    }
    return `https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${websiteUrl}&size=128`;
};
export const AccountPageHeader = ({ tenant, account, accountBlocksToRender, scrollToBlockHandler, allResearchBlocks, existingResearchBlockIds, createNewResearchBlock, updateResearchBlock, generateResearchBlockOutput, }) => {
    const [addBlockOpen, setAddBlockOpen] = useState(false);
    const [createBlockOpen, setCreateBlockOpen] = useState(false);
    const { data: llmBasedAccountDescription, isFetching: accountDescriptionFetching, } = useQuery({
        queryKey: ["accountDescription", account.id],
        queryFn: () => getAccountDescription(account.id),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const { data: appIntegrationData } = useQuery({
        queryKey: ["appIntegrationData", tenant.id],
        queryFn: getAppIntegrationData,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const AccountLinks = () => {
        return (_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [account.linkedin_profile_url &&
                    account.linkedin_profile_url.length > 0 && (_jsx("div", Object.assign({ className: "flex w-10 h-10 p-2 items-center justify-center shrink-0" }, { children: _jsx("a", Object.assign({ href: account.linkedin_profile_url, target: "_blank", rel: "noopener noreferrer", className: "inline-block" }, { children: _jsx("img", { className: "h-5 w-5", src: "https://storage.googleapis.com/wiser-ai-public2/linkedin-logo-enable.png" }) })) }))), tenant.connected_crm && account.external_id && (_jsx("div", Object.assign({ className: "flex w-10 h-10 p-2 items-center justify-center shrink-0" }, { children: _jsx("a", Object.assign({ href: getExternalCrmObjectLink(tenant.connected_crm, appIntegrationData !== null && appIntegrationData !== void 0 ? appIntegrationData : [], "Account", account.external_id), target: "_blank", rel: "noopener noreferrer", className: "inline-block" }, { children: _jsx("img", { className: "h-5 w-5", src: getConnectedCrmLogo(tenant.connected_crm) }) })) })))] })));
    };
    const AccountOverview = ({ setAddBlockOpen, }) => {
        var _a;
        // account.profile_pic_url comes from Apollo API, it may be empty
        const companyLogoUrl = account.profile_pic_url
            ? account.profile_pic_url
            : getCompanyLogoFromWebsiteUrl(account.website);
        return (_jsx("div", Object.assign({ className: "flex flex-col items-start justify-center py-4 px-7 gap-2 self-stretch" }, { children: _jsxs("div", Object.assign({ className: "flex items-start gap-12 self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex items-start gap-6 grow" }, { children: [_jsx("img", { className: "w-16 h-16 rounded-lg shrink-0", src: companyLogoUrl }), _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-2 self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-3xl" }, { children: getCrmAccountName(account) })), accountDescriptionFetching ? (_jsx("span", Object.assign({ className: "text-wds-gray-4 animate-pulse" }, { children: "Generating account description" }))) : (_jsx("span", Object.assign({ className: "line-clamp-4" }, { children: llmBasedAccountDescription &&
                                                    llmBasedAccountDescription.description.length > 0
                                                    ? llmBasedAccountDescription.description
                                                    : (_a = getCrmAccountDescription(account)) !== null && _a !== void 0 ? _a : "No description found" })))] })), _jsx(AccountBlocksDropdown, {})] }))] })), _jsxs(Button, Object.assign({ variant: "outline", onClick: () => setAddBlockOpen(true), className: "flex items-center justify-center gap-1" }, { children: ["Add block", _jsx(ChevronDownIcon, { className: "h-4 w-4 shrink-0" })] }))] })) })));
    };
    const AccountBlocksDropdown = () => {
        return (_jsxs("div", Object.assign({ className: "flex items-center justify-center gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-sm text-wds-gray-5 font-medium shrink-0" }, { children: "Jump to" })), _jsx(Dropdown, { options: accountBlocksToRender.map((block) => {
                        return {
                            value: block.id,
                            label: block.label,
                        };
                    }), defaultSelected: "account-overview", onOptionSelect: (option) => {
                        scrollToBlockHandler(option);
                    }, buttonClasses: "h-8 bg-white rounded-lg border border-wds-gray-3" })] })));
    };
    return (_jsxs("div", Object.assign({ className: "sticky z-[2] top-0 flex flex-col items-start self-stretch border border-wds-gray-3 rounded-lg bg-white" }, { children: [_jsxs("div", Object.assign({ className: "flex px-3 py-1 justify-between items-center self-stretch" }, { children: [_jsx(Link, Object.assign({ to: "/crm/home/accounts" }, { children: _jsxs(Button, Object.assign({ variant: "link", className: "flex gap-2 px-3 items-center h-8" }, { children: [_jsx(ChevronLeftIcon, { className: "w-4 h-4" }), "All accounts"] })) })), _jsx(AccountLinks, {})] })), _jsx(Separator, {}), _jsx(AccountOverview, { setAddBlockOpen: setAddBlockOpen }), addBlockOpen && (_jsx(AddBriefingBlockModal, { researchBlockType: "ACCOUNT", open: addBlockOpen, onClose: () => setAddBlockOpen(false), onAddBriefingBlocks: (blockIds) => {
                    blockIds.forEach((blockId) => generateResearchBlockOutput(account.id, blockId, 
                    /* forceRefresh= */ true));
                    setAddBlockOpen(false);
                }, allBlocks: allResearchBlocks, existingBlockIds: existingResearchBlockIds, createCallback: () => {
                    setAddBlockOpen(false);
                    setCreateBlockOpen(true);
                }, linkToBlock: false })), createBlockOpen && (_jsx(CreateOrEditAccountResearchBlockModal, { open: createBlockOpen, onClose: () => {
                    setCreateBlockOpen(false);
                    setAddBlockOpen(true);
                }, accountResearchBlock: null, createNewResearchBlock: createNewResearchBlock, updateResearchBlock: updateResearchBlock }))] })));
};
