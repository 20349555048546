export const convertSearchParamsToBackendOrderBy = (searchParams) => {
    if (!searchParams || !searchParams.get("order_by")) {
        return undefined;
    }
    const orderByClauses = searchParams.get("order_by");
    return JSON.parse(orderByClauses);
};
export const convertBackendOrderByClausesToSearchParams = (backendOrderByClauses) => {
    if (backendOrderByClauses.length === 0) {
        return undefined;
    }
    return JSON.stringify(backendOrderByClauses);
};
