import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { flexRender } from "@tanstack/react-table";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
import { Checkbox } from "../components/checkbox";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../components/tooltip";
import { CheckCircleIcon, ChevronRightIcon, XCircleIcon, } from "@heroicons/react/24/outline";
import { Button } from "../components/button";
export const addSearchParamsToQuery = (searchParams, tableName, debouncedQuery) => {
    const copySearchParams = new URLSearchParams(searchParams.toString());
    let filter = copySearchParams.has("filter")
        ? JSON.parse(copySearchParams.get("filter"))
        : undefined;
    let order_by = copySearchParams.has("order_by")
        ? JSON.parse(copySearchParams.get("order_by"))
        : undefined;
    let json_query = {
        table: tableName,
    };
    if (filter) {
        json_query.structured_filter = filter;
    }
    if (order_by) {
        json_query.order_by_v2 = order_by;
    }
    if (debouncedQuery) {
        json_query.structured_filter = {
            where_condition: {
                operator: WiserBackendQueryOperator.AND,
                subconditions: [
                    {
                        where_condition: {
                            operator: WiserBackendQueryOperator.OR,
                            subconditions: [
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "name",
                                        value: debouncedQuery,
                                    },
                                },
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "email",
                                        value: debouncedQuery,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        };
        if (filter) {
            json_query.structured_filter.where_condition.subconditions.push(filter);
        }
    }
    return JSON.stringify(json_query);
};
export function getColumns(onRowClick) {
    const renderBoolean = (value, yesText = "Yes", noText = "No") => {
        return (_jsxs("div", Object.assign({ className: "flex w-[6.25rem] items-center" }, { children: [value && (_jsxs(_Fragment, { children: [_jsx(CheckCircleIcon, { className: "mr-2 h-4 w-4 text-muted-foreground", "aria-hidden": "true" }), _jsx("span", Object.assign({ className: "capitalize" }, { children: yesText }))] })), !value && (_jsxs(_Fragment, { children: [_jsx(XCircleIcon, { className: "mr-2 h-4 w-4 text-muted-foreground", "aria-hidden": "true" }), _jsx("span", Object.assign({ className: "capitalize" }, { children: noText }))] }))] })));
    };
    return [
        {
            id: "select",
            header: ({ table }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                        (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
            cell: ({ row }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }) }))),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.id,
        },
        {
            id: "name",
            accessorKey: "name",
            header: "Name",
            cell: ({ row }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: row.original.name })) })), _jsx(TooltipContent, { children: row.original.name })] }) })),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.name,
        },
        {
            id: "email",
            accessorKey: "email",
            header: "Email",
            cell: ({ row, cell }) => flexRender(row.original.email, cell.getContext()),
            accessorFn: (row) => row.email,
        },
        {
            id: "is_active",
            accessorKey: "is_active",
            header: "Active",
            cell: ({ row }) => renderBoolean(row.original.is_active, "Active", "Inactive"),
            accessorFn: (row) => row.is_active,
        },
        {
            id: "is_tenant_admin",
            accessorKey: "is_tenant_admin",
            header: "Company admin",
            cell: ({ row }) => renderBoolean(row.original.is_tenant_admin),
            accessorFn: (row) => row.is_tenant_admin,
        },
        {
            id: "precall_notification_duration",
            accessorKey: "precall_notification_duration",
            header: "Pre-call notification duration",
            cell: ({ row, cell }) => flexRender(row.original.precall_notification_duration, cell.getContext()),
            accessorFn: (row) => row.precall_notification_duration,
        },
        {
            id: "precall_email_enabled",
            accessorKey: "precall_email_enabled",
            header: "Pre-call email enabled",
            cell: ({ row }) => renderBoolean(row.original.precall_email_enabled),
            accessorFn: (row) => row.precall_email_enabled,
        },
        {
            id: "precall_slack_enabled",
            accessorKey: "precall_slack_enabled",
            header: "Pre-call slack enabled",
            cell: ({ row }) => renderBoolean(row.original.precall_slack_enabled),
            accessorFn: (row) => row.precall_slack_enabled,
        },
        {
            id: "autojoin_external_meetings",
            accessorKey: "autojoin_external_meetings",
            header: "Autojoin External meetings",
            cell: ({ row }) => renderBoolean(row.original.autojoin_external_meetings),
            accessorFn: (row) => row.autojoin_external_meetings,
        },
        {
            id: "autojoin_internal_meetings",
            accessorKey: "autojoin_internal_meetings",
            header: "Autojoin Internal meetings",
            cell: ({ row }) => renderBoolean(row.original.autojoin_internal_meetings),
            accessorFn: (row) => row.autojoin_internal_meetings,
        },
        {
            id: "custom_bot_name",
            accessorKey: "custom_bot_name",
            header: "Bot name",
            cell: ({ row, cell }) => flexRender(row.original.custom_bot_name, cell.getContext()),
        },
        {
            id: "no_live_bot",
            accessorKey: "no_live_bot",
            header: "No live bot",
            cell: ({ row }) => renderBoolean(row.original.no_live_bot),
            accessorFn: (row) => row.no_live_bot,
        },
        {
            id: "timezone",
            accessorKey: "timezone",
            header: "Timezone",
            cell: ({ cell, row }) => flexRender(row.original.timezone, cell.getContext()),
        },
        {
            id: "manager",
            accessorKey: "manager",
            header: "Manager",
            cell: ({ cell, row }) => flexRender(row.original.manager, cell.getContext()),
        },
        {
            id: "team",
            accessorKey: "Team",
            header: "Team",
            cell: ({ cell, row }) => flexRender(row.original.team, cell.getContext()),
        },
        {
            id: "last_login_at",
            accessorKey: "last_login_at",
            header: "Last login at",
            cell: ({ cell, row }) => flexRender(row.original.last_login_at !== "Never"
                ? new Date(row.original.last_login_at).toLocaleString()
                : "Never", cell.getContext()),
        },
        {
            id: "total_meetings",
            accessorKey: "total_meetings",
            header: "Total meetings",
            cell: ({ row, cell }) => flexRender(row.original.total_meetings, cell.getContext()),
        },
        {
            id: "total_internal_meetings",
            accessorKey: "total_internal_meetings",
            header: "Total internal meetings",
            cell: ({ row, cell }) => flexRender(row.original.total_internal_meetings, cell.getContext()),
        },
        {
            id: "total_external_meetings",
            accessorKey: "total_external_meetings",
            header: "Total external meetings",
            cell: ({ row, cell }) => flexRender(row.original.total_external_meetings, cell.getContext()),
        },
        {
            id: "actions",
            accessorKey: "actions",
            header: "Update user",
            cell: ({ row }) => (_jsx(Button, Object.assign({ variant: "link", size: "icon", onClick: () => onRowClick(row.original) }, { children: _jsx(ChevronRightIcon, { className: "w-5 h-5" }) }))),
        },
    ];
}
export const FIELDS = [
    {
        id: "name",
        name: "Name",
        type: "string",
    },
    {
        id: "email",
        name: "Email",
        type: "string",
    },
    {
        id: "is_active",
        name: "Active",
        type: "boolean",
    },
    {
        id: "is_tenant_admin",
        name: "Company admin",
        type: "string",
    },
    {
        id: "last_login_at",
        name: "Last login at",
        type: "date",
        options: [
            "Today",
            "Yesterday",
            "Tomorrow",
            "Next week",
            "Last week",
            "Last 2 weeks",
            "Last month",
            "Last year",
        ],
    },
    {
        id: "precall_email_enabled",
        name: "Pre-call email enabled",
        type: "boolean",
    },
    {
        id: "precall_slack_enabled",
        name: "Pre-call slack enabled",
        type: "boolean",
    },
    {
        id: "autojoin_external_meetings",
        name: "Autojoin External meetings",
        type: "boolean",
    },
    {
        id: "autojoin_internal_meetings",
        name: "Autojoin Internal meetings",
        type: "boolean",
    },
    {
        id: "custom_bot_name",
        name: "Bot name",
        type: "string",
    },
    {
        id: "no_live_bot",
        name: "No live bot",
        type: "boolean",
    },
    {
        id: "total_meetings",
        name: "Total meetings",
        type: "number",
    },
    {
        id: "total_internal_meetings",
        name: "Total internal meetings",
        type: "number",
    },
    {
        id: "total_external_meetings",
        name: "Total external meetings",
        type: "number",
    },
];
export const SORTABLE_FIELDS = [
    {
        id: "name",
        name: "Name",
        type: "string",
    },
    {
        id: "email",
        name: "Email",
        type: "string",
    },
    {
        id: "last_login_at",
        name: "Last login",
        type: "date",
    },
];
