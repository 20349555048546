import { useQuery } from "react-query";
import { createInitialPostCallOutputs } from "../common/endpoints";
// This hook creates initial post-call outputs and field mappings
// for a call. It is a custom hook so that it can be reused across
// different components and not cause mutliple triggers for the same BE API.
export const useFieldMappings = ({ callId }) => {
    const { data: postCallOutputs } = useQuery({
        queryKey: ["createPostCallOutputs", callId],
        queryFn: () => createInitialPostCallOutputs(callId),
        // StaleTime: Infinity ensures that this data is not considered as stale on
        // component mount/unmount, and hence will be delivered via cache.
        // Note that if the callId changes, it will still be fetched again.
        staleTime: Infinity,
    });
    return {
        postCallOutputs,
    };
};
