import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { debounce } from "../common/debounce";
import classNames from "../class_names";
import { CheckIcon } from "@heroicons/react/24/outline";
import useIntersectionObserver from "../common/intesection_observer";
import { LoadingSelectItems } from "../common/loaders";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Command, CommandGroup, CommandItem, CommandList } from "./command";
import { SparklesIcon } from "@heroicons/react/24/solid";
/**
 * Asynchronously loads items for a select combobox.
 *
 * Provides a searchable dropdown experience without requiring all items upfront.
 *
 * **Usage:** Similar to `AsyncSearchSelect`, but accepts a width param to set the search width dynamically.
 *
 *
 * **Example:** See `ChatBoxV3` for usage.
 *
 * @param dataFetcher - Async function to fetch select options.
 */
export const ChatSearchSelect = (props) => {
    var _a, _b, _c, _d, _e;
    const [searchQuery, setSearchQuery] = [
        props.searchQuery,
        props.setSearchQuery,
    ];
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    // We use debouncedSearchQuery to refetch new select options instead
    // of search query to reduce the number of API calls when user types,
    // and not being reactive on every key-stroke by the user.
    // Why not make the searchQuery state debounced? Because then it makes the user
    // feel that their key-strokes are not being recorded
    const debouncedQuery = debounce(searchQuery, 400);
    const { data: itemsFetchResponse, isLoading: itemsLoading, fetchNextPage, hasNextPage, isFetchingNextPage, } = useInfiniteQuery(["fetchSelectItems", debouncedQuery, (_a = props.id) !== null && _a !== void 0 ? _a : ""], ({ pageParam = null }) => props.dataFetcher(debouncedQuery !== null && debouncedQuery !== void 0 ? debouncedQuery : undefined, pageParam), {
        getNextPageParam: (prevPage, pages) => prevPage.next_cursor || null,
    });
    const selectItems = useMemo(() => {
        if (!itemsFetchResponse || !itemsFetchResponse.pages) {
            return [];
        }
        // It any item matches exactly what the search input is, don't show popover
        // This is because it's likely the user selected a suggestion, so don't show popover
        const items = itemsFetchResponse.pages.flatMap((page) => page.results);
        if (items.find((item) => item.label === searchQuery) !== undefined) {
            return [];
        }
        return items;
    }, [itemsFetchResponse]);
    const loadMoreRef = useIntersectionObserver(() => {
        if (hasNextPage && fetchNextPage) {
            fetchNextPage();
        }
    }, { threshold: 1.0 });
    const sections = props.separator
        ? props.separator(selectItems)
        : [{ options: selectItems }];
    return (_jsxs(Popover, Object.assign({ open: isPopoverOpen, onOpenChange: setIsPopoverOpen }, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "grow" }, { children: _jsx("input", { className: "w-full grow rounded-full border-none px-4 py-2 bg-wds-gray-1 text-wds-black focus:ring-0", placeholder: (_b = props.placeholder) !== null && _b !== void 0 ? _b : "", onChange: (e) => {
                            setSearchQuery(e.target.value);
                        }, value: (_d = searchQuery !== null && searchQuery !== void 0 ? searchQuery : (_c = props.selectedOption) === null || _c === void 0 ? void 0 : _c.label) !== null && _d !== void 0 ? _d : undefined, onBeforeInput: () => setIsPopoverOpen(true), autoFocus: isPopoverOpen, onKeyDown: props.onKeyDown }) })) })), selectItems && selectItems.length > 0 && (_jsx(PopoverContent, Object.assign({ align: "start", side: "bottom", className: classNames("w-[var(--radix-popover-trigger-width)] p-0 bg-white shadow-md rounded-md max-h-96 overflow-y-auto overflow-x-hidden", (_e = props.className) !== null && _e !== void 0 ? _e : ""), style: { width: props.width }, 
                // Focus should stay on the input text box.
                onOpenAutoFocus: (e) => e.preventDefault() }, { children: _jsx(Command, { children: _jsx(CommandList, { children: itemsLoading ? (_jsx("div", Object.assign({ className: "w-[var(--radix-popover-trigger-width)]" }, { children: _jsx(LoadingSelectItems, {}) }))) : (_jsx(_Fragment, { children: sections.map((section, sectionIndex) => (_jsxs(CommandGroup, Object.assign({ heading: section.title }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row w-full py-1 px-4 gap-2" }, { children: [_jsx(SparklesIcon, { className: "w-5 h-5 text-wds-blue-4 shrink-0" }), _jsx("div", Object.assign({ className: "grow text-wds-blue-4 font-bold" }, { children: "Suggestions" }))] })), section.options.map((option) => {
                                        var _a;
                                        const isSelected = ((_a = props.selectedOption) === null || _a === void 0 ? void 0 : _a.value) === option.value;
                                        return (_jsxs(CommandItem, Object.assign({ className: "flex w-full items-center justify-between pr-4 py-1 cursor-pointer text-wds-black pl-10", onSelect: () => {
                                                props.onSelect(option);
                                                setSearchQuery(option.label);
                                                setIsPopoverOpen(false);
                                            } }, { children: [props.itemRenderer
                                                    ? props.itemRenderer(option, searchQuery !== null && searchQuery !== void 0 ? searchQuery : "")
                                                    : option.label, isSelected && (_jsx(CheckIcon, { className: "w-4 h-4 text-wds-blue-4 shrink-0" }))] }), option.value));
                                    }), isFetchingNextPage && _jsx(LoadingSelectItems, {}), !isFetchingNextPage && hasNextPage && (_jsx("div", { ref: loadMoreRef, style: { height: "1px" } }))] }), sectionIndex))) })) }) }) })))] })));
};
