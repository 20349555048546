import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { markLatestActivityTime } from "../activity_utils";
import Navbar from "../common/nav_bar";
import { useLocation, Outlet } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../App";
const Settings = () => {
    // For jwt token refresh
    markLatestActivityTime();
    const location = useLocation();
    const activeTab = location.pathname;
    const { userIsTenantAdmin } = useContext(UserContext);
    const tabs = [
        { label: "User settings", toPath: "/settings/user_settings" },
        { label: "Integrations", toPath: "/settings/integrations" },
    ];
    if (userIsTenantAdmin) {
        tabs.push({ label: "API access", toPath: "/settings/api" });
        tabs.push({ label: "Chat templates", toPath: "/settings/chat_templates" });
    }
    return (_jsxs("div", Object.assign({ className: "w-full h-[calc(100vh - 3.125rem)] lg:h-screen bg-neutral-100" }, { children: [_jsxs("div", Object.assign({ className: "w-full bg-white border-b border-neutral-200 flex flex-col" }, { children: [_jsx("div", Object.assign({ className: "text-black text-2xl font-bold leading-loose mt-8 ml-8" }, { children: "My profile settings" })), _jsx(Navbar, { tabs: tabs, activeTab: activeTab })] })), _jsx(Outlet, {})] })));
};
export default Settings;
