var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import * as endpoints from "../common/endpoints";
import { ArrowDownTrayIcon, ArrowUpTrayIcon, CheckBadgeIcon, ExclamationTriangleIcon, } from "@heroicons/react/24/outline";
import BaseModal from "../base_modal";
import JsonFileUploader from "./json_file_uploader";
import ImportStatusModal from "./import_status_modal";
import { useNavigate } from "react-router-dom";
import useFileUploader from "./use_file_uploader";
import BaseTable from "../base_table";
const PAGE_SIZE = 10;
const ImportExportPlaybooks = () => {
    const [selectedIds, setSelectedIds] = useState([]);
    const [trainingState, setTrainingState] = useState("TRAINED");
    const [isTraining, setIsTraining] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [showImportStatusModal, setShowImportStatusModal] = useState(false);
    const [importStatusLabel, setImportStatusLabel] = useState("");
    const [isExporting, setIsExporting] = useState(false);
    const { handleFileChange, handleUploadClick } = useFileUploader();
    const [replaceExisting, setReplaceExisting] = useState(false);
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const selectCallback = (id, selected) => {
        // If it's selected, remove it; otherwise, add it to the array
        setSelectedIds((prevSelectedRows) => selected
            ? [...prevSelectedRows, id]
            : prevSelectedRows.filter((selectedId) => selectedId !== id));
    };
    const fetchPlaybooks = () => __awaiter(void 0, void 0, void 0, function* () {
        return yield endpoints.fetchAllPlaybooksPaginated(PAGE_SIZE);
    });
    const handleExportClick = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsExporting(true);
        let data = yield fetchPlaybooks();
        if (selectedIds.length > 0) {
            data = data.filter((playbook) => selectedIds.includes(playbook.id));
        }
        // Create a JSON blob with the data
        const exportData = JSON.stringify(data, null, 2);
        const blob = new Blob([exportData], { type: "application/json" });
        // Create a link element and trigger a download
        const downloadEl = document.createElement("a");
        downloadEl.href = window.URL.createObjectURL(blob);
        const currentDate = new Date();
        downloadEl.download = `playbooks_${currentDate
            .toISOString()
            .replace(/[Z]/g, "")}.json`;
        downloadEl.click();
        setIsExporting(false);
    });
    const retrainPlaybooks = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsTraining(true);
        yield endpoints.retrainAllPlaybooks();
        setIsTraining(false);
    });
    const onFileUpload = (content) => __awaiter(void 0, void 0, void 0, function* () {
        const dataToImport = JSON.parse(content);
        // Render the modal for import status.
        setShowImportModal(false);
        setImportStatusLabel("Importing playbooks!");
        setShowImportStatusModal(true);
        let importPromises = [];
        dataToImport.forEach((playbook, idx) => {
            importPromises.push(endpoints.batchCreatePlaybook(playbook));
        });
        yield Promise.all(importPromises);
        setImportStatusLabel("Retraining playbooks!");
        yield retrainPlaybooks();
        setShowImportStatusModal(false);
        // Navigating to the playbooks table to show the newly imported trackers.
        navigate("/trackers");
    });
    useEffect(() => {
        fetchPlaybooks().then((playbooks) => {
            setRows(playbooks.map((playbook) => ({
                id: playbook.id,
                cells: [playbook.name],
            })));
        });
    }, []);
    useEffect(() => {
        endpoints.getTrainingStatus("PLAYBOOKS").then(({ training_state }) => {
            setTrainingState(training_state);
        });
    }, [isTraining]);
    return (_jsxs(_Fragment, { children: [_jsx(BaseTable, { addName: "", addCallback: undefined, title: "Trackers", description: "Trackers are trackable objectives for sales calls. Trackers can be added to your reps' calls through tracker sets.", columnNames: ["Name"], rows: rows, selectCallback: selectCallback, tableActions: [
                    {
                        label: (_jsxs("div", Object.assign({ className: "flex flex-row gap-1" }, { children: [isTraining ? "Training in progress" : "Retrain", !isTraining && trainingState === "TRAINED" && (_jsx(CheckBadgeIcon, { className: "w-5 h-5 text-green-600" })), !isTraining && trainingState === "NEEDS_RETRAINING" && (_jsx(ExclamationTriangleIcon, { className: "w-5 h-5 text-amber-600" }))] }))),
                        buttonDisabled: isTraining,
                        callback: retrainPlaybooks,
                    },
                    {
                        label: (_jsxs("div", Object.assign({ className: "flex flex-row gap-1" }, { children: ["Import", _jsx(ArrowDownTrayIcon, { className: "w-5 h-5" })] }))),
                        buttonDisabled: false,
                        callback: () => setShowImportModal(true),
                    },
                    {
                        label: (_jsxs("div", Object.assign({ className: "flex flex-row gap-1" }, { children: [isExporting
                                    ? "Exporting..."
                                    : selectedIds.length > 0
                                        ? "Export selected"
                                        : "Export all", !isExporting && _jsx(ArrowUpTrayIcon, { className: "w-5 h-5" })] }))),
                        buttonDisabled: isExporting,
                        callback: handleExportClick,
                    },
                ] }), _jsx(BaseModal, { title: "Import Trackers", description: "Upload the file from which trackers need to be imported", fields: [
                    {
                        name: "Replace existing",
                        type: "checkbox",
                        value: replaceExisting,
                    },
                ], setField: (fieldName, value) => {
                    if (fieldName == "Replace existing") {
                        setReplaceExisting(value);
                    }
                }, open: showImportModal, setOpen: setShowImportModal, submitCallback: (_fields) => {
                    handleUploadClick((content) => __awaiter(void 0, void 0, void 0, function* () {
                        if (replaceExisting) {
                            // Replace existing is set
                            // Delete all playbooks first.
                            const playbooks = yield endpoints.fetchAllPlaybooksPaginated(PAGE_SIZE);
                            const playbookDeletePromises = [];
                            playbooks.forEach((p, idx) => playbookDeletePromises.push(endpoints.deletePlaybook(p.id)));
                            yield Promise.all(playbookDeletePromises);
                        }
                        yield onFileUpload(content);
                    }));
                }, submitText: "Import", children: _jsx(JsonFileUploader, { handleFileChange: handleFileChange }) }), showImportStatusModal && (_jsx(ImportStatusModal, { statusLabel: importStatusLabel, show: showImportStatusModal, onClose: () => setShowImportStatusModal(false) }))] }));
};
export default ImportExportPlaybooks;
