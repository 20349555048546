var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { addPreCallPrepBlockToCall, getAllPreCallPrepBlocks, getAllPreCallPrepTemplates, getPreCallPrepTemplateForCall, removePreCallPrepBlockFromCall, reorderPreCallPrepBlocksInCall, updateAllPreCallPrepTemplateForCall, updatePreCallPrepTemplateForCall, } from "../common/endpoints";
import CallPrepBlock from "./call_prep_block";
import { markLatestActivityTime } from "../activity_utils";
import ButtonWithIcon from "../common/button_with_icon";
import { useEffect, useState } from "react";
import Dropdown from "../common/dropdown";
import { DragDropContext, Draggable, Droppable, } from "react-beautiful-dnd";
import AddBriefingBlockModal from "./add_briefing_block_modal";
import { ArrowPathIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../delete_modal";
const PreCallPrep = ({ call, dashboardView }) => {
    var _a;
    // For jwt token refresh
    markLatestActivityTime();
    const [template, setTemplate] = useState(null);
    const [allTemplates, setAllTemplates] = useState([]);
    const [addingBlock, setAddingBlock] = useState(false);
    const [allBlocks, setAllBlocks] = useState([]);
    const [regenerateAllBlocks, setRegenerateAllBlocks] = useState(false);
    const [deleteModal, setShowDeleteModal] = useState(false);
    const [deletingBlock, setDeletingBlock] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        fetchPreCallPrepTemplate();
    }, [call.id]);
    useEffect(() => {
        getAllPreCallPrepTemplates().then(setAllTemplates);
        getAllPreCallPrepBlocks().then(setAllBlocks);
    }, []);
    const fetchPreCallPrepTemplate = () => __awaiter(void 0, void 0, void 0, function* () {
        const template = yield getPreCallPrepTemplateForCall(call.id);
        setTemplate(template);
    });
    const onAddBriefingBlockClick = () => {
        setAddingBlock(true);
    };
    function PlusIcn() {
        return _jsx(PlusIcon, { className: "w-4 h-4 text-wds-blue-3" });
    }
    if (!template) {
        // this should never happen as the backend automatically creates and assigns an empty template
        return null;
    }
    const onDragEnd = (result) => __awaiter(void 0, void 0, void 0, function* () {
        if (!result.destination)
            return;
        const reorderedBlocks = Array.from(template.blocks);
        const [removed] = reorderedBlocks.splice(result.source.index, 1);
        reorderedBlocks.splice(result.destination.index, 0, removed);
        setTemplate(Object.assign(Object.assign({}, template), { blocks: reorderedBlocks }));
        yield reorderPreCallPrepBlocksInCall(call.id, reorderedBlocks.map((block) => block.id));
    });
    const tooltipId = "refresh-pre-call-" + call.id;
    return (_jsxs("div", Object.assign({ className: "w-full h-full px-8 py-4" }, { children: [_jsxs("div", Object.assign({ className: "w-full flex justify-between items-center mb-4" }, { children: [_jsx("div", Object.assign({ className: "font-bold text-[18px]" }, { children: "Pre-call research" })), !dashboardView ? (_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("button", Object.assign({ className: "p-2 rounded-full justify-center items-center gap-2 flex hover:bg-blue-100", "data-tooltip-id": tooltipId, "data-tooltip-content": regenerateAllBlocks
                                    ? "Regenerating..."
                                    : "Regenerate all research blocks", "data-tooltip-place": "top", "data-tooltip-offset": 5, "data-tooltip-position-strategy": "fixed", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    setRegenerateAllBlocks(true);
                                    yield updateAllPreCallPrepTemplateForCall(call.id);
                                    getAllPreCallPrepTemplates().then(setAllTemplates);
                                    getAllPreCallPrepBlocks().then(setAllBlocks);
                                    setRegenerateAllBlocks(false);
                                }), disabled: regenerateAllBlocks }, { children: _jsx(ArrowPathIcon, { className: "h-5 w-5 text-zinc-800" }) })), _jsx("div", Object.assign({ className: "relative" }, { children: _jsx(Dropdown, { options: allTemplates.map((template) => ({
                                        value: template.id.toString(),
                                        label: template.name,
                                    })), defaultSelected: (_a = template === null || template === void 0 ? void 0 : template.id.toString()) !== null && _a !== void 0 ? _a : "", onOptionSelect: (option) => __awaiter(void 0, void 0, void 0, function* () {
                                        yield updatePreCallPrepTemplateForCall(call.id, parseInt(option, 10));
                                        fetchPreCallPrepTemplate();
                                    }), buttonClasses: "h-8 text-sm" }) })), _jsx(ButtonWithIcon, { label: "Add research block", Icon: PlusIcn, onClick: onAddBriefingBlockClick })] }))) : null] })), _jsx("div", { children: _jsx(DragDropContext, Object.assign({ onDragEnd: onDragEnd }, { children: _jsx(Droppable, Object.assign({ droppableId: "pre-call-prep-blocks" }, { children: (dndProps) => {
                            return (_jsxs("div", Object.assign({}, dndProps.droppableProps, { ref: dndProps.innerRef }, { children: [template.blocks.map((callPrepBlock, index) => (_jsx(Draggable, Object.assign({ draggableId: callPrepBlock.id.toString(), index: index }, { children: (dndProps) => {
                                            return (_jsx("div", Object.assign({}, dndProps.draggableProps, { ref: dndProps.innerRef }, { children: _jsx(CallPrepBlock, { dragHandleProps: dndProps.dragHandleProps, callPrepBlock: callPrepBlock, onDelete: () => {
                                                        setDeletingBlock(callPrepBlock.id);
                                                        setShowDeleteModal(true);
                                                    }, callId: call.id, templateId: template.id, defaultOpen: index === 0, regeneratingAllBlocks: regenerateAllBlocks }, callPrepBlock.name) })));
                                        } }), `${callPrepBlock.id}-${call.id}`))), dndProps.placeholder] })));
                        } })) })) }), _jsx(AddBriefingBlockModal, { researchBlockType: "CALL", open: addingBlock, onClose: () => setAddingBlock(false), onAddBriefingBlocks: (blockIds) => __awaiter(void 0, void 0, void 0, function* () {
                    yield Promise.all(blockIds.map((blockId) => addPreCallPrepBlockToCall(call.id, blockId)));
                    yield fetchPreCallPrepTemplate();
                    setAddingBlock(false);
                }), allBlocks: allBlocks, existingBlockIds: template.blocks.map((b) => b.id), linkToBlock: true, createCallback: () => {
                    navigate("/research", {
                        state: { templateId: template.id, creatingBlock: true },
                    });
                } }), _jsx(Tooltip, { id: tooltipId, noArrow: true, className: "wiser-frame-tooltip" }), _jsx(DeleteModal, { deleteModal: deleteModal && !!deletingBlock, setShowDeleteModal: setShowDeleteModal, title: "Are you sure?", description: "Delete this research block.", deleteCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (deletingBlock) {
                        yield removePreCallPrepBlockFromCall(call.id, deletingBlock);
                        yield fetchPreCallPrepTemplate();
                        setDeletingBlock(undefined);
                    }
                }) })] })));
};
export default PreCallPrep;
