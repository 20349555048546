export function checkExtensionInstalled() {
    return new Promise((resolve) => {
        setTimeout(() => {
            const wiser_dismissal_count_string = localStorage.getItem("wiser-extension-dismissal-count") || "0";
            const wiser_dismissal_count = parseInt(wiser_dismissal_count_string);
            const userAgent = navigator.userAgent;
            const isChromeBrowser = /Chrome|Chromium/.test(userAgent) &&
                !/Edge|OPR|Brave|SamsungBrowser/.test(userAgent);
            return resolve(!isChromeBrowser ||
                wiser_dismissal_count > 3 ||
                !!window.document.getElementById("wiser-extension-installed"));
        }, 5000);
        // Wait for the page to load, the extension runs AFTER the page is loaded
        // It adds a div with the id "wiser-extension-installed" to the page
        // If the page load takes longer than 5 seconds we assume the extension is not installed
    });
}
