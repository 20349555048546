import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Dropdown from "../common/dropdown";
import classNames from "../class_names";
import { CRM_FIELD_TYPE_OPTIONS, DEFAULT_MULTI_SELECT_FIELD, DEFAULT_NUMBER_FIELD, DEFAULT_SINGLE_SELECT_FIELD, DEFAULT_TEXT_FIELD, } from "./crm_field_type_config";
const TextRestrictionsEditor = ({ crmFieldTypeDefinition, setCrmFieldTypeDefinition, }) => {
    const [valid, setValid] = useState(true);
    const validate = (value) => {
        // Check if the value is a number and is within the allowed range.
        if (value === "" || isNaN(parseInt(value))) {
            setValid(false);
            return;
        }
        const parsedValue = parseInt(value);
        // We have kept the allowed range to be maximum 32768 characters which is
        // the maximum limit for a text field in external CRMs like Salesforce.
        if (parsedValue < 1 || parsedValue > 32768) {
            setValid(false);
            return;
        }
        setValid(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx("label", Object.assign({ className: "block text-sm font-medium leading-6 text-gray-900" }, { children: "Maximum characters" })), _jsx("input", { type: "number", value: crmFieldTypeDefinition.restrictions.length, onChange: (e) => {
                    validate(e.target.value);
                    setCrmFieldTypeDefinition({
                        type: "text",
                        restrictions: {
                            length: parseInt(e.target.value),
                        },
                    });
                }, className: classNames("block w-full rounded-md p-1.5 text-gray-900 shadow-sm", "focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6", valid
                    ? "ring-1 ring-inset ring-gray-300  border-0"
                    : "border border-red-500") })] }));
};
const NumberRestrictionsEditor = ({ crmFieldTypeDefinition, setCrmFieldTypeDefinition, }) => {
    const [validPrecision, setValidPreicision] = useState(true);
    const [validScale, setValidScale] = useState(true);
    const validate = (value, minAllowed, maxAllowed) => {
        if (value === "" || isNaN(parseInt(value))) {
            return false;
        }
        const parsedValue = parseInt(value);
        if (parsedValue < minAllowed || parsedValue > maxAllowed) {
            return false;
        }
        return true;
    };
    const validatePrecision = (value) => {
        const valid = validate(value, 1, 18);
        setValidPreicision(valid);
    };
    const validateScale = (value) => {
        const valid = validate(value, 0, 18);
        setValidScale(valid);
    };
    return (_jsxs("div", Object.assign({ className: "flex gap-2 py-1" }, { children: [_jsx("label", Object.assign({ className: "self-center block text-sm font-medium leading-6 text-gray-900" }, { children: "Precision" })), _jsx("input", { type: "number", value: crmFieldTypeDefinition.restrictions.precision, onChange: (e) => {
                    validatePrecision(e.target.value);
                    setCrmFieldTypeDefinition({
                        type: "number",
                        restrictions: {
                            precision: parseInt(e.target.value),
                            scale: crmFieldTypeDefinition.restrictions.scale,
                        },
                    });
                }, className: classNames("block w-full rounded-md p-1.5 text-gray-900 shadow-sm", "focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6", validPrecision
                    ? "ring-1 ring-inset ring-gray-300  border-0"
                    : "border border-red-500") }), _jsx("label", Object.assign({ className: "self-center block text-sm font-medium leading-6 text-gray-900" }, { children: "Scale" })), _jsx("input", { type: "number", value: crmFieldTypeDefinition.restrictions.scale, onChange: (e) => {
                    validateScale(e.target.value);
                    setCrmFieldTypeDefinition({
                        type: "number",
                        restrictions: {
                            precision: crmFieldTypeDefinition.restrictions.precision,
                            scale: parseInt(e.target.value),
                        },
                    });
                }, className: classNames("block w-full rounded-md p-1.5 text-gray-900 shadow-sm", "focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6", validScale
                    ? "ring-1 ring-inset ring-gray-300  border-0"
                    : "border border-red-500") })] })));
};
const SelectRestrictionsEditor = ({ crmFieldTypeDefinition, setCrmFieldTypeDefinition, }) => {
    const [newText, setNewText] = useState("");
    const addPicklistValue = () => {
        setCrmFieldTypeDefinition({
            type: crmFieldTypeDefinition.type,
            restrictions: {
                picklist_values: [
                    ...crmFieldTypeDefinition.restrictions.picklist_values,
                    newText,
                ],
            },
        });
        setNewText("");
    };
    const removePicklistValue = (value) => {
        setCrmFieldTypeDefinition({
            type: crmFieldTypeDefinition.type,
            restrictions: {
                picklist_values: crmFieldTypeDefinition.restrictions.picklist_values.filter((v) => v !== value),
            },
        });
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx("label", Object.assign({ className: "block text-sm font-medium leading-6 text-gray-900" }, { children: "Allowed values" })), _jsxs("div", Object.assign({ className: "flex flex-row mt-2" }, { children: [_jsx("input", { type: "text", value: newText, onChange: (e) => setNewText(e.target.value), className: "flex block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6", placeholder: "Enter a value" }), _jsx("button", Object.assign({ type: "button", onClick: addPicklistValue, className: "flex inline-flex w-24 justify-center rounded-md bg-indigo-600 ml-2 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2" }, { children: "Add" }))] })), _jsx("div", Object.assign({ className: "mt-2" }, { children: crmFieldTypeDefinition.restrictions.picklist_values.map((value, index) => (_jsx("div", Object.assign({ className: "inline-flex p-1" }, { children: _jsxs("span", Object.assign({ className: "inline-flex items-center gap-x-0.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700" }, { children: [value, _jsxs("button", Object.assign({ type: "button", className: "group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-blue-600/20", onClick: () => removePicklistValue(value) }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Remove" })), _jsx("svg", Object.assign({ viewBox: "0 0 14 14", className: "h-3.5 w-3.5 stroke-blue-800/50 group-hover:stroke-blue-800/75" }, { children: _jsx("path", { d: "M4 4l6 6m0-6l-6 6" }) })), _jsx("span", { className: "absolute -inset-1" })] }))] })) }), value))) }))] })));
};
const CrmFieldTypeSelector = ({ crmFieldTypeDefinition, setCrmFieldTypeDefinition, }) => {
    // We use a local state for the selected type to render the restrictions editor
    // correctly from the selected item in the dropdown.
    const [selectedType, setSelectedType] = useState(crmFieldTypeDefinition.type);
    useEffect(() => {
        setSelectedType(crmFieldTypeDefinition.type);
    }, [crmFieldTypeDefinition.type]);
    useEffect(() => {
        if (crmFieldTypeDefinition.type === selectedType) {
            return;
        }
        switch (selectedType) {
            case "text":
                setCrmFieldTypeDefinition(DEFAULT_TEXT_FIELD);
                break;
            case "number":
                setCrmFieldTypeDefinition(DEFAULT_NUMBER_FIELD);
                break;
            case "single_select":
                setCrmFieldTypeDefinition(DEFAULT_SINGLE_SELECT_FIELD);
                break;
            case "multi_select":
                setCrmFieldTypeDefinition(DEFAULT_MULTI_SELECT_FIELD);
                break;
            case "boolean":
                setCrmFieldTypeDefinition({ type: "boolean" });
                break;
            case "date":
                setCrmFieldTypeDefinition({ type: "date" });
                break;
        }
    }, [selectedType]);
    const renderRestrictionsEditor = () => {
        switch (selectedType) {
            case "text":
                return (_jsx(TextRestrictionsEditor, { crmFieldTypeDefinition: crmFieldTypeDefinition.type == "text"
                        ? crmFieldTypeDefinition
                        : DEFAULT_TEXT_FIELD, setCrmFieldTypeDefinition: setCrmFieldTypeDefinition }));
            case "number":
                return (_jsx(NumberRestrictionsEditor, { crmFieldTypeDefinition: crmFieldTypeDefinition.type === "number"
                        ? crmFieldTypeDefinition
                        : DEFAULT_NUMBER_FIELD, setCrmFieldTypeDefinition: setCrmFieldTypeDefinition }));
            case "single_select":
            case "multi_select":
                return (_jsx(SelectRestrictionsEditor, { crmFieldTypeDefinition: crmFieldTypeDefinition.type === selectedType
                        ? crmFieldTypeDefinition
                        : selectedType === "single_select"
                            ? DEFAULT_SINGLE_SELECT_FIELD
                            : DEFAULT_MULTI_SELECT_FIELD, setCrmFieldTypeDefinition: setCrmFieldTypeDefinition }));
            default:
                return null;
        }
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-1 pb-3" }, { children: [_jsx("label", Object.assign({ className: "block text-sm font-medium leading-6 text-gray-900" }, { children: "Content type" })), _jsx(Dropdown, { options: CRM_FIELD_TYPE_OPTIONS, defaultSelected: crmFieldTypeDefinition.type, onOptionSelect: (option) => {
                    setSelectedType(option);
                }, buttonClasses: "h-8 hover:none bg-white border border-solid border-gray-300 rounded-lg text-sm" }), renderRestrictionsEditor()] })));
};
export default CrmFieldTypeSelector;
