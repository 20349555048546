var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useId, useRef, useState } from "react";
import Badge from "../common/badge";
import classNames from "../class_names";
import { AsyncSearchSelect, } from "../components/async_search_select";
/**
 * This component is used to render the editable columns in the table
 * that reference other CRM objects. Example: Account in Contacts, etc.
 *
 * This component supports both:
 *  a.) Fetching objects asynchronously using the API on user input
 *  b.) Using a pre-fetched list of items as options
 */
export const CrmObjectReferenceSwitcher = (props) => {
    const [editing, setEditing] = useState(false);
    const newValueDivRef = useRef(null);
    const componentId = useId();
    const handleKeyDown = (event) => {
        // Users can come out of the edit mode by pressing Esc.
        if (event.key === "Escape") {
            setEditing(false);
        }
    };
    useEffect(() => {
        // Attach a click event listener to document to exit edit mode on outside click
        const handleOutsideClick = (event) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            const clickedElement = event.target;
            const isClickedInsideComponent = (_a = newValueDivRef.current) === null || _a === void 0 ? void 0 : _a.contains(clickedElement);
            // The clicked inside app check ensures that we treat only those clicks as
            // "outside" that are not in the Radix portal. Otherwise, clicks in the Radix
            // components rendered in a portal like DropdownMenu is also considered an
            // outside click and thus disrupts the functioning of this hook.
            const isClickedInsideApp = (_b = document
                .getElementById("app")) === null || _b === void 0 ? void 0 : _b.contains(clickedElement);
            if (isClickedInsideApp && !isClickedInsideComponent) {
                setEditing(false);
            }
        });
        if (editing) {
            // Capture the click event on the document to exit edit mode on outside click
            document.addEventListener("click", handleOutsideClick, true);
        }
        return () => {
            document.removeEventListener("click", handleOutsideClick, true);
        };
    }, [editing]);
    return (_jsx("div", Object.assign({ className: classNames("p-2 h-full", editing
            ? ""
            : "hover:outline-none hover:cursor-pointer hover:bg-gray-100"), onClick: () => setEditing(true) }, { children: editing ? (_jsx("div", Object.assign({ ref: newValueDivRef, onKeyDown: handleKeyDown }, { children: _jsx(AsyncSearchSelect, { id: componentId, dataFetcher: props.columnObjectsFetcher.objectsFetcher, onSelect: (option) => {
                    if (!option)
                        return;
                    setEditing(false);
                    props.onSelectedOptionChange(option);
                }, selectedOption: props.selectedOption, placeholder: "Search..." }) }))) : (_jsxs("div", Object.assign({ className: classNames("flex items-start gap-2 break-words whitespace-pre-wrap", props.selectedOption ? "justify-between" : "justify-end") }, { children: [props.selectedOption && (_jsx(Badge, { text: props.selectedOption.label, classes: "rounded-lg border boreder-gray-300 bg-gray-100 w-fit", textClasses: "text-sm font-medium text-gray-600" })), props.showRequired && _jsx("span", Object.assign({ className: "text-red-700" }, { children: "*" }))] }))) })));
};
