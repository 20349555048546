import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import classNames from "./class_names";
export default function IntentDebugger(props) {
    // Draws a card with each intent name, score, shown by bar graph.
    // Sorts by score, highest first.
    // Scores tend to range from -1.X to 1.X. I'm more interested in the difference between high scores
    // than low scores, hence the -1.0 to 3.0 range.
    const minScore = -1.0;
    const maxScore = 3.0;
    let sortedIntents = props.intentResults.sort((a, b) => b.score - a.score);
    // Only show the first 15
    sortedIntents = sortedIntents.slice(0, 15);
    const getWidth = (score) => {
        let scoreFraction = Math.round((100 * (score - minScore)) / (maxScore - minScore));
        scoreFraction = Math.min(100, Math.max(0, scoreFraction));
        let widthValue = scoreFraction + "%";
        return widthValue;
    };
    return (_jsx("div", Object.assign({ className: "bg-white py-2 my-3 border-2 rounded-lg shadow-lg" }, { children: _jsx("div", Object.assign({ className: "mx-auto max-w-7xl px-6 lg:px-8 py-4" }, { children: _jsx("div", Object.assign({ className: "mx-auto max-w-2xl" }, { children: _jsx("div", Object.assign({ className: "mt-1 text-xs" }, { children: sortedIntents.map((intent, i) => (_jsxs("div", Object.assign({ className: "flex flex-row justify-between relative bg-transparent py-0.5 hover:bg-gray-200" }, { children: [_jsx("h3", Object.assign({ className: "bg-transparent z-10" }, { children: intent.intent })), _jsxs("div", Object.assign({ className: "font-bold rounded-lg px-4 mx-2 bg-transparent z-10" }, { children: [intent.score, _jsx("button", Object.assign({ onClick: () => {
                                            props.chooseIntent(intent);
                                        } }, { children: _jsx(CheckCircleIcon, { className: "h-5 w-5 text-gray-500 mx-2" }) }))] })), _jsx("div", { className: classNames(
                                // TODO: make the threshold here match something meaningful from the backend.
                                intent.score > -0.1 ? "bg-green-300" : "bg-blue-200", "absolute top-0 left-0 rounded-lg h-4 w-1/2"), style: { width: getWidth(intent.score) } })] }), i))) })) })) })) })));
}
