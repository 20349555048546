var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { Controller, FormProvider, useFormContext, } from "react-hook-form";
import classNames from "../class_names";
import { Label } from "./label";
/**
 * # IMPORTANT
 * Fill the `defaultValues` with the actual data in the server when using `react-hook-form`
 * This data **MUST** be the same shape as the form schema AND has to be fetched via `react-query`
 * The data must be present before the form hook is triggered.
 *
 * ## Description
 * The `<Form />` component is a wrapper around the react-hook-form library. It provides a few things:
 *  - Composable components for building forms.
 *  - A `<FormField />` component for building controlled form fields.
 *  - Form validation using zod
 *  - Handles accessibility and error messages.
 *  - Uses `React.useId()` for generating unique IDs.
 *  - Applies the correct aria attributes to form fields based on states
 *  - Built to work with all Radix UI components.
 *  - Uses `zod` as the schema validation library
 * The anatomy of a form
 * ```
 * <Form>
 * <FormField
 *   control={...}
 *   name="..."
 *   render={() => (
 *     <FormItem>
 *       <FormLabel />
 *       <FormControl>
 *         <Input />
*        </FormControl>
 *       <FormDescription />
 *       <FormMessage />
 *     </FormItem>
 *   )}
 * />
 * </Form>
 * ```
 *
 * How to use the form component with zod:
 *  1. Define the shape of your form using a Zod schema. Read more [here](https://zod.dev/)
 *  ```
 *  import { z } from "zod"
 *  const formSchema = z.object({
 *    username: z.string().min(2).max(50),
 *  })
 *  ```
 *  2. Define the form with the `useForm` hook
 *  ```
 *  import { zodResolver } from "@hookform/resolvers/zod"
 *  import { useForm } from "react-hook-form"
 *  export const WiserForm = () => {
 *    const form = useForm<z.infer<typeof formSchema>>({
 *      resolver: zodResolver(formSchema),
 *      defaultValues: {
 *        username: "",
 *      }
 *    })
 *    const onSubmit = (values: z.infer<typeof formSchema>) => {
 *      // This will be type-safe and validated
 *      console.log(values);
 *    }
 *  };
 *  ```
 *  3. Define the form
 *  ```
 *  <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input placeholder="wiserbot" {...field} />
              </FormControl>
              <FormDescription>
                This is your public display name.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">Submit</Button>
      </form>
    </Form>
    ```
 *  4. Thats it!
 *
 */
const Form = FormProvider;
const FormFieldContext = React.createContext({});
const FormField = (_a) => {
    var props = __rest(_a, []);
    return (_jsx(FormFieldContext.Provider, Object.assign({ value: { name: props.name } }, { children: _jsx(Controller, Object.assign({}, props)) })));
};
const useFormField = () => {
    const fieldContext = React.useContext(FormFieldContext);
    const itemContext = React.useContext(FormItemContext);
    const { getFieldState, formState } = useFormContext();
    const fieldState = getFieldState(fieldContext.name, formState);
    if (!fieldContext) {
        throw new Error("useFormField should be used within <FormField>");
    }
    const { id } = itemContext;
    return Object.assign({ id, name: fieldContext.name, formItemId: `${id}-form-item`, formDescriptionId: `${id}-form-item-description`, formMessageId: `${id}-form-item-message` }, fieldState);
};
const FormItemContext = React.createContext({});
const FormItem = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    const id = React.useId();
    return (_jsx(FormItemContext.Provider, Object.assign({ value: { id } }, { children: _jsx("div", Object.assign({ ref: ref, className: classNames("space-y-2", className) }, props)) })));
});
FormItem.displayName = "FormItem";
const FormLabel = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    const { error, formItemId } = useFormField();
    return (_jsx(Label, Object.assign({ ref: ref, className: classNames(error && "text-destructive", className), htmlFor: formItemId }, props)));
});
FormLabel.displayName = "FormLabel";
const FormControl = React.forwardRef((_a, ref) => {
    var props = __rest(_a, []);
    const { error, formItemId, formDescriptionId, formMessageId } = useFormField();
    return (_jsx(Slot, Object.assign({ ref: ref, id: formItemId, "aria-describedby": !error
            ? `${formDescriptionId}`
            : `${formDescriptionId} ${formMessageId}`, "aria-invalid": !!error }, props)));
});
FormControl.displayName = "FormControl";
const FormDescription = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    const { formDescriptionId } = useFormField();
    return (_jsx("p", Object.assign({ ref: ref, id: formDescriptionId, className: classNames("text-[0.8rem] text-muted-foreground", className) }, props)));
});
FormDescription.displayName = "FormDescription";
const FormMessage = React.forwardRef((_a, ref) => {
    var { className, children } = _a, props = __rest(_a, ["className", "children"]);
    const { error, formMessageId } = useFormField();
    const body = error ? String(error === null || error === void 0 ? void 0 : error.message) : children;
    if (!body) {
        return null;
    }
    return (_jsx("p", Object.assign({ ref: ref, id: formMessageId, className: classNames("text-[0.8rem] font-medium text-destructive", className) }, props, { children: body })));
});
FormMessage.displayName = "FormMessage";
export { useFormField, Form, FormItem, FormLabel, FormControl, FormDescription, FormMessage, FormField, };
