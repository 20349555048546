var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CrmTableV2 } from "./crm_table_v2";
import { useMemo, useRef, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Checkbox } from "../components/checkbox";
import CrmFieldValue from "./crm_field_value";
import { createCrmOpportunity, createOrUpdateCrmOpportunityData, getAllPreCallPrepBlocks, getCrmOpportunity, getPaginatedCrmData, queryCrmOwners, updateCrmOpportunity, } from "../common/endpoints";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { DataTableSkeleton } from "../components/data_table_skeleton";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
import { convertSearchParamsToBackendFilter } from "../common/query_builder/search_params_utils";
import { convertSearchParamsToBackendOrderBy } from "../common/sort_rule_builder/search_params_utils";
import { CrmObjectReferenceSwitcher } from "./crm_object_reference_switcher";
import { getCrmAccountName } from "../common/call_account_utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../components/tooltip";
import { ScrollArea } from "../components/scroll_area";
import ReactMarkdown from "react-markdown";
import { useCrmFields } from "./use_crm_fields";
import { useCustomSearchParams } from "./use_memoised_search_params";
const DEFAULT_OPPORTUNITIES_FILTER = {
    where_condition: {
        operator: WiserBackendQueryOperator.AND,
        subconditions: [],
    },
};
const DEFAULT_OPPORTUNITIES_ORDER_BY = [
    {
        field: "name",
        order: "ASC",
    },
];
const DEFAULT_TABLE_LAYOUT = {
    table_name: "Opportunity",
    name: "Default view",
    is_default: true,
    is_public: false,
    query_string: "",
    columns: {
        "row-select": 40,
        name: 150,
        account: 200,
        last_modified_time: 250,
        last_call_time: 250,
        last_call_link: 150,
        last_call_summary: 400,
    },
    column_order: [],
};
export const CrmOpportunitiesV2 = () => {
    var _a, _b;
    const { searchParams, layouts, isLoading, isError } = useCustomSearchParams("Opportunity", DEFAULT_TABLE_LAYOUT);
    const filter = searchParams.has("filter")
        ? (_a = convertSearchParamsToBackendFilter(searchParams)) !== null && _a !== void 0 ? _a : DEFAULT_OPPORTUNITIES_FILTER
        : DEFAULT_OPPORTUNITIES_FILTER;
    const orderBy = searchParams.has("order_by")
        ? (_b = convertSearchParamsToBackendOrderBy(searchParams)) !== null && _b !== void 0 ? _b : DEFAULT_OPPORTUNITIES_ORDER_BY
        : DEFAULT_OPPORTUNITIES_ORDER_BY;
    // State to store the CRM opportunities keyed by their ID.
    // These are useful when the user inline edits a column of an opportunity.
    const [crmOpportunitiesMap, setCrmOpportunitiesMap] = useState(new Map());
    const searchQuery = useRef("");
    const setSearchQuery = (query) => {
        searchQuery.current = query;
        queryClient.invalidateQueries({ queryKey: ["getPaginatedOpportunities"] });
    };
    const { data, fetchNextPage, isFetching, isLoading: isLoadingOpportunities, } = useInfiniteQuery(["getPaginatedOpportunities", filter, orderBy], ({ pageParam = 0 }) => getPaginatedCrmData("Opportunity", JSON.stringify({
        table: "Opportunity",
        structured_filter: {
            where_condition: {
                operator: WiserBackendQueryOperator.AND,
                subconditions: [
                    filter,
                    {
                        where_condition: {
                            operator: WiserBackendQueryOperator.OR,
                            subconditions: [
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "name",
                                        value: searchQuery.current,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        order_by_v2: orderBy,
    }), pageParam + 1, 50), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        getNextPageParam: (_lastGroup, groups) => groups.length,
        onSettled: (data) => {
            if (!data)
                return;
            setCrmOpportunitiesMap(new Map(data.pages
                .flatMap((page) => page.data)
                .map((opportunity) => [opportunity.id, opportunity])));
        },
    });
    const opportunitiesPaginatedData = useMemo(() => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.flatMap((page) => page.data)) !== null && _b !== void 0 ? _b : []; }, [data]);
    const total = useMemo(() => { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.length) && data.pages.length > 0 ? data === null || data === void 0 ? void 0 : data.pages[0].total : 0; }, [data]);
    // Fetch all Account Research Blocks for rendering the research block
    // columns
    const { data: allResearchBlocks } = useQuery({
        queryKey: ["allResearchBlocks"],
        queryFn: getAllPreCallPrepBlocks,
        refetchOnWindowFocus: false,
    });
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        const columns = [
            columnHelper.accessor("id", {
                id: "row-select",
                header: ({ table }) => (_jsx("div", Object.assign({ className: "flex items-center justify-center pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                            (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
                cell: ({ row }) => (_jsx("div", Object.assign({ className: "flex gap-1 items-center justify-center shadow-2xl pr-2" }, { children: _jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }) }))),
                enableHiding: false,
                size: 40,
            }),
            columnHelper.accessor((row) => row.opportunity_name, {
                id: "name",
                header: "Name",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 1000 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        setCrmOpportunitiesMap((prevCrmOpportunitiesMap) => {
                            const updatedCrmOpportunity = prevCrmOpportunitiesMap.get(parseInt(props.row.id));
                            if (updatedCrmOpportunity) {
                                updatedCrmOpportunity.opportunity_name = newValue;
                                return new Map([
                                    ...prevCrmOpportunitiesMap,
                                    [parseInt(props.row.id), updatedCrmOpportunity],
                                ]);
                            }
                            return prevCrmOpportunitiesMap;
                        });
                        yield updateCrmOpportunity(parseInt(props.row.id), 
                        /* name= */ newValue, 
                        /* accountId= */ undefined);
                    }) })),
                enableHiding: false,
                meta: {
                    fieldId: -1,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor((row) => { var _a, _b, _c, _d; return (_d = (_b = (_a = row.account) === null || _a === void 0 ? void 0 : _a.account_name) !== null && _b !== void 0 ? _b : (_c = row.account) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : ""; }, {
                id: "account",
                header: "Account",
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                            columnId: "account",
                            objectsFetcher: (userQuery, cursor) => __awaiter(void 0, void 0, void 0, function* () { return accountsFetcher(userQuery, /* ids= */ undefined, cursor); }),
                        }, selectedOption: props.row.original.account
                            ? {
                                label: (_a = props.row.original.account.account_name) !== null && _a !== void 0 ? _a : props.row.original.account.name,
                                value: `${props.row.original.account.id}`,
                            }
                            : undefined, onSelectedOptionChange: (option) => __awaiter(void 0, void 0, void 0, function* () {
                            const updatedOpportunity = yield updateCrmOpportunity(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* accountId= */ parseInt(option.value));
                            setCrmOpportunitiesMap((prevMap) => {
                                const existingOpportunity = prevMap.get(parseInt(props.row.id));
                                if (updatedOpportunity) {
                                    return new Map([
                                        ...prevMap,
                                        [
                                            parseInt(props.row.id),
                                            Object.assign(Object.assign({}, existingOpportunity), { account: updatedOpportunity.account }),
                                        ],
                                    ]);
                                }
                                return prevMap;
                            });
                        }) }));
                },
            }),
            columnHelper.accessor("last_updated_time", {
                id: "last_modified_time",
                header: "Last modified time",
                cell: (props) => new Date(props.getValue()).toLocaleString(),
            }),
            columnHelper.accessor("last_call_time", {
                id: "last_call_time",
                header: "Last call time",
                cell: (props) => props.getValue() ? new Date(props.getValue()).toLocaleString() : null,
            }),
            columnHelper.accessor("last_call_link", {
                id: "last_call_link",
                header: "Last call link",
                cell: (props) => (_jsx("span", Object.assign({ className: "link-styles" }, { children: props.getValue() ? (_jsx("a", Object.assign({ href: props.getValue() }, { children: "Link to call" }))) : null }))),
            }),
            columnHelper.accessor("last_call_summary", {
                id: "last_call_summary",
                header: "Last call summary",
                cell: (props) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: props.getValue() })) })), _jsx(TooltipContent, { children: _jsx(ScrollArea, Object.assign({ className: "w-96 max-h-60 overflow-y-auto" }, { children: _jsx("span", Object.assign({ className: "whitespace-pre-wrap" }, { children: props.getValue() })) })) })] }) })),
            }),
            ...(allResearchBlocks !== null && allResearchBlocks !== void 0 ? allResearchBlocks : [])
                .filter((block) => block.research_block_type === "ACCOUNT")
                .map((researchBlock) => columnHelper.accessor((row) => {
                var _a, _b, _c;
                return (_c = (_b = (_a = row.research_block_outputs) === null || _a === void 0 ? void 0 : _a.find((blockOutput) => blockOutput.block_id === researchBlock.id)) === null || _b === void 0 ? void 0 : _b.markdown) !== null && _c !== void 0 ? _c : "";
            }, {
                id: `${researchBlock.id}`,
                header: researchBlock.name,
                cell: (props) => {
                    const content = props.getValue();
                    if (!content || content.length === 0) {
                        return null;
                    }
                    return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: props.getValue() })) })), _jsx(TooltipContent, { children: _jsx(ScrollArea, Object.assign({ className: "w-96 max-h-60 overflow-y-auto" }, { children: _jsx(ReactMarkdown, { children: props.getValue() }) })) })] }) }));
                },
                size: 400,
            })),
            columnHelper.accessor("crm_owner", {
                id: "owner_id",
                header: "Owner",
                cell: (props) => {
                    const crmOwner = props.getValue();
                    return (_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                            columnId: "crm_owner",
                            objectsFetcher: (userQuery, cursor) => __awaiter(void 0, void 0, void 0, function* () {
                                const { results } = yield queryCrmOwners(userQuery, 
                                /* ids= */ undefined, cursor);
                                return {
                                    results: results.map((owner) => ({
                                        value: owner.id.toString(),
                                        label: owner.name || owner.email,
                                    })),
                                };
                            }),
                        }, onSelectedOptionChange: () => { }, selectedOption: crmOwner
                            ? {
                                value: crmOwner.id.toString(),
                                label: crmOwner.name || crmOwner.email,
                            }
                            : undefined }));
                },
            }),
        ];
        return columns;
    }, []);
    const queryClient = useQueryClient();
    const accountsFetcher = (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const accountsQueryResponse = yield queryClient.fetchQuery({
            queryFn: () => {
                const filterConditions = [
                    {
                        where_condition: {
                            field: "name",
                            operator: WiserBackendQueryOperator.LIKE,
                            value: userQuery,
                        },
                    },
                ];
                if (ids) {
                    filterConditions.push({
                        where_condition: {
                            field: "id",
                            operator: WiserBackendQueryOperator.IN,
                            value: ids,
                        },
                    });
                }
                return getPaginatedCrmData("Account", JSON.stringify({
                    table: "Account",
                    order_by_fields: ["name"],
                    structured_filter: {
                        where_condition: {
                            operator: WiserBackendQueryOperator.AND,
                            subconditions: filterConditions,
                        },
                    },
                }), 1, 200);
            },
            queryKey: ["getAccounts", cursor, userQuery, ids],
        });
        return {
            results: accountsQueryResponse.data.map((account) => {
                return {
                    label: getCrmAccountName(account),
                    value: account.id.toString(),
                };
            }),
        };
    });
    const referenceObjectFetcherMap = useMemo(() => {
        return new Map([
            ["account_id", accountsFetcher],
            [
                "owner",
                (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
                    const { results } = yield queryCrmOwners(userQuery, ids, cursor);
                    const ownerOptions = results.map((owner) => ({
                        value: owner.id.toString(),
                        label: owner.name || owner.email,
                    }));
                    // We fetch by ids to restore the filter values in the UI after the user
                    // saves the filter. Essentially if the user applied the filter:
                    // User in [a, b, c] and saves, the only values in the query that get
                    // saved are the ids of a, b and c. And hence to render the name (labels)
                    // of those ids in the UI when the user revisits the page, we first fetch
                    // the Users by ids using this API.
                    // For self, we only need to add it in the result if the user saved the filter
                    // with -99 (special id for self).
                    if (!ids || ids.includes(-99)) {
                        ownerOptions.unshift({
                            value: "-99",
                            label: "Self",
                        });
                    }
                    return {
                        results: ownerOptions.filter((owner) => owner.label.toLowerCase().includes(userQuery.toLowerCase())),
                    };
                }),
            ],
        ]);
    }, []);
    const filterFields = useMemo(() => {
        const filterFields = [
            {
                id: "name",
                name: "Name",
                type: "string",
                meta: {
                    model: "Opportunity",
                },
            },
            {
                id: "account_id",
                name: "Account",
                type: "reference",
                meta: {
                    model: "Opportunity",
                },
            },
            {
                id: "last_call_time",
                name: "Last call time",
                type: "date",
                meta: {
                    // This is added as an annotation in the Opportunity queryset.
                    model: "Opportunity",
                },
                options: [
                    "Today",
                    "Yesterday",
                    "Tomorrow",
                    "Next week",
                    "Last week",
                    "Last 2 weeks",
                    "Last month",
                    "Last year",
                ],
            },
            {
                id: "timestamp",
                name: "Last modified time",
                type: "date",
                meta: {
                    model: "Opportunity",
                },
                options: [
                    "Today",
                    "Yesterday",
                    "Tomorrow",
                    "Next week",
                    "Last week",
                    "Last 2 weeks",
                    "Last month",
                    "Last year",
                ],
            },
            {
                id: "owner",
                name: "Owner",
                type: "reference",
                meta: {
                    model: "Account",
                },
            },
        ];
        return filterFields;
    }, []);
    // Memoize the table data to prevent unnecessary re-renders.
    const tableData = useMemo(() => {
        return Array.from(crmOpportunitiesMap.values());
    }, [crmOpportunitiesMap]);
    const onNewRowCreate = (newRowData, crmFieldValues) => __awaiter(void 0, void 0, void 0, function* () {
        const newCrmOpportunity = yield createCrmOpportunity(newRowData.find((rowData) => rowData.columnId === "name").value, newRowData.find((rowData) => rowData.columnId === "account").value, crmFieldValues);
        const updatedCrmOpportunity = yield getCrmOpportunity(newCrmOpportunity.id);
        setCrmOpportunitiesMap((prevMap) => {
            return new Map([
                ...prevMap,
                [updatedCrmOpportunity.id, updatedCrmOpportunity],
            ]);
        });
    });
    const onCrmFieldValueChange = (opportunityId, field, newValue) => __awaiter(void 0, void 0, void 0, function* () {
        const updatedCrmDataId = yield createOrUpdateCrmOpportunityData(opportunityId, field.id, newValue);
        setCrmOpportunitiesMap((prevMap) => {
            const updatedOpportunity = prevMap.get(opportunityId);
            if (updatedOpportunity) {
                // If crm_data contains a field with the same ID, update the value.
                // else add a new crm_data object.
                if (updatedOpportunity.crm_data.some((crmData) => crmData.crm_field.id === field.id)) {
                    updatedOpportunity.crm_data = updatedOpportunity.crm_data.map((crmData) => crmData.crm_field.id === field.id
                        ? Object.assign(Object.assign({}, crmData), { id: updatedCrmDataId, value: newValue }) : crmData);
                }
                else {
                    updatedOpportunity.crm_data.push({
                        id: updatedCrmDataId,
                        crm_field: field,
                        value: newValue,
                    });
                }
                return new Map([...prevMap, [opportunityId, updatedOpportunity]]);
            }
            return prevMap;
        });
    });
    const { crmFields } = useCrmFields();
    if (!opportunitiesPaginatedData ||
        isLoadingOpportunities ||
        !crmFields ||
        isLoading ||
        isError ||
        !layouts ||
        layouts.length === 0) {
        return (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true }));
    }
    return (_jsx(CrmTableV2, { crmObject: "Opportunity", crmFields: crmFields, tableRows: tableData, totalRows: total, defaultColumns: columns, defaultFilterFields: filterFields, referenceObjectFetcherMap: referenceObjectFetcherMap, columnObjectsFetcher: [
            {
                columnId: "account",
                objectsFetcher: (userQuery, cursor) => __awaiter(void 0, void 0, void 0, function* () { return accountsFetcher(userQuery, /* ids= */ undefined, cursor); }),
            },
        ], defaultTableLayout: DEFAULT_TABLE_LAYOUT, onNewRowCreate: onNewRowCreate, onCrmFieldValueChange: onCrmFieldValueChange, onSearchQueryChange: setSearchQuery, searchParams: searchParams, layouts: layouts, fetchNextPage: fetchNextPage, isFetching: isFetching }));
};
