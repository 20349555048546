import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ExclamationCircleIcon, PencilIcon, UserIcon, UserPlusIcon, UsersIcon, } from "@heroicons/react/24/outline";
import classNames from "../class_names";
import CallCrmDetailsPopover, { classesForPopover, classesForPopoverIcon, } from "./call_crm_details_popover";
import { useState } from "react";
import { CallCrmContactModal } from "./call_crm_contact_modal";
const ParticipantsDetails = ({ tenant, call, openCallCrmContactModal, }) => {
    const renderContact = (contact) => {
        var _a;
        const contactExistsInCrm = !tenant.connected_crm || !!contact.external_id;
        const isExternalContact = !tenant.domain_name || contact.email.split("@")[1] !== tenant.domain_name;
        return (_jsxs("div", Object.assign({ className: "flex items-start gap-4 justify-between self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex items-start gap-4 self-stretch overflow-hidden" }, { children: [_jsx("div", Object.assign({ className: "w-10 h-10 flex items-center rounded-full shrink-0" }, { children: contact.profile_pic && contact.profile_pic.length > 0 ? (_jsx("img", { src: contact.profile_pic_url })) : (_jsx(UserIcon, { className: "p-2 text-wds-gray-4 rounded-full" })) })), _jsxs("div", Object.assign({ className: "flex flex-col items-start font-medium text-wds-gray-6 overflow-hidden" }, { children: [_jsx("span", Object.assign({ className: "font-bold text-black break-all truncate w-full" }, { children: contact.full_name.length > 0
                                        ? contact.full_name
                                        : contact.contact_name && contact.contact_name.length > 0
                                            ? contact.contact_name
                                            : "No name found" })), !contactExistsInCrm && isExternalContact && (_jsxs("div", Object.assign({ className: "flex items-center justify-start gap-1 text-yellow-600" }, { children: [_jsx(ExclamationCircleIcon, { className: "w-4 h-4" }), _jsx("span", { children: "Missing contact." }), _jsx("button", Object.assign({ onClick: () => openCallCrmContactModal(contact.id), className: "text-wds-blue-3" }, { children: "Create" }))] }))), _jsx("span", Object.assign({ className: classNames("break-all truncate w-full", contact.occupation.length > 0 ? "" : "text-wds-gray-4") }, { children: contact.occupation.length > 0
                                        ? contact.occupation
                                        : "No job title" })), _jsx("span", Object.assign({ className: "break-all truncate w-full" }, { children: (_a = contact.account) === null || _a === void 0 ? void 0 : _a.account_name })), _jsx("span", Object.assign({ className: "break-all truncate w-full" }, { children: contact.email }))] }))] })), _jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx("div", Object.assign({ className: "flex w-8 h-8 p-2 items-center justify-center shrink-0" }, { children: _jsx("a", Object.assign({ href: contact.linkedin_profile_url, target: "_blank", rel: "noopener noreferrer", className: "inline-block" }, { children: _jsx("img", { className: "h-4 w-4", src: contact.linkedin_profile_url &&
                                        contact.linkedin_profile_url.length > 0
                                        ? "https://storage.googleapis.com/wiser-ai-public2/linkedin-logo-enable.png"
                                        : "https://storage.googleapis.com/wiser-ai-public2/linkedin-logo-disable.png" }) })) })), _jsx("div", Object.assign({ className: "flex items-center justify-center" }, { children: _jsx("button", Object.assign({ onClick: () => openCallCrmContactModal(contact.id) }, { children: contactExistsInCrm ? (_jsx(PencilIcon, { className: "h-4 w-4 text-wds-blue-4" })) : (_jsx(UserPlusIcon, { className: "h-4 w-4 text-wds-blue-4" })) })) }))] }))] }), contact.id));
    };
    return (_jsx("div", Object.assign({ className: "w-96 flex p-4 flex-col items-start gap-4" }, { children: call.participants.map((c) => renderContact(c)) })));
};
const ContactsPopoverV2 = (props) => {
    const [callCrmContactModalOpen, setCallCrmContactModalOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const isExternalParticipant = (contact) => {
        if (!props.tenant.domain_name) {
            return true;
        }
        return contact.email.split("@")[1] !== props.tenant.domain_name;
    };
    // Boolean indicating whether all the participants of the call exist
    // in the user's CRM.
    const allParticipantsExistInCrm = !props.tenant.connected_crm ||
        props.call.participants
            .filter((p) => isExternalParticipant(p))
            .every((c) => !!c.external_id);
    return (_jsxs(_Fragment, { children: [_jsx(CallCrmDetailsPopover, { button: _jsxs("div", Object.assign({ className: classNames("flex py-[2px] px-2 gap-2 items-center rounded-lg border", classesForPopover(allParticipantsExistInCrm, props.variant)) }, { children: [_jsx(UsersIcon, { className: classNames("w-4 h-4", classesForPopoverIcon(allParticipantsExistInCrm, props.variant)) }), _jsx("div", Object.assign({ className: "font-normal leading-snug" }, { children: `Contacts: ${props.call.participants.length}` }))] })), panel: _jsx(ParticipantsDetails, { tenant: props.tenant, call: props.call, openCallCrmContactModal: (contactId) => {
                        setSelectedContact(props.call.participants.find((p) => p.id === contactId));
                        setCallCrmContactModalOpen(true);
                    } }) }), selectedContact && (_jsx(CallCrmContactModal, { tenant: props.tenant, call: props.call, contact: selectedContact, open: callCrmContactModalOpen, setOpen: setCallCrmContactModalOpen, updateSelectedCall: props.updateSelectedCall }))] }));
};
export default ContactsPopoverV2;
