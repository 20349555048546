import { jsx as _jsx } from "react/jsx-runtime";
import parse from "html-react-parser";
import ProvenancePopover from "./provenance_popover";
import { ProvenanceCard } from "../provenance";
import { ExpandablePostCall, useExpandablePostCall, } from "../components/expandable";
function ResearchBlockWithProvenance({ htmlString, provenanceRecords, collapsable = false, }) {
    // Function to find and modify <span> elements
    const transform = (node) => {
        var _a;
        if (node.type !== "tag" ||
            node.name !== "span" ||
            ((_a = node.attribs) === null || _a === void 0 ? void 0 : _a.markup_type) !== "provenance") {
            return node;
        }
        const record = provenanceRecords.find((r) => { var _a; return String(r.id) === ((_a = node.attribs) === null || _a === void 0 ? void 0 : _a.provenance_id); });
        if (!record) {
            return node;
        }
        // Check if the node has markup_type="provenance"
        // Make the new-lines in popupString visible
        return (_jsx(ProvenancePopover, { button: _jsx("div", Object.assign({ className: "text-blue-500" }, { children: node.children[0].data })), panel: _jsx(ProvenanceCard, { record: record }) }));
    };
    // Parse and modify HTML string
    const parsedHTML = parse(htmlString, { replace: transform });
    if (!collapsable) {
        return _jsx("div", Object.assign({ className: "break-words" }, { children: parsedHTML }));
    }
    const CollapsableHTML = () => {
        const { textBoxRef } = useExpandablePostCall();
        return _jsx("div", Object.assign({ ref: textBoxRef }, { children: parsedHTML }));
    };
    return (_jsx("div", Object.assign({ className: "break-words" }, { children: _jsx(ExpandablePostCall, { showLessContent: _jsx(CollapsableHTML, {}), showMoreContent: _jsx(CollapsableHTML, {}), alwaysShowExpandButton: false, lineClampClass: "line-clamp-[12]" }) })));
}
export default ResearchBlockWithProvenance;
