export const getFilterFieldType = (crmFieldSchema) => {
    switch (crmFieldSchema.type) {
        case "boolean":
            return "boolean";
        case "date":
            return "date";
        case "number":
            return "number";
        default:
            return "string";
    }
};
export const getQueryFieldForCrmField = (crmFieldSchema) => {
    switch (crmFieldSchema.type) {
        case "boolean":
            return "boolean_value";
        case "date":
            return "datetime_value";
        case "number":
            return "number_value";
        default:
            return "value";
    }
};
