import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DocumentTextIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { AccountBlockCard } from "./styled_components";
import { useAccountCalls } from "./use_account_calls";
import { Link } from "react-router-dom";
import { Button } from "../../components/button";
import { STATIC_ACCOUNT_BLOCK_IDS } from "./use_account_research_blocks";
import { useQuery } from "react-query";
import { getAccountSummary } from "../../common/endpoints";
import ResearchBlockWithProvenance from "../../common/research_block_with_provenance";
const Interactions = ({ scrollToBlock, queryCallsResponse, }) => {
    if (!queryCallsResponse)
        return null;
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-5 text-sm font-medium" }, { children: "Interactions" })), _jsx("div", Object.assign({ className: "flex items-start gap-2" }, { children: _jsx(Button, Object.assign({ variant: "link", onClick: () => scrollToBlock(STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CALLS), className: "p-0 h-auto" }, { children: _jsxs("div", Object.assign({ className: "flex items-center gap-1 text-wds-blue-3" }, { children: [_jsx(PhoneIcon, { className: "w-5 h-5 shrink-0" }), _jsx("span", { children: `${queryCallsResponse.total} ${queryCallsResponse.total === 1 ? "call" : "calls"}` })] })) })) }))] })));
};
const NextInteraction = ({ queryCallsResponse, }) => {
    const futureCalls = queryCallsResponse === null || queryCallsResponse === void 0 ? void 0 : queryCallsResponse.calls.filter((call) => new Date(call.call_time) > new Date());
    const nextCall = futureCalls && futureCalls.length > 0 ? futureCalls[0] : undefined;
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-5 text-sm font-medium" }, { children: "Next scheduled call" })), _jsx("div", Object.assign({ className: "flex items-center gap-1" }, { children: nextCall ? (_jsx(Link, Object.assign({ to: `/call/${nextCall.id}`, target: "_blank" }, { children: _jsx(Button, Object.assign({ variant: "link", className: "p-0 h-auto" }, { children: _jsx("span", Object.assign({ className: "text-wds-blue-3" }, { children: nextCall.name })) })) }))) : (_jsx("span", Object.assign({ className: "text-wds-gray-4" }, { children: "No next call scheduled yet" }))) }))] })));
};
const AccountSummary = ({ account, accountSummaryBlock, }) => {
    var _a, _b;
    // Only query this if the account summary block is not provided
    const { data: accountSummary, isFetching: accountSummaryFetching } = useQuery({
        queryKey: ["accountSummary", account.id],
        queryFn: () => getAccountSummary(account.id),
        enabled: !accountSummaryBlock || !accountSummaryBlock.html,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-5 self-stretch text-sm font-medium" }, { children: "Wiser's summary" })), accountSummaryFetching ? (_jsx("span", Object.assign({ className: "text-wds-gray-5 animate-pulse" }, { children: "Generating account summary..." }))) : accountSummaryBlock && accountSummaryBlock.html ? (_jsx(ResearchBlockWithProvenance, { provenanceRecords: accountSummaryBlock.provenance_records, htmlString: accountSummaryBlock.html, collapsable: false })) : (_jsx("span", Object.assign({ className: "text-black self-stretch whitespace-pre-wrap" }, { children: (_b = (_a = accountSummaryBlock === null || accountSummaryBlock === void 0 ? void 0 : accountSummaryBlock.html) !== null && _a !== void 0 ? _a : accountSummary === null || accountSummary === void 0 ? void 0 : accountSummary.summary) !== null && _b !== void 0 ? _b : "No summary available for this account." })))] })));
};
export const AccountOverviewBlock = ({ account, accountSummaryBlock, scrollToBlock, }) => {
    const { queryCallsResponse, callsLoading } = useAccountCalls({
        accountId: account.id,
    });
    return (_jsxs(AccountBlockCard, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx(DocumentTextIcon, { className: "w-6 h-6 shrink-0" }), _jsx("span", Object.assign({ className: "text-lg font-bold" }, { children: "Overview" }))] })), callsLoading || !queryCallsResponse ? (_jsx("span", Object.assign({ className: "text-wds-gray-4 animate-pulse" }, { children: "Fetching calls" }))) : (_jsxs("div", Object.assign({ className: "flex items-start gap-12" }, { children: [_jsx(Interactions, { scrollToBlock: scrollToBlock, queryCallsResponse: queryCallsResponse }), _jsx(NextInteraction, { queryCallsResponse: queryCallsResponse })] }))), _jsx(AccountSummary, { account: account, accountSummaryBlock: accountSummaryBlock })] }));
};
