var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import Badge from "../../common/badge";
import { Popover, PopoverContent, PopoverTrigger, } from "../../components/popover";
import ResearchBlockWithProvenance from "../../common/research_block_with_provenance";
import { AccountBlockCard } from "./styled_components";
import { Button } from "../../components/button";
import { ArrowPathIcon, EllipsisVerticalIcon, PencilIcon, TrashIcon, } from "@heroicons/react/24/outline";
import { CreateOrEditAccountResearchBlockModal } from "./create_or_edit_account_research_block_modal";
import classNames from "../../class_names";
import { getRefreshLabel } from "./refresh_time_utils";
export const AccountResearchBlockCard = ({ account, researchBlock, outputGenerationInProgress, generateResearchBlockOutput, accountResearchBlockOutput, createNewResearchBlock, updateResearchBlock, deleteResearchBlock, }) => {
    var _a;
    const [menuOpen, setMenuOpen] = useState(false);
    const [editBlockConfiguration, setEditBlockConfigurationOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs(AccountBlockCard, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between self-stretch gap-4" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx("span", Object.assign({ className: "text-lg font-bold" }, { children: researchBlock.name })), _jsx(Badge, { text: "Custom research block", classes: "bg-wds-gray-1", textClasses: "text-wds-gray-6 font-medium text-sm" })] })), _jsxs("div", Object.assign({ className: "flex gap-1 items-center" }, { children: [accountResearchBlockOutput && (_jsx("span", Object.assign({ className: "text-wds-gray-5 text-xs font-medium pr-2" }, { children: getRefreshLabel((new Date().getTime() -
                                            new Date(accountResearchBlockOutput.last_update_timestamp).getTime()) /
                                            1000) }))), _jsx(Button, Object.assign({ variant: "ghost", className: "flex items-center self-start gap-1 p-0 hover:bg-transparent text-wds-gray-5 ", onClick: (e) => {
                                            e.stopPropagation();
                                            setMenuOpen(false);
                                            generateResearchBlockOutput(account.id, researchBlock.id, 
                                            /*forceRefresh=*/ true);
                                        }, disabled: outputGenerationInProgress }, { children: _jsx(ArrowPathIcon, { className: classNames("w-4 h-4 relative", outputGenerationInProgress ? "animate-spin" : "") }) })), _jsxs(Popover, Object.assign({ open: menuOpen, onOpenChange: setMenuOpen }, { children: [_jsx(PopoverTrigger, Object.assign({ type: "button", onClick: (e) => {
                                                    setMenuOpen((prev) => !prev);
                                                    e.stopPropagation();
                                                }, className: "text-wds-black hover:text-wds-black rounded-full p-2 hover:bg-wds-gray-1" }, { children: _jsx(EllipsisVerticalIcon, { className: "w-5 h-5" }) })), _jsx(PopoverContent, Object.assign({ side: "bottom", align: "end", sideOffset: 0 }, { children: _jsxs("div", Object.assign({ className: "flex flex-col w-max bg-white items-start" }, { children: [_jsxs(Button, Object.assign({ variant: "ghost", className: "flex items-center self-start gap-1 p-0 hover:bg-transparent", onClick: (e) => {
                                                                e.stopPropagation();
                                                                setMenuOpen(false);
                                                                setEditBlockConfigurationOpen(true);
                                                            }, disabled: outputGenerationInProgress }, { children: [_jsx(PencilIcon, { className: "w-4 h-4 relative" }), "Edit block configuration"] })), _jsxs(Button, Object.assign({ variant: "ghost", className: "flex items-center self-start gap-1 p-0 hover:bg-transparent", onClick: (e) => __awaiter(void 0, void 0, void 0, function* () {
                                                                // TODO: confirm modal, unfortunately the common confirm modal doesn't work in drawer
                                                                e.stopPropagation();
                                                                yield deleteResearchBlock(researchBlock.id);
                                                                setMenuOpen(false);
                                                            }), disabled: outputGenerationInProgress }, { children: [_jsx(TrashIcon, { className: "w-4 h-4 relative text-red-600" }), "Delete block"] }))] })) }))] }))] }))] })), outputGenerationInProgress ? (_jsx("span", Object.assign({ className: "animate-pulse text-sm text-wds-gray-5 flex items-center" }, { children: "Generating output..." }))) : (accountResearchBlockOutput === null || accountResearchBlockOutput === void 0 ? void 0 : accountResearchBlockOutput.html) ? (_jsx("div", { children: _jsx(ResearchBlockWithProvenance, { htmlString: accountResearchBlockOutput.html, provenanceRecords: accountResearchBlockOutput.provenance_records, collapsable: true }) })) : (_jsx("span", { children: (_a = accountResearchBlockOutput === null || accountResearchBlockOutput === void 0 ? void 0 : accountResearchBlockOutput.error_message) !== null && _a !== void 0 ? _a : "No information available" }))] }), editBlockConfiguration && (_jsx(CreateOrEditAccountResearchBlockModal, { open: editBlockConfiguration, onClose: () => setEditBlockConfigurationOpen(false), accountResearchBlock: researchBlock, createNewResearchBlock: createNewResearchBlock, updateResearchBlock: updateResearchBlock }))] }));
};
