var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "../class_names";
import { Badge } from "./badge";
import { Command, CommandItem, CommandEmpty, CommandList } from "./command";
import { Command as CommandPrimitive } from "cmdk";
import { createContext, forwardRef, useCallback, useContext, useState, } from "react";
const MultiSelectContext = createContext(null);
const useMultiSelect = () => {
    const context = useContext(MultiSelectContext);
    if (!context) {
        throw new Error("useMultiSelect must be used within MultiSelectProvider");
    }
    return context;
};
/**
 * Multiselect component, fully supports keyboard actions, backspace removes selected items
 * Pressing enter selects items, escape closes the dropdown
 * Component is fully styled according to primary and secondary theme
 * Example usage
 * ```
 * <MultiSelector values={value} onValuesChange={setValue} loop className="max-w-xs">
    <MultiSelectorTrigger>
      <MultiSelectorInput placeholder="Select CRM account" />
    </MultiSelectorTrigger>
    <MultiSelectorContent>
      <MultiSelectorList>
        <MultiSelectorItem value={"anirudh@getwiser.io"}>Anirudh</MultiSelectorItem>
        <MultiSelectorItem value={"nitin@getwiser.io"}>Nitin</MultiSelectorItem>
        <MultiSelectorItem value={"harsh@getwiser.io"}>Harsh</MultiSelectorItem>
      </MultiSelectorList>
    </MultiSelectorContent>
  </MultiSelector>
  ```
 * @param param0
 * @returns
 */
const MultiSelector = (_a) => {
    var { values: value, onValuesChange: onValueChange, loop = false, className, children, dir } = _a, props = __rest(_a, ["values", "onValuesChange", "loop", "className", "children", "dir"]);
    const [inputValue, setInputValue] = useState("");
    const [open, setOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const onValueChangeHandler = useCallback((val) => {
        if (value.includes(val)) {
            onValueChange(value.filter((item) => item !== val));
        }
        else {
            onValueChange([...value, val]);
        }
    }, [value]);
    // TODO : change from else if use to switch case statement
    const handleKeyDown = useCallback((e) => {
        const moveNext = () => {
            const nextIndex = activeIndex + 1;
            setActiveIndex(nextIndex > value.length - 1 ? (loop ? 0 : -1) : nextIndex);
        };
        const movePrev = () => {
            const prevIndex = activeIndex - 1;
            setActiveIndex(prevIndex < 0 ? value.length - 1 : prevIndex);
        };
        if ((e.key === "Backspace" || e.key === "Delete") && value.length > 0) {
            if (inputValue.length === 0) {
                if (activeIndex !== -1 && activeIndex < value.length) {
                    onValueChange(value.filter((item) => item !== value[activeIndex]));
                    const newIndex = activeIndex - 1 < 0 ? 0 : activeIndex - 1;
                    setActiveIndex(newIndex);
                }
                else {
                    onValueChange(value.filter((item) => item !== value[value.length - 1]));
                }
            }
        }
        else if (e.key === "Enter") {
            setOpen(true);
        }
        else if (e.key === "Escape") {
            if (activeIndex !== -1) {
                setActiveIndex(-1);
            }
            else {
                setOpen(false);
            }
        }
        else if (dir === "rtl") {
            if (e.key === "ArrowRight") {
                movePrev();
            }
            else if (e.key === "ArrowLeft" && (activeIndex !== -1 || loop)) {
                moveNext();
            }
        }
        else {
            if (e.key === "ArrowLeft") {
                movePrev();
            }
            else if (e.key === "ArrowRight" && (activeIndex !== -1 || loop)) {
                moveNext();
            }
        }
    }, [value, inputValue, activeIndex, loop]);
    return (_jsx(MultiSelectContext.Provider, Object.assign({ value: {
            value,
            onValueChange: onValueChangeHandler,
            open,
            setOpen,
            inputValue,
            setInputValue,
            activeIndex,
            setActiveIndex,
        } }, { children: _jsx(Command, Object.assign({ onKeyDown: handleKeyDown, className: classNames("overflow-visible bg-transparent flex flex-col space-y-2", className), dir: dir }, props, { children: children })) })));
};
const MultiSelectorTrigger = forwardRef((_a, ref) => {
    var { className, children } = _a, props = __rest(_a, ["className", "children"]);
    const { value, onValueChange, activeIndex } = useMultiSelect();
    const mousePreventDefault = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);
    return (_jsxs("div", Object.assign({ ref: ref, className: classNames("flex flex-wrap gap-1 p-1 py-2 border border-muted rounded-lg bg-background", className) }, props, { children: [value.map((item, index) => (_jsxs(Badge, Object.assign({ className: classNames("px-1 rounded-xl flex items-center gap-1", activeIndex === index && "ring-2 ring-muted-foreground "), variant: "secondary" }, { children: [_jsx("span", Object.assign({ className: "text-xs" }, { children: item })), _jsxs("button", Object.assign({ "aria-label": `Remove ${item} option`, "aria-roledescription": "button to remove option", type: "button", onMouseDown: mousePreventDefault, onClick: () => onValueChange(item) }, { children: [_jsxs("span", Object.assign({ className: "sr-only" }, { children: ["Remove ", item, " option"] })), _jsx(XMarkIcon, { className: "h-4 w-4 hover:stroke-destructive" })] }))] }), item))), children] })));
});
MultiSelectorTrigger.displayName = "MultiSelectorTrigger";
const MultiSelectorInput = forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    const { setOpen, inputValue, setInputValue, activeIndex, setActiveIndex } = useMultiSelect();
    return (_jsx(CommandPrimitive.Input, Object.assign({}, props, { ref: ref, value: inputValue, onValueChange: activeIndex === -1 ? setInputValue : undefined, onBlur: () => setOpen(false), onFocus: () => setOpen(true), onClick: () => setActiveIndex(-1), className: classNames("ml-2 bg-transparent outline-none placeholder:text-muted-foreground flex-1", className, activeIndex !== -1 && "caret-transparent") })));
});
MultiSelectorInput.displayName = "MultiSelectorInput";
const MultiSelectorContent = forwardRef(({ children }, ref) => {
    const { open } = useMultiSelect();
    return (_jsx("div", Object.assign({ ref: ref, className: "relative" }, { children: open && children })));
});
MultiSelectorContent.displayName = "MultiSelectorContent";
const MultiSelectorList = forwardRef(({ className, children }, ref) => {
    return (_jsxs(CommandList, Object.assign({ ref: ref, className: classNames("p-2 flex flex-col gap-2 rounded-md scrollbar-thin scrollbar-track-transparent transition-colors scrollbar-thumb-muted-foreground dark:scrollbar-thumb-muted scrollbar-thumb-rounded-lg w-full absolute bg-background shadow-md z-10 border border-muted top-0", className) }, { children: [children, _jsx(CommandEmpty, { children: _jsx("span", Object.assign({ className: "text-muted-foreground" }, { children: "No results found" })) })] })));
});
MultiSelectorList.displayName = "MultiSelectorList";
const MultiSelectorItem = forwardRef((_a, ref) => {
    var { className, value, children } = _a, props = __rest(_a, ["className", "value", "children"]);
    const { value: Options, onValueChange, setInputValue } = useMultiSelect();
    const mousePreventDefault = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);
    const isIncluded = Options.includes(value);
    return (_jsxs(CommandItem, Object.assign({ ref: ref }, props, { onSelect: () => {
            onValueChange(value);
            setInputValue("");
        }, className: classNames("rounded-md cursor-pointer px-2 py-1 transition-colors flex justify-between ", className, isIncluded && "opacity-50 cursor-default", props.disabled && "opacity-50 cursor-not-allowed"), onMouseDown: mousePreventDefault }, { children: [children, isIncluded && _jsx(CheckIcon, { className: "h-4 w-4" })] })));
});
MultiSelectorItem.displayName = "MultiSelectorItem";
export { MultiSelector, MultiSelectorTrigger, MultiSelectorInput, MultiSelectorContent, MultiSelectorList, MultiSelectorItem, };
