export const getConnectedCrmLogo = (connectedCrm) => {
    if (!connectedCrm) {
        return "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png";
    }
    switch (connectedCrm) {
        case "Salesforce":
            return "https://storage.googleapis.com/wiser-ai-public2/salesforce_logo_color.png";
        case "HubSpot":
            return "https://cdn.useparagon.com/3.9.2/dashboard/public/integrations/hubspot.svg";
        default:
            return "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png";
    }
};
