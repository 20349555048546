import tw from "tailwind-styled-components";
export const DropdownMenuButtonContainer = tw.div `
  flex
  h-8
  px-3
  items-center
  gap-2
  hover:cursor-pointer
  border
  border-wds-gray-3
  rounded-lg
  justify-between
  self-strech
  bg-white
`;
