import { useEffect, useMemo, useState } from "react";
import { QueryBuilder } from "./api";
/**
 * This is a custom hook that provides React state management for Wiser-Frontend filters,
 * and the APIs to interact with the filter state.
 * Under the hood this invokes the QueryBuilder APIs to manage the filters but if clients
 * want to react to the filter changes, they can use this hook.
 *
 * Example usage:
 *
 *  const MyReactComponentThatRequiresFilters = () => {
 *      const filterConfig: QueryBuilderConfig = {
 *        fields: <an Array of all the fields as per `FilterField`>
 *        maxSubConditions: 3 // Maximum number of subconditions allowed per filter group
 *      };
 *
 *      const filterManager = useQueryFilter(filterConfig);
 *      const filterConverter = new WiserBackendQueryConverter(filterConfig);
 *
 *      const navigate = useNavigate();
 *
 *      useEffect(() => {
 *        // Add side-effect on filter change like reloading the page.
 *        const backendFilter = filterConverter.convertQueryFilterToBackendFilter(
 *          filterManager.state.queryFilter
 *        );
 *        const backendFilterQueryParam = convertBackendFilterToSearchParams(backendFilter);
 *        navigate(`${
 *          createQueryString({
 *            page: 1,
 *            page_size: 20,
 *            filter: backendFilterQueryParam,
 *          })
 *        }`);
 *      }, [filterManager.state.queryFilter])
 *
 *      return (
 *        <button onClick={() => filterManager.addFilter(createFilter(...))}>
 *          Add filter
 *        </button>
 *        ...
 *      );
 *  }
 *
 *
 * @param config Parameter of type `QueryBuilderConfig` to define the filter config
 * @returns
 */
export const useQueryFilter = (config, initialFilter) => {
    const [queryFilter, setQueryFilter] = useState(null);
    const queryBuilder = useMemo(() => {
        return new QueryBuilder(config);
    }, [config]);
    useEffect(() => {
        if (!queryBuilder || !initialFilter) {
            return;
        }
        if (initialFilter && queryBuilder.getQueryFilter()) {
            // This means we switched layouts, we need to reset the filter
            const filterGroup = queryBuilder.getQueryFilter();
            queryBuilder.removeFilterGroup(undefined, filterGroup.id);
        }
        addFilterGroup(/* filterGroupId= */ undefined, initialFilter);
    }, [queryBuilder, initialFilter]);
    // Adds a filter to a specific filter group.
    // This will throw an error if the maxSubConditions have reached for the
    // `parentFilterGroup`.
    const addFilter = (filterGroupId, filter) => {
        queryBuilder.addFilter(filterGroupId, Object.assign({}, filter));
        updateQueryFilter();
    };
    // Updates an existing filter in a filter group.
    const updateFilter = (filterGroupId, filterId, updatedFilter) => {
        queryBuilder.updateFilter(filterGroupId, filterId, Object.assign({}, updatedFilter));
        updateQueryFilter();
    };
    // Removes an existing filter from a filter group.
    const removeFilter = (filterGroupId, filterId) => {
        queryBuilder.removeFilter(filterGroupId, filterId);
        updateQueryFilter();
    };
    // Adds a top-level or nested filter group
    // This will throw an error if the maxSubConditions have reached for the
    // `parentFilterGroup`.
    const addFilterGroup = (parentFilterGroupId, filterGroup) => {
        queryBuilder.addFilterGroup(parentFilterGroupId, Object.assign({}, filterGroup));
        updateQueryFilter();
    };
    // Updates an existing filter group (could be a top-level group or a nested group)
    const updateFilterGroup = (parentFilterGroupId, filterGroupId, updatedFilterGroup) => {
        queryBuilder.updateFilterGroup(parentFilterGroupId, filterGroupId, Object.assign({}, updatedFilterGroup));
        updateQueryFilter();
    };
    // Removes an existing filter group.
    const removeFilterGroup = (parentFilterGroupId, filterGroupId) => {
        queryBuilder.removeFilterGroup(parentFilterGroupId, filterGroupId);
        updateQueryFilter();
    };
    const updateQueryFilter = () => {
        const updatedFilter = structuredClone(queryBuilder.getQueryFilter());
        setQueryFilter(updatedFilter);
    };
    const getQueryFilter = () => {
        return queryBuilder.getQueryFilter();
    };
    return {
        queryFilter,
        addFilter,
        updateFilter,
        removeFilter,
        addFilterGroup,
        updateFilterGroup,
        removeFilterGroup,
        getQueryFilter,
    };
};
