import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { PostCallOutputTarget, } from "../types";
import { useEffect, useState } from "react";
import classNames from "../class_names";
import FieldTargetPopover from "./field_target_popover";
import { getFieldSchemaForPostCallOutputItem, isCrmEventPushItem, isEmailItem, } from "./utils";
import { SalesforceTargetItem } from "./sfdc_target_renderers";
import { SlackTargetItem } from "./slack_target_renderers";
import { EmptyTargetFieldMappingValue } from "./field_mapping_new_value";
import { WiserCrmTargetItem } from "./wiser_crm_target_renderers";
import { FieldMappingCardValue } from "./field_mapping_card_value";
import { ArrowPathIcon, ArrowUturnLeftIcon, Cog8ToothIcon, } from "@heroicons/react/24/outline";
import { Button } from "../components/button";
import { HubspotTargetItem } from "./hubspot_target_renderer";
import { CrmEventTargetItem } from "./crm_event_target_renderers";
import tw from "tailwind-styled-components";
const getPlaybookScoresMap = (score) => {
    let playbookScoreColors = ["wds-gray-2", "wds-gray-2", "wds-gray-2"];
    if (score > 0) {
        if (score >= 0.8) {
            playbookScoreColors = ["wds-green-1", "wds-green-1", "wds-green-1"];
        }
        else if (score >= 0.6) {
            playbookScoreColors = ["wds-lime-1", "wds-lime-1", "wds-gray-2"];
        }
        else if (score >= 0.1) {
            playbookScoreColors = ["wds-yellow-1", "wds-gray-2", "wds-gray-2"];
        }
    }
    return playbookScoreColors;
};
const TrackerItemScoreContainer = tw.div `
    rounded-full
    overflow-hidden
    inline-flex
    justify-start
    items-start
    gap-px
    shrink-0
`;
const TrackerItemScoreBlock = tw.div `
    w-3 h-4
`;
const SourcePlaybookItem = ({ playbookItemName, playbookItemScore, }) => {
    return (_jsxs("div", Object.assign({ className: "pr-2 py-1 flex items-center gap-4" }, { children: [_jsx(TrackerItemScoreContainer, { children: getPlaybookScoresMap(playbookItemScore).map((color, idx) => (_jsx(TrackerItemScoreBlock, { className: "w-2 h-4 bg-" + color }, idx))) }), _jsx("div", Object.assign({ className: "text-sm font-semibold font-black" }, { children: playbookItemName }))] })));
};
const SourceManualNote = () => {
    return (_jsxs("div", Object.assign({ className: "pr-2 py-1 flex items-center gap-4" }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png", className: "w-6 h-6" }), _jsxs("div", Object.assign({ className: "inline-flex flex-col items-start" }, { children: [_jsx("span", Object.assign({ className: classNames("flex items-center self-stretch gap-2", "text-xs font-medium font-black") }, { children: "Manual note" })), _jsx("span", Object.assign({ className: "text-sm font-semibold font-black" }, { children: "Notes captured during the call" }))] }))] })));
};
const NoSourceRenderer = () => {
    return (_jsxs("div", Object.assign({ className: "pr-2 py-1 flex items-center gap-4" }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png", className: "w-6 h-6" }), _jsx("div", Object.assign({ className: "inline-flex flex-col items-start" }, { children: _jsx("span", Object.assign({ className: "text-sm font-semibold font-black" }, { children: "Meeting details from Wiser" })) }))] })));
};
const FieldMappingTarget = ({ call, postCallOutputItem, itemLoadingStatus, onDataItemTargetUpdate, onDataItemValueUpdate, postCallIntegrations, integrationsData, executePostCallAfterNMinutes, onPostCallOutputRegenerateClicked, postCallOutputRegenerationInProgress, undoStack, }) => {
    var _a;
    const [showUndoButton, setShowUndoButton] = useState(false);
    useEffect(() => {
        setShowUndoButton(undoStack.length > 0);
    }, [undoStack]);
    const onUndoClick = () => {
        onDataItemValueUpdate(undoStack.pop(), /* undoClicked= */ true);
        setShowUndoButton(undoStack.length > 0);
    };
    const processing = itemLoadingStatus === "SYNCING" ||
        itemLoadingStatus === "UPDATING" ||
        itemLoadingStatus === "USER_EDITING";
    return (_jsxs("div", Object.assign({ className: "flex flex-row w-full items-center justify-between py-2 px-4 rounded-t-lg" }, { children: [_jsx("div", Object.assign({ className: classNames("flex flex-row gap-4 items-center", postCallOutputItem.state === "IGNORED" ? "opacity-50" : "") }, { children: postCallOutputItem.fieldMap.sourcePlaybook &&
                    postCallOutputItem.fieldMap.sourcePlaybookItem ? (_jsx(SourcePlaybookItem, { playbookItemName: postCallOutputItem.fieldMap.sourcePlaybookItem.name, playbookItemScore: (_a = postCallOutputItem.playbookItemScore) !== null && _a !== void 0 ? _a : 0.0 })) : postCallOutputItem.fieldMap.sourceManualNoteId ? (_jsx(SourceManualNote, {})) : (_jsx(NoSourceRenderer, {})) })), _jsxs("div", Object.assign({ className: "flex flex-row space-x-2 items-center relative" }, { children: [showUndoButton && (_jsx(Button, Object.assign({ variant: "ghost", className: "flex h-auto p-0 hover:bg-wds-gray-2 rounded-full h-6 w-6", onClick: onUndoClick }, { children: _jsx(ArrowUturnLeftIcon, { className: "w-5 h-5 text-wds-gray-5" }) }))), _jsx(Button, Object.assign({ variant: "ghost", onClick: onPostCallOutputRegenerateClicked, disabled: postCallOutputRegenerationInProgress, className: "flex h-auto p-0 hover:bg-wds-gray-2 rounded-full h-6 w-6" }, { children: _jsx(ArrowPathIcon, { className: classNames("w-5 h-5 shrink-0 text-wds-gray-5 hover:text-black", postCallOutputRegenerationInProgress ? "animate-spin" : "") }) })), _jsx(FieldTargetPopover, { call: call, postCallOutputItem: postCallOutputItem, executePostCallAfterNMinutes: executePostCallAfterNMinutes, popoverButton: _jsx(Cog8ToothIcon, { className: "w-5 h-5 text-wds-gray-5" }), disabled: isCrmEventPushItem(postCallOutputItem) ||
                            processing ||
                            isEmailItem(postCallOutputItem), onDataItemTargetUpdate: onDataItemTargetUpdate, postCallIntegrations: postCallIntegrations, integrationsData: integrationsData })] }))] })));
};
const FieldMappingValue = ({ postCallOutputItem, onDataItemValueUpdate, toggleDataItemEditOpen, fieldSchema, showTrackerNotes, postCallOutputRegenerationInProgress, onPostCallOutputRegenerateClicked, onSendClick, provenanceRecords, }) => {
    return (_jsx(_Fragment, { children: postCallOutputItem.target ||
            isEmailItem(postCallOutputItem) ||
            isCrmEventPushItem(postCallOutputItem) ? (_jsx(FieldMappingCardValue, { postCallOutputItem: postCallOutputItem, onDataItemValueUpdate: onDataItemValueUpdate, toggleDataItemEditOpen: toggleDataItemEditOpen, fieldSchema: fieldSchema, showCurrentFieldValue: 
            // TODO: This should be moved to the integration config
            postCallOutputItem.target === PostCallOutputTarget.SALESFORCE ||
                postCallOutputItem.target === PostCallOutputTarget.WISER_CRM ||
                postCallOutputItem.target === PostCallOutputTarget.HUBSPOT, showTrackerNotes: showTrackerNotes, postCallOutputRegenerationInProgress: postCallOutputRegenerationInProgress, onPostCallOutputRegenerateClicked: onPostCallOutputRegenerateClicked, onSendClick: onSendClick, provenanceRecords: provenanceRecords })) : (_jsx(EmptyTargetFieldMappingValue, { postCallOutputItem: postCallOutputItem, provenanceRecords: provenanceRecords })) }));
};
const FieldMappingCard = (props) => {
    const fieldSchema = getFieldSchemaForPostCallOutputItem(props.postCallOutputItem, props.integrationsData);
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-stretch gap-2 border border-solid border-gray-300 rounded-lg bg-white text-sm" }, { children: [_jsx(FieldMappingTarget, { call: props.call, undoStack: props.undoStack, postCallOutputItem: props.postCallOutputItem, itemLoadingStatus: props.itemLoadingStatus, onDataItemTargetUpdate: props.onDataItemTargetUpdate, onDataItemValueUpdate: props.onDataItemValueUpdate, postCallIntegrations: props.postCallIntegrations, integrationsData: props.integrationsData, executePostCallAfterNMinutes: props.executePostCallAfterNMinutes, postCallOutputRegenerationInProgress: props.postCallOutputRegenerationInProgress, onPostCallOutputRegenerateClicked: props.onPostCallOutputRegenerateClicked }), _jsx(FieldMappingValue, { postCallOutputItem: props.postCallOutputItem, onDataItemValueUpdate: props.onDataItemValueUpdate, toggleDataItemEditOpen: props.toggleDataItemEditOpen, fieldSchema: fieldSchema, showTrackerNotes: !!props.showTrackerNotes, postCallOutputRegenerationInProgress: props.postCallOutputRegenerationInProgress, onPostCallOutputRegenerateClicked: props.onPostCallOutputRegenerateClicked, onSendClick: props.onSendClick, provenanceRecords: props.provenanceRecords })] })));
};
const TargetItemSummary = ({ postCallOutputItem, }) => {
    const renderTargetItem = () => {
        switch (postCallOutputItem.target) {
            case PostCallOutputTarget.SALESFORCE:
                return (_jsx(SalesforceTargetItem, { targetDetails: postCallOutputItem.targetDetails }));
            case PostCallOutputTarget.SLACK:
                return (_jsx(SlackTargetItem, { targetDetails: postCallOutputItem.targetDetails }));
            case PostCallOutputTarget.WISER_CRM:
                return (_jsx(WiserCrmTargetItem, { targetDetails: postCallOutputItem.targetDetails }));
            case PostCallOutputTarget.CRM_EVENT:
                return (_jsx(CrmEventTargetItem, { targetDetails: postCallOutputItem.targetDetails }));
            case PostCallOutputTarget.HUBSPOT:
                return (_jsx(HubspotTargetItem, { targetDetails: postCallOutputItem.targetDetails }));
        }
    };
    return (_jsxs("div", Object.assign({ className: classNames("px-2 py-1 flex items-center gap-4 shrink-0") }, { children: [!postCallOutputItem.target && (_jsx("span", Object.assign({ className: "font-bold" }, { children: "No target selected" }))), renderTargetItem()] })));
};
const FieldMappingTargetSummary = ({ postCallOutputItem, }) => {
    return (_jsx("div", Object.assign({ className: "inline-flex flex-row items-stretch items-center justify-between py-1 px-4 bg-gray-100 rounded" }, { children: _jsxs("div", Object.assign({ className: classNames("inline-flex flex-row gap-4 items-center", postCallOutputItem.state === "IGNORED" ? "opacity-50" : "") }, { children: [postCallOutputItem.fieldMap.sourcePlaybook &&
                    postCallOutputItem.fieldMap.sourcePlaybookItem ? (_jsx(SourcePlaybookItem, { playbookItemName: postCallOutputItem.fieldMap.sourcePlaybookItem.name, playbookItemScore: 0.0 })) : (_jsx(SourceManualNote, {})), _jsx(ArrowRightIcon, { className: "w-5 h-5" }), _jsx(TargetItemSummary, { postCallOutputItem: postCallOutputItem })] })) })));
};
/**
 * This component is used to render the summary of a post call output item
 * before the complete details are fetched for the post call output item.
 * This renders a loading spinner on top of the value summary to indicate
 * that the details are being fetched.
 *
 * @param postCallOutputItem The post call output item object to render
 * @returns
 */
const FieldMappingCardSummary = ({ postCallOutputItem, }) => {
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-stretch gap-2 p-1 border border-solid border-gray-300 rounded-lg bg-white text-sm" }, { children: [_jsx(FieldMappingTargetSummary, { postCallOutputItem: postCallOutputItem }), _jsx(EmptyTargetFieldMappingValue, { loading: true, postCallOutputItem: postCallOutputItem, provenanceRecords: [] })] })));
};
export { FieldMappingCardSummary };
export default FieldMappingCard;
