import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowsUpDownIcon, ChevronDownIcon, XMarkIcon, } from "@heroicons/react/24/outline";
import { Popover, PopoverContent, PopoverTrigger, } from "../../../components/popover";
import { ScrollArea } from "../../../components/scroll_area";
import { useSortRule } from "../use_sort_rule";
import { Button } from "../../../components/button";
import { v4 as uuid4 } from "uuid";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, } from "../../../components/dropdown_menu";
import { DropdownMenuButtonContainer } from "./styled_components";
import { getOrderLabel } from "../config";
import { useMemo } from "react";
import { WiserBackendSortRuleConverter } from "../backend_order_by_clause_converter";
import { SearchableDropdown } from "../../searchable_dropdown";
export const SortRuleBuilderPopover = ({ title, panelTitle, config, onSortRulesChange, initialSortRules, }) => {
    const sortRuleManager = useSortRule(config, initialSortRules);
    const wiserBackendSortRuleConverter = useMemo(() => {
        return new WiserBackendSortRuleConverter(config);
    }, [config]);
    const renderFieldSelector = (sortRule) => {
        return (_jsx(SearchableDropdown, { options: config.fields.map((field) => {
                return {
                    label: field.name,
                    value: field.id,
                };
            }), selectedOption: {
                label: sortRule.field.name,
                value: sortRule.field.id,
            }, onSelect: (option) => {
                const field = config.fields.find((field) => field.id === option.value);
                sortRuleManager.updateSortRule(sortRule.id, Object.assign(Object.assign({}, sortRule), { field }));
            }, buttonClasses: "w-52 rounded-none rounded-l-lg" }));
    };
    const renderSortOrderSelector = (sortRule) => {
        return (_jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, Object.assign({ asChild: true }, { children: _jsxs(DropdownMenuButtonContainer, Object.assign({ className: "w-52 rounded-none rounded-r-lg" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-medium truncate text-ellipsis" }, { children: getOrderLabel(sortRule.field.type, sortRule.sortOrder) })), _jsx(ChevronDownIcon, { className: "h-5 w-5 shrink-0" })] })) })), _jsx(DropdownMenuContent, Object.assign({ align: "start", className: "max-h-52 overflow-y-auto" }, { children: _jsxs(ScrollArea, { children: [_jsx(DropdownMenuItem, Object.assign({ onClick: () => {
                                    sortRuleManager.updateSortRule(sortRule.id, Object.assign(Object.assign({}, sortRule), { sortOrder: "ASC" }));
                                }, className: "hover:cursor-pointer text-sm" }, { children: getOrderLabel(sortRule.field.type, "ASC") }), `${sortRule.id}-asc`), _jsx(DropdownMenuItem, Object.assign({ onClick: () => {
                                    sortRuleManager.updateSortRule(sortRule.id, Object.assign(Object.assign({}, sortRule), { sortOrder: "DESC" }));
                                }, className: "hover:cursor-pointer text-sm" }, { children: getOrderLabel(sortRule.field.type, "DESC") }), `${sortRule.id}-desc`)] }) }))] }));
    };
    const renderSortRule = (sortRule) => {
        return (_jsxs("div", Object.assign({ className: "flex items-center gap-4" }, { children: [_jsxs("div", Object.assign({ className: "flex" }, { children: [renderFieldSelector(sortRule), renderSortOrderSelector(sortRule)] })), _jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsx(Button, Object.assign({ variant: "ghost", className: "h-auto p-0 hover:bg-transparent text-wds-gray-5", onClick: () => {
                            sortRuleManager.removeSortRule(sortRule.id);
                        } }, { children: _jsx(XMarkIcon, { className: "w-5 h-5 shrink-0" }) })) }))] }), sortRule.id));
    };
    if (!config.fields.length) {
        return null;
    }
    return (_jsxs(Popover, Object.assign({ onOpenChange: (openState) => {
            if (openState)
                return;
            // We dispatch a "sort rules changed" event when the user closes
            // the sort rule builder popover.
            const feSortRules = sortRuleManager.getSortRules();
            if (!feSortRules || feSortRules.length === 0) {
                // If the user has removed all the sort rules, dispatches sort rules changed
                // event with "undefined".
                onSortRulesChange(/* updatedFilter= */ undefined);
                return;
            }
            const beOrderByClauses = wiserBackendSortRuleConverter.convertSortRulesToBackendOrderBy(feSortRules);
            onSortRulesChange(beOrderByClauses);
        } }, { children: [_jsx(PopoverTrigger, Object.assign({ className: "focus:outline-none hover:bg-gray-200 rounded-lg py-1" }, { children: _jsxs("div", Object.assign({ className: "flex px-3 gap-2 items-center text-gray-500 font-bold text-sm" }, { children: [_jsx(ArrowsUpDownIcon, { className: "h-4 w-4" }), _jsx("span", { children: title !== null && title !== void 0 ? title : "Sort" })] })) })), _jsx(PopoverContent, Object.assign({ className: "w-max py-4 px-6" }, { children: _jsx(ScrollArea, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-2" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-5 text-sm font-bold" }, { children: panelTitle })), sortRuleManager.getSortRules().length > 0 && (_jsx(Button, Object.assign({ variant: "ghost", className: "h-auto p-0 text-sm font-bold text-wds-blue-3 hover:bg-transparent", onClick: sortRuleManager.removeAllSortRules }, { children: "Clear all" })))] })), _jsx("div", Object.assign({ className: "flex flex-col items-start gap-1 pl-3" }, { children: sortRuleManager.getSortRules().map(renderSortRule) })), sortRuleManager.getSortRules().length < config.maxConditions && (_jsx(Button, Object.assign({ variant: "ghost", className: "h-auto py-0 px-3 text-sm font-bold text-wds-blue-3 hover:bg-transparent", onClick: () => {
                                    sortRuleManager.addSortRule({
                                        id: uuid4(),
                                        field: config.fields[0],
                                        sortOrder: "ASC",
                                    });
                                } }, { children: "+ Add sorting rule" })))] })) }) }))] })));
};
