import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import classNames from "../class_names";
import { Switch } from "@headlessui/react";
import MultiSelectDropdown from "../common/multi_select_dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cva } from "class-variance-authority";
import { DropdownMenu, DropdownMenuItem, DropdownMenuContent, DropdownMenuTrigger, } from "../components/dropdown_menu";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ScrollArea } from "../components/scroll_area";
import { DropdownMenuButtonContainer } from "./styled_components";
const textFieldVariants = cva("px-3 py-1 rounded-lg focus:outline-none focus:ring-0 border border-solid resize-none font-medium text-sm placeholder:text-wds-gray-4 text-wds-gray-6", {
    variants: {
        variant: {
            table: "bg-gray-100 border-black focus:border-black",
            modal: "max-h-8 bg-white border-wds-gray-3 focus:border-black",
        },
        size: {
            default: "w-full",
        },
    },
    defaultVariants: {
        variant: "table",
        size: "default",
    },
});
const dateFieldVariants = cva("rounded-lg focus:outline-none focus:ring-0 border border-solid resize-none font-normal text-sm", {
    variants: {
        variant: {
            table: "px-2 py-1 rounded-lg bg-gray-100",
            modal: "bg-white max-h-8",
        },
        size: {
            default: "w-full",
        },
    },
    defaultVariants: {
        variant: "table",
        size: "default",
    },
});
const TextFieldEditor = ({ crmObjectId, crmFieldId, crmFieldSchema, value, onValueEdit, handleKeyDown, inputDisabled, variant, placeholder, }) => {
    const newValueTextAreaRef = useRef(null);
    useEffect(() => {
        const adjustNewValueTextAreaHeight = () => {
            if (newValueTextAreaRef.current) {
                newValueTextAreaRef.current.style.height = `${Math.max(newValueTextAreaRef.current.scrollHeight, 48)}px`;
            }
        };
        adjustNewValueTextAreaHeight();
    }, [value]);
    const lengthLimitExceeded = value.length > crmFieldSchema.restrictions.length;
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-1 w-full" }, { children: [_jsx("textarea", { ref: newValueTextAreaRef, id: `${crmObjectId}-${crmFieldId}`, name: `${crmObjectId}-${crmFieldId}`, value: value, className: classNames(textFieldVariants({ variant }), lengthLimitExceeded ? "border-red-500 focus:border-red-500" : ""), onChange: (e) => {
                    const formattedContent = e.target.value.replace(/\n/g, "\n");
                    onValueEdit(formattedContent);
                }, onKeyDown: handleKeyDown, disabled: inputDisabled, placeholder: placeholder }), _jsxs("div", Object.assign({ className: classNames("flex items-end self-stretch", lengthLimitExceeded ? "justify-between" : "justify-end") }, { children: [lengthLimitExceeded && (_jsx("span", Object.assign({ className: "place-self-start text-xs font-medium text-red-500" }, { children: "Exceeded maximum characters" }))), _jsx("span", Object.assign({ className: classNames("self-end text-xs font-medium", lengthLimitExceeded ? "text-red-500" : "text-gray-500") }, { children: `${value.length}/${crmFieldSchema.restrictions.length}` }))] }))] })));
};
const NumberFieldEditor = ({ crmObjectId, crmFieldId, crmFieldSchema, value, onValueEdit, handleKeyDown, inputDisabled, }) => {
    var _a;
    // Check if number of digits before the decimal point has exceeded the precision
    const precisionExceeded = value.split(".")[0].length >
        crmFieldSchema.restrictions.precision - crmFieldSchema.restrictions.scale;
    const scaleExceeded = ((_a = value.split(".")[1]) === null || _a === void 0 ? void 0 : _a.length) > crmFieldSchema.restrictions.scale;
    return (_jsxs("div", Object.assign({ className: "flex flex-col justify-end gap-1" }, { children: [_jsx("input", { type: "number", id: `${crmObjectId}-${crmFieldId}`, name: `${crmObjectId}-${crmFieldId}`, value: value, className: classNames("px-2 py-1 rounded-lg bg-gray-100 focus:outline-none focus:ring-0 border border-solid", "resize-none font-normal text-sm", precisionExceeded || scaleExceeded
                    ? "border-red-500"
                    : "border-black"), onChange: (e) => {
                    const formattedContent = e.target.value.replace(/\n/g, "\n");
                    onValueEdit(formattedContent);
                }, onKeyDown: handleKeyDown, disabled: inputDisabled }), _jsx("div", Object.assign({ className: "flex items-start self-stretch" }, { children: precisionExceeded ? (_jsx("span", Object.assign({ className: "place-self-start text-xs font-medium text-red-500" }, { children: "Exceeded maximum number of digits before the decimal point" }))) : scaleExceeded ? (_jsx("span", Object.assign({ className: "place-self-start text-xs font-medium text-red-500" }, { children: "Exceeded maximum number of digits after the decimal point" }))) : null }))] })));
};
const BooleanFieldEditor = ({ crmObjectId, crmFieldId, value, onValueEdit, }) => {
    const checked = !!value && value.toLowerCase() === "true";
    return (_jsxs("label", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx(Switch, Object.assign({ id: `${crmObjectId}-${crmFieldId}`, checked: checked, onChange: (newValue) => onValueEdit(newValue ? "true" : "false"), className: classNames(checked ? "bg-wds-blue-3" : "bg-gray-200", "relative inline-flex items-center h-6 rounded-full w-11 transition-colors") }, { children: _jsx("span", { className: `${checked ? "translate-x-6" : "translate-x-1"} inline-block w-4 h-4 transform bg-white rounded-full transition-transform` }) })), _jsx("span", Object.assign({ className: classNames(checked ? "font-bold" : "") }, { children: checked ? "True" : "False" }))] })));
};
const SingleSelectFieldEditor = ({ crmObjectId, crmFieldId, crmFieldSchema, value, onValueEdit, handleKeyDown, }) => {
    return (_jsx("div", Object.assign({ className: "flex flex-col justify-end gap-1 w-full", onKeyDown: handleKeyDown }, { children: _jsxs(DropdownMenu, Object.assign({ modal: false }, { children: [_jsx(DropdownMenuTrigger, Object.assign({ asChild: true }, { children: _jsxs(DropdownMenuButtonContainer, { children: [_jsx("span", Object.assign({ className: "text-sm font-medium text-wds-gray-6 truncate text-ellipsis" }, { children: value })), _jsx(ChevronDownIcon, { className: "h-5 w-5 shrink-0" })] }) })), _jsx(DropdownMenuContent, Object.assign({ align: "start", className: "max-h-52 overflow-y-auto" }, { children: _jsx(ScrollArea, { children: crmFieldSchema.restrictions.picklist_values.map((option) => (_jsx(DropdownMenuItem, Object.assign({ onClick: () => {
                                onValueEdit(option);
                            }, className: "hover:cursor-pointer" }, { children: option }), `${crmObjectId}-${crmFieldId}-${option}`))) }) }))] })) })));
};
const MultiSelectFieldEditor = ({ crmObjectId, crmFieldId, crmFieldSchema, value, onValueEdit, handleKeyDown, }) => {
    // Filtering empty strings that are created by the split function if the value is empty.
    const selectedOptions = value.split(";").filter((v) => v !== "");
    return (_jsx("div", Object.assign({ className: "flex flex-col justify-end gap-1", onKeyDown: handleKeyDown }, { children: _jsx(MultiSelectDropdown, { options: crmFieldSchema.restrictions.picklist_values, selectedOptions: selectedOptions, setSelectedOptions: (selectedOptions) => onValueEdit(selectedOptions.join(";")) }, `${crmObjectId}-${crmFieldId}`) })));
};
const DateFieldEditor = ({ crmObjectId, crmFieldId, value, onValueEdit, handleKeyDown, variant, }) => {
    const currentValueAsDate = value && !isNaN(parseInt(value)) ? new Date(parseInt(value)) : undefined;
    return (_jsx("div", Object.assign({ className: "flex flex-col justify-end gap-1" }, { children: _jsx(DatePicker, { id: `${crmObjectId}-${crmFieldId}`, selected: currentValueAsDate, onChange: (date) => onValueEdit(date.getTime().toString()), onKeyDown: handleKeyDown, className: classNames(dateFieldVariants({ variant })) }) })));
};
const CrmFieldValueEditor = ({ crmObjectId, crmFieldId, crmFieldSchema, value, onValueEdit, handleKeyDown, inputDisabled, variant, placeholder, }) => {
    switch (crmFieldSchema.type) {
        case "number":
            return (_jsx(NumberFieldEditor, { crmObjectId: crmObjectId, crmFieldId: crmFieldId, crmFieldSchema: crmFieldSchema, value: value, onValueEdit: onValueEdit, handleKeyDown: handleKeyDown, inputDisabled: inputDisabled }));
        case "boolean":
            return (_jsx(BooleanFieldEditor, { crmObjectId: crmObjectId, crmFieldId: crmFieldId, value: value, onValueEdit: onValueEdit }));
        case "single_select":
            return (_jsx(SingleSelectFieldEditor, { crmObjectId: crmObjectId, crmFieldId: crmFieldId, crmFieldSchema: crmFieldSchema, value: value, onValueEdit: onValueEdit, handleKeyDown: handleKeyDown }));
        case "multi_select":
            return (_jsx(MultiSelectFieldEditor, { crmObjectId: crmObjectId, crmFieldId: crmFieldId, crmFieldSchema: crmFieldSchema, value: value, onValueEdit: onValueEdit, handleKeyDown: handleKeyDown }));
        case "date":
            return (_jsx(DateFieldEditor, { crmObjectId: crmObjectId, crmFieldId: crmFieldId, value: value, onValueEdit: onValueEdit, handleKeyDown: handleKeyDown, variant: variant }));
        default:
            return (_jsx(TextFieldEditor, { crmObjectId: crmObjectId, crmFieldId: crmFieldId, crmFieldSchema: crmFieldSchema, value: value, onValueEdit: onValueEdit, handleKeyDown: handleKeyDown, inputDisabled: inputDisabled, variant: variant, placeholder: placeholder }));
    }
};
export default CrmFieldValueEditor;
