import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import * as endpoints from "./common/endpoints";
import BaseModal from "./base_modal";
/**
 * AddPlaybookModal is a component that renders a modal for adding a playbook.
 * It fetches the list of playbooks and provides a dropdown for the user to select from.
 *
 * @param {boolean} addPlaybookModalOpen - A state variable indicating whether the modal is open.
 * @param {(open: boolean) => void} setAddPlaybookModalOpen - A function to set the state of addPlaybookModalOpen.
 * @param {(playbook: PlaybookDetails) => void} addPlaybook - A function to save the selected playbook.
 */
const AddPlaybookModal = ({ addPlaybookModalOpen, setAddPlaybookModalOpen, addPlaybook, selectedPlaybookId, }) => {
    const [playbooks, setPlaybooks] = useState([]);
    // This moves the state of the selected playbook to the parent component.
    // This is to ensure that the selected playbook is not lost when the parent component re-renders.
    const [selectedPlaybook, setSelectedPlaybook] = useState(selectedPlaybookId);
    useEffect(() => {
        setSelectedPlaybook(selectedPlaybookId);
    }, [selectedPlaybookId]);
    useEffect(() => {
        endpoints.getPlaybooks().then(setPlaybooks);
    }, []);
    if (playbooks.length === 0) {
        return null;
    }
    return (_jsx(BaseModal, { title: "Add a tracker set", description: "", open: addPlaybookModalOpen, setOpen: setAddPlaybookModalOpen, submitCallback: (fields) => {
            const playbookToAdd = playbooks.find(({ id }) => id === Number(fields[0].value));
            addPlaybook(playbookToAdd);
            setAddPlaybookModalOpen(false);
        }, fields: [
            {
                name: "Choose a tracker set",
                type: "select",
                value: selectedPlaybook !== null && selectedPlaybook !== void 0 ? selectedPlaybook : playbooks[0].id,
                options: playbooks.map(({ id, name }) => ({
                    label: name,
                    value: id,
                })),
            },
        ], submitText: "Save", setField: (fieldName, value) => {
            if (fieldName === "Choose a tracker set") {
                setSelectedPlaybook(Number(value));
            }
        } }));
};
export default AddPlaybookModal;
