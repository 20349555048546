import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { getDataSourceConfigForResearchBlock } from "../common/endpoints";
import { MultiSelector, MultiSelectorContent, MultiSelectorInput, MultiSelectorItem, MultiSelectorList, MultiSelectorTrigger, } from "../components/multi_select";
import { Separator } from "../components/separator";
const defaultDeliveryTargetsConfig = [
    {
        id: "Slack",
        name: "Slack",
    },
    {
        id: "Email",
        name: "Email",
    },
];
const CreateOrEditBriefing = (props) => {
    const { name, setName, prompt, setPrompt, selectedDataSources, deliveryTargets, setSelectedDataSources, setDeliveryTargets, potentialSourceBricks, selectedSourceBricks, selectSourceBricks, } = props;
    const { data: dataSourcesConfig } = useQuery({
        queryKey: ["dataSourceConfig", props.researchBlockType],
        queryFn: () => getDataSourceConfigForResearchBlock(props.researchBlockType),
    });
    const isParentSelected = (parentId) => {
        return dataSourcesConfig === null || dataSourcesConfig === void 0 ? void 0 : dataSourcesConfig.some((ds) => ds.parent_id === parentId && selectedDataSources.has(ds.id));
    };
    const handleParentChange = (parentId, checked) => {
        const newSelectedDataSources = new Set(selectedDataSources);
        dataSourcesConfig === null || dataSourcesConfig === void 0 ? void 0 : dataSourcesConfig.forEach((ds) => {
            if (ds.parent_id === parentId) {
                if (checked)
                    newSelectedDataSources.add(ds.id);
                else
                    newSelectedDataSources.delete(ds.id);
            }
        });
        setSelectedDataSources(newSelectedDataSources);
    };
    const handleChildChange = (dataSourceId, checked) => {
        const newSelectedDataSources = new Set(selectedDataSources);
        if (checked)
            newSelectedDataSources.add(dataSourceId);
        else
            newSelectedDataSources.delete(dataSourceId);
        setSelectedDataSources(newSelectedDataSources);
    };
    const deliveryTargetsConfig = props.deliveryTargetOptions || defaultDeliveryTargetsConfig;
    const handleDeliveryTargetsChanged = (target, checked) => {
        const newDeliveryTargets = new Set(deliveryTargets);
        if (checked) {
            newDeliveryTargets.add(target.id);
        }
        else {
            newDeliveryTargets.delete(target.id);
        }
        setDeliveryTargets(newDeliveryTargets);
    };
    // Dynamically generate checkboxes for both parent and child data sources
    const dataSourceCheckboxes = useMemo(() => {
        if (!dataSourcesConfig)
            return null;
        const parents = [
            ...dataSourcesConfig.filter((ds) => !ds.parent_id),
        ];
        return parents
            .sort((p1, p2) => p1.order - p2.order)
            .map(({ id: parentId, label: parentLabel }) => (_jsxs(React.Fragment, { children: [_jsxs("label", Object.assign({ className: "self-stretch pl-2 pr-4 py-1 rounded-lg justify-start items-center gap-2 inline-flex" }, { children: [_jsx("input", { type: "checkbox", className: "w-4 h-4", checked: isParentSelected(parentId), onChange: (e) => handleParentChange(parentId, e.target.checked) }), _jsx("div", Object.assign({ className: "font-medium leading-tight" }, { children: parentLabel }))] })), dataSourcesConfig
                    .filter((ds) => ds.parent_id === parentId)
                    .map((childDataSource) => (_jsxs("label", Object.assign({ className: "self-stretch pl-8 pr-4 py-1 rounded-lg justify-start items-center gap-2 inline-flex" }, { children: [_jsx("input", { type: "checkbox", className: "w-4 h-4", checked: selectedDataSources.has(childDataSource.id), onChange: (e) => handleChildChange(childDataSource.id, e.target.checked) }), _jsx("div", Object.assign({ className: "font-medium leading-tight" }, { children: childDataSource.label }))] }), childDataSource.id)))] }, parentId)));
    }, [selectedDataSources, dataSourcesConfig]);
    return (_jsxs("div", Object.assign({ className: "self-stretch bg-neutral-100 flex flex-col justify-start items-start gap-6" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch flex flex-col justify-start items-start gap-1" }, { children: [_jsx("div", Object.assign({ className: "flex justify-start items-start gap-1" }, { children: _jsxs("div", Object.assign({ className: "text-neutral-500 text-xs font-medium leading-none" }, { children: ["Block name ", _jsx("span", Object.assign({ className: "text-red-700" }, { children: "*" }))] })) })), _jsx("input", { type: "text", placeholder: "Enter block name", className: "self-stretch px-4 py-3 bg-white rounded-lg border border-neutral-200 w-full", onChange: (e) => setName(e.target.value), value: name })] })), _jsxs("div", Object.assign({ className: "self-stretch h-[416px] flex-grow flex-shrink basis-0 border-t border-neutral-200 flex justify-start items-start gap-6" }, { children: [_jsxs("div", Object.assign({ className: "pl-2 py-6 h-full bg-white border-r border-t border-neutral-200 flex flex-col justify-start items-start gap-4 overflow-y-auto" }, { children: [_jsx("div", Object.assign({ className: "self-stretch px-4 flex flex-col justify-start items-start gap-4" }, { children: _jsx("div", Object.assign({ className: "self-stretch h-6 flex flex-col justify-start items-start gap-1" }, { children: _jsx("div", Object.assign({ className: "text-base font-bold leading-normal" }, { children: "Data sources" })) })) })), _jsx("div", Object.assign({ className: "self-stretch flex-col justify-start items-start flex" }, { children: dataSourceCheckboxes })), potentialSourceBricks &&
                                selectedSourceBricks &&
                                selectSourceBricks && (_jsxs(_Fragment, { children: [_jsx(Separator, {}), _jsxs("div", Object.assign({ className: "self-stretch px-4 flex flex-col justify-start items-start gap-4 font-bold text-base" }, { children: ["Use other prompts as sources", _jsxs(MultiSelector, Object.assign({ values: selectedSourceBricks.map((brick) => brick.name), onValuesChange: (values) => selectSourceBricks(potentialSourceBricks.filter((brick) => values.includes(brick.name))), loop: true, className: "max-w-xs" }, { children: [_jsx(MultiSelectorTrigger, { children: _jsx(MultiSelectorInput, { placeholder: "Search prompt names..." }) }), _jsx(MultiSelectorContent, { children: _jsx(MultiSelectorList, { children: potentialSourceBricks.map((brick) => (_jsx(MultiSelectorItem, Object.assign({ value: brick.name }, { children: brick.name }), brick.id))) }) })] }))] }))] }))] })), _jsxs("div", Object.assign({ className: "grow h-full shrink basis-0 self-stretch py-6 flex flex-col justify-start items-start gap-6 overflow-y-auto" }, { children: [_jsx("div", Object.assign({ className: "text-base font-bold leading-normal" }, { children: "Block configuration" })), _jsxs("div", Object.assign({ className: "self-stretch flex flex-col justify-start items-start gap-1" }, { children: [_jsxs("div", Object.assign({ className: "text-neutral-500 text-xs font-medium leading-none" }, { children: ["Prompt ", _jsx("span", Object.assign({ className: "text-red-700" }, { children: "*" }))] })), _jsx("textarea", { className: "self-stretch px-4 py-3 bg-white rounded-lg border border-neutral-200", placeholder: "Enter an AI prompt to generate content", onChange: (e) => setPrompt(e.target.value), value: prompt, rows: 8 })] })), (props.researchBlockType === "CALL" ||
                                props.deliveryTargetOptions) && (_jsxs("div", Object.assign({ className: "flex-col justify-start items-start gap-1 inline-flex" }, { children: [_jsxs("div", Object.assign({ className: "justify-start items-start gap-1 inline-flex" }, { children: [_jsx("div", Object.assign({ className: "text-neutral-500 text-xs font-medium leading-none" }, { children: "Deliver this research block to" })), _jsx("div", { className: "w-4 h-4 relative" })] })), deliveryTargetsConfig.map((target) => (_jsx("div", Object.assign({ className: "justify-start items-center gap-1 inline-flex" }, { children: _jsxs("label", Object.assign({ className: "self-stretch rounded-lg justify-start items-center gap-2 inline-flex" }, { children: [_jsx("input", { type: "checkbox", className: "w-4 h-4", checked: deliveryTargets.has(target.id), onChange: (e) => handleDeliveryTargetsChanged(target, e.target.checked) }), _jsx("div", Object.assign({ className: "font-medium leading-tight" }, { children: target.name }))] }), target.id) }), target.id)))] })))] }))] }))] })));
};
export default CreateOrEditBriefing;
