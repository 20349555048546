import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
const FileUpload = (props) => {
    var _a, _b;
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            props.onFileUpload(file);
        });
    }, []);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            "text/csv": [".csv"],
        },
        // 50 MB by default
        maxSize: (_a = props.maxFileSize) !== null && _a !== void 0 ? _a : 50 * 1024 * 1024,
        // 1 file by default
        maxFiles: (_b = props.maxFiles) !== null && _b !== void 0 ? _b : 1,
    });
    return (_jsxs("div", Object.assign({}, getRootProps(), { className: "flex h-20 px-4 py-3 items-start gap-2 self-stretch rounded-lg border border-dashed border-gray-400 hover:cursor-pointer" }, { children: [_jsx("input", Object.assign({}, getInputProps())), _jsx("span", Object.assign({ className: "text-wds-blue-3 font-normal text-sm text-center" }, { children: "Drag and drop a file here, or click to browse" }))] })));
};
export default FileUpload;
