import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import WaitingSpinner from "../waiting_spinner";
const ConnectionStatus = ({ status }) => {
    if (status === "Connected") {
        return (_jsxs("div", Object.assign({ className: "p-2 bg-emerald-100 rounded flex items-center gap-1" }, { children: [_jsx("div", { className: "w-1 h-1 bg-emerald-600 rounded-full" }), _jsx("div", Object.assign({ className: "text-emerald-900 text-xs leading-none" }, { children: "Connected" }))] })));
    }
    if (status === "Needs reconnection") {
        return (_jsxs("div", Object.assign({ className: "p-2 bg-red-100 rounded flex items-center gap-1" }, { children: [_jsx("div", { className: "w-1 h-1 bg-red-600 rounded-full" }), _jsx("div", Object.assign({ className: "text-red-900 text-xs leading-none" }, { children: "Needs reconnection" }))] })));
    }
    if (status === "Connecting") {
        return (_jsxs("div", Object.assign({ className: "p-2 bg-neutral-200 rounded flex items-center" }, { children: [_jsx("div", { className: "w-1 h-1 bg-zinc-400 rounded-full" }), _jsxs("div", Object.assign({ className: "text-zinc-800 text-xs leading-none flex items-center" }, { children: [_jsx(WaitingSpinner, { text: "" }), _jsx("span", { children: "Syncing, may take a couple of minutes" })] }))] })));
    }
    return (_jsxs("div", Object.assign({ className: "p-2 bg-neutral-200 rounded flex items-center gap-1" }, { children: [_jsx("div", { className: "w-1 h-1 bg-zinc-400 rounded-full" }), _jsx("div", Object.assign({ className: "text-zinc-800 text-xs leading-none" }, { children: "Not Connected" }))] })));
};
export default ConnectionStatus;
