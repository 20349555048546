var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Button } from "./button";
import { ChevronDownIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { Separator } from "./separator";
import { DataTableViewSwitcher } from "./data_table_layout_selector";
import { useCallback, useMemo, useState, } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createOrUpdateTableLayout, deleteTableLayout, } from "../common/endpoints";
import { Checkbox } from "./checkbox";
import { useSearchParams } from "react-router-dom";
/**
 * React component to manage table layouts for DataTables.
 * This is merely a copy paste of table layout related functionality from
 * the DataTableNewToolbar component.
 * @returns
 */
export function DataTableLayoutsPopover({ table, layouts, 
// Default table layout in case user doesn't have any saved layouts
defaultTableLayout, selectedLayout, setSelectedLayout, setResetLayout, }) {
    var _a;
    const [searchParams, setSearchParams] = useSearchParams();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const defaultLayout = (_a = layouts.find((l) => l.is_default)) !== null && _a !== void 0 ? _a : defaultTableLayout;
    const copySearchParams = useMemo(() => {
        return new URLSearchParams(searchParams.toString());
    }, [searchParams]);
    const queryClient = useQueryClient();
    const createOrUpdateLayoutMutation = useMutation({
        mutationFn: createOrUpdateTableLayout,
        onSuccess: (data, variables) => {
            if (!data.find((layout) => layout.is_default)) {
                data.push(defaultTableLayout);
            }
            queryClient.setQueryData(["getTableLayouts", defaultLayout.table_name], data);
            setSelectedLayout(variables);
        },
    });
    const deleteLayoutMutation = useMutation({
        mutationFn: deleteTableLayout,
        onSuccess: (data) => {
            if (!data.find((layout) => layout.is_default)) {
                data.push(defaultTableLayout);
            }
            queryClient.setQueryData(["getTableLayouts", defaultLayout.table_name], data);
            setSelectedLayout(layouts.find((layout) => layout.is_default));
        },
    });
    const getVisibleColumnWidths = (columnSizingState) => {
        // This function ensures that the column sizing state only contains columns that are visible
        const correctedSizingState = {};
        const visibleColumns = Object.entries(table.getVisibleLeafColumns()).map(([columnId, column]) => {
            return column.id;
        });
        Object.entries(columnSizingState).forEach(([column, size]) => {
            if (visibleColumns.includes(column)) {
                correctedSizingState[column] = size;
            }
        });
        // If a visible column's width does not change fro mdefault 150 then it's not present in the sizing state
        // Our code assumes that all visible columns have a width, an empty key means the column is not visible
        visibleColumns.forEach((column) => {
            if (!correctedSizingState[column]) {
                correctedSizingState[column] = 150;
            }
        });
        return correctedSizingState;
    };
    // This fetches the ColumnSizingState from tanstack table if present
    // By default when no changes are made this is empty, our code completely relies on this
    // Thus we pick it from the layout if it is empty
    const columnSizing = Object.keys(table.getState().columnSizing).length > 0
        ? table.getState().columnSizing
        : (selectedLayout === null || selectedLayout === void 0 ? void 0 : selectedLayout.columns) || defaultLayout.columns;
    const correctedColumnSizing = useMemo(() => {
        return getVisibleColumnWidths(columnSizing);
    }, [
        columnSizing,
        table.getVisibleLeafColumns(),
        table.getState().columnVisibility,
    ]);
    const currentViewDiffersFromLayout = useMemo(() => {
        function areObjectsEqual(obj1, obj2) {
            // Check if the objects have the same number of keys
            const obj1Keys = Object.keys(obj1);
            const obj2Keys = Object.keys(obj2);
            if (obj1Keys.length !== obj2Keys.length) {
                return false;
            }
            // Compare each key-value pair
            for (const key of obj1Keys) {
                if (obj1[key] !== obj2[key]) {
                    return false;
                }
            }
            return true;
        }
        const layout = selectedLayout || defaultLayout;
        const columnsDiffer = !areObjectsEqual(layout.columns, correctedColumnSizing);
        const missingColumns = table
            .getAllColumns()
            .filter((column) => !layout.column_order.includes(column.id));
        const layoutColumnOrder = [
            ...layout.column_order,
            ...missingColumns.map((c) => c.id),
        ];
        const columnOrderDiffers = layoutColumnOrder.join(",") !== table.getState().columnOrder.join(",");
        // TODO: Write a migration to remove all pagination params from the DB
        // Once that's done this code can be removed
        const searchParamsWithoutPaginationParams = new URLSearchParams(copySearchParams.toString());
        searchParamsWithoutPaginationParams.delete("page");
        searchParamsWithoutPaginationParams.delete("per_page");
        searchParamsWithoutPaginationParams.delete("default");
        const isURLEmpty = searchParamsWithoutPaginationParams.toString().length === 0;
        const currentURLMatchesLayout = layout.query_string === decodeURIComponent(copySearchParams.toString());
        const isInitialPageNavigation = copySearchParams.has("default") && isURLEmpty;
        if ((!currentURLMatchesLayout && !isInitialPageNavigation) ||
            columnsDiffer ||
            columnOrderDiffers) {
            return true;
        }
        return false;
    }, [
        correctedColumnSizing,
        selectedLayout === null || selectedLayout === void 0 ? void 0 : selectedLayout.query_string,
        selectedLayout === null || selectedLayout === void 0 ? void 0 : selectedLayout.columns,
        copySearchParams,
        defaultLayout,
        layouts,
    ]);
    const getClassNamesForLayoutButton = () => {
        if (!currentViewDiffersFromLayout) {
            return "text-wds-blue-4 bg-wds-blue-1 border-wds-blue-2 border-[1px] rounded-lg";
        }
        return "text-yellow-800 bg-yellow-100 border-yellow-200 border-[1px] rounded-lg";
    };
    const getLayoutName = useCallback((layout) => {
        if (layout.is_public) {
            return `${layout.name} (Shared)`;
        }
        return layout.name;
    }, []);
    return (_jsxs(Popover, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsxs(Button, Object.assign({ variant: "ghost", size: "sm", className: getClassNamesForLayoutButton() }, { children: [_jsx(TableCellsIcon, { className: "mr-2 h-4 w-4" }), currentViewDiffersFromLayout
                            ? "Unsaved view"
                            : selectedLayout
                                ? getLayoutName(selectedLayout)
                                : getLayoutName(defaultLayout), _jsx(ChevronDownIcon, { className: "ml-2 h-4 w-4 text-wds-gray-5" })] })) })), _jsx(PopoverContent, { children: _jsxs("div", Object.assign({ className: "w-[320px] flex-col justify-end items-end inline-flex" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch pb-4 border-b flex-col justify-start items-start gap-2 flex" }, { children: [_jsx("div", Object.assign({ className: "text-wds-gray-6 text-xs font-bold leading-[15px]" }, { children: "Switch to a different view" })), _jsx(DataTableViewSwitcher, { storedLayouts: layouts, selectedLayout: selectedLayout || defaultLayout, setSelectedLayout: (layout) => {
                                        if (layout !== (selectedLayout === null || selectedLayout === void 0 ? void 0 : selectedLayout.name)) {
                                            layouts
                                                .filter((l) => l.name === layout)
                                                .map((l) => setSelectedLayout(l));
                                        }
                                        else {
                                            // This means you are trying to go back to the layout after making changes
                                            setResetLayout((prev) => !prev);
                                        }
                                    } })] })), _jsxs("div", Object.assign({ className: "self-stretch h-[103px] flex-col justify-start items-start gap-2 flex" }, { children: [_jsx("div", Object.assign({ className: "self-stretch text-wds-gray-6 text-xs font-bold leading-4 pt-4" }, { children: currentViewDiffersFromLayout
                                        ? "Name this view"
                                        : "Rename this view" })), _jsxs("div", Object.assign({ className: "self-stretch justify-start items-start gap-2 inline-flex" }, { children: [_jsx("input", { className: "focus:outline-none border-[1px] border-wds-gray-3 focus:ring-0 placeholder:text-gray-400 text-sm rounded-sm w-full", placeholder: currentViewDiffersFromLayout
                                                ? "Name this view"
                                                : selectedLayout
                                                    ? selectedLayout.name
                                                    : defaultLayout.name, value: selectedLayout ? selectedLayout.name : defaultLayout.name, onChange: (event) => setSelectedLayout((prev) => {
                                                if (!prev) {
                                                    const searchParamsWithoutPagination = copySearchParams.has("default")
                                                        ? new URLSearchParams(defaultLayout.query_string)
                                                        : new URLSearchParams(copySearchParams.toString());
                                                    searchParamsWithoutPagination.delete("page");
                                                    searchParamsWithoutPagination.delete("per_page");
                                                    return Object.assign(Object.assign({}, defaultLayout), { name: event.target.value, query_string: searchParamsWithoutPagination // Do not use default layout query string as it may have changed
                                                            .toString() });
                                                }
                                                return Object.assign(Object.assign({}, prev), { name: event.target.value });
                                            }) }), _jsx(Button, Object.assign({ size: "sm", disabled: isSaving || !selectedLayout, onClick: () => __awaiter(this, void 0, void 0, function* () {
                                                if (!selectedLayout) {
                                                    return;
                                                }
                                                setIsSaving(true);
                                                // Never save "default=true" or pagination params into the query string
                                                const searchParamsWithoutPagination = copySearchParams.has("default")
                                                    ? new URLSearchParams(selectedLayout.query_string)
                                                    : new URLSearchParams(copySearchParams.toString());
                                                searchParamsWithoutPagination.delete("page");
                                                searchParamsWithoutPagination.delete("per_page");
                                                yield createOrUpdateLayoutMutation.mutateAsync({
                                                    table_name: selectedLayout.table_name,
                                                    name: selectedLayout.name,
                                                    query_string: decodeURIComponent(searchParamsWithoutPagination.toString()),
                                                    columns: correctedColumnSizing,
                                                    is_default: selectedLayout.is_default,
                                                    is_public: selectedLayout.is_public,
                                                    column_order: table.getState().columnOrder,
                                                });
                                                setIsSaving(false);
                                            }) }, { children: "Save" }))] })), _jsxs("div", Object.assign({ className: "h-6 justify-start items-center gap-1 inline-flex pt-4" }, { children: [_jsx(Checkbox, { checked: selectedLayout
                                                ? selectedLayout.is_default
                                                : defaultLayout.is_default, onCheckedChange: (checked) => setSelectedLayout((prev) => {
                                                if (!prev) {
                                                    return Object.assign(Object.assign({}, defaultLayout), { is_default: checked ? true : false });
                                                }
                                                return Object.assign(Object.assign({}, prev), { is_default: checked ? true : false });
                                            }) }), _jsx("div", Object.assign({ className: "text-wds-gray-6 text-sm font-bold leading-tight" }, { children: "Make this the default view" }))] })), _jsxs("div", Object.assign({ className: "h-6 justify-start items-center gap-1 inline-flex pt-4" }, { children: [_jsx(Checkbox, { checked: selectedLayout
                                                ? selectedLayout.is_public
                                                : defaultLayout.is_public, onCheckedChange: (checked) => setSelectedLayout((prev) => {
                                                if (!prev) {
                                                    return Object.assign(Object.assign({}, defaultLayout), { is_public: checked ? true : false });
                                                }
                                                return Object.assign(Object.assign({}, prev), { is_public: checked ? true : false });
                                            }) }), _jsx("div", Object.assign({ className: "text-wds-gray-6 text-sm font-bold leading-tight" }, { children: "Share this view with your organization" }))] }))] })), _jsx(Separator, { orientation: "horizontal", className: "mt-12 mb-2" }), _jsx(Button, Object.assign({ variant: "ghost", className: "bg-red-50 text-red-600 font-bolt hover:bg-red-100 hover:text-red-900", disabled: isDeleting || (selectedLayout === null || selectedLayout === void 0 ? void 0 : selectedLayout.is_default) || !selectedLayout, onClick: () => __awaiter(this, void 0, void 0, function* () {
                                if (!selectedLayout) {
                                    return;
                                }
                                setIsDeleting(true);
                                yield deleteLayoutMutation.mutateAsync({
                                    table_name: selectedLayout.table_name,
                                    name: selectedLayout.name,
                                });
                                setIsDeleting(false);
                            }) }, { children: "Delete this view" }))] })) })] }));
}
