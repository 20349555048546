import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import classNames from "../class_names";
const PaginationControls = ({ paginationStateControl, }) => {
    const { currentPage, pageSize, totalRows, goToNextPage, goToPreviousPage } = paginationStateControl;
    const isOnFirstPage = currentPage === 1;
    const isOnLastPage = currentPage >= Math.ceil(totalRows / pageSize);
    const currentPageStart = Math.min((currentPage - 1) * pageSize + 1, totalRows);
    const currentPageEnd = Math.min(currentPageStart + pageSize - 1, totalRows);
    const previousButtonClasses = classNames("relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0", isOnFirstPage ? "cursor-not-allowed opacity-50" : "");
    const nextButtonClasses = classNames("relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0", isOnLastPage ? "cursor-not-allowed opacity-50" : "");
    return (_jsxs("nav", Object.assign({ className: "flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6", "aria-label": "Pagination" }, { children: [_jsx("div", Object.assign({ className: "hidden sm:block" }, { children: _jsxs("p", Object.assign({ className: "text-sm text-gray-700" }, { children: ["Showing ", _jsxs("span", Object.assign({ className: "font-medium" }, { children: [currentPageStart, " "] })), "to ", _jsxs("span", Object.assign({ className: "font-medium" }, { children: [currentPageEnd, " "] })), "of ", _jsxs("span", Object.assign({ className: "font-medium" }, { children: [totalRows, " "] })), "results"] })) })), _jsxs("div", Object.assign({ className: "flex flex-1 justify-between sm:justify-end" }, { children: [_jsx("button", Object.assign({ onClick: goToPreviousPage, className: previousButtonClasses, disabled: isOnFirstPage, "aria-disabled": isOnFirstPage }, { children: "Previous" })), _jsx("button", Object.assign({ onClick: goToNextPage, className: nextButtonClasses, disabled: isOnLastPage, "aria-disabled": isOnLastPage }, { children: "Next" }))] }))] })));
};
export default PaginationControls;
