// This file is used to store the default layout of the live call UI
// for different versions of the mosaic state.
// The default layout is stored as a MosaicNode<string> object.
// We have default mosaic states for each version in this file, and the user
// level preferences are stored in the database.
const defaultStateV1 = {
    direction: "column",
    first: {
        direction: "row",
        first: {
            direction: "column",
            first: "playbook",
            second: "manualNotes",
            splitPercentage: 70,
        },
        second: "feedAssist",
        splitPercentage: 70,
    },
    second: "transcript",
    splitPercentage: 90,
};
const defaultStateV2 = {
    direction: "column",
    first: {
        direction: "row",
        first: {
            direction: "column",
            first: "dashboard",
            second: "manualNotes",
            splitPercentage: 70,
        },
        second: "feedAssist",
        splitPercentage: 70,
    },
    second: "transcript",
    splitPercentage: 90,
};
const defaultStateV3 = {
    direction: "row",
    first: {
        direction: "column",
        first: "dashboard",
        second: "manualNotes",
        splitPercentage: 70,
    },
    second: "feedAssist",
    splitPercentage: 70,
};
// Default mosaic states for each version
// A new version should be created if we want to change the default layout
// for all users.
// In most cases we should just create a new version rather than updating
// the existing layout for a version.
const DEFAULT_MOSAIC_STATES = {
    1: defaultStateV1,
    2: defaultStateV2,
    3: defaultStateV3,
};
// Returns the default mosaic state for a given version
export const getDefaultMosaicStateForVersion = (version) => {
    return DEFAULT_MOSAIC_STATES[version];
};
// This method is used to compute the correct mosaic state for a user
// for the given version.
// If the user has a stored preference for the version, we return that.
// If not, we return the default state for the version.
export const getMosaicStateForUser = (version, userProfile) => {
    if (userProfile.versioned_live_call_mosaic_state[version]) {
        return userProfile.versioned_live_call_mosaic_state[version];
    }
    return DEFAULT_MOSAIC_STATES[version];
};
