import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChevronDownIcon, FunnelIcon, XMarkIcon, } from "@heroicons/react/24/outline";
import { Popover, PopoverContent, PopoverTrigger, } from "../../../components/popover";
import { useQueryFilter } from "../use_query_filter";
import { ScrollArea } from "../../../components/scroll_area";
import { FilterLogicalOperator, FilterOperator, } from "../types";
import { Button } from "../../../components/button";
import { createFilter, createFilterGroup } from "../api";
import { useMemo, useState } from "react";
import { DEFAULT_OPERATORS_BY_TYPE, NULL_VALUE_ALLOWED_OPERATORS, } from "../config";
import classNames from "../../../class_names";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, } from "../../../components/dropdown_menu";
import { DropdownMenuButtonContainer, OperatorTextContainer, } from "./styled_components";
import { BooleanValueEditor, DateValueEditor, NumberValueEditor, ReferenceValueEditor, SelectValueEditor, StringValueEditor, SubstringInFilterEditor, } from "./filter_value_editors";
import { WiserBackendQueryConverter } from "../json_query_converter";
import { SearchableDropdown } from "../../searchable_dropdown";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../../../components/tooltip";
export const QueryFilterPopover = (props) => {
    var _a;
    const filterManager = useQueryFilter(props.filterConfig, props.initialFilter);
    const wiserBackendQueryConverter = useMemo(() => {
        return new WiserBackendQueryConverter(props.filterConfig);
    }, [props.filterConfig]);
    const [filtersApplied, setFiltersApplied] = useState(props.initialFilter
        ? JSON.stringify(wiserBackendQueryConverter.convertQueryFilterToBackendFilter(props.initialFilter)).split('"field":').length - 1
        : 0); // Stores the number of filters applied
    // This is derieved from the count of "field" in the query params
    const createDefaultFilterGroup = (parentId) => {
        return createFilterGroup(FilterLogicalOperator.AND, [], parentId);
    };
    const createDefaultFilter = (parentId) => {
        const field = props.filterConfig.fields[0];
        return createFilter({
            field,
            operator: FilterOperator.EQUALS,
            value: undefined,
            parentFilterGroupId: parentId,
        });
    };
    const renderAddConditionsButtons = (filterGroup) => {
        const maxSubConditionsExceeded = filterGroup &&
            filterGroup.conditions.length >= props.filterConfig.maxSubConditions;
        return (_jsxs("div", Object.assign({ className: "flex items-start gap-2" }, { children: [_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, { children: _jsx(Button, Object.assign({ variant: "ghost", className: "h-auto py-0 px-3 text-sm font-bold text-wds-blue-3 hover:bg-transparent", disabled: !!maxSubConditionsExceeded, onClick: () => {
                                        let parentFilterGroup = filterGroup;
                                        if (!parentFilterGroup) {
                                            const newFilterGroup = createDefaultFilterGroup();
                                            filterManager.addFilterGroup(undefined, newFilterGroup);
                                            parentFilterGroup = newFilterGroup;
                                        }
                                        filterManager.addFilter(parentFilterGroup.id, createDefaultFilter(parentFilterGroup.id));
                                    } }, { children: "+ Add condition" })) }), maxSubConditionsExceeded && (_jsx(TooltipContent, { children: _jsx("div", { children: `Can only have a maximum of ${props.filterConfig.maxSubConditions} condition groups` }) }))] }) }), (!filterGroup || !filterGroup.parentId) && (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, { children: _jsx(Button, Object.assign({ variant: "ghost", className: "h-auto py-0 px-3 text-sm font-bold text-wds-blue-3 hover:bg-transparent", disabled: !!maxSubConditionsExceeded, onClick: () => {
                                        let parentFilterGroup = filterGroup;
                                        if (!parentFilterGroup) {
                                            const newFilterGroup = createDefaultFilterGroup();
                                            filterManager.addFilterGroup(undefined, newFilterGroup);
                                            parentFilterGroup = newFilterGroup;
                                        }
                                        filterManager.addFilterGroup(parentFilterGroup.id, createDefaultFilterGroup(parentFilterGroup.id));
                                    } }, { children: "+ Add condition group" })) }), maxSubConditionsExceeded && (_jsx(TooltipContent, { children: _jsx("div", { children: `Can only have a maximum of ${props.filterConfig.maxSubConditions} per group` }) }))] }) }))] })));
    };
    const updateFilterGroupOperator = (filterGroup, operator) => {
        filterManager.updateFilterGroup(filterGroup.parentId, filterGroup.id, Object.assign(Object.assign({}, filterGroup), { operator }));
    };
    const renderFilterGroupOperatorSelector = (filterGroup) => {
        return (_jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, Object.assign({ asChild: true }, { children: _jsxs(DropdownMenuButtonContainer, Object.assign({ className: "w-20" }, { children: [_jsx("span", Object.assign({ className: "text-xs font-medium truncate text-ellipsis" }, { children: filterGroup.operator.toLowerCase() })), _jsx(ChevronDownIcon, { className: "w-5 h-5 shrink-0" })] })) })), _jsxs(DropdownMenuContent, Object.assign({ align: "start" }, { children: [_jsx(DropdownMenuItem, Object.assign({ onClick: () => updateFilterGroupOperator(filterGroup, FilterLogicalOperator.AND), className: "hover:cursor-pointer text-sm" }, { children: "and" })), _jsx(DropdownMenuItem, Object.assign({ onClick: () => updateFilterGroupOperator(filterGroup, FilterLogicalOperator.OR), className: "hover:cursor-pointer text-sm" }, { children: "or" }))] }))] }));
    };
    const renderFilterFieldSelector = (filterGroup, filter) => {
        return (_jsx(SearchableDropdown, { options: props.filterConfig.fields.map((field) => {
                return {
                    label: field.name,
                    value: field.id,
                };
            }), selectedOption: {
                label: filter.field.name,
                value: filter.field.id,
            }, onSelect: (option) => {
                const field = props.filterConfig.fields.find((field) => field.id === option.value);
                filterManager.updateFilter(filterGroup.id, filter.id, Object.assign(Object.assign({}, filter), { field, operator: Array.from(DEFAULT_OPERATORS_BY_TYPE[field.type].values())[0], value: undefined }));
            }, buttonClasses: "w-52 rounded-none rounded-l-lg" }));
    };
    const renderFilterOperatorSelector = (filterGroup, filter) => {
        return (_jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, Object.assign({ asChild: true }, { children: _jsxs(DropdownMenuButtonContainer, Object.assign({ className: classNames("w-52 rounded-none", NULL_VALUE_ALLOWED_OPERATORS.includes(filter.operator)
                            ? "rounded-r-lg"
                            : "") }, { children: [_jsx("span", Object.assign({ className: "text-sm font-medium truncate text-ellipsis" }, { children: filter.operator.toLowerCase() })), _jsx(ChevronDownIcon, { className: "h-5 w-5 shrink-0" })] })) })), _jsx(DropdownMenuContent, Object.assign({ align: "start", className: "max-h-52 overflow-y-auto" }, { children: _jsx(ScrollArea, { children: Array.from(DEFAULT_OPERATORS_BY_TYPE[filter.field.type].values()).map((operator) => (_jsx(DropdownMenuItem, Object.assign({ onClick: () => {
                                let updatedFilter = Object.assign({}, filter);
                                if (NULL_VALUE_ALLOWED_OPERATORS.includes(operator)) {
                                    updatedFilter = Object.assign(Object.assign({}, filter), { operator: operator, value: null });
                                }
                                else {
                                    updatedFilter = Object.assign(Object.assign({}, filter), { operator: operator, value: undefined });
                                }
                                filterManager.updateFilter(filterGroup.id, filter.id, updatedFilter);
                            }, className: "hover:cursor-pointer text-sm" }, { children: operator.toLowerCase() }), operator))) }) }))] }));
    };
    const renderFilterValueEditor = (filterGroup, filter) => {
        var _a;
        if (NULL_VALUE_ALLOWED_OPERATORS.includes(filter.operator)) {
            return null;
        }
        switch (filter.field.type) {
            case "string":
                return filter.operator == FilterOperator.SUBSTRING_IN ? (_jsx(SubstringInFilterEditor, { onInputChange: (newValue) => {
                        filterManager.updateFilter(filterGroup.id, filter.id, Object.assign(Object.assign({}, filter), { operator: FilterOperator.SUBSTRING_IN, 
                            // It is not incorrect to send empty list to the BE filter, but keeping
                            // undefined for such case ensures that we don't send the filter in the
                            // query at all to save url characters.
                            value: newValue.length ? newValue : undefined }));
                    }, value: filter.value })) : (_jsx(StringValueEditor, { onInputChange: (newValue) => {
                        filterManager.updateFilter(filterGroup.id, filter.id, Object.assign(Object.assign({}, filter), { operator: filter.operator, value: newValue }));
                    }, value: filter.value }));
            case "select":
                return (_jsx(SelectValueEditor, { onInputChange: (newValue) => {
                        filterManager.updateFilter(filterGroup.id, filter.id, Object.assign(Object.assign({}, filter), { operator: filter.operator, value: newValue }));
                    }, options: filter.field.options || [], value: filter.value
                        ? filter.value
                        : (filter.field.options || [undefined])[0] }));
            case "number":
                return (_jsx(NumberValueEditor, { onInputChange: (newValue) => {
                        filterManager.updateFilter(filterGroup.id, filter.id, Object.assign(Object.assign({}, filter), { operator: filter.operator, value: newValue }));
                    }, value: filter.value }));
            case "boolean":
                return (_jsx(BooleanValueEditor, { onInputChange: (newValue) => {
                        filterManager.updateFilter(filterGroup.id, filter.id, Object.assign(Object.assign({}, filter), { operator: filter.operator, value: newValue }));
                    }, value: filter.value }));
            case "date":
            case "datestring":
                if (filter.operator === FilterOperator.IN) {
                    return (_jsx(SelectValueEditor, { onInputChange: (newValue) => {
                            filterManager.updateFilter(filterGroup.id, filter.id, Object.assign(Object.assign({}, filter), { operator: filter.operator, value: newValue }));
                        }, options: filter.field.options || [], value: filter.value
                            ? filter.value
                            : (filter.field.options || [undefined])[0] }));
                }
                return (_jsx(DateValueEditor, { onInputChange: (newValue) => {
                        filterManager.updateFilter(filterGroup.id, filter.id, Object.assign(Object.assign({}, filter), { operator: filter.operator, value: newValue }));
                    }, value: filter.value }));
            case "reference":
                return (_jsx(ReferenceValueEditor, { filterField: filter.field, dataFetcher: (_a = props.filterConfig.referenceObjectFetcherMap) === null || _a === void 0 ? void 0 : _a.get(filter.field.id), onInputChange: (newValue) => {
                        filterManager.updateFilter(filterGroup.id, filter.id, Object.assign(Object.assign({}, filter), { operator: filter.operator, value: newValue }));
                    }, value: filter.value }));
        }
    };
    const renderClauseOrOperatorForFilterGroup = (filterGroup, idx) => {
        return (_jsx(_Fragment, { children: idx === 0 ? (_jsx(OperatorTextContainer, { children: _jsx("span", Object.assign({ className: "font-medium text-sm text-wds-gray-6" }, { children: "Where" })) })) : idx === 1 ? (renderFilterGroupOperatorSelector(filterGroup)) : (_jsx(OperatorTextContainer, { children: _jsx("span", Object.assign({ className: "font-medium text-sm text-wds-gray-6" }, { children: filterGroup.operator.toLowerCase() })) })) }));
    };
    const renderClearFilterButton = (onClear) => {
        return (_jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsx(Button, Object.assign({ variant: "ghost", className: "h-auto p-0 hover:bg-transparent text-wds-gray-5", onClick: onClear }, { children: _jsx(XMarkIcon, { className: "w-5 h-5 shrink-0" }) })) })));
    };
    const renderFilter = (filterGroup, filter, idx) => {
        return (_jsxs("div", Object.assign({ className: "flex items-center gap-4" }, { children: [renderClauseOrOperatorForFilterGroup(filterGroup, idx), _jsxs("div", Object.assign({ className: "flex items-start gap-[-1px]" }, { children: [renderFilterFieldSelector(filterGroup, filter), renderFilterOperatorSelector(filterGroup, filter), renderFilterValueEditor(filterGroup, filter)] })), renderClearFilterButton(() => {
                    filterManager.removeFilter(filterGroup.id, filter.id);
                })] }), filter.id));
    };
    const renderFilterGroup = (parentFilterGroup, filterGroup, idx) => {
        if (!filterGroup) {
            return renderAddConditionsButtons(null);
        }
        return (_jsxs("div", Object.assign({ className: "flex items-center gap-4" }, { children: [parentFilterGroup
                    ? renderClauseOrOperatorForFilterGroup(parentFilterGroup, idx)
                    : null, _jsxs("div", Object.assign({ className: classNames("flex flex-col items-start gap-2", filterGroup.parentId
                        ? "bg-wds-gray-1 gap-3 p-2 rounded-lg border border-wds-gray-3"
                        : "") }, { children: [filterGroup && filterGroup.conditions.length ? (_jsx("div", Object.assign({ className: "flex pl-3 flex-col items-start gap-1" }, { children: filterGroup.conditions.map((condition, idx) => condition.objectType === "FILTER"
                                ? renderFilter(filterGroup, condition, idx)
                                : renderFilterGroup(filterGroup, condition, idx)) }))) : null, renderAddConditionsButtons(filterGroup)] }), filterGroup.id), filterGroup && filterGroup.parentId
                    ? renderClearFilterButton(() => {
                        filterManager.removeFilterGroup(filterGroup.parentId, filterGroup.id);
                    })
                    : null] }), filterGroup.id));
    };
    return (_jsxs(Popover, Object.assign({ onOpenChange: (openState) => {
            if (openState)
                return;
            // We dispatch a "filter changed" event when the user closes
            // the filter popover.
            const feFilter = filterManager.getQueryFilter();
            if (!feFilter) {
                // If the user has removed all the filters, dispatches filter changed
                // event with "undefined".
                setFiltersApplied(0); // Fixes clear all not reseting filter count
                props.onFilterChange(/* updatedFilter= */ undefined);
                return;
            }
            const beFilter = wiserBackendQueryConverter.convertQueryFilterToBackendFilter(feFilter);
            setFiltersApplied(JSON.stringify(beFilter).split('"field":').length - 1);
            props.onFilterChange(beFilter);
        } }, { children: [_jsx(PopoverTrigger, Object.assign({ className: classNames("focus:outline-none hover:bg-gray-200 rounded-lg py-1", filtersApplied > 0 ? "text-wds-blue-3" : "text-wds-gray-5") }, { children: _jsxs("div", Object.assign({ className: "flex px-3 gap-2 items-center font-bold text-sm" }, { children: [_jsx(FunnelIcon, { className: "h-4 w-4" }), _jsx("span", { children: (_a = props.title) !== null && _a !== void 0 ? _a : "Filter" }), filtersApplied > 0 && (_jsx("span", Object.assign({ className: "text-white font-bold bg-wds-blue-3 rounded-full px-2" }, { children: filtersApplied })))] })) })), _jsx(PopoverContent, Object.assign({ className: "w-max py-4 px-6" }, { children: _jsx(ScrollArea, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-2" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-5 text-sm font-bold" }, { children: props.filterPanelTitle })), filterManager.queryFilter &&
                                        filterManager.queryFilter.conditions.length > 0 && (_jsx(Button, Object.assign({ variant: "ghost", className: "h-auto p-0 text-sm font-bold text-wds-blue-3 hover:bg-transparent", onClick: () => {
                                            if (!filterManager.queryFilter) {
                                                return;
                                            }
                                            filterManager.removeFilterGroup(
                                            /* parentFilterGroupId= */ undefined, filterManager.queryFilter.id);
                                        } }, { children: "Clear all" })))] })), renderFilterGroup(null, filterManager.queryFilter, 0)] })) }) }))] })));
};
