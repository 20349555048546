var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import BaseTable from "./base_table";
import axios from "axios";
import BaseModal from "./base_modal";
import { getCookie } from "./cookies";
import EditTrigger from "./edit_trigger";
import LiveCardTextEditor from "./live_card_rich_text";
import dompurify from "dompurify";
import WaitingSpinner from "./waiting_spinner";
import { showErrorModal } from "./error_modal";
import usePagination from "./pagination/use_pagination";
import { getLiveCard, createVerbalTrigger, proposeLiveCard, } from "./common/endpoints";
export default function LiveCardTable({ showAdd = true, showDelete = true, showEdit = true, selectCallback, tableActions, }) {
    const [rows, setRows] = useState([]);
    const [addLiveCardOpen, setAddLiveCardOpen] = useState(false);
    const [editingLiveCardOpen, setEditingLiveCardOpen] = useState(false);
    const [editingLiveCard, setEditingLiveCard] = useState({
        cells: [],
        id: -1,
    });
    const [liveCards, setLiveCards] = useState(new Map());
    const paginationStateControl = usePagination();
    const cardName = useRef("");
    const [proposing, setProposing] = useState(false);
    // liveCardContent duplicates information from liveCards, which lets us reduce the amount
    // of global updates, so that changes to content don't affect re-rendering of the other
    // fields.
    //
    // There is an unfortunate setup here, where the editing state is held here for the rich text editor,
    // and at the BaseModal level for other fields (currently, the only other field is the name).
    // This means that updates from that rich text editor need to be stored separately from liveCards,
    // or the BaseModal will interpret a content edit as "I got a new live card and should reset."
    //
    // Splitting out liveCardContent achieves this. Note that I also needed to make editingFields stateful,
    // otherwise re-renders of this component cause BaseModal to reset/re-render, because it only
    // does a shallow equality check on the fields.
    const [liveCardContent, setLiveCardContent] = useState(new Map());
    // Making these stateful lets us memoize them, so dependent modules don't re-render unless
    // the fields are changed.
    const [editingFields, setEditingFields] = useState([]);
    const [isTraining, setIsTraining] = useState(false);
    let lookupLiveCards = (additional_id) => {
        axios
            .get("/call_nav/get_live_cards_paginated", {
            params: {
                page: paginationStateControl.currentPage,
                page_size: paginationStateControl.pageSize,
            },
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
            },
        })
            .then((res) => __awaiter(this, void 0, void 0, function* () {
            let live_cards = res.data.data;
            let newRows = [];
            let newLiveCardDetails = new Map();
            let newLiveCardContent = new Map();
            for (let i = 0; i < live_cards.length; i++) {
                // Santizing with dompurify makes this not dangerous.
                // Also, our content is created by a tenant, for that tenant, so our XSS risk is low to begin with.
                let contentAsHtml = (_jsx("div", { className: `${live_cards[i].content.includes("ql-ui") ? "ql-editor" : ""}`, dangerouslySetInnerHTML: {
                        __html: dompurify.sanitize(live_cards[i].content),
                    } }));
                newRows.push({
                    id: live_cards[i].id,
                    cells: [live_cards[i].name, contentAsHtml, live_cards[i].timestamp],
                });
                let live_cardDetails = {
                    name: live_cards[i].name,
                    content: live_cards[i].content,
                    intentId: live_cards[i].intent_id,
                    id: live_cards[i].id,
                    verbalTriggers: live_cards[i].verbal_triggers,
                    keywordTriggers: live_cards[i].keyword_triggers,
                };
                newLiveCardDetails.set(live_cards[i].id, live_cardDetails);
                newLiveCardContent.set(live_cards[i].id, live_cards[i].content);
            }
            setRows(newRows);
            paginationStateControl.setTotalRows(res.data.total);
            // The pagenated lookup may not return the currently-edited content. This
            // happens on create, because we are editing content on the last page, but
            // the paged lookup hasn't gotten that far yet.
            if (additional_id != null) {
                const live_card = yield getLiveCard(additional_id);
                let live_cardDetails = {
                    name: live_card.name,
                    content: live_card.content,
                    intentId: live_card.intent_id,
                    id: live_card.id,
                    verbalTriggers: live_card.verbal_triggers,
                    keywordTriggers: live_card.keyword_triggers,
                };
                newLiveCardDetails.set(live_card.id, live_cardDetails);
                newLiveCardContent.set(live_card.id, live_card.content);
            }
            setLiveCards(newLiveCardDetails);
            setLiveCardContent(newLiveCardContent);
        }));
    };
    useEffect(() => {
        lookupLiveCards(null);
    }, [paginationStateControl.currentPage]);
    useEffect(() => {
        setEditingFields([
            {
                name: "Name",
                type: "text",
                value: editingLiveCard === null || editingLiveCard === void 0 ? void 0 : editingLiveCard.cells[0],
                required: true,
                placeholder: "Name of this live card",
            },
        ]);
        cardName.current = editingLiveCard === null || editingLiveCard === void 0 ? void 0 : editingLiveCard.cells[0];
    }, [editingLiveCard]);
    let createLiveCard = (name, content) => {
        axios
            .post("/call_nav/create_live_card", {
            name: name,
            content: content,
        }, {
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((res) => {
            // Make sure to update the content, which is stored separately
            // from the liveCards map.
            let liveCardContentCopy = new Map(liveCardContent);
            liveCardContentCopy.set(res.data.id, content);
            setLiveCardContent(liveCardContentCopy);
            setEditingLiveCard({
                id: res.data.id,
                cells: [name, content, ""],
            });
            // We just created a new card, so we need to to update liveCards,
            // a map of the known, as-stored cards.
            lookupLiveCards(res.data.id);
            setAddLiveCardOpen(false);
            setEditingLiveCardOpen(true);
        })
            .catch((err) => {
            showErrorModal(err);
        });
    };
    let currentLiveCard = liveCards.get(editingLiveCard.id) || {
        name: "",
        content: "",
        intentId: -1,
        id: -1,
        verbalTriggers: [],
        keywordTriggers: [],
    };
    let editLiveCard = (id, name, content) => {
        if (isTraining)
            return;
        setIsTraining(true);
        axios
            .post("/call_nav/update_live_card", {
            live_card_id: id,
            name: name,
            content: content,
        }, {
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((res) => {
            lookupLiveCards(null);
            setEditingLiveCardOpen(false);
            setIsTraining(false);
        })
            .catch((err) => {
            setIsTraining(false);
            showErrorModal(err);
        });
    };
    let deleteLiveCard = (id) => {
        axios
            .post("/call_nav/delete_live_card", {
            live_card_id: id,
        }, {
            headers: {
                Authorization: localStorage.getItem("wiser_jwt"),
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((res) => {
            lookupLiveCards(null);
        })
            .catch((err) => {
            showErrorModal(err);
        });
    };
    let fieldsToMap = (fields) => {
        let map = new Map();
        for (let i = 0; i < fields.length; i++) {
            map.set(fields[i].name, fields[i].value);
        }
        return map;
    };
    let setQuestions = (questions) => {
        let live_cardsCopy = new Map(liveCards);
        let live_cardCopy = live_cardsCopy.get(editingLiveCard.id);
        live_cardCopy.verbalTriggers = questions;
        live_cardsCopy.set(editingLiveCard.id, live_cardCopy);
        setLiveCards(live_cardsCopy);
    };
    let setKeywords = (keywords) => {
        let live_cardsCopy = new Map(liveCards);
        let live_cardCopy = live_cardsCopy.get(editingLiveCard.id);
        live_cardCopy.keywordTriggers = keywords;
        live_cardsCopy.set(editingLiveCard.id, live_cardCopy);
        setLiveCards(live_cardsCopy);
    };
    let proposeCard = () => __awaiter(this, void 0, void 0, function* () {
        if (proposing)
            return;
        setProposing(true);
        const proposeResult = yield proposeLiveCard(cardName.current, true);
        let content = proposeResult.content;
        const triggerPhrases = proposeResult.trigger_phrases;
        // TODO: Fix data model here. Round-tripping through the backend is not ideal.
        // We currently need to already have a verbal trigger id in order for the
        // EditTriggers UI to work, so we add the new triggers to the card here.
        let newTriggers = [];
        for (let i = 0; i < triggerPhrases.length; i++) {
            let triggerId = (yield createVerbalTrigger(currentLiveCard.intentId, triggerPhrases[i])).id;
            const newTrigger = { id: triggerId, text: triggerPhrases[i] };
            newTriggers.push(newTrigger);
        }
        setQuestions(newTriggers);
        let liveCardContentCopy = new Map(liveCardContent);
        liveCardContentCopy.set(editingLiveCard.id, content);
        setLiveCardContent(liveCardContentCopy);
        setEditingLiveCard({
            id: editingLiveCard.id,
            cells: [cardName.current, content, ""],
        });
        setProposing(false);
    });
    return (_jsxs(_Fragment, { children: [_jsx(BaseTable, { title: "LiveCards", description: "Active cards", addName: "Add LiveCard", addCallback: showAdd
                    ? () => {
                        setAddLiveCardOpen(true);
                    }
                    : undefined, columnNames: ["Name", "Content", "Last Updated"], rows: rows, paginationStateControl: paginationStateControl, editCallback: showEdit
                    ? (live_card) => {
                        setEditingLiveCard(live_card);
                        setEditingLiveCardOpen(true);
                    }
                    : undefined, deleteCallback: showDelete
                    ? (live_card) => {
                        deleteLiveCard(live_card.id);
                    }
                    : undefined, selectCallback: selectCallback, tableActions: tableActions }), _jsx(BaseModal, { title: "Add LiveCard", description: "A live card is a pre-built card that can be used to answer questions during your calls.", open: addLiveCardOpen, setOpen: setAddLiveCardOpen, submitCallback: (fields) => {
                    let fieldMap = fieldsToMap(fields);
                    createLiveCard(fieldMap.get("Name"), fieldMap.get("Content"));
                }, setField: (fieldName, value) => {
                    if (fieldName === "Name") {
                        cardName.current = value;
                    }
                }, fields: [
                    {
                        name: "Name",
                        type: "text",
                        value: "",
                        required: true,
                        placeholder: "Name of this live card",
                    },
                    {
                        name: "Content",
                        type: "textarea",
                        value: "",
                        required: true,
                        placeholder: "Content",
                    },
                ], submitText: "Create LiveCard" }), _jsxs(BaseModal, Object.assign({ title: "Edit LiveCard", description: "Edit the existing live_card called " + (editingLiveCard === null || editingLiveCard === void 0 ? void 0 : editingLiveCard.cells[0]), open: editingLiveCardOpen, setOpen: setEditingLiveCardOpen, setField: (fieldName, value) => {
                    if (fieldName === "Name") {
                        cardName.current = value;
                    }
                }, submitCallback: (fields) => {
                    let fieldMap = fieldsToMap(fields);
                    editLiveCard(editingLiveCard.id, fieldMap.get("Name"), liveCardContent.get(currentLiveCard.id) || "");
                }, fields: editingFields, submitText: isTraining ? _jsx(WaitingSpinner, { text: "Training" }) : "Save Changes" }, { children: [_jsx("button", Object.assign({ type: "button", onClick: proposeCard, className: "flex inline-flex w-30 justify-center rounded-md bg-indigo-600 ml-2 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2" }, { children: proposing ? _jsx(WaitingSpinner, { text: "Proposing..." }) : "Propose" })), _jsx("label", Object.assign({ className: "block text-sm font-medium leading-6 text-gray-900" }, { children: "Content" })), _jsx(LiveCardTextEditor, { content: liveCardContent.get(currentLiveCard.id) || "", setContent: (value) => {
                            let liveCardContentCopy = new Map(liveCardContent);
                            liveCardContentCopy.set(editingLiveCard.id, value);
                            setLiveCardContent(liveCardContentCopy);
                        } }), _jsx(EditTrigger, { intentId: currentLiveCard.intentId, triggers: currentLiveCard.verbalTriggers, setTriggers: setQuestions, add_trigger_url: "/call_nav/create_verbal_trigger", remove_trigger_url: "/call_nav/delete_verbal_trigger", suggest_url: "/call_nav/suggest_card_trigger_phrases", title: "Sample questions", info: "Sample questions are examples of questions that would trigger this card to show up.", placeholder: "Add a sample question" }), _jsx(EditTrigger, { intentId: currentLiveCard.intentId, triggers: currentLiveCard.keywordTriggers, setTriggers: setKeywords, add_trigger_url: "/call_nav/create_keyword_trigger", remove_trigger_url: "/call_nav/delete_keyword_trigger", title: "Keywords", info: "Keywords are words or phrases that would trigger this card to show up.", placeholder: "Add a keyword" })] }))] }));
}
