export const updateSearchParams = (updatedParams, searchParams) => {
    const params = new URLSearchParams(searchParams);
    updatedParams.forEach((updatedParam) => {
        if (updatedParam.value) {
            params.set(updatedParam.key, updatedParam.value);
        }
        else {
            params.delete(updatedParam.key);
        }
    });
    return params.toString();
};
