var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { DataTableViewOptions } from "./data_table_view_options";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { DataTableLayoutsPopover } from "./data_table_layouts_popover";
import { QueryFilterPopover } from "../common/query_builder/ui/query_filter_popover";
import { SortRuleBuilderPopover } from "../common/sort_rule_builder/ui/sort_rule_builder_popover";
import { DocumentDuplicateIcon, MagnifyingGlassIcon, ShareIcon, } from "@heroicons/react/24/outline";
import { Button } from "./button";
import BaseModal from "../base_modal";
import { useQueryClient } from "react-query";
import { createSharedTableLayout } from "../common/endpoints";
import { LoadingSelectItems } from "../common/loaders";
import { debounce } from "../common/debounce";
/**
 * Data table toolbar to render table layout manager, filter, and sort buttons.
 * This is slightly different than the `DataTableNewToolbar` component as this
 * component uses the filter builder, and sort builder components by default thus
 * providing clients the option to handle onFiltersChange, and onSortRulesChange events.
 */
export function DataTableToolbarV2(_a) {
    var { table, columnIdMap, enableFiltering = true, enableSorting = true, enableLayoutManagement = true, filterConfig, sortRuleConfig, layouts, defaultTableLayout, exportComponent, onFiltersChange, onVisibleColumnsChange, onSortRulesChange, filterPanelTitle, sortRuleBuilderPanelTitle, initialFilters, initialSortRules, showSearchBar = false, onSearchQueryChange, children, className } = _a, props = __rest(_a, ["table", "columnIdMap", "enableFiltering", "enableSorting", "enableLayoutManagement", "filterConfig", "sortRuleConfig", "layouts", "defaultTableLayout", "exportComponent", "onFiltersChange", "onVisibleColumnsChange", "onSortRulesChange", "filterPanelTitle", "sortRuleBuilderPanelTitle", "initialFilters", "initialSortRules", "showSearchBar", "onSearchQueryChange", "children", "className"]);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryClient = useQueryClient();
    const showFilterBuilder = useMemo(() => {
        // The filter builder is only rendered if filterConfig, and onFiltersChange
        // callback is provided to this component.
        return enableFiltering && !!filterConfig && !!onFiltersChange;
    }, [enableFiltering, filterConfig, onFiltersChange]);
    const showSortRuleBuilder = useMemo(() => {
        // The sort rule builder is only rendered if sortRuleConfig, and onSortRulesChange
        // callback is provided to this component.
        return enableSorting && !!sortRuleConfig && !!onSortRulesChange;
    }, [enableSorting, sortRuleConfig, onSortRulesChange]);
    const [selectedLayout, setSelectedLayout] = React.useState(props.matchingLayout);
    const [shareModalOpen, setShareModalOpen] = React.useState(false);
    const [shareId, setShareId] = React.useState(null);
    // This flag indicates when you want to reset to the current selected layout
    // Toggling this resets the layout
    const [resetLayout, setResetLayout] = React.useState(false);
    // The current value of the search query. Only applicable if showSearchBar is true.
    // The combination of state, debounced state, and useEffect helps us in perofrming
    // a debounced search of table without re-rendering the table on every keystroke.
    const [searchQueryValue, setSearchQueryValue] = React.useState("");
    const debouncedSearchQuery = debounce(searchQueryValue, 500);
    React.useEffect(() => {
        if (showSearchBar && onSearchQueryChange) {
            onSearchQueryChange(debouncedSearchQuery);
        }
    }, [debouncedSearchQuery]);
    React.useEffect(() => {
        // Toggle column visibility based on the views
        if (searchParams.has("layout")) {
            return;
        }
        if (!selectedLayout) {
            if (searchParams.has("default")) {
                table.setColumnSizing(Object.assign({ "row-select": defaultTableLayout.columns["row-select"], select: defaultTableLayout.columns.select }, defaultTableLayout.columns));
            }
            else {
                table.setColumnSizing({
                    "row-select": defaultTableLayout.columns["row-select"],
                    select: defaultTableLayout.columns.select,
                });
            }
            return;
        }
        table.getAllColumns().forEach((column) => {
            if (!column.getIsVisible() &&
                Object.keys(selectedLayout.columns).includes(column.id)) {
                column.toggleVisibility(true);
            }
            else if (column.getIsVisible() &&
                !Object.keys(selectedLayout.columns).includes(column.id)) {
                column.toggleVisibility(false);
            }
        });
        table.setColumnSizing(selectedLayout.columns);
        if (selectedLayout.column_order.length > 0) {
            const missingColumns = table
                .getAllColumns()
                .filter((column) => !selectedLayout.column_order.includes(column.id));
            const newColumnOrder = [
                ...selectedLayout.column_order,
                ...missingColumns.map((c) => c.id),
            ];
            table.setColumnOrder(newColumnOrder);
        }
        // Navigate to correct query params based on the selected layout
        const copySearchParams = new URLSearchParams(searchParams.toString());
        const copyWithoutPaginationParams = new URLSearchParams(copySearchParams.toString());
        copyWithoutPaginationParams.delete("page");
        copyWithoutPaginationParams.delete("per_page");
        copyWithoutPaginationParams.delete("default");
        if (copyWithoutPaginationParams.toString().length === 0 &&
            selectedLayout.is_default &&
            copySearchParams.has("default")) {
            // If the query string is empty and the layout is default, we don't want to navigate
            return;
        }
        let layoutQueryString = selectedLayout.query_string;
        if (copySearchParams.toString() !== selectedLayout.query_string) {
            navigate(`?${layoutQueryString}`);
        }
    }, [
        table.getAllColumns(),
        selectedLayout === null || selectedLayout === void 0 ? void 0 : selectedLayout.columns,
        selectedLayout === null || selectedLayout === void 0 ? void 0 : selectedLayout.query_string,
        resetLayout,
    ]);
    const getVisibleColumnWidths = (columnSizingState) => {
        // This function ensures that the column sizing state only contains columns that are visible
        const correctedSizingState = {};
        const visibleColumns = Object.entries(table.getVisibleLeafColumns()).map(([columnId, column]) => {
            return column.id;
        });
        Object.entries(columnSizingState).forEach(([column, size]) => {
            if (visibleColumns.includes(column)) {
                correctedSizingState[column] = size;
            }
        });
        // If a visible column's width does not change fro mdefault 150 then it's not present in the sizing state
        // Our code assumes that all visible columns have a width, an empty key means the column is not visible
        visibleColumns.forEach((column) => {
            if (!correctedSizingState[column]) {
                correctedSizingState[column] = 150;
            }
        });
        return correctedSizingState;
    };
    // This fetches the ColumnSizingState from tanstack table if present
    // By default when no changes are made this is empty, our code completely relies on this
    // Thus we pick it from the layout if it is empty
    const columnSizing = Object.keys(table.getState().columnSizing).length > 0
        ? table.getState().columnSizing
        : (selectedLayout === null || selectedLayout === void 0 ? void 0 : selectedLayout.columns) || defaultTableLayout.columns;
    // This side effect notifies the parent component of changes
    // to the set of visible columns.
    React.useEffect(() => {
        const visibleColumns = Object.entries(table.getVisibleLeafColumns()).map(([columnId, column]) => {
            return column.id;
        });
        onVisibleColumnsChange === null || onVisibleColumnsChange === void 0 ? void 0 : onVisibleColumnsChange(visibleColumns);
    }, [table.getVisibleLeafColumns()]);
    const correctedColumnSizing = useMemo(() => {
        return getVisibleColumnWidths(columnSizing);
    }, [
        columnSizing,
        table.getVisibleLeafColumns(),
        table.getState().columnVisibility,
    ]);
    const createShareDialog = () => __awaiter(this, void 0, void 0, function* () {
        const searchParamsWithoutDefault = new URLSearchParams(searchParams.toString());
        searchParamsWithoutDefault.delete("default");
        const data = yield queryClient.fetchQuery({
            queryFn: () => createSharedTableLayout({
                table_name: defaultTableLayout.table_name,
                query_string: decodeURIComponent(searchParamsWithoutDefault.toString()),
                columns: correctedColumnSizing,
                column_order: table.getState().columnOrder,
            }),
        });
        if (data) {
            setShareId(data.id);
        }
    });
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex space-x-2" }, { children: [enableLayoutManagement && layouts && (_jsx(DataTableLayoutsPopover, { table: table, layouts: layouts, defaultTableLayout: defaultTableLayout, selectedLayout: selectedLayout, setSelectedLayout: setSelectedLayout, setResetLayout: setResetLayout })), showFilterBuilder && (_jsx(QueryFilterPopover, { filterConfig: filterConfig, filterPanelTitle: filterPanelTitle !== null && filterPanelTitle !== void 0 ? filterPanelTitle : "Filter rows", onFilterChange: onFiltersChange, initialFilter: initialFilters })), showSortRuleBuilder && (_jsx(SortRuleBuilderPopover, { config: sortRuleConfig, panelTitle: sortRuleBuilderPanelTitle !== null && sortRuleBuilderPanelTitle !== void 0 ? sortRuleBuilderPanelTitle : "Sort row by", onSortRulesChange: onSortRulesChange, initialSortRules: initialSortRules })), _jsx(DataTableViewOptions, { table: table, columnIdMap: columnIdMap })] })), _jsxs("div", Object.assign({ className: "flex flex-row space-x-2" }, { children: [_jsxs(Button, Object.assign({ variant: "ghost", size: "sm", className: "text-wds-gray-5", onClick: () => __awaiter(this, void 0, void 0, function* () {
                                    setShareModalOpen(true);
                                    yield createShareDialog();
                                }) }, { children: [_jsx(ShareIcon, { className: "h-4 w-4 mr-2" }), " Share"] })), showSearchBar && (_jsxs("div", Object.assign({ className: "flex w-80 h-8 px-3 items-center gap-1 border border-gray-300 bg-white rounded-lg" }, { children: [_jsx(MagnifyingGlassIcon, { className: "h-4 w-4 text-gray-500 shrink-0" }), _jsx("input", { type: "text", placeholder: "Search", className: "w-full h-full focus:outline-none border-0 focus:ring-0 text-gray-500", value: searchQueryValue, onChange: (e) => {
                                            setSearchQueryValue(e.target.value);
                                        } })] }))), exportComponent] }))] })), _jsx(BaseModal, Object.assign({ title: "Share layout", description: "Share this layout with a collegue", open: shareModalOpen, setOpen: (open) => {
                    setShareModalOpen(open);
                    if (!open) {
                        // Ensure that previous ID never conflicts
                        setShareId(null);
                    }
                }, submitText: "Done", submitCallback: () => {
                    setShareModalOpen(false);
                    setShareId(null);
                }, fields: [] }, { children: _jsx("div", Object.assign({ className: "flex flex-col gap-4" }, { children: _jsx("div", Object.assign({ className: "w-full h-12 px-4 py-2 bg-wds-gray-3 rounded-lg justify-between items-center inline-flex" }, { children: shareId ? (_jsxs(_Fragment, { children: [_jsx("div", { children: `${window.location.origin}${window.location.pathname}?layout=${shareId}` }), _jsx(Button, Object.assign({ variant: "ghost", size: "sm", onClick: () => {
                                        navigator.clipboard.writeText(`${window.location.origin}${window.location.pathname}?layout=${shareId}`);
                                    }, "aria-label": "Copy link" }, { children: _jsx(DocumentDuplicateIcon, { className: "w-5 h-5 relative" }) }))] })) : (_jsx(LoadingSelectItems, {})) })) })) }))] }));
}
