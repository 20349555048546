var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { EllipsisVerticalIcon, PencilIcon, PlusIcon, TrashIcon, } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import ButtonWithIcon from "../common/button_with_icon";
import AddPlaybookItemGroupModal from "./add_playbook_item_group_modal";
import { getPlaybookItemGroups, getPlaybooks } from "../common/endpoints";
import Dropdown from "../common/dropdown";
import { confirmModal } from "../common/confirmation_modal";
import { useNavigate } from "react-router-dom";
import { Popover, PopoverContent, PopoverTrigger } from "../components/popover";
import { Button } from "../components/button";
/**
 * Call Agenda tab in the Call Launch page for calls in the future. Mainly has view playbooks & edit playbooks feature.
 *
 * @param {PlaybookDetails | null} playbook - The current playbook object.
 * @param {boolean} isFutureCall - A state variable indicating whether the call is in the future.
 * @param {() => void} addPlaybook - A function to add a new playbook.
 */
export default ({ playbook, addPlaybook, deletePlaybookItemGroup, addPlaybookItemGroup, updateCallPlaybook, }) => {
    var _a;
    const [addPlaybookItemGroupModalOpen, setAddPlaybookItemGroupModalOpen] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [allPlaybookItemGroups, setAllPlaybookItemGroups] = useState([]);
    const [allPlaybooks, setAllPlaybooks] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        getPlaybooks().then(setAllPlaybooks);
        getPlaybookItemGroups().then(setAllPlaybookItemGroups);
    }, []);
    const groupPlaybookItemGroupsByPlaybook = (playbookItemGroups) => {
        // Sort gives priority to the current playbook in the case where one item is part of multiple playbooks
        const sortedPlaybooks = allPlaybooks.sort((a, b) => {
            if (a.id === (playbook === null || playbook === void 0 ? void 0 : playbook.id))
                return -1;
            if (b.id === (playbook === null || playbook === void 0 ? void 0 : playbook.id))
                return 1;
            return 0;
        });
        return playbookItemGroups.reduce((acc, item) => {
            var _a;
            const playbookId = ((_a = sortedPlaybooks.find((playbook) => playbook.playbook_item_groups
                .map((itemGroup) => itemGroup.id)
                .includes(item.id))) === null || _a === void 0 ? void 0 : _a.id) || -1;
            if (!acc[playbookId]) {
                acc[playbookId] = [];
            }
            acc[playbookId].push(item);
            return acc;
        }, {});
    };
    const renderPlaybook = (playbook) => {
        const playbookItemGroupsGrouped = groupPlaybookItemGroupsByPlaybook(playbook.playbook_item_groups);
        return Object.entries(playbookItemGroupsGrouped).map(([playbookId, playbookItemGroups]) => {
            const playbook = allPlaybooks.find((p) => p.id === parseInt(playbookId, 10));
            return (_jsxs("div", Object.assign({ className: "p-4 bg-white rounded-lg border border-neutral-200 flex flex-col gap-4 mb-4" }, { children: [_jsx("div", Object.assign({ className: "flex justify-between items-center w-full" }, { children: (playbook === null || playbook === void 0 ? void 0 : playbook.name) ? (_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: "text-base font-bold leading-normal" }, { children: playbook.name })), _jsx("div", Object.assign({ className: "flex ml-2 px-2 py-0.5 space-x-2 rounded-lg border border-wds-gray-3 bg-wds-gray-1" }, { children: _jsx("div", Object.assign({ className: "text-[14px] text-wds-black font-lato leading-20 px-2" }, { children: "Tracker" })) }))] }))) : null })), playbookItemGroups.map(({ name, id, default_item }) => (_jsx("div", Object.assign({ className: "p-4 bg-white rounded-lg border border-neutral-200 flex flex-col gap-2" }, { children: _jsxs("div", Object.assign({ className: "flex justify-between items-center gap-2" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: "font-bold leading-snug" }, { children: name })), _jsx("div", Object.assign({ className: "flex p-1 px-2 ml-8 space-x-2 rounded-lg border border-wds-gray-3 bg-wds-gray-1 text-wds-gray-6 font-normal leading-snug" }, { children: default_item.call_stage === "LIVE_CALL"
                                                ? "Live"
                                                : "Post-call" }))] })), _jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsxs(Popover, { children: [_jsx(PopoverTrigger, { children: _jsx(Button, Object.assign({ variant: "ghost", className: "text-wds-black hover:text-wds-black rounded-full p-2 leading-snug" }, { children: _jsx(EllipsisVerticalIcon, { className: "w-5 h-5" }) })) }), _jsx(PopoverContent, Object.assign({ side: "bottom", align: "start", sideOffset: 0, className: "w-full h-full py-2 px-0" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsxs(Button, Object.assign({ variant: "ghost", className: "text-wds-black font-normal hover:bg-wds-gray-1 hover:text-wds-black rounded-none items-start justify-start", onClick: () => {
                                                                navigate(`/trackers`, {
                                                                    state: {
                                                                        playbookId: playbook === null || playbook === void 0 ? void 0 : playbook.id,
                                                                        editingPlaybookGroupId: id,
                                                                    },
                                                                });
                                                            }, style: {
                                                                fontFamily: "Lato, sans-serif",
                                                                alignItems: "center",
                                                                alignSelf: "stretch",
                                                            } }, { children: [_jsx(PencilIcon, { className: "w-5 h-5 relative text-primary hover:text-primary-dark mr-2" }), "Edit block configuration"] })), _jsxs(Button, Object.assign({ variant: "ghost", className: "font-normal hover:bg-wds-gray-1 text-wds-black hover:text-wds-black rounded-none items-start justify-start", onClick: () => {
                                                                confirmModal(() => __awaiter(void 0, void 0, void 0, function* () {
                                                                    deletePlaybookItemGroup(id);
                                                                }), `Remove tracker`, `Are you sure you want to remove this tracker from the call?`, "Remove", "Cancel");
                                                            }, style: {
                                                                fontFamily: "Lato, sans-serif",
                                                            } }, { children: [_jsx(TrashIcon, { className: "w-5 h-5 relative text-destructive hover:text-destructive-dark mr-2" }), "Remove from this call"] }))] })) }))] }) }))] })) }), id)))] })));
        });
    };
    const renderAddPlaybookButton = () => {
        return (_jsxs(_Fragment, { children: [_jsxs("button", Object.assign({ className: "border border-dashed border-wds-blue-3 flex w-full h-14 p-4 bg-wds-white text-blue-600 font-bold rounded-lg justify-center items-center gap-2 hover:bg-blue-200", onClick: addPlaybook }, { children: ["Add a tracker set", _jsx(PlusIcon, { className: "w-5 h-5" })] })), _jsx("div", Object.assign({ className: "lg:hidden w-full h-14 p-4 bg-blue-100 text-blue-600 font-bold rounded-lg justify-center items-center gap-2 inline-flex" }, { children: "No trackers added to this call. Expand your browser to add a tracker set." }))] }));
    };
    function PlusIcn() {
        return _jsx(PlusIcon, { className: "w-4 h-4 text-wds-blue-3" });
    }
    const openAddPlaybookItemModal = () => {
        setAddPlaybookItemGroupModalOpen(true);
    };
    return (_jsxs("div", Object.assign({ className: "w-full px-8" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-center mb-4" }, { children: [_jsx("div", Object.assign({ className: "font-bold text-[18px] leading-6" }, { children: "Trackers" })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: "relative" }, { children: _jsx(Dropdown, { options: allPlaybooks.map((playbook) => ({
                                        value: playbook.id.toString(),
                                        label: playbook.name,
                                    })), defaultSelected: (_a = playbook === null || playbook === void 0 ? void 0 : playbook.id.toString()) !== null && _a !== void 0 ? _a : "", onOptionSelect: (id) => {
                                        updateCallPlaybook(allPlaybooks.find((playbook) => playbook.id === parseInt(id)));
                                    }, buttonClasses: "h-8 text-sm" }) })), _jsx(ButtonWithIcon, { label: "Add Tracker", Icon: PlusIcn, onClick: openAddPlaybookItemModal })] }))] })), playbook ? renderPlaybook(playbook) : renderAddPlaybookButton(), playbook && addPlaybookItemGroupModalOpen ? (_jsx(AddPlaybookItemGroupModal, { open: addPlaybookItemGroupModalOpen, onClose: () => setAddPlaybookItemGroupModalOpen(false), addPlaybookItemGroups: (playbookItemGroups) => __awaiter(void 0, void 0, void 0, function* () {
                    if (!playbook)
                        return;
                    yield Promise.all(playbookItemGroups.map((playbookItemGroup) => addPlaybookItemGroup(playbookItemGroup.id)));
                    setAddPlaybookItemGroupModalOpen(false);
                }), allPlaybookItemGroups: allPlaybookItemGroups, existingPlaybookItemGroupIds: playbook.playbook_item_groups.map((itemGroup) => itemGroup.id), linkToPlaybookItemGroup: true, createCallback: () => {
                    navigate("/trackers", {
                        state: {
                            creatingPlaybookItemGroup: true,
                            playbookId: playbook.id,
                        },
                    });
                } })) : null] })));
};
