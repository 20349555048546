import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useReactTable, getCoreRowModel, flexRender, getFilteredRowModel, } from "@tanstack/react-table";
import { ChevronRightIcon, MagnifyingGlassIcon, PlusIcon, } from "@heroicons/react/24/outline";
import classNames from "./class_names";
const SelectionTable = (props) => {
    const { columns, rows, canSelectRow, createCallback, createLabel, expandCallback, } = props;
    const [globalFilter, setGlobalFilter] = useState("");
    const [rowSelection, setRowSelection] = useState({});
    const table = useReactTable({
        data: rows,
        columns: [
            {
                id: "selection",
                header: (props) => null,
                cell: (props) => (_jsx("input", { type: "checkbox", checked: props.row.getIsSelected(), onChange: props.row.getToggleSelectedHandler(), disabled: !props.row.getCanSelect(), className: classNames("w-4 h-4 rounded focus:ring-0 focus:outline-none", props.row.getCanSelect()
                        ? "cursor-pointer border-gray-3000"
                        : "border-gray-300") })),
                enableSorting: false,
                enableColumnFilter: false,
            },
            ...columns,
        ],
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: "includesString",
        state: {
            globalFilter: globalFilter,
            rowSelection: rowSelection,
        },
        enableGlobalFilter: true,
        enableRowSelection: (row) => canSelectRow ? canSelectRow(row.original) : true,
        onRowSelectionChange: setRowSelection,
    });
    useEffect(() => {
        props.setSelectedRows(table.getSelectedRowModel().rows.map((row) => row.original));
    }, [table.getSelectedRowModel().rows]);
    return (_jsxs("div", Object.assign({ className: "w-full h-full py-4 bg-neutral-100" }, { children: [_jsx("div", Object.assign({ className: "w-full justify-end inline-flex items-end" }, { children: _jsxs("div", Object.assign({ className: "w-full px-3 bg-white rounded-lg border border-zinc-200 justify-start items-center flex" }, { children: [_jsx(MagnifyingGlassIcon, { className: "text-gray-400 w-5 h-5 relative" }), _jsx("input", { className: "w-full focus:outline-none border-0 focus:ring-0 placeholder:text-gray-400 text-sm", placeholder: "Search", onChange: (e) => {
                                setGlobalFilter(e.target.value);
                            } })] })) })), _jsx("div", Object.assign({ className: "w-full flex flex-col mt-4" }, { children: _jsx("div", Object.assign({ className: "rounded-lg border border-solid border-gray-300" }, { children: _jsxs("table", Object.assign({ className: "w-full bg-white rounded-lg" }, { children: [_jsx("thead", { children: table.getHeaderGroups().map((headerGroup) => (_jsxs("tr", Object.assign({ className: "[&>*:first-child]:rounded-tl-lg [&>*:last-child]:rounded-tr-lg [&>*:not(:last-child)]:border-r" }, { children: [headerGroup.headers.map((header) => (_jsx("th", Object.assign({ className: "font-bold text-gray-500 bg-gray-100 p-2 border-gray-300" }, { children: _jsx("div", Object.assign({ className: "flex flex-row items-center justify-between self-stretch px-2 gap-4" }, { children: header.isPlaceholder
                                                    ? null
                                                    : flexRender(header.column.columnDef.header, header.getContext()) })) }), header.id))), expandCallback ? (_jsx("th", { className: "font-bold text-gray-500 bg-gray-100 p-2 border-gray-300" })) : null] }), headerGroup.id))) }), _jsx("tbody", { children: table.getRowModel().rows.map((row) => (_jsxs("tr", Object.assign({ className: "[&>*:first-child]:rounded-bl-lg [&>*:last-child]:rounded-br-lg [&>*:not(:last-child)]:border-r" }, { children: [row.getVisibleCells().map((cell) => (_jsx("td", Object.assign({ className: "border-t border-t-gray-100 p-2 bg-white" }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id))), expandCallback ? (_jsx("td", Object.assign({ className: "text-gray-500 border-t border-t-gray-100 bg-white text-center w-12" }, { children: _jsx("button", Object.assign({ className: "rounded-full justify-center items-center inline-flex hover:bg-gray-200 w-8 h-8", onClick: () => {
                                                    expandCallback(row.original);
                                                }, "aria-label": "Edit" }, { children: _jsx(ChevronRightIcon, { className: "w-5 h-5 relative" }) })) }))) : null] }), row.id))) }), createCallback ? (_jsx("tfoot", { children: _jsx("tr", { children: _jsx("td", Object.assign({ colSpan: table.getAllColumns().length }, { children: _jsxs("button", Object.assign({ className: "w-full h-9 px-1 bg-white shadow border-t border-zinc-200 justify-start items-center gap-1 inline-flex rounded-bl-lg rounded-br-lg", onClick: () => {
                                                createCallback();
                                            } }, { children: [_jsx("div", Object.assign({ className: "w-8 h-8 rounded-full justify-center items-center flex" }, { children: _jsx(PlusIcon, { className: "w-5 h-5 text-blue-600" }) })), _jsx("div", Object.assign({ className: "text-blue-600 font-medium leading-tight" }, { children: createLabel }))] })) })) }) })) : null] })) })) }))] })));
};
export default SelectionTable;
