import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
const StringListInput = (props) => {
    const [stringList, setStringList] = useState(props.strings);
    const [newString, setNewString] = useState("");
    const handleAddString = () => {
        if (newString.trim() === "") {
            return;
        }
        const newStrings = [...stringList, newString];
        setStringList(newStrings);
        setNewString("");
        props.onChangeCallback(newStrings);
    };
    const handleRemoveString = (stringIdxToRemove) => {
        const newStrings = [...stringList];
        newStrings.splice(stringIdxToRemove, 1);
        setStringList(newStrings);
        props.onChangeCallback(newStrings);
    };
    return (_jsxs("div", { children: [_jsxs("div", { children: [_jsx("input", { type: "text", placeholder: "Enter string...", value: newString, onChange: (e) => setNewString(e.target.value), className: "w-full self-stretch bg-white rounded-lg border border-wds-gray-3 justify-start items-center gap-2 inline-flex font-normal font-['Lato'] leading-snug placeholder:text-xs" }), _jsx("button", Object.assign({ type: "button", className: "mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1", onClick: handleAddString }, { children: "Add" }))] }), _jsx("br", {}), _jsx("ul", Object.assign({ className: "inline-flex flex-col sm:gap-2" }, { children: stringList.map((string, index) => (_jsxs("li", Object.assign({ className: "inline-flex sm:gap-2" }, { children: [_jsx("span", Object.assign({ className: "mt-2" }, { children: string })), _jsx("button", Object.assign({ type: "button", className: "mt-3 inline-flex w-24 justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-600 sm:col-start-1 sm:mt-0", onClick: () => handleRemoveString(index) }, { children: "Remove" }))] }), index))) }))] }));
};
export default StringListInput;
