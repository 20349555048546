import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getTableLayouts } from "../common/endpoints";
function useTableSearchParams(rawSearchParams, layouts) {
    return useMemo(() => {
        if (rawSearchParams.has("filter") ||
            rawSearchParams.has("order_by") ||
            !rawSearchParams.has("default")) {
            return rawSearchParams;
        }
        if (layouts && layouts.length > 0) {
            const defaultTableLayout = layouts.find((tableLayout) => tableLayout.is_default);
            if (defaultTableLayout) {
                return new URLSearchParams(defaultTableLayout.query_string);
            }
        }
        return rawSearchParams;
    }, [layouts, rawSearchParams]);
}
export function useCustomSearchParams(tableName, defaultLayout) {
    const [rawSearchParams, setRawSearchParams] = useSearchParams();
    const { data: layouts, isLoading, isError, } = useQuery(["getTableLayouts", tableName], () => getTableLayouts(tableName, defaultLayout), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
    const searchParams = useTableSearchParams(rawSearchParams, layouts);
    return { searchParams, layouts, isLoading, isError };
}
