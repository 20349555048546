var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button } from "../components/button";
import { Separator } from "../components/separator";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BaseModal from "../base_modal";
import { createWebhookSubscription, deleteWebhookSubscription, getWebhookSubscriptions, sendDummyWebhookEvent, } from "../common/endpoints";
import { confirmModal } from "../common/confirmation_modal";
const Webhooks = () => {
    const [isCreatingWebhookSubscription, setIsCreatingWebhookSubscription] = useState(false);
    const { data: webhookSubscriptions } = useQuery({
        queryKey: ["webhook_subscriptions"],
        queryFn: getWebhookSubscriptions,
    });
    const queryClient = useQueryClient();
    const deleteMutation = useMutation({
        mutationFn: deleteWebhookSubscription,
        onSuccess: (_, id) => {
            queryClient.setQueryData(["webhook_subscriptions"], (prev) => {
                return ((prev === null || prev === void 0 ? void 0 : prev.filter((webhookSubscription) => webhookSubscription.id !== id)) || []);
            });
        },
    });
    const createMutation = useMutation({
        mutationFn: createWebhookSubscription,
        onSuccess: (createdWebhookSubscription) => {
            queryClient.setQueryData(["webhook_subscriptions"], (prev) => [
                ...(prev || []),
                createdWebhookSubscription,
            ]);
        },
    });
    return (_jsxs("div", Object.assign({ className: "mt-8 mb-8 m-auto inline-flex flex-col items-start p-6 gap-6 rounded-lg border-solid border-gray-300 bg-white lg:w-[720px] w-[480px]" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between w-full" }, { children: [_jsx("span", Object.assign({ className: "font-black text-lg" }, { children: "Webhooks" })), _jsx(Button, Object.assign({ onClick: () => setIsCreatingWebhookSubscription(true), size: "lg" }, { children: "Create webhook" }))] })), _jsx(Separator, {}), _jsx("div", Object.assign({ className: "w-full" }, { children: webhookSubscriptions === null || webhookSubscriptions === void 0 ? void 0 : webhookSubscriptions.map((webhookSubscription) => (_jsxs("div", Object.assign({ className: "bg-white shadow-md rounded-lg p-5 mb-5 border border-gray-200" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-center mb-2" }, { children: [_jsx("h2", Object.assign({ className: "text-lg font-semibold text-gray-900" }, { children: webhookSubscription.name })), _jsxs("div", Object.assign({ className: "flex space-x-3" }, { children: [_jsx(Button, Object.assign({ variant: "secondary", size: "sm", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                yield sendDummyWebhookEvent(webhookSubscription.id);
                                            }) }, { children: "Send dummy event" })), _jsx(Button, Object.assign({ onClick: () => navigator.clipboard.writeText(webhookSubscription.secret), variant: "outline", size: "sm" }, { children: "Copy secret" })), _jsx(Button, Object.assign({ variant: "destructive", size: "sm", onClick: () => confirmModal(() => deleteMutation.mutateAsync(webhookSubscription.id), "Delete webhook", "Are you sure you want to delete this webhook?", "Delete", "Cancel") }, { children: "Delete" }))] }))] })), _jsxs("div", Object.assign({ className: "mb-3 text-sm text-gray-600" }, { children: [_jsx("span", Object.assign({ className: "font-semibold" }, { children: "Events: " })), webhookSubscription.events.join(", ")] })), _jsxs("div", Object.assign({ className: "text-xs text-gray-500" }, { children: [_jsx("span", Object.assign({ className: "font-semibold" }, { children: "Signing secret: " })), "****", webhookSubscription.secret.slice(-6)] }))] }), webhookSubscription.id))) })), isCreatingWebhookSubscription && (_jsx(BaseModal, { title: "Create webhook subscription", setOpen: () => setIsCreatingWebhookSubscription(false), description: "", fields: [
                    {
                        name: "Webhook name",
                        value: "",
                        type: "text",
                        required: true,
                        placeholder: "my webhook",
                    },
                    {
                        name: "Webhook subscription URL",
                        value: "",
                        type: "text",
                        required: true,
                        placeholder: "https://example.com/webhook",
                    },
                    {
                        name: "Webhook events",
                        value: [],
                        type: "multi_select",
                        options: [
                            {
                                label: "transcript_available",
                                value: "transcript_available",
                            },
                        ],
                    },
                ], submitCallback: (fields) => __awaiter(void 0, void 0, void 0, function* () {
                    const name = fields[0].value;
                    const url = fields[1].value;
                    const events = fields[2].value;
                    yield createMutation.mutateAsync({ name, url, events });
                    setIsCreatingWebhookSubscription(false);
                }), submitText: "Create webhook subscription", open: isCreatingWebhookSubscription }))] })));
};
export default Webhooks;
