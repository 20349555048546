import { useQuery } from "react-query";
import { queryLiveCalls } from "../../common/endpoints";
/**
 * This hook fetches the calls, and related metadata like total number of calls
 * for rendering in the account research page.
 * Note that this hook does not fetch ALL calls for the account and only provides
 * the most recent 5 calls to be rendered in the calls block.
 *
 * @param accountId: The account id for which this hook needs to fetch calls
 * @returns
 */
export const useAccountCalls = ({ accountId }) => {
    const jsonQuery = {
        table: "LiveCall",
        order_by_fields: ["-call_time", "id"],
        structured_filter: {
            where_condition: {
                operator: "AND",
                subconditions: [
                    {
                        where_condition: {
                            field: "account_id",
                            operator: "EQUALS",
                            value: accountId,
                        },
                    },
                ],
            },
        },
    };
    const { data: queryCallsResponse, isLoading: callsLoading, isError: callsError, } = useQuery({
        queryKey: ["callsByAccount", accountId],
        // We assume that the total number of calls per account do not exceed
        // the per page limit (which is 100 defined in the client).
        queryFn: () => queryLiveCalls(JSON.stringify(jsonQuery), 
        /* cursor= */ undefined, 
        /* order_by= */ undefined, 
        // We only render most recent 5 calls in the account research page,
        // and give a redirect link for all calls for the account.
        /* per_page= */ 5),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
    });
    return {
        queryCallsResponse,
        callsLoading,
        callsError,
    };
};
