var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMemo } from "react";
import { authorizeForCalendarRead, getCalendarIntegrationData, removeCalendarAuth, } from "../common/endpoints";
import { integrationListingContent } from "./constants";
import { useMutation, useQuery, useQueryClient } from "react-query";
/**
 * Fetches & returns the data needed to load caledar integration data on first user login and integrations page
 */
const useCalendarIntegrations = (onConnect) => {
    const queryClient = useQueryClient();
    const { data: calendarIntegrationData, isLoading: isCalendarDataLoading, isError: calendarDataError, } = useQuery("calendarIntegrationData", getCalendarIntegrationData);
    const disconnectCalendarMutation = useMutation({
        mutationFn: removeCalendarAuth,
        onSuccess: () => {
            // Set calendar disconnected
            queryClient.setQueryData("calendarIntegrationData", {
                status: "Not connected",
            });
        },
    });
    const fetchCalendarAfterConnectionChange = () => __awaiter(void 0, void 0, void 0, function* () {
        queryClient.invalidateQueries("calendarIntegrationData");
        if (onConnect) {
            onConnect();
        }
    });
    const connectCalendarIntegration = () => __awaiter(void 0, void 0, void 0, function* () {
        authorizeForCalendarRead(fetchCalendarAfterConnectionChange);
    });
    const formattedIntegrations = useMemo(() => {
        return [
            {
                name: "Google Calendar",
                icon: "https://storage.googleapis.com/wiser-ai-public2/google-calendar-official.png",
                status: (calendarIntegrationData === null || calendarIntegrationData === void 0 ? void 0 : calendarIntegrationData.status) || "Not connected",
                handleConnect: connectCalendarIntegration,
                handleDisconnect: disconnectCalendarMutation.mutateAsync,
                listingContent: integrationListingContent["Google Calendar"],
            },
        ];
    }, [calendarIntegrationData]);
    if (isCalendarDataLoading || calendarDataError) {
        return {
            integrations: [],
            loaded: !isCalendarDataLoading,
        };
    }
    return {
        integrations: formattedIntegrations,
        loaded: !isCalendarDataLoading,
    };
};
export default useCalendarIntegrations;
