import { useQuery } from "react-query";
import { getCrmFields } from "../common/endpoints";
export const useCrmFields = () => {
    const { data: crmFields, isLoading: crmFieldsLoading, isError: crmFieldsError, } = useQuery({
        queryKey: ["crmFieldsQueryKey"],
        queryFn: getCrmFields,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        // to not fetch everytime this component re-renders
        staleTime: Infinity,
    });
    return {
        crmFields,
        crmFieldsLoading,
        crmFieldsError,
    };
};
