import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MinusIcon, PlusIcon, UsersIcon } from "@heroicons/react/24/outline";
import { AccountBlockCard } from "./styled_components";
import { useAccountContacts } from "./use_account_contacts";
import { useState } from "react";
import { Button } from "../../components/button";
import { Separator } from "../../components/separator";
import { ContactInfo } from "../common/contact_info";
import { Link } from "react-router-dom";
const WorkExperiences = ({ contact, expanded, setExpanded, }) => {
    const DEFAULT_NUMBER_OF_EXPERIENCES_SHOWN = 2;
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-4 grow" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-medium text-wds-gray-5" }, { children: "Work history" })), !contact.experiences || contact.experiences.length === 0 ? (_jsx("span", Object.assign({ className: "text-base text-wds-gray-5 font-medium" }, { children: "No work history found for this contact" }))) : (_jsxs(_Fragment, { children: [contact.experiences
                        .slice(0, expanded ? undefined : DEFAULT_NUMBER_OF_EXPERIENCES_SHOWN)
                        .map((experience, index) => {
                        var _a;
                        return (_jsxs("div", Object.assign({ className: "flex flex-col justify-center items-start gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-base font-bold text-black" }, { children: experience.company })), _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 pl-4" }, { children: [_jsx("span", Object.assign({ className: "text-black" }, { children: experience.title })), _jsx("span", Object.assign({ className: "text-xs text-wds-gray-5" }, { children: `${experience.start_date} - ${(_a = experience.end_date) !== null && _a !== void 0 ? _a : "present"}` }))] }))] }), index));
                    }), contact.experiences.length > DEFAULT_NUMBER_OF_EXPERIENCES_SHOWN && (_jsx(Button, Object.assign({ variant: "ghost", onClick: () => setExpanded(!expanded), className: "flex gap-1 hover:bg-transparent p-0" }, { children: expanded ? (_jsxs(_Fragment, { children: [_jsx(MinusIcon, { className: "w-4 h-4 text-wds-blue-3" }), _jsx("span", { children: "Hide" })] })) : (_jsxs(_Fragment, { children: [_jsx(PlusIcon, { className: "w-4 h-4 text-wds-blue-3" }), _jsx("span", { children: `${contact.experiences.length - DEFAULT_NUMBER_OF_EXPERIENCES_SHOWN} more` })] })) })))] }))] })));
};
const ContactCard = ({ contact, expanded, setExpanded, }) => {
    return (_jsx("div", Object.assign({ className: "flex p-4 justify-between self-stretch items-start rounded-lg border border-wds-gray-3 bg-white" }, { children: _jsxs("div", Object.assign({ className: "flex items-start grow gap-8 self-stretch" }, { children: [_jsx(ContactInfo, { contact: contact, showProfilePic: true, showTitle: true, showLocation: true, showEmail: true, showLinkedin: true, showContactsTableLink: true }), _jsx(Separator, { orientation: "vertical" }), _jsx(WorkExperiences, { contact: contact, expanded: expanded, setExpanded: setExpanded })] })) })));
};
export const AccountContactsBlock = ({ account, }) => {
    // Maximum number of contacts to render in the card.
    // If there are more contacts, we will show a button to redirect
    // the user to the contacts table.
    const MAX_CONTACTS_TO_RENDER = 5;
    const { accountContacts, contactsLoading, contactsError } = useAccountContacts({
        accountId: account.id,
    });
    const [expandedContactIds, setExpandedContactIds] = useState(new Set());
    const updateContactExpansionState = (expanded, contactId) => {
        if (expanded) {
            setExpandedContactIds((prev) => {
                const updatedSet = new Set(prev);
                updatedSet.add(contactId);
                return updatedSet;
            });
            return;
        }
        setExpandedContactIds((prev) => {
            const updatedSet = new Set(prev);
            updatedSet.delete(contactId);
            return updatedSet;
        });
    };
    if (contactsError) {
        return null;
    }
    return (_jsxs(AccountBlockCard, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx(UsersIcon, { className: "w-6 h-6 shrink-0" }), _jsx("span", Object.assign({ className: "text-lg font-bold" }, { children: "Contacts" })), accountContacts && (_jsx("span", Object.assign({ className: "text-wds-gray-5" }, { children: `(${accountContacts.length})` })))] })), contactsLoading && (_jsx("span", Object.assign({ className: "text-wds-gray-4 animate-pulse pb-6" }, { children: "Fetching contacts" }))), (!accountContacts || accountContacts.length === 0) && (_jsx("span", Object.assign({ className: "text-sm text-wds-gray-5 font-medium" }, { children: "No contacts found for this account" }))), accountContacts === null || accountContacts === void 0 ? void 0 : accountContacts.slice(/* start= */ 0, /* end= */ MAX_CONTACTS_TO_RENDER).map((contact) => {
                return (_jsx(ContactCard, { contact: contact, expanded: expandedContactIds.has(contact.id), setExpanded: (expanded) => updateContactExpansionState(expanded, contact.id) }, contact.id));
            }), accountContacts && accountContacts.length > MAX_CONTACTS_TO_RENDER && (_jsxs("div", Object.assign({ className: "w-full" }, { children: [_jsx(Separator, { orientation: "horizontal" }), _jsx("div", Object.assign({ className: "flex items-center justify-center h-12" }, { children: _jsx(Link, Object.assign({ to: `/crm/home/contacts?page=1&per_page=20&filter={"where_condition":{"operator":"AND","subconditions":[{"where_condition":{"operator":"IN","field":"account_id","value":[${account.id}]}}]}}`, target: "_blank" }, { children: _jsx(Button, Object.assign({ variant: "link" }, { children: "View all contacts" })) })) }))] })))] }));
};
