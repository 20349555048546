import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import ImportExportCards from "./cards";
import ImportExportPlaybooks from "./playbooks";
import { markLatestActivityTime } from "../activity_utils";
const ImportExport = () => {
    const { entity } = useParams();
    // For jwt token refresh
    markLatestActivityTime();
    if (entity === "live_cards") {
        return _jsx(ImportExportCards, {});
    }
    if (entity === "trackers") {
        return _jsx(ImportExportPlaybooks, {});
    }
    return null;
};
export default ImportExport;
