// Logical operators supported for filter groups.
export var FilterLogicalOperator;
(function (FilterLogicalOperator) {
    FilterLogicalOperator["AND"] = "AND";
    FilterLogicalOperator["OR"] = "OR";
})(FilterLogicalOperator || (FilterLogicalOperator = {}));
export var RelativeDate;
(function (RelativeDate) {
    RelativeDate["TODAY"] = "Today";
    RelativeDate["YESTERDAY"] = "Yesterday";
    RelativeDate["TOMORROW"] = "Tomorrow";
    RelativeDate["NEXT_WEEK"] = "Next week";
    RelativeDate["LAST_WEEK"] = "Last week";
    RelativeDate["LAST_2_WEEKS"] = "Last 2 weeks";
    RelativeDate["LAST_MONTH"] = "Last month";
    RelativeDate["LAST_YEAR"] = "Last year";
})(RelativeDate || (RelativeDate = {}));
// Conditional operators support for the filters. These will be applicable
// to different fields based on the field type.
// The config to determine which conditional operators are applicable to what field
// types should be passed to the query builder.
export var FilterOperator;
(function (FilterOperator) {
    FilterOperator["EQUALS"] = "EQUALS";
    FilterOperator["NOT_EQUAL"] = "IS NOT EQUAL TO";
    FilterOperator["GREATER_THAN_OR_EQUAL"] = ">=";
    FilterOperator["LESS_THAN_OR_EQUAL"] = "<=";
    FilterOperator["CONTAINS"] = "CONTAINS";
    FilterOperator["DOES_NOT_CONTAIN"] = "DOES NOT CONTAIN";
    FilterOperator["IS_EMPTY"] = "IS EMPTY";
    FilterOperator["IS_NOT_EMPTY"] = "IS NOT EMPTY";
    FilterOperator["STARTS_WITH"] = "STARTS WITH";
    FilterOperator["ENDS_WITH"] = "ENDS WITH";
    FilterOperator["IN"] = "IN";
    FilterOperator["SUBSTRING_IN"] = "CONTAINS ANY OF";
})(FilterOperator || (FilterOperator = {}));
/**
 * This interface represents the Wiser query engine JSON query structure.
 * While some parts here might feel repetitive, but that's because of the common
 * structure between Wiser Frontend Query Builder interfaces, and the BE query engine
 * query structure.
 * However, the benefit of keeping them separate is we can easily switch to other
 * BE structures without the need of re-writing the FE query builder.
 */
export var WiserBackendQueryOperator;
(function (WiserBackendQueryOperator) {
    WiserBackendQueryOperator["IN"] = "IN";
    WiserBackendQueryOperator["EQUALS"] = "EQUALS";
    WiserBackendQueryOperator["GREATER_THAN_OR_EQUAL"] = "GTE";
    WiserBackendQueryOperator["LESS_THAN_OR_EQUAL"] = "LTE";
    WiserBackendQueryOperator["LIKE"] = "LIKE";
    WiserBackendQueryOperator["NOT_LIKE"] = "NOT_LIKE";
    WiserBackendQueryOperator["NOT_EQUALS"] = "NOT_EQUALS";
    WiserBackendQueryOperator["IS_NULL"] = "IS_NULL";
    WiserBackendQueryOperator["IS_NOT_NULL"] = "IS_NOT_NULL";
    WiserBackendQueryOperator["STARTS_WITH"] = "STARTS_WITH";
    WiserBackendQueryOperator["ENDS_WITH"] = "ENDS_WITH";
    WiserBackendQueryOperator["SUBSTRING_IN"] = "SUBSTRING_IN";
    WiserBackendQueryOperator["AND"] = "AND";
    WiserBackendQueryOperator["OR"] = "OR";
})(WiserBackendQueryOperator || (WiserBackendQueryOperator = {}));
