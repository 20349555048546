var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useMemo } from "react";
import { WiserBackendQueryConverter } from "../common/query_builder/json_query_converter";
import { convertBackendFilterToSearchParams, convertSearchParamsToBackendFilter, } from "../common/query_builder/search_params_utils";
import { updateSearchParams } from "../common/table_utils";
import { accountsFetcher, contactsFetcher, usersFetcher, } from "../calls_table/calls_table_fetchers";
export function useFilterConfig(FIELDS) {
    const referenceObjectFetcherMap = useMemo(() => {
        return new Map([
            ["account_id", accountsFetcher],
            ["contact_id", contactsFetcher],
            [
                "owner_id",
                (userQuery, ids, cursor, queryClient) => __awaiter(this, void 0, void 0, function* () {
                    const { results, next_cursor } = yield usersFetcher(userQuery, ids, cursor, queryClient);
                    // We fetch by ids to restore the filter values in the UI after the user
                    // saves the filter. Essentially if the user applied the filter:
                    // User in [a, b, c] and saves, the only values in the query that get
                    // saved are the ids of a, b and c. And hence to render the name (labels)
                    // of those ids in the UI when the user revisits the page, we first fetch
                    // the Users by ids using this API.
                    // For self, we only need to add it in the result if the user saved the filter
                    // with -99 (special id for self).
                    if (!ids || ids.includes(-99)) {
                        results.unshift({
                            value: "-99",
                            label: "Self",
                        });
                    }
                    return {
                        results: results.filter((result) => result.label.toLowerCase().includes(userQuery.toLowerCase())),
                        next_cursor,
                    };
                }),
            ],
        ]);
    }, []);
    const filterConfig = useMemo(() => {
        return {
            fields: FIELDS,
            maxSubConditions: 5,
            referenceObjectFetcherMap,
        };
    }, [FIELDS]);
    const wiserBackendQueryConverter = useMemo(() => {
        return new WiserBackendQueryConverter(filterConfig);
    }, [filterConfig]);
    return { filterConfig, wiserBackendQueryConverter };
}
export function useInitialFilter(searchParams, wiserBackendQueryConverter) {
    return useMemo(() => {
        const beFilter = convertSearchParamsToBackendFilter(searchParams);
        if (!beFilter) {
            return undefined;
        }
        return wiserBackendQueryConverter.convertBackendFilterToQueryFilter(beFilter);
    }, [searchParams, wiserBackendQueryConverter]);
}
export function useFilterChange(navigate, searchParams) {
    return useCallback((updatedFilter) => {
        const filterParam = updatedFilter
            ? convertBackendFilterToSearchParams(updatedFilter)
            : null;
        navigate(`?${updateSearchParams([
            {
                key: "filter",
                value: filterParam,
            },
        ], searchParams)}`);
    }, [navigate, searchParams]);
}
export function useBuildFieldsForFilter(crmFields, fields) {
    return useMemo(() => {
        const buildFields = (crmField, objectType) => {
            const field = {
                id: `${objectType.toLowerCase()}_fields__z${crmField.id}`,
                name: crmField.name.startsWith(objectType)
                    ? crmField.name
                    : `${objectType}: ${crmField.name}`,
            };
            switch (crmField.content_type_definition.type) {
                case "text":
                    field.type = "string";
                    break;
                case "number":
                case "boolean":
                    field.type = crmField.content_type_definition.type;
                    break;
                case "date":
                    field.type = "datestring";
                    break;
                case "single_select":
                case "multi_select":
                    field.type = "select";
                    field.options = crmField.content_type_definition.restrictions.picklist_values;
                    break;
                default:
                    return null;
            }
            return field;
        };
        const accountFields = [];
        const opportunityFields = [];
        crmFields.forEach((crmField) => {
            if (crmField.applicable_objects.includes("Account")) {
                const field = buildFields(crmField, "Account");
                if (field)
                    accountFields.push(field);
            }
            if (crmField.applicable_objects.includes("Opportunity")) {
                const field = buildFields(crmField, "Opportunity");
                if (field)
                    opportunityFields.push(field);
            }
        });
        return [...fields, ...accountFields, ...opportunityFields];
    }, [crmFields, fields]);
}
