// This function is used to authenticate a user with Paragon using a token.
// It first checks if the Paragon SDK is available and if a token is present.
// If both are available, it calls the 'authenticate' method on the Paragon SDK with the project ID and the token.
// Once the user is authenticated, it sets the 'user' state with the authenticated user.
// If the authentication fails, it logs the error to the console.
// The function returns the authenticated user.
// ref: https://docs.useparagon.com/tutorials/building-an-in-app-integrations-catalog
import { SDK_EVENT } from "@useparagon/connect";
import { useEffect, useState } from "react";
import { getParagonUserToken, paragonIntegrationsUpdateCallback, } from "./common/endpoints";
import useParagonGlobal from "./use_paragon_global";
const PARAGON_PROJECT_ID = window.location.href.startsWith("https://staging.getwiser.io")
    ? process.env.PARAGON_STAGING_PROJECT_ID
    : process.env.PARAGON_PROJECT_ID;
export default function useParagonAuth() {
    const paragon = useParagonGlobal();
    const [loaded, setLoaded] = useState(false);
    const [token, setToken] = useState(null);
    const [user, setUser] = useState();
    useEffect(() => {
        getParagonUserToken().then(setToken);
    }, []);
    useEffect(() => {
        const listener = () => {
            if (paragon) {
                const user = paragon.getUser();
                paragonIntegrationsUpdateCallback(user);
                setUser(Object.assign({}, user));
            }
        };
        paragon === null || paragon === void 0 ? void 0 : paragon.subscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, listener);
        paragon === null || paragon === void 0 ? void 0 : paragon.subscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, listener);
        return () => {
            paragon === null || paragon === void 0 ? void 0 : paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, listener);
            paragon === null || paragon === void 0 ? void 0 : paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, listener);
        };
    }, [paragon]);
    useEffect(() => {
        if (paragon && token) {
            paragon
                .authenticate(PARAGON_PROJECT_ID, token)
                .then(() => setUser(paragon.getUser()))
                .catch(console.error)
                .finally(() => setLoaded(true));
        }
    }, [paragon, token]);
    return { user, paragon, paragonUserLoaded: loaded };
}
