import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from "../components/skeleton";
export const ListItemLoaderFrame = () => {
    return (_jsxs("div", Object.assign({ className: "flex justify-center items-center space-x-4 m-2" }, { children: [_jsx(Skeleton, { className: "h-12 w-12 rounded-full" }), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx(Skeleton, { className: "h-4 w-[250px]" }), _jsx(Skeleton, { className: "h-4 w-[200px]" })] }))] })));
};
export const CallItemLoaderFrame = () => {
    return (_jsx("div", Object.assign({ className: "flex justify-center items-center flex-col space-y-3 m-4" }, { children: _jsxs("div", Object.assign({ className: "space-y-2 w-5/6" }, { children: [_jsx(Skeleton, { className: "h-4 w-full" }), _jsx(Skeleton, { className: "h-4 w-2/3" })] })) })));
};
export const NoteLoaderFrame = () => {
    return (_jsxs("div", Object.assign({ className: "flex flex-col space-y-4 p-4" }, { children: [_jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx(Skeleton, { className: "h-8 w-full" }), " ", _jsx(Skeleton, { className: "h-6 w-1/2" }), " "] })), _jsx(Skeleton, { className: "h-4 w-full" }), " ", _jsx(Skeleton, { className: "h-4 w-3/4" }), _jsx(Skeleton, { className: "h-4 w-1/2" }), _jsx(Skeleton, { className: "h-20 w-full rounded-lg" }), " "] })));
};
export const LoadingSelectItems = () => {
    return (_jsx("div", Object.assign({ className: "flex items-center justify-center w-full" }, { children: _jsxs("div", Object.assign({ className: "px-4 py-2 space-y-2 w-full" }, { children: [_jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" })] })) })));
};
export const SettingsLoader = () => {
    return (_jsx("div", Object.assign({ className: "flex items-center justify-center w-full" }, { children: _jsxs("div", Object.assign({ className: "w-3/5 grid grid-cols-2 gap-4 px-4 py-2" }, { children: [_jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" }), _jsx(Skeleton, { className: "h-6 w-full" })] })) })));
};
