import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes } from "react-router-dom";
import WelcomeAuth from "./welcome_auth";
import WiserFrame from "./wiser_frame";
import ExpiringPublicLinkPage from "./expiring_public_link_page";
const WiserLandingPage = ({ onSignIn, onUserLoggedOut, loggedIn, }) => {
    if (loggedIn) {
        return _jsx(WiserFrame, { onUserLoggedOut: onUserLoggedOut });
    }
    // Public routes
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/shared/:linkId", element: _jsx(ExpiringPublicLinkPage, {}) }), _jsx(Route, { path: "*", element: _jsx(WelcomeAuth, { onSignIn: onSignIn }) })] }));
};
export default WiserLandingPage;
