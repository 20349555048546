import tw from "tailwind-styled-components";
export const SettingsContainer = tw.div `
  mb-2
  mx-auto
  inline-flex
  flex-col
  items-start
  p-6
  gap-6
  rounded-lg
  border
  border-gray-300
  bg-white
  w-[480px]
  lg:w-[720px]
`;
export const SettingsTitle = tw.span `
  font-black
  text-lg
`;
export const SettingsDivider = tw.div `
  w-full
  border-b
  border-gray-300
`;
export const SettingsContent = tw.div `
  flex
  flex-col
  items-start
  gap-4
`;
export const SettingsDescription = tw.span `
  font-bold
  mb-1
`;
export const CenterScreen = tw.div `
  flex
  justify-center
  items-center
  h-screen
`;
