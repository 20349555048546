import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import CallAssistanceItem from "./call_assistance_item";
import { getCoachingPrompts } from "../common/endpoints";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Tab } from "@headlessui/react";
import classNames from "../class_names";
import "./carousel.css";
import CardsCarousel from "./cards_carousel";
const CallAssistanceCarousel = ({ addCoachingPromptsToChatAndRespond, showMoreSuggestions, showingMoreSuggestions, allCards, addCardToChat, suggestionSpace, }) => {
    const [coachingPrompts, setCoachingPrompts] = useState([]);
    useEffect(() => {
        getCoachingPrompts().then((prompts) => setCoachingPrompts(prompts));
    }, []);
    const visibleCoachingPrompts = showingMoreSuggestions
        ? coachingPrompts
        : coachingPrompts.slice(0, suggestionSpace);
    const tabs = [
        { name: "All" },
        { name: "Detected info" },
        { name: "Call advice" },
    ];
    if (showingMoreSuggestions) {
        return (_jsxs(Tab.Group, Object.assign({ as: "div", className: "slide-in-animation", style: { animationDuration: "0.25s" } }, { children: [_jsx("div", Object.assign({ className: "w-full pl-4 pr-8 h-[38px] justify-between items-center inline-flex my-4" }, { children: _jsx(Tab.List, Object.assign({ className: "bg-white justify-start items-center flex" }, { children: tabs.map((tab) => (_jsx(Tab, Object.assign({ className: ({ selected }) => classNames("outline-none px-4 py-2 justify-start items-center flex rounded-tl-lg rounded-tr-lg", selected
                                ? "border-b-2 border-wds-blue-3 font-bold"
                                : "hover:bg-blue-100 font-normal border-b border-wds-gray-3") }, { children: _jsx("div", Object.assign({ className: classNames("leading-snug") }, { children: tab.name })) }), tab.name))) })) })), _jsxs(Tab.Panels, Object.assign({ className: "flex-col flex-shrink gap-2 overflow-y-auto w-full h-full" }, { children: [_jsx(Tab.Panel, { children: visibleCoachingPrompts.map((prompt, index) => (_jsx("div", Object.assign({ className: "p-2" }, { children: _jsx(CallAssistanceItem, { onClick: () => addCoachingPromptsToChatAndRespond(prompt), name: prompt.overrideTitle }) }), index))) }), _jsx(Tab.Panel, { children: _jsx(CardsCarousel, { allCards: allCards, addCardToChat: (card) => {
                                    addCardToChat(card);
                                    showMoreSuggestions(false);
                                } }) }), _jsx(Tab.Panel, { children: visibleCoachingPrompts.map((prompt, index) => (_jsx("div", Object.assign({ className: "p-2" }, { children: _jsx(CallAssistanceItem, { onClick: () => addCoachingPromptsToChatAndRespond(prompt), name: prompt.overrideTitle }) }), index))) })] }))] })));
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col shrink gap-2 w-full md:h-full ease-in-animation" }, { children: [visibleCoachingPrompts.map((prompt, index) => (_jsx("div", Object.assign({ className: "p-2 pl-12 pr-2" }, { children: _jsx(CallAssistanceItem, { onClick: () => addCoachingPromptsToChatAndRespond(prompt), name: prompt.overrideTitle }) }), index))), _jsxs("button", Object.assign({ className: "flex w-full font-bold justify-center items-center text-wds-blue-3 p-3", onClick: () => showMoreSuggestions(true) }, { children: [_jsx("span", { children: "Show more suggestions" }), _jsx(ChevronRightIcon, { className: "ml-2 w-4 h-4 stroke-2" })] }))] })));
};
export default CallAssistanceCarousel;
