export const DEFAULT_TEXT_FIELD = {
    type: "text",
    restrictions: {
        length: 255,
    },
};
export const DEFAULT_NUMBER_FIELD = {
    type: "number",
    restrictions: {
        precision: 10,
        scale: 4,
    },
};
export const DEFAULT_SINGLE_SELECT_FIELD = {
    type: "single_select",
    restrictions: {
        picklist_values: [],
    },
};
export const DEFAULT_MULTI_SELECT_FIELD = {
    type: "multi_select",
    restrictions: {
        picklist_values: [],
    },
};
export const FIELD_TYPE_LABEL = {
    text: "Text",
    number: "Number",
    boolean: "Boolean",
    single_select: "Single Select",
    multi_select: "Multi Select",
    date: "Date",
};
// This is the list of options that will be shown in the dropdown for selecting
// the CRM field type.
export const CRM_FIELD_TYPE_OPTIONS = [
    {
        value: "text",
        label: FIELD_TYPE_LABEL.text,
    },
    {
        value: "number",
        label: FIELD_TYPE_LABEL.number,
    },
    {
        value: "boolean",
        label: FIELD_TYPE_LABEL.boolean,
    },
    {
        value: "single_select",
        label: FIELD_TYPE_LABEL.single_select,
    },
    {
        value: "multi_select",
        label: FIELD_TYPE_LABEL.multi_select,
    },
    {
        value: "date",
        label: FIELD_TYPE_LABEL.date,
    },
];
