export const computeSalespersonTalkRatioAndColor = (talkTimeMetrics) => {
    if (!talkTimeMetrics || !talkTimeMetrics.totalTalkTime) {
        return { ratio: 0, color: "yellow-600" };
    }
    const salespersonTalkRatio = Math.round((talkTimeMetrics.salespersonTalkTime / talkTimeMetrics.totalTalkTime) * 100);
    let color = "green-600";
    if (salespersonTalkRatio <= 10) {
        color = "yellow-600";
    }
    else if (salespersonTalkRatio > 60 && salespersonTalkRatio <= 85) {
        color = "yellow-600";
    }
    else if (salespersonTalkRatio > 85) {
        color = "red-600";
    }
    return { ratio: salespersonTalkRatio, color: color };
};
export const computeSalespersonSpeechRateAndColor = (speechRateMetrics) => {
    if (!speechRateMetrics ||
        !speechRateMetrics.salespersonWordCount ||
        !speechRateMetrics.salespersonSpeechDurationMsec ||
        speechRateMetrics.number_of_transcriptions < 5 ||
        speechRateMetrics.time_since_start < 15000) {
        // 0 is a special case, where we don't want to render any warning or alert color
        return { rate: 0, color: "gray-300", percentRate: 0, rateLabel: "-" };
    }
    const wordsPerMinute = Math.round((speechRateMetrics.salespersonWordCount /
        speechRateMetrics.salespersonSpeechDurationMsec) *
        60000);
    let color = "green-600";
    let rateLabel = "Normal";
    if (wordsPerMinute <= 50) {
        // Too slow
        color = "red-600";
        rateLabel = "Low";
    }
    else if (wordsPerMinute > 50 && wordsPerMinute <= 100) {
        // Slow, but not too bad
        color = "yellow-600";
        rateLabel = "Medium";
    }
    else if (wordsPerMinute > 200) {
        // Too fast
        color = "red-600";
        rateLabel = "High";
    }
    return {
        rate: wordsPerMinute,
        color: color,
        // Assuming the range is 0-300 wpm, so we can calculate the percentage
        percentRate: (wordsPerMinute / 300) * 100,
        rateLabel,
    };
};
