import { useState } from "react";
export const useSortRule = (config, initialSortRules) => {
    const [sortRules, setSortRules] = useState(initialSortRules !== null && initialSortRules !== void 0 ? initialSortRules : []);
    const addSortRule = (sortRule) => {
        if (sortRules.length >= config.maxConditions) {
            throw new Error("Maximum number of sort rules reached!");
        }
        setSortRules((prev) => [...prev, sortRule]);
    };
    const removeSortRule = (sortRuleId) => {
        setSortRules((prev) => [
            ...prev.filter((sortRule) => sortRule.id !== sortRuleId),
        ]);
    };
    const updateSortRule = (sortRuleId, updatedSortRule) => {
        setSortRules((prev) => {
            return [
                ...prev.map((sortRule) => {
                    if (sortRule.id !== sortRuleId) {
                        return sortRule;
                    }
                    return updatedSortRule;
                }),
            ];
        });
    };
    const removeAllSortRules = () => {
        setSortRules([]);
    };
    const getSortRules = () => {
        return [...sortRules];
    };
    return {
        addSortRule,
        removeSortRule,
        removeAllSortRules,
        updateSortRule,
        getSortRules,
    };
};
