import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import classNames from "../class_names";
import { RemovableBadge } from "./removable_badge";
/**
 * A simple multi-select combo-box style string list editor but without options.
 * Allows users to add arbitrary multiple string inputs.
 *
 * Usage:
 *  <StringListEditor
 *      onChange={<your callback for handling updated selected string>}
 *      selectedStrings={<controlled list of entered strings from the parent>}
 *  />
 *
 *
 * @param id: Optional. If provided, this helps in managing type changes for the same
 *              component. Example: options changing from Account to Opportunity
 * @param onChange: Callback to invoke when selected strings change
 * @param selectedStrings: Selected strings
 * @param placeholder: Optional. Placeholder text to render in the input search
 * @param wrapPills: Optional. Boolean to indicate if the pills should be wrapped. Note that in this case
 *                      the input box will grow to wrap the selected options.
 * @param className: Optional. Additional classes for custom styling.
 */
export const StringListEditor = (props) => {
    var _a;
    const [currentInput, setCurrentInput] = useState("");
    const handleOptionsChange = (options) => {
        props.onChange(options);
        setCurrentInput("");
    };
    const handleDeselect = (deselectedString) => {
        const updatedSelections = props.selectedStrings.filter((option) => option !== deselectedString);
        props.onChange(updatedSelections);
        setCurrentInput("");
    };
    const handleInputKeyDown = (event) => {
        if (event.key == "Enter" && currentInput && currentInput.length) {
            handleOptionsChange([...props.selectedStrings, currentInput]);
        }
        if (event.key === "Backspace" &&
            currentInput === "" &&
            props.selectedStrings.length) {
            handleDeselect(props.selectedStrings[props.selectedStrings.length - 1]);
        }
    };
    return (_jsx("div", Object.assign({ className: classNames("flex w-80 h-8 py-0 px-2 gap-1 justify-between self-stretch", "bg-white border border-wds-gray-3 rounded-lg", props.wrapPills ? "h-auto" : "rounded-none rounded-r-lg") }, { children: _jsxs("div", Object.assign({ className: classNames("flex p-1 gap-2", props.wrapPills ? "flex-wrap" : "flex-nowrap overflow-x-auto") }, { children: [props.selectedStrings.map((selectedString) => (_jsx(RemovableBadge, { content: selectedString, onRemove: () => handleDeselect(selectedString) }, selectedString))), _jsx("input", { className: "p-1 text-sm text-gray-900 border-none bg-transparent focus:ring-0 focus:outline-none placeholder:text-wds-gray-4 rounded", placeholder: (_a = props.placeholder) !== null && _a !== void 0 ? _a : "", onChange: (e) => {
                        setCurrentInput(e.target.value);
                    }, onKeyDown: handleInputKeyDown, value: currentInput, onClick: (e) => {
                        e.stopPropagation();
                    } })] })) })));
};
