export const LOCAL_SOCKET_STATUS = {
    UNKNOWN: -1,
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
};
// Video with detailed explanation - https://drive.google.com/file/d/1v2x6jNS4o4dQjqXk_5igkYX7npkRUfbz/view?usp=drive_link
export class WebSocketManagerSingleton {
    constructor() {
        this.socketStatus = LOCAL_SOCKET_STATUS.UNKNOWN;
    }
    static getInstance() {
        if (!WebSocketManagerSingleton.instance) {
            WebSocketManagerSingleton.instance = new WebSocketManagerSingleton();
        }
        return WebSocketManagerSingleton.instance;
    }
}
export default WebSocketManagerSingleton;
