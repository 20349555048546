const getAccountLabel = (connectedCrm) => {
    if (connectedCrm === "HubSpot") {
        return "company";
    }
    return "account";
};
const getOpportunityLabel = (connectedCrm) => {
    if (connectedCrm === "HubSpot") {
        return "deal";
    }
    return "opportunity";
};
export { getAccountLabel, getOpportunityLabel };
