var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Page to visualize/debug stream reduce.
import classNames from "./class_names";
import { useEffect, useRef, useState } from "react";
import { getStreamReduceLogs } from "./common/endpoints";
import IntentDebugger from "./intent_debugger";
export function StreamReduceVisualizer() {
    var _a;
    // Full logs for the time window.
    const [stageLogs, setStageLogs] = useState(new Map());
    // Useful for ordering stages, so that earlier stages like transcription come
    // to the left of stages like cards and notes.
    const [stageNamesTopoSorted, setStageNamesTopoSorted] = useState([]);
    const [intentsAndScores, setIntentsAndScores] = useState([]);
    const [selectedLog, setSelectedLog] = useState(null);
    const [pauseButtonText, setPauseButtonText] = useState("Pause");
    // I'm using refs here, so we can get  state into the update_loop.
    const lookBack = useRef(60 * 1000);
    const isPaused = useRef(false);
    const scrollRef = useRef(null);
    useEffect(() => {
        let isSubscribed = true;
        const update_loop = () => __awaiter(this, void 0, void 0, function* () {
            if (isPaused.current)
                return;
            const response = yield getStreamReduceLogs(lookBack.current);
            if (!isSubscribed)
                return;
            const stageLogs = new Map();
            setStageNamesTopoSorted(response.stageNamesTopoSorted);
            for (const log of response.logs) {
                if (!stageLogs.has(log.stage)) {
                    stageLogs.set(log.stage, []);
                }
                stageLogs.get(log.stage).push(log);
            }
            // Sorts each stage by wall end msec
            for (const [stage, log] of stageLogs.entries()) {
                log.sort((a, b) => {
                    return b.wallEndMsec - a.wallEndMsec;
                });
            }
            if (response.intentsAndScores) {
                setIntentsAndScores(response.intentsAndScores);
            }
            setStageLogs(stageLogs);
            if (scrollRef.current) {
                scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            }
        });
        const intervalId = setInterval(update_loop, 1000);
        return () => {
            isSubscribed = false; // Prevents setting state after unmounting
            clearInterval(intervalId); // clears the loop on component unmount
        };
    }, []);
    const handleScroll = () => {
        const node = scrollRef.current;
        if (!node)
            return;
        // Check if scrolled to bottom. We have some margin for error here,
        // since the auto-scroll can fall slightly behind. The different margins based
        // on whether we're paused gives a "latching" behavior to stick with the current
        // mode (pause or play).
        const scrollMarginForError = isPaused.current ? 0 : 400;
        if (node.scrollHeight - node.scrollTop - node.clientHeight <
            scrollMarginForError) {
            // Restart the feed.
            isPaused.current = false;
        }
        else {
            // Scrolled above bottom -- pause the feed.
            isPaused.current = true;
        }
        setPauseButtonText(isPaused.current ? "Resume" : "Pause");
    };
    // Listener so we can pause on scroll up.
    useEffect(() => {
        const node = scrollRef.current;
        if (node) {
            node.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (node) {
                node.removeEventListener("scroll", handleScroll);
            }
        };
    }, [scrollRef]);
    const handleLookBackChange = (e) => {
        const value = Number(e.target.value);
        if (!isNaN(value) && value >= 0) {
            lookBack.current = value * 1000;
        }
    };
    const handlePauseToggle = () => {
        const newPausedState = !isPaused.current;
        isPaused.current = newPausedState;
        setPauseButtonText(newPausedState ? "Resume" : "Pause");
    };
    let virtualStartTime = 0;
    let wallStartTime = 0;
    for (const [stage, log] of (_a = stageLogs === null || stageLogs === void 0 ? void 0 : stageLogs.entries()) !== null && _a !== void 0 ? _a : []) {
        for (const entry of log) {
            if (virtualStartTime == 0 || entry.startMsec < virtualStartTime) {
                virtualStartTime = entry.startMsec;
            }
            if (wallStartTime == 0 || entry.wallStartMsec < wallStartTime) {
                wallStartTime = entry.wallStartMsec;
            }
        }
    }
    // The debug text can either be a string or an object, requiring different rendering.
    const selectedLogOutIsJson = selectedLog && typeof selectedLog.output !== "string";
    const selectedLogInIsJson = selectedLog && typeof selectedLog.input !== "string";
    return (
    // h-[calc(100vh - 3.125rem)] lg:h-screen is to allow for the "expand your browser" notice on small screens.
    _jsxs("div", Object.assign({ className: "w-full flex flex-col h-[calc(100vh - 3.125rem)] lg:h-screen" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between bg-gray-800 text-white p-4" }, { children: [_jsxs("div", { children: [_jsx("label", Object.assign({ htmlFor: "lookBack", className: "mr-2" }, { children: "Look Back (s):" })), _jsx("input", { type: "number", id: "lookBack", value: lookBack.current / 1000, onChange: handleLookBackChange, className: "p-2 text-black" })] }), _jsx("button", Object.assign({ onClick: handlePauseToggle, className: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" }, { children: pauseButtonText }))] })), _jsx("div", Object.assign({ className: "flex flex-row w-3/4" }, { children: stageNamesTopoSorted.map((stage) => {
                    return (_jsx("div", Object.assign({ className: "w-64 border-white bg-blue-800 text-white text-lg font-bold flex flex-row justify-center" }, { children: _jsx("span", { children: stage }) }), stage));
                }) })), _jsxs("div", Object.assign({ className: "flex flex-row flex-grow" }, { children: [_jsx("div", Object.assign({ ref: scrollRef, className: "w-3/4 overflow-auto" }, { children: _jsx("div", Object.assign({ className: "h-full flex flex-row" }, { children: stageNamesTopoSorted.map((stage) => {
                                var _a;
                                const log = (_a = stageLogs.get(stage)) !== null && _a !== void 0 ? _a : [];
                                return (_jsx("div", Object.assign({ className: "flex w-64" }, { children: _jsx(StageViz, { stage: stage, log: log, virtualStartTime: virtualStartTime, wallStartTime: wallStartTime, selectLog: (log) => {
                                            setSelectedLog(log);
                                        } }) }), stage));
                            }) })) })), _jsxs("div", Object.assign({ className: "flex flex-col w-1/4" }, { children: [selectedLog && (_jsxs("div", Object.assign({ className: "flex flex-col p-2" }, { children: [_jsx("div", Object.assign({ className: "font-bold text-md py-2" }, { children: "Selected Log" })), _jsxs("div", { children: ["Stage: ", selectedLog.stage] }), _jsxs("div", { children: ["Start: ", selectedLog.startMsec - virtualStartTime, "ms"] }), _jsxs("div", { children: ["End: ", selectedLog.endMsec - virtualStartTime, "ms"] }), _jsxs("div", { children: ["Duration: ", selectedLog.endMsec - selectedLog.startMsec, "ms"] }), _jsxs("div", { children: ["Compute Time:", " ", selectedLog.wallEndMsec - selectedLog.wallStartMsec, "ms"] }), _jsxs("div", Object.assign({ className: "text-md p-2 border border-gray-600 rounded" }, { children: [_jsx("span", Object.assign({ className: "font-bold" }, { children: "Input" })), selectedLogInIsJson ? (_jsx("div", Object.assign({ className: "whitespace-pre-wrap" }, { children: JSON.stringify(selectedLog.input, null, 2) }))) : (_jsx("div", Object.assign({ className: "whitespace-pre-wrap" }, { children: selectedLog.input })))] })), _jsxs("div", Object.assign({ className: "text-md p-2 border border-gray-600 rounded" }, { children: [_jsx("span", Object.assign({ className: "font-bold whitespace-pre-wrap" }, { children: "Output" })), selectedLogOutIsJson ? (_jsx("div", Object.assign({ className: "whitespace-pre-wrap" }, { children: JSON.stringify(selectedLog.output, null, 2) }))) : (_jsx("div", Object.assign({ className: "whitespace-pre-wrap" }, { children: selectedLog.output })))] }))] }))), _jsxs("div", Object.assign({ className: "flex flex-col p-2" }, { children: [_jsx("div", Object.assign({ className: "font-bold text-md py-2" }, { children: "Intent Debugger" })), _jsx(IntentDebugger, { intentResults: intentsAndScores, chooseIntent: () => { } })] }))] }))] })), _jsx("button", Object.assign({ className: classNames(isPaused.current
                    ? "fixed w-64 z-40 p-2 bottom-0 inset-x-0 mx-auto rounded bg-blue-700 text-white text-center"
                    : "hidden"), onClick: handlePauseToggle }, { children: "Paused, scroll to bottom or click \"Resume\" to resume." }))] })));
}
export function StageViz(props) {
    // This draws the visualization for a single stage. Each stage is comprised
    // of a series of "chunks", which are the output of the stage.
    //
    // Chunks visualize the time spent at that output from the stage.
    //
    // Hovering a chunk shows more details and brings that chunk to the
    // foreground (may overlap).
    //
    // Clicking a chunk selects it and shows the input/output details on the
    // right hand side (in the parent component).
    const yScale = 10; // msec per pixel
    const chunks = props.log.map((entry, i) => {
        const processingHeight = (entry.wallEndMsec - entry.wallStartMsec) / yScale;
        const processingBottom = (entry.wallEndMsec - props.wallStartTime) / yScale + 100;
        const virtualHeight = (entry.endMsec - entry.startMsec) / yScale;
        const delayHeight = (entry.wallStartMsec - entry.endMsec) / yScale;
        return (_jsxs("div", Object.assign({ className: "group/chunk" }, { children: [_jsx("button", Object.assign({ className: "bg-orange-300 w-3/4 align-middle group/delay group-hover/chunk:z-20 group-hover/chunk:border-black group-hover/chunk:border", style: {
                        height: delayHeight,
                        position: "absolute",
                        left: 0,
                        top: processingBottom - processingHeight - delayHeight,
                    }, onClick: props.selectLog.bind(null, entry) }, { children: _jsxs("span", Object.assign({ className: "hidden group-hover/delay:block text-xs" }, { children: [entry.wallStartMsec - entry.endMsec, "ms Accum Delay"] })) })), _jsxs("button", Object.assign({ className: "bg-red-300 w-3/4 align-middle group/processing group-hover/chunk:z-20 group-hover/chunk:border-black group-hover/chunk:border", style: {
                        height: processingHeight,
                        position: "absolute",
                        left: 0,
                        top: processingBottom - processingHeight,
                    }, onClick: props.selectLog.bind(null, entry) }, { children: [_jsx("span", Object.assign({ className: "hidden group-hover/processing:block text-xs" }, { children: "Stage Processing Time" })), _jsxs("span", Object.assign({ className: "text-xs" }, { children: [Math.round(entry.wallEndMsec - entry.wallStartMsec), "ms"] }))] })), _jsxs("button", Object.assign({ className: "rounded border group/virtual group-hover/chunk:border-black bg-gray-300 w-1/4 z-10 group-hover/chunk:z-30 flex flex-col justify-between", style: {
                        height: virtualHeight,
                        position: "absolute",
                        left: 60,
                        top: processingBottom - virtualHeight,
                    }, onClick: props.selectLog.bind(null, entry) }, { children: [_jsx("span", Object.assign({ className: "group-hover/chunk:font-bold" }, { children: Math.round(entry.startMsec - props.virtualStartTime) / 1000 })), _jsx("span", Object.assign({ className: "hidden group-hover/virtual:block text-xs" }, { children: "Input Window" })), _jsx("span", Object.assign({ className: "group-hover/chunk:font-bold" }, { children: Math.round(entry.endMsec - props.virtualStartTime) / 1000 }))] }))] }), i));
    });
    return _jsx("div", Object.assign({ className: "flex flex-col w-32 relative" }, { children: chunks }));
}
