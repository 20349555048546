import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "react-query";
import AccountPopoverV2 from "../common/account_popover_v2";
import ContactsPopoverV2 from "../common/contacts_popover_v2";
import OpportunityPopover from "../common/opportunity_popover";
import CallTimeDisplay from "../home/call_time_display";
import { getActiveTenant } from "../common/endpoints";
import classNames from "../class_names";
export const CallDetailsChipsOptions = {
    Default: "default",
    V3: "v3",
};
export const CallDetailsChips = ({ call, updateCall, className, variant = CallDetailsChipsOptions.Default, }) => {
    const { data: tenant } = useQuery({
        queryKey: ["activeTenant", call.id],
        queryFn: getActiveTenant,
    });
    return (_jsxs("div", Object.assign({ className: "flex justify-start items-center gap-2 flex-wrap py-2" }, { children: [_jsx(CallTimeDisplay, { call: call, showDate: true, showClockIcon: true, containerClassName: classNames("flex py-[2px] items-center space-x-2 rounded-lg border", variant === CallDetailsChipsOptions.Default
                    ? "border-wds-gray-3 bg-wds-gray-1"
                    : "border-wds-blue-2 bg-wds-blue-1", className), variant: variant }), tenant && (_jsxs(_Fragment, { children: [_jsx(ContactsPopoverV2, { tenant: tenant, call: call, updateSelectedCall: updateCall, variant: variant }), _jsx(AccountPopoverV2, { tenant: tenant, call: call, updateSelectedCall: updateCall, variant: variant }), _jsx(OpportunityPopover, { tenant: tenant, call: call, updateSelectedCall: updateCall, variant: variant })] }))] })));
};
