import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "../class_names";
import { Badge } from "./badge";
export const RemovableBadge = ({ content, onRemove, }) => {
    return (_jsxs(Badge, Object.assign({ className: classNames("px-1 rounded-xl flex items-center gap-1", "hover:ring-2 hover:ring-muted-foreground"), variant: "secondary" }, { children: [_jsx("span", Object.assign({ className: "text-xs" }, { children: content })), _jsxs("button", Object.assign({ "aria-label": `Remove ${content} option`, "aria-roledescription": "button to remove option", type: "button", onMouseDown: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }, onClick: onRemove }, { children: [_jsxs("span", Object.assign({ className: "sr-only" }, { children: ["Remove ", content, " option"] })), _jsx(XMarkIcon, { className: "h-4 w-4 hover:stroke-destructive" })] }))] })));
};
