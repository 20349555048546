import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import BaseModal from "../base_modal";
import SelectionTable from "../selection_table";
import { useNavigate } from "react-router-dom";
import { callStageDisplayName } from "../edit_playbook";
const AddPlaybookItemGroupModal = (props) => {
    const { open, onClose, addPlaybookItemGroups, allPlaybookItemGroups, existingPlaybookItemGroupIds, createCallback, linkToPlaybookItemGroup, } = props;
    const navigate = useNavigate();
    const [selectedPlaybookItemGroups, setSelectedPlaybookItemGroups] = useState([]);
    const expandCallback = (playbookItemGroup) => {
        navigate("/trackers", {
            state: {
                editingPlaybookGroupId: playbookItemGroup.id,
                playbookId: playbookItemGroup.playbook_id,
            },
        });
    };
    return (_jsx(BaseModal, Object.assign({ title: "Add Tracker", description: "A tracker represents individual agenda items you want to complete during the call. You can choose to add an existing tracker, or create a new one.", open: open, setOpen: onClose, fields: [], submitCallback: () => {
            addPlaybookItemGroups(selectedPlaybookItemGroups);
        }, submitText: "Add" }, { children: _jsx(SelectionTable, { columns: [
                {
                    header: "Tracker",
                    accessorKey: "name",
                },
                {
                    header: "Stage",
                    accessorKey: "default_item.call_stage",
                    cell: ({ getValue }) => {
                        return callStageDisplayName(getValue());
                    },
                },
            ], rows: allPlaybookItemGroups, setSelectedRows: setSelectedPlaybookItemGroups, canSelectRow: (row) => {
                return !existingPlaybookItemGroupIds.includes(row.id);
            }, createCallback: createCallback, createLabel: "Create new tracker", expandCallback: linkToPlaybookItemGroup ? expandCallback : undefined }) })));
};
export default AddPlaybookItemGroupModal;
