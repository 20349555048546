// Common types used throughout the app
// Types used in RPC's should go here as well.
import { z } from "zod";
export var PostCallOutputTarget;
(function (PostCallOutputTarget) {
    PostCallOutputTarget["SALESFORCE"] = "SALESFORCE";
    PostCallOutputTarget["SLACK"] = "SLACK";
    PostCallOutputTarget["WISER_CRM"] = "WISER_CRM";
    PostCallOutputTarget["HUBSPOT"] = "HUBSPOT";
    PostCallOutputTarget["GMAIL"] = "GMAIL";
    PostCallOutputTarget["CRM_EVENT"] = "CRM_EVENT";
})(PostCallOutputTarget || (PostCallOutputTarget = {}));
export var CallFilter;
(function (CallFilter) {
    CallFilter["DATE"] = "date";
    CallFilter["ACCOUNT"] = "account";
    CallFilter["CONTACT"] = "contact";
    CallFilter["SUBQUERY"] = "subQuery";
    CallFilter["OWNER"] = "owner";
})(CallFilter || (CallFilter = {}));
export var MatchOptions;
(function (MatchOptions) {
    MatchOptions["MATCH_ANY"] = "matchAny";
    MatchOptions["MATCH_ALL"] = "matchAll";
})(MatchOptions || (MatchOptions = {}));
export const ChatSourceSchema = z.enum([
    "Account info",
    "Past call transcripts",
    "This deal",
    "This call",
    "Knowledge base",
]);
export const ChatApplicabletoSchema = z.enum([
    "Account",
    "Live Call",
    "Post Call Output",
]);
