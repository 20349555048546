import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import classNames from "../class_names";
import RadioSelector from "./radio_selector";
import WaitingSpinner from "../waiting_spinner";
import { getConnectedCrmLogo } from "./crm_logo";
import CrmFieldValueEditor from "../crm/field_value_editor";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, } from "../components/modal_dialog";
import { Button } from "../components/button";
import { Separator } from "../components/separator";
import { XCircleIcon } from "@heroicons/react/24/outline";
/**
 * This component is used to render input fields for new CRM object creation form.
 * NOTE: This component is not responsible for any input validation.
 *
 * @param fieldLabel: Label to render for the input field
 * @param fieldValue: Current value of the field
 * @param onValueChange: callback to invoke when user types in the input field
 * @returns
 */
export const EditableCrmField = ({ fieldLabel, fieldValue, onValueChange, }) => {
    const [value, setValue] = useState(fieldValue);
    const [isEditing, setIsEditing] = useState(false);
    useEffect(() => {
        setValue(fieldValue);
        setIsEditing(false);
    }, [fieldValue]);
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch w-full overflow-hidden" }, { children: [_jsx("span", Object.assign({ className: "text-xs text-wds-gray-5 font-medium" }, { children: fieldLabel })), isEditing ? (_jsx("input", { 
                // We only render string inputs for now. In future we'll add support
                // for different field types here.
                type: "text", value: value, className: classNames("flex w-full py-1 px-2 items-start", "border border-black rounded-lg bg-gray-100", "focus:outline-none focus:ring-0 focus:border-black", "font-normal"), onChange: (e) => {
                    setValue(e.target.value);
                    onValueChange(e.target.value);
                }, onBlur: () => {
                    setIsEditing(false);
                } })) : (_jsx("span", Object.assign({ className: classNames("break-all truncate", value.length === 0 ? "text-red-900" : ""), onClick: () => setIsEditing(true) }, { children: value.length > 0 ? value : `No ${fieldLabel} set` })))] })));
};
/**
 * This component is used to render input fields for new CRM object creation form.
 * NOTE: This component is not responsible for any input validation.
 *
 * @param fieldLabel: Label to render for the input field
 * @param onValueChange: callback to invoke when user types in the input field
 * @param placeholder: Optional: Placeholder text to be shown
 * @returns
 */
export const CrmInputField = ({ fieldId, fieldSchema, fieldLabel, fieldValue, onValueChange, placeholder, inputDisabled, }) => {
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-xs text-wds-gray-5 font-medium" }, { children: fieldLabel })), _jsx(CrmFieldValueEditor, { crmObjectId: -1, crmFieldId: fieldId, crmFieldSchema: fieldSchema !== null && fieldSchema !== void 0 ? fieldSchema : { type: "text", restrictions: { length: 1000 } }, value: fieldValue !== null && fieldValue !== void 0 ? fieldValue : "", onValueEdit: onValueChange, handleKeyDown: (e) => { }, inputDisabled: inputDisabled, variant: "modal", placeholder: placeholder })] })));
};
/**
 *
 * @param objectSelector: Enum: EXISTING|NEW determing whether user wants to select EXISTING
 *  CRM object or create a new CRM object.
 * @returns
 */
export const CallCrmObjectLinkForm = ({ objectSelector, setObjectSelector, existingObjectForm, newObjectForm, }) => {
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [_jsx(RadioSelector, { options: [
                    {
                        label: "Link existing",
                        value: "EXISTING",
                    },
                    {
                        label: "Create new",
                        value: "NEW",
                    },
                ], selectedOption: objectSelector, setSelectedOption: (option) => {
                    setObjectSelector(option);
                } }), objectSelector === "EXISTING" && _jsx(_Fragment, { children: existingObjectForm }), objectSelector === "NEW" && _jsx(_Fragment, { children: newObjectForm })] })));
};
const CallCrmObjectBaseModal = ({ tenant, title, description, open, setOpen, submitCallback, disableSubmit, child, isSaving, error, }) => {
    var _a;
    return (_jsx(Dialog, Object.assign({ open: open, onOpenChange: (value) => setOpen(value) }, { children: _jsxs(DialogContent, Object.assign({ className: "w-[512] space-y-2 bg-wds-gray-1" }, { children: [_jsx(DialogHeader, { children: title }), _jsx(Separator, { orientation: "horizontal" }), _jsx(DialogDescription, { children: description }), _jsxs("div", Object.assign({ className: "flex flex-col gap-4 px-6 -mt-4" }, { children: [_jsxs("div", Object.assign({ className: "flex items-start gap-2" }, { children: [_jsxs("span", Object.assign({ className: "text-black font-bold text-base" }, { children: ["Connected CRM: ", (_a = tenant.connected_crm) !== null && _a !== void 0 ? _a : "WiserCRM"] })), _jsx("div", Object.assign({ className: "flex h-6 w-6 shrink-0 items-center justify-center" }, { children: _jsx("img", { src: getConnectedCrmLogo(tenant.connected_crm), className: "w-6 h-6 shrink-0" }) }))] })), child] })), _jsx(Separator, { orientation: "horizontal" }), _jsxs(DialogFooter, Object.assign({ className: "flex sm:flex-col sm:justify-start px-6 gap-3" }, { children: [error && (_jsx("div", Object.assign({ className: "flex items-center pr-8 pl-2 py-2 bg-white rounded-lg border-l-4 border-l-red-600" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-center gap-2" }, { children: [_jsx(XCircleIcon, { className: "w-4 h-4 text-red-600" }), _jsx("div", Object.assign({ className: "flex flex-col gap-1 text-sm font-normal" }, { children: _jsx("span", { children: error }) }))] })) }))), _jsxs("div", Object.assign({ className: "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2" }, { children: [_jsx(Button, Object.assign({ type: "button", variant: "ghost", onClick: () => setOpen(false) }, { children: "Cancel" })), _jsx(Button, Object.assign({ type: "button", variant: "default", onClick: () => submitCallback([]), disabled: disableSubmit }, { children: isSaving ? _jsx(WaitingSpinner, { text: "" }) : "Save" }))] }))] }))] })) })));
};
export default CallCrmObjectBaseModal;
