import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import BaseTable from "../base_table";
import * as endpoints from "../common/endpoints";
import { showErrorModal, showMessageModal } from "../error_modal";
import BaseModal from "../base_modal";
import CrmFieldTypeSelector from "./crm_field_type_selector";
const WISER_CRM_OBJECT_TYPES = [
    "Account",
    "Opportunity",
    "Contact",
];
const CreateCrmField = ({ open, setOpen, createCallback, }) => {
    const [name, setName] = useState("");
    const [type, setType] = useState({
        type: "text",
        restrictions: { length: 500 },
    });
    const [applicableObjects, setApplicableObjects] = useState(["Account", "Opportunity", "Contact"]);
    const handleCreateClick = () => {
        if (applicableObjects.length === 0) {
            showMessageModal("Please select at least one applicable object.");
            return;
        }
        endpoints
            .createCrmField(name, type, applicableObjects)
            .then(() => {
            setOpen(false);
            setName("");
            createCallback();
        })
            .catch((err) => {
            showErrorModal(err);
        });
    };
    return (_jsxs(BaseModal, Object.assign({ title: "Create a new CRM field", description: "", fields: [{ name: "Name", value: name, type: "text", required: true }], setField: (fieldName, value) => {
            if (fieldName === "Name") {
                setName(value);
            }
        }, open: open, setOpen: setOpen, submitCallback: handleCreateClick, submitText: "Create" }, { children: [_jsx(CrmFieldTypeSelector, { crmFieldTypeDefinition: type, setCrmFieldTypeDefinition: setType }), _jsx("div", Object.assign({ className: "text-sm font-medium text-gray-900 pt-2" }, { children: "Applicable objects" })), _jsx("div", Object.assign({ className: "flex flex-col gap-2 pt-2" }, { children: WISER_CRM_OBJECT_TYPES.map((objectType) => (_jsxs("div", Object.assign({ className: "flex gap-2 items-center" }, { children: [_jsx("input", { type: "checkbox", id: objectType, name: objectType, checked: applicableObjects.includes(objectType), onChange: (e) => {
                                if (e.target.checked) {
                                    setApplicableObjects((prev) => [...prev, objectType]);
                                }
                                else {
                                    setApplicableObjects((prev) => prev.filter((object) => object !== objectType));
                                }
                            } }), _jsx("label", Object.assign({ htmlFor: objectType }, { children: objectType }))] }), objectType))) }))] })));
};
const CrmFieldsTable = () => {
    const [rows, setRows] = useState([]);
    const [createCrmFieldOpen, setCreateCrmFieldOpen] = useState(false);
    const fetchCrmFields = () => {
        endpoints
            .getCrmFields()
            .then((res) => {
            let newRows = [];
            res.forEach((field) => {
                newRows.push({
                    id: field.id,
                    cells: [
                        field.name,
                        fieldTypeRenderer(field.content_type_definition),
                        field.applicable_objects.sort().join(", "),
                    ],
                });
            });
            setRows(newRows);
        })
            .catch((err) => {
            showErrorModal(err);
        });
    };
    const deleteCrmField = (row) => {
        endpoints.deleteCrmField(row.id).then(() => fetchCrmFields());
    };
    useEffect(() => {
        fetchCrmFields();
    }, []);
    const fieldTypeRenderer = (fieldType) => {
        switch (fieldType.type) {
            case "text":
                return (_jsxs("div", Object.assign({ className: "flex flex-col gap-1" }, { children: [_jsx("span", { children: "Text" }), _jsx("span", { children: `Max characters: ${fieldType.restrictions.length}` })] })));
            case "boolean":
                return _jsx("span", { children: "Boolean" });
            case "number":
                return (_jsxs("div", Object.assign({ className: "flex flex-col gap-1" }, { children: [_jsx("span", { children: "Number" }), _jsx("span", { children: `Digits allowed before decimal: ${fieldType.restrictions.precision - fieldType.restrictions.scale}` }), _jsx("span", { children: `Digits allowed after decimal: ${fieldType.restrictions.scale}` })] })));
            case "single_select":
                return (_jsxs("div", Object.assign({ className: "flex flex-col gap-1 w-[400px]" }, { children: [_jsx("span", { children: "Single select" }), _jsx("span", Object.assign({ className: "whitespace-pre-wrap" }, { children: `Values: ${fieldType.restrictions.picklist_values.join(", ")}` }))] })));
            case "multi_select":
                return (_jsxs("div", Object.assign({ className: "flex flex-col gap-1 w-[400px]" }, { children: [_jsx("span", { children: "Multi select" }), _jsx("span", Object.assign({ className: "whitespace-pre-wrap" }, { children: `Values: ${fieldType.restrictions.picklist_values.join(", ")}` }))] })));
            case "date":
                return _jsx("span", { children: "Date" });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(BaseTable, { title: "CRM fields", description: "Manage CRM fields here.", addName: "Create a new CRM field", addCallback: () => setCreateCrmFieldOpen((prev) => !prev), columnNames: ["Name", "Field type", "Applicable objects"], rows: rows, editCallback: undefined, deleteCallback: deleteCrmField }), _jsx(CreateCrmField, { open: createCrmFieldOpen, setOpen: setCreateCrmFieldOpen, createCallback: fetchCrmFields })] }));
};
export default CrmFieldsTable;
