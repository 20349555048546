import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { useDataTable } from "../table_hooks/use_data_table";
import { DataTable } from "../components/data_table";
import { getColumnIdColumnNameMap, getColumns } from "./calls_table_columns";
import { CallsTableFloatingBar } from "./calls_table_floating_bar";
import { CallsTableToolbarActions } from "./calls_table_toolbar_actions";
import { useNavigate, useParams } from "react-router-dom";
import { DEFAULT_TABLE_LAYOUT } from "../settings/constants";
import { DataTableToolbarV2 } from "../components/data_table_toolbar_v2";
import { FIELDS, SORTABLE_FIELDS } from "./calls_table_filters";
import { useBuildFieldsForFilter, useFilterChange, useFilterConfig, useInitialFilter, } from "../table_hooks/use_filters";
import { useSortRuleConfig, useInitialSortRules, useSortRulesChange, } from "../table_hooks/use_sort";
import { Drawer, DrawerContent, DrawerTitle, } from "../components/drawer_dialog";
import CallPage from "../home/call_v3";
import { useQuery, useQueryClient, } from "react-query";
import { getAppIntegrationData } from "../common/endpoints";
import { getColumnOrder, useMatchingLayout, } from "../components/data_table_toolbar_hooks";
import { exportTableToCSV } from "../table_helpers";
import { AccountPage } from "../crm/account_details";
export function CallsTable(data) {
    // Feature flags for showcasing some additional features. Feel free to remove them.
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { callIdParam, accountIdParam } = useParams();
    const hasCallOrAccountIdParam = callIdParam !== undefined || accountIdParam !== undefined;
    const calls = data.calls;
    const total = data.total;
    const { data: appIntegrationData } = useQuery({
        queryKey: ["appIntegrationData", data.tenant.id],
        queryFn: getAppIntegrationData,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    // Memoize the columns so they don't re-render on every render
    const columns = React.useMemo(() => getColumns(data.tenant, data.playbookItemGroups, data.filterQuery, data.crmFields, queryClient, appIntegrationData), [data.playbookItemGroups, appIntegrationData]);
    const matchingLayout = useMatchingLayout(data.layouts, data.searchParams);
    const [columnOrder, setColumnOrder] = React.useState(getColumnOrder(columns, matchingLayout));
    const columnIdMap = React.useMemo(() => getColumnIdColumnNameMap(), []);
    const playbook_item_names = data.playbookItemGroups.map((item) => item.default_item.name);
    const fieldsForFilter = useBuildFieldsForFilter(data.crmFields, FIELDS);
    const { filterConfig, wiserBackendQueryConverter } = useFilterConfig(fieldsForFilter);
    const { sortRuleConfig, wiserBackendSortRuleConverter } = useSortRuleConfig(SORTABLE_FIELDS);
    const initialFilter = useInitialFilter(data.searchParams, wiserBackendQueryConverter);
    const initialSortRules = useInitialSortRules(data.searchParams, wiserBackendSortRuleConverter);
    const handleFilterChange = useFilterChange(navigate, data.searchParams);
    const handleSortRulesChange = useSortRulesChange(navigate, data.searchParams);
    const { table } = useDataTable({
        data: calls,
        columns,
        initialState: {
            columnPinning: { right: ["actions"], left: ["select", "name"] },
            columnVisibility: Object.assign(Object.assign(Object.assign({ call_state: false, call_state_with_reason: false, call_source: false, meeting_host: false, participants: false, call_duration_minutes: false, post_call_all_slack_items_sent: false, post_call_any_slack_item_sent: false, post_call_crm_updates_mapped: false, post_call_crm_updates_pushed: false, playbook_content: false, description: false, account_id: false, opportunity_id: false, salesperson_speech_speed_wpm: false, salesperson_talk_time_percent: false }, Object.fromEntries(playbook_item_names.map((name) => [name, false]))), Object.fromEntries(data.crmFields.map((crmField) => [
                `account_fields__z${crmField.id.toString()}`,
                false,
            ]))), Object.fromEntries(data.crmFields.map((crmField) => [
                `opportunity_fields__z${crmField.id.toString()}`,
                false,
            ]))),
        },
        columnOrder,
        onColumnOrderChange: setColumnOrder,
        enableAdvancedFilter: true,
        enableAdvancedSorting: true,
        // For remembering the previous row selection on page change
        getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    });
    // Special condition to handle table export.
    // TODO: Find a better way to achieve this, since pagination is no longer a table state
    // setting table.setPageSize() no longer works.
    React.useEffect(() => {
        if (data.searchParams.has("pageSize") &&
            data.searchParams.get("pageSize") === "1000") {
            exportTableToCSV(table, {
                filename: "calls",
                excludeColumns: ["select", "actions", "playbook_items"],
            });
        }
    }, []);
    const renderNewToolBar = () => {
        return (_jsx(DataTableToolbarV2, { table: table, columnIdMap: columnIdMap, layouts: data.layouts, filterConfig: filterConfig, sortRuleConfig: sortRuleConfig, defaultTableLayout: DEFAULT_TABLE_LAYOUT, exportComponent: _jsx(CallsTableToolbarActions, { table: table }), onVisibleColumnsChange: data.onVisibleColumnsChange, onFiltersChange: handleFilterChange, onSortRulesChange: handleSortRulesChange, filterPanelTitle: "Show me calls", sortRuleBuilderPanelTitle: "Sort calls by", initialFilters: initialFilter, initialSortRules: initialSortRules, showSearchBar: true, onSearchQueryChange: data.setSearchQuery, matchingLayout: matchingLayout }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(DataTable, Object.assign({ table: table, floatingBar: _jsx(CallsTableFloatingBar, { table: table }), columnOrder: columnOrder, setColumnOrder: setColumnOrder, fetchNextPage: data.fetchNextPage, total: total, totalFetched: calls.length, isFetching: data.isFetching }, { children: renderNewToolBar() })), _jsx(Drawer, Object.assign({ open: hasCallOrAccountIdParam, onOpenChange: (isOpen) => {
                    if (!isOpen)
                        navigate("/calls" + window.location.search);
                } }, { children: _jsxs(DrawerContent, Object.assign({ className: "h-full sm:max-w-none p-0 sm:w-5/6", hideCloseButton: true }, { children: [!accountIdParam && (_jsxs(_Fragment, { children: [_jsx(DrawerTitle, Object.assign({ className: "hidden" }, { children: "Call Details" })), _jsx(CallPage, { updateSelectedCall: () => { }, onClose: () => navigate("/calls" + window.location.search) })] })), accountIdParam && _jsx(AccountPage, { accId: accountIdParam })] })) }))] }));
}
