import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/20/solid";
import classNames from "../class_names";
import Badge from "./badge";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, } from "../components/dropdown_menu";
import { ScrollArea } from "../components/scroll_area";
const MultiSelectDropdown = (props) => {
    const [selectedOptions, setSelectedOptions] = useState(props.selectedOptions);
    const handleOptionClick = (option) => {
        const newSelectedOptions = (selectedOptions.includes(option)
            ? selectedOptions.filter((o) => o !== option)
            : [...selectedOptions, option]).filter((o) => props.options.includes(o));
        setSelectedOptions(newSelectedOptions);
        props.setSelectedOptions(newSelectedOptions);
    };
    return (_jsxs(DropdownMenu, Object.assign({ modal: false }, { children: [_jsx(DropdownMenuTrigger, Object.assign({ asChild: true }, { children: _jsxs("div", Object.assign({ className: classNames("flex justify-between self-stretch gap-2 py-1 px-3 w-full items-center", "hover:cursor-pointer border border-wds-gray-3 rounded-lg bg-white") }, { children: [_jsx("div", Object.assign({ className: "flex gap-1 flex-wrap" }, { children: selectedOptions.length === 0
                                ? "Select an option"
                                : selectedOptions.map((option) => (_jsx(Badge, { text: option, classes: "bg-wds-blue-1", textClasses: "text-sm text-wds-blue-4 font-medium" }, option))) })), _jsx(ChevronDownIcon, { className: "w-4 h-4 shrink-0" })] })) })), _jsx(DropdownMenuContent, Object.assign({ align: "start", className: "max-h-52 overflow-y-auto" }, { children: _jsx(ScrollArea, { children: props.options.map((option) => (_jsx(DropdownMenuItem, Object.assign({ onClick: (e) => {
                            e.preventDefault();
                            handleOptionClick(option);
                        } }, { children: _jsxs("div", Object.assign({ className: classNames("inline-flex flex-grow flex-row items-center self-stretch justify-between", selectedOptions.includes(option) ? "font-bold" : "") }, { children: [option, selectedOptions.includes(option) && (_jsx(CheckIcon, { className: "w-4 h-4 text-blue-600" }))] })) }), option))) }) }))] })));
};
export default MultiSelectDropdown;
