import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu } from "@headlessui/react";
import classNames from "../class_names";
import { AcademicCapIcon, BookOpenIcon, CheckIcon, FolderIcon, GlobeAltIcon, PhoneIcon, UserGroupIcon, } from "@heroicons/react/24/outline";
import { useRef } from "react";
import FilterIcon from "../icons/filter_icon";
const iconStyle = "shrink-0 w-5 h-5 text-wds-gray-6";
export const KNOWLEDGE_BASE_CHAT_SOURCE = {
    id: "knowledge_base",
    label: "Knowledge base",
    description: "Ask a question against your product docs accessible by Wiser",
    options: {
        useCardInfo: true,
    },
    icon: _jsx(BookOpenIcon, { className: iconStyle, "aria-hidden": "true" }),
};
export const TRANSCRIPT_CHAT_SOURCE = {
    id: "route_transcript",
    label: "Transcript",
    description: "Ask a question against the current call's transcript",
    options: {
        useCardInfo: false,
    },
    icon: _jsx(PhoneIcon, { className: iconStyle, "aria-hidden": "true" }),
};
export const PRE_CALL_CHAT_SOURCE = {
    id: "call_research",
    label: "Call research",
    description: "Ask a question against the current call's research",
    options: {
        useCardInfo: false,
    },
    icon: _jsx(AcademicCapIcon, { className: iconStyle, "aria-hidden": "true" }),
};
export const PAST_CALLS_CHAT_SOURCE = {
    id: "past_call_transcripts",
    label: "Past call transcripts",
    description: "Ask a question against past calls with this account",
    options: {
        useCardInfo: false,
    },
    icon: _jsx(PhoneIcon, { className: iconStyle, "aria-hidden": "true" }),
};
export const ACCOUNT_10K_CHAT_SOURCE = {
    id: "account_sec_10k_documents",
    // TODO: Check on the label and description
    label: "Account's latest 10-K",
    description: "Ask a question against latest 10-K for this account",
    options: {
        useCardInfo: false,
    },
    icon: _jsx(UserGroupIcon, { className: iconStyle, "aria-hidden": "true" }),
};
export const ACCOUNT_SOURCES_AND_WEB_DATA = {
    id: "account_sources_and_web_data",
    label: "Account info",
    description: "Ask a question against this account",
    options: {
        useCardInfo: false,
    },
    icon: _jsx(GlobeAltIcon, { className: iconStyle, "aria-hidden": "true" }),
};
export const CURRENT_CALL = {
    id: "current_call",
    label: "This call",
    description: "Ask a question against this call and its research",
    options: {
        useCardInfo: false,
    },
    icon: _jsx(PhoneIcon, { className: iconStyle, "aria-hidden": "true" }),
};
export const CURRENT_DEAL = {
    id: "current_deal",
    label: "This deal",
    description: "Ask a question against the deal (eg past calls).",
    options: {
        useCardInfo: false,
    },
    icon: _jsx(FolderIcon, { className: iconStyle, "aria-hidden": "true" }),
};
export const CALLS_TABLE = {
    id: "calls_table",
    label: "Calls that appear in the calls table",
    description: "Ask a question against the currently-filtered calls table.",
    options: {
        useCardInfo: false,
    },
    icon: _jsx(FolderIcon, { className: iconStyle, "aria-hidden": "true" }),
};
export const CALLS_TABLE_QUANTITATIVE = {
    id: "calls_table_quantitative",
    label: "Quantiative chat with calls table",
    description: "Ask a question against the currently-filtered calls table.",
    options: {
        useCardInfo: false,
    },
    icon: _jsx(FolderIcon, { className: iconStyle, "aria-hidden": "true" }),
};
// This is an ordered list of all the chat sources that Wiser supports.
// The order of the items in this list determine the order in which they
// will be rendered in the UI menu dropdown.
// The id here should be same as what we support in force_source in chat_router.py
export const CHAT_SOURCES = [
    CURRENT_DEAL,
    CURRENT_CALL,
    KNOWLEDGE_BASE_CHAT_SOURCE,
];
const ChatSourceMenuItem = ({ chatSource, selected, }) => {
    return (_jsxs("div", Object.assign({ className: "flex py-2 px-4 justify-between items-center self-stretch w-full" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-start items-start space-x-2" }, { children: [_jsx("div", Object.assign({ className: "w-5" }, { children: chatSource.icon ? chatSource.icon : _jsx("div", {}) })), _jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx("span", Object.assign({ className: classNames("text-[15px]", selected ? "font-bold" : "") }, { children: chatSource.label })), _jsx("span", Object.assign({ className: "text-[13px] text-wds-gray-5" }, { children: chatSource.description }))] }))] })), selected && _jsx(CheckIcon, { className: "shrink-0 w-5 h-5 text-wds-blue-3" })] })));
};
export const ChatSourceMenuItems = ({ chatSources, selectedSource, setSelectedSource, filterQuery, openByDefault, lastMenuItemRef, renderedInCard, }) => {
    const allChatSources = chatSources && chatSources.length > 0 ? chatSources : CHAT_SOURCES;
    const chatSourceOptionsToRender = allChatSources.filter((chatSource) => filterQuery && filterQuery.length > 0
        ? chatSource.label.toLowerCase().includes(filterQuery.toLowerCase())
        : true);
    if (chatSourceOptionsToRender.length <= 0) {
        return null;
    }
    return (_jsx(Menu.Items, Object.assign({ className: classNames("flex flex-col flex-auto items-start gap-1", "min-w-full py-2 my-1", "rounded-lg border border-wds-gray-3 bg-white", "absolute z-20", renderedInCard ? "w-max right-0 top-full" : "bottom-full", "focus:outline-none focus:ring-0"), static: openByDefault }, { children: chatSourceOptionsToRender.map((chatSource) => (_jsx(Menu.Item, { children: ({ active }) => (_jsx("button", Object.assign({ type: "button", onClick: () => setSelectedSource(chatSource), className: classNames("w-full text-start", "focus:outline-none focus:ring-0", active ? "cursor-pointer bg-wds-gray-1" : ""), ref: lastMenuItemRef }, { children: _jsx(ChatSourceMenuItem, { chatSource: chatSource, selected: selectedSource.id === chatSource.id }, chatSource.id) }))) }, chatSource.id))) })));
};
const ChatSourceMenu = (props) => {
    const lastMenuItemRef = useRef(null);
    return (_jsxs(Menu, Object.assign({ as: "div", className: "relative flex flex-auto self-stretch" }, { children: [_jsxs(Menu.Button, Object.assign({ type: "button", className: classNames("flex py-1 px-2 items-center justify-center gap-2 hover:rounded-full", "focus:outline-none focus:ring-0", props.renderedInCard
                    ? "text-xs bg-wds-blue-1 hover:bg-wds-blue-2 rounded-full"
                    : "hover:bg-wds-blue-1", props.className) }, { children: [_jsx(FilterIcon, {}), _jsx("span", Object.assign({ className: "text-wds-gray-6 font-bold text-sm" }, { children: props.selectedSource.label }))] })), _jsx(ChatSourceMenuItems, { chatSources: props.chatSources, selectedSource: props.selectedSource, setSelectedSource: props.setSelectedSource, openByDefault: false, lastMenuItemRef: lastMenuItemRef, renderedInCard: props.renderedInCard })] })));
};
export default ChatSourceMenu;
