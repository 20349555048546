var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { AccountOverviewBlock } from "./account_overview_block";
import { AccountDetailsBlock } from "./account_details_block";
import { AccountCallsBlock } from "./account_calls_block";
import { AccountContactsBlock } from "./account_contacts_block";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createPreCallPrepBlock, deletePreCallPrepBlock, getAccountResearchBlockOutputs, getAllPreCallPrepBlocks, executeBricks, updatePreCallPrepBlock, } from "../../common/endpoints";
import { AccountResearchBlockCard } from "./account_research_block_card";
export const STATIC_ACCOUNT_BLOCK_IDS = {
    ACCOUNT_OVERVIEW: "account-overview",
    ACCOUNT_CALLS: "account-calls",
    ACCOUNT_CONTACTS: "account-contacts",
    ACCOUNT_DETAILS: "account-details",
};
// This hook manages everything related to account blocks:
// both static: (Account Overview, Account Details, Calls, Contacts)
// as well as dynamic research blocks that the user adds/creates.
export const useAccountResearchBlocks = ({ account, }) => {
    const queryClient = useQueryClient();
    const { data: allResearchBlocks } = useQuery({
        queryKey: ["researchBlocks"],
        queryFn: getAllPreCallPrepBlocks,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const { data: accountResearchBlockOutputs } = useQuery({
        queryKey: ["researchBlockOutputs", account === null || account === void 0 ? void 0 : account.id],
        queryFn: () => {
            if (!account)
                return undefined;
            return getAccountResearchBlockOutputs(account.id);
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled: !!account,
    });
    const applyUpdatedResearchBlockOutput = (accountResearchBlockOutput) => {
        queryClient.setQueryData(["researchBlockOutputs", account === null || account === void 0 ? void 0 : account.id], (prev) => {
            if (accountResearchBlockOutput.account_id !== (account === null || account === void 0 ? void 0 : account.id)) {
                console.error("Mismatch in account id");
                return prev || [];
            }
            if (!prev) {
                return [accountResearchBlockOutput];
            }
            if (prev.find((output) => output.id === accountResearchBlockOutput.id)) {
                return prev.map((output) => output.id === accountResearchBlockOutput.id
                    ? accountResearchBlockOutput
                    : output);
            }
            let updatedBlocks = [...prev, accountResearchBlockOutput];
            updatedBlocks.sort((a, b) => a.order - b.order);
            return updatedBlocks;
        });
        setGenerationInProgressBlockIds((prev) => {
            const newInProgressBlockIds = new Set(prev);
            newInProgressBlockIds.delete(accountResearchBlockOutput.research_block_id);
            return newInProgressBlockIds;
        });
    };
    const [generationInProgressBlockIds, setGenerationInProgressBlockIds] = useState(new Set());
    const [triggeredAllBlockOutputGeneration, setTriggeredAllBlockOutputGeneration,] = useState(false);
    useEffect(() => {
        // This hook is responsible for generating research block outputs
        // once for all the available research blocks when the user
        // lands on this page.
        // We do not have a concept of default templates for account
        // research blocks yet, and hence we generate all the outputs as
        // these are light-weight as compared to calls.
        // However, this should be re-visited once we add template support
        // for account research blocks as well.
        if (triggeredAllBlockOutputGeneration) {
            // We have already triggered this hook
            console.log("Already triggered running all block output generation");
            return;
        }
        if (!account || !allResearchBlocks) {
            // Required data is not fetched from the BE yet
            return;
        }
        setTriggeredAllBlockOutputGeneration(true);
        const accountResearchBlocks = allResearchBlocks.filter((block) => block.research_block_type === "ACCOUNT");
        const accountResearchBlockIds = accountResearchBlocks.map((block) => block.id);
        setGenerationInProgressBlockIds(new Set(accountResearchBlockIds));
        executeBricks(accountResearchBlockIds, applyUpdatedResearchBlockOutput, undefined, account.id);
    }, [account, allResearchBlocks]);
    // This ref is used to manipulate the scrolling of the blocks when
    // the user "jumps to" a different block using the dropdown.
    // Why ref instead of state?
    // This is the preferred way of such DOM manipulation in react
    // Details: https://react.dev/learn/manipulating-the-dom-with-refs
    const blocksRef = useRef(new Map());
    const getBlockRefMap = () => {
        return blocksRef.current;
    };
    // This is invoked when React mounts/unmounts each block.
    // Essentially we're setting the corresponding div ref in the map for
    // each account block when it is rendered, and unsetting it when it is
    // cleared from the DOM.
    const blocksRefCallback = (blockId, blockDivContainer) => {
        const blocksRefMap = getBlockRefMap();
        if (blockDivContainer) {
            blocksRefMap.set(blockId, blockDivContainer);
        }
        else {
            blocksRefMap.delete(blockId);
        }
    };
    const scrollToBlock = (blockId) => {
        const blocksRefMap = getBlockRefMap();
        if (blocksRefMap.has(blockId)) {
            blocksRefMap.get(blockId).scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };
    const addNewResearchBlockMutation = useMutation({
        mutationFn: ({ dataSources, name, prompt, }) => createPreCallPrepBlock(name, dataSources, prompt, 
        /* deliveryTargets= */ ["AccountUI"], 
        /* researchBlockType= */ "ACCOUNT"),
        onSuccess: (newBlock) => {
            if (!newBlock)
                return;
            queryClient.setQueryData(["researchBlocks"], (prevBlocks) => {
                return prevBlocks ? [...prevBlocks, newBlock] : [newBlock];
            });
        },
    });
    const updateResearchBlockMutation = useMutation({
        mutationFn: ({ blockId, dataSources, name, prompt, }) => updatePreCallPrepBlock(blockId, name, dataSources, prompt, 
        /* deliveryTargets= */ ["AccountUI"]),
        onSuccess: (updatedBlock) => {
            if (!updatedBlock)
                return;
            queryClient.setQueryData(["researchBlocks"], (prevBlocks) => {
                return prevBlocks
                    ? [
                        ...prevBlocks.filter((block) => block.id !== updatedBlock.id),
                        updatedBlock,
                    ]
                    : [updatedBlock];
            });
        },
    });
    const generateResearchBlockOutput = (accountId, researchBlockId, forceRefresh) => {
        setGenerationInProgressBlockIds((prev) => {
            const newInProgressBlockIds = new Set(prev);
            newInProgressBlockIds.add(researchBlockId);
            return newInProgressBlockIds;
        });
        return executeBricks([researchBlockId], applyUpdatedResearchBlockOutput, undefined, accountId, forceRefresh);
    };
    const createNewResearchBlock = (dataSources, name, prompt) => __awaiter(void 0, void 0, void 0, function* () {
        yield addNewResearchBlockMutation.mutateAsync({
            dataSources,
            name,
            prompt,
        });
    });
    const updateResearchBlock = (blockId, dataSources, name, prompt) => __awaiter(void 0, void 0, void 0, function* () {
        yield updateResearchBlockMutation.mutateAsync({
            blockId,
            dataSources,
            name,
            prompt,
        });
        // When a block is updated, automatically regenerate the output
        if (account) {
            generateResearchBlockOutput(account.id, blockId, 
            /* forceRefresh= */ true);
        }
    });
    const deleteResearchBlockMutation = useMutation({
        mutationFn: deletePreCallPrepBlock,
        onSuccess: () => {
            queryClient.invalidateQueries(["researchBlockMemberships", account === null || account === void 0 ? void 0 : account.id]);
        },
    });
    const deleteResearchBlock = (researchBlockId) => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteResearchBlockMutation.mutateAsync(researchBlockId);
    });
    const summaryBlockContent = accountResearchBlockOutputs === null || accountResearchBlockOutputs === void 0 ? void 0 : accountResearchBlockOutputs.filter((output) => output.delivery_targets.includes("AccountSummary"));
    // The order of the blocks here determines the order in which they
    // will be rendered in the UI.
    const accountBlocks = useMemo(() => {
        if (!account)
            return [];
        const staticBlocks = [
            {
                id: STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_OVERVIEW,
                label: "Overview",
                renderer: (_props) => (_jsx(AccountOverviewBlock, { account: account, accountSummaryBlock: summaryBlockContent === null || summaryBlockContent === void 0 ? void 0 : summaryBlockContent[0], scrollToBlock: scrollToBlock }, STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_OVERVIEW)),
            },
            {
                id: STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_DETAILS,
                label: "Account details",
                renderer: (_props) => (_jsx(AccountDetailsBlock, { account: account }, STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_DETAILS)),
            },
            {
                id: STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CALLS,
                label: "Calls",
                renderer: (_props) => (_jsx(AccountCallsBlock, { account: account }, STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CALLS)),
            },
            {
                id: STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CONTACTS,
                label: "Contacts",
                renderer: (_props) => (_jsx(AccountContactsBlock, { account: account }, STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CONTACTS)),
            },
        ];
        if (!allResearchBlocks) {
            return [...staticBlocks];
        }
        const researchBlocks = (accountResearchBlockOutputs === null || accountResearchBlockOutputs === void 0 ? void 0 : accountResearchBlockOutputs.filter((output) => {
            return output.delivery_targets.includes("AccountUI");
        }).map((output) => {
            const researchBlock = allResearchBlocks.find((block) => block.id === output.research_block_id);
            return {
                id: `${output.id}`,
                label: researchBlock.name,
                renderer: (props) => (_jsx(AccountResearchBlockCard, Object.assign({ account: account, researchBlock: researchBlock, accountResearchBlockOutput: output, outputGenerationInProgress: generationInProgressBlockIds.has(output.research_block_id), generateResearchBlockOutput: generateResearchBlockOutput, createNewResearchBlock: createNewResearchBlock, updateResearchBlock: updateResearchBlock, deleteResearchBlock: deleteResearchBlock }, props), `${output.id}`)),
            };
        })) || [];
        return [...staticBlocks, ...researchBlocks];
    }, [
        account,
        allResearchBlocks,
        accountResearchBlockOutputs,
        generationInProgressBlockIds,
    ]);
    return {
        staticBlocksCount: 4,
        allResearchBlocks,
        accountResearchBlockOutputs,
        accountBlocks,
        scrollToBlock,
        blocksRefCallback,
        createNewResearchBlock,
        updateResearchBlock,
        generateResearchBlockOutput,
    };
};
