export const SORTABLE_FIELDS = [
    {
        id: "name",
        name: "Name",
        type: "string",
    },
    {
        id: "call_type",
        name: "Call type",
        type: "select",
    },
    {
        id: "call_time",
        name: "Call time",
        type: "relative_date",
    },
];
export const FIELDS = [
    {
        id: "name",
        name: "Name",
        type: "string",
    },
    {
        id: "call_type",
        name: "Call type",
        type: "select",
        options: ["External", "Internal"],
    },
    {
        id: "wiser_joined",
        name: "Wiser joined",
        type: "boolean",
    },
    {
        id: "content",
        name: "Tracker notes",
        type: "string",
        meta: {
            model: "PlaybookItemNotes",
            relatedModelFieldName: "live_call_id",
        },
    },
    {
        id: "call_time",
        name: "Call time",
        type: "date",
        options: [
            "Today",
            "Yesterday",
            "Tomorrow",
            "Next week",
            "Last week",
            "Last 2 weeks",
            "Last month",
            "Last year",
        ],
    },
    // TODO - Find a solution to move this to unstructured filters - https://getwiser.atlassian.net/browse/WISER-1788
    // {
    //   id: "transcripts",
    //   name: "Transcripts",
    //   type: "string",
    // },
    {
        id: "account_id",
        name: "Account",
        type: "reference",
    },
    {
        id: "owner_id",
        name: "User",
        type: "reference",
    },
    {
        id: "contact_id",
        name: "Contact",
        type: "reference",
        meta: {
            model: "Participants",
            relatedModelFieldName: "call_id",
        },
    },
    {
        id: "call_source",
        name: "Call source",
        type: "select",
        options: ["Uploaded from Gong", "Zoom", "Local Audio", "Google Meet"],
    },
];
