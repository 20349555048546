import { PostCallOutputTarget, } from "../types";
import { DEFAULT_FIELD_SCHEMA } from "./data_processing_config";
export const isPostCallOutputEligibleForExecuting = (postCallOutput) => {
    return (postCallOutput.target &&
        postCallOutput.targetDetails &&
        (postCallOutput.state === "PENDING" || postCallOutput.state === "FAILED"));
};
const getFieldSchemaForSfdcTarget = (targetDetails, sfdcIntegrationData) => {
    if (!sfdcIntegrationData) {
        return DEFAULT_FIELD_SCHEMA;
    }
    const targetObjectType = targetDetails.objectType;
    const targetFieldName = targetDetails.fieldName;
    const targetField = sfdcIntegrationData.sfdcFields[targetObjectType].find((field) => field.name === targetFieldName);
    return targetField ? targetField.schema : DEFAULT_FIELD_SCHEMA;
};
const getFieldSchemaForHubSpotTarget = (targetDetails, hubSpotIntegrationData) => {
    if (!hubSpotIntegrationData) {
        return DEFAULT_FIELD_SCHEMA;
    }
    const targetObjectType = targetDetails.objectType;
    const targetFieldId = targetDetails.fieldName;
    const targetField = hubSpotIntegrationData.crmFields[targetObjectType].find((field) => field.name === targetFieldId);
    return targetField ? targetField.schema : DEFAULT_FIELD_SCHEMA;
};
export const convertWiserCrmFieldSchemaToPostCallOutputFieldSchema = (wiserCrmFieldSchema) => {
    switch (wiserCrmFieldSchema.type) {
        case "text":
            return {
                type: "Text",
                restrictions: {
                    length: wiserCrmFieldSchema.restrictions.length,
                },
            };
        case "number":
            return {
                type: "Number",
                restrictions: {
                    precision: wiserCrmFieldSchema.restrictions.precision,
                    scale: wiserCrmFieldSchema.restrictions.scale,
                },
            };
        case "boolean":
            return {
                type: "Boolean",
            };
        case "single_select":
            return {
                type: "Single select",
                restrictions: {
                    picklist_values: wiserCrmFieldSchema.restrictions.picklist_values,
                },
            };
        case "multi_select":
            return {
                type: "Multi select",
                restrictions: {
                    picklist_values: wiserCrmFieldSchema.restrictions.picklist_values,
                },
            };
        case "date":
            return {
                type: "Date",
            };
    }
};
const getFieldSchemaForWiserCrmTarget = (targetDetails, wiserCrmIntegrationData) => {
    const targetObjectType = targetDetails.objectType;
    const targetFieldId = targetDetails.fieldId;
    if (!wiserCrmIntegrationData) {
        return DEFAULT_FIELD_SCHEMA;
    }
    const targetField = wiserCrmIntegrationData.crmFields[targetObjectType].find((field) => field.id === targetFieldId);
    return targetField
        ? convertWiserCrmFieldSchemaToPostCallOutputFieldSchema(targetField.schema)
        : DEFAULT_FIELD_SCHEMA;
};
export const getFieldSchemaForPostCallOutputItem = (postCallOutputItem, integrationsData) => {
    const target = postCallOutputItem.target;
    if (target === PostCallOutputTarget.SALESFORCE) {
        return getFieldSchemaForSfdcTarget(postCallOutputItem.targetDetails, integrationsData.some((integrationData) => integrationData.name === PostCallOutputTarget.SALESFORCE)
            ? integrationsData.find((integrationData) => integrationData.name === PostCallOutputTarget.SALESFORCE)
            : null);
    }
    if (target === PostCallOutputTarget.WISER_CRM) {
        return getFieldSchemaForWiserCrmTarget(postCallOutputItem.targetDetails, integrationsData.find((integrationData) => integrationData.name === PostCallOutputTarget.WISER_CRM));
    }
    if (target === PostCallOutputTarget.HUBSPOT) {
        return getFieldSchemaForHubSpotTarget(postCallOutputItem.targetDetails, integrationsData.find((integrationData) => integrationData.name === PostCallOutputTarget.HUBSPOT));
    }
    return DEFAULT_FIELD_SCHEMA;
};
// This method is used to check if the tenant is connected to ANY external CRM
// in order to determine whether to show Wiser CRM as an option in the integration selector.
export const isTenantConnectedToExternalCrm = (connectedIntegrations) => {
    const externalCRMs = [
        PostCallOutputTarget.SALESFORCE,
        PostCallOutputTarget.HUBSPOT,
    ];
    return connectedIntegrations.some((integration) => externalCRMs.includes(integration.name));
};
export const isEmailItem = (postCallOutput) => {
    var _a;
    return (((_a = postCallOutput.fieldMap.sourcePlaybookItem) === null || _a === void 0 ? void 0 : _a.call_stage) === "FOLLOW_UP_EMAIL");
};
export const isCrmEventPushItem = (postCallOutput) => {
    return postCallOutput.fieldMap.target === PostCallOutputTarget.CRM_EVENT;
};
/**
 * This helper method fetches subject, and body content from the text
 * using regex matching
 * Example:
 *    text = "
 *      Subject: Hello from Wiser!
 *
 *      Hi John,
 *      Hope you are doing well!
 *      Just wanted to say hello from the entire Wiser team.
 *      Thanks,
 *      Harsh
 *    "
 * @param text: The text content from which we need to get the email content
 *
 * @returns an object containing subject and email parsed from the text.
 * Note that both can be empty based on whether we were able to parse them.
 */
export const getEmailContentFromText = (text) => {
    const emailText = text.trim();
    // Use regex to match the subject line
    const subjectMatch = emailText.match(/^Subject:\s*(.+)$/m);
    const subject = subjectMatch && subjectMatch.length > 1 ? subjectMatch[1].trim() : "";
    // Find the body content, starting after the subject and skipping any number of line breaks
    let body = "";
    if (subjectMatch && subjectMatch.length) {
        const bodyStartIndex = subjectMatch[0].length;
        body = emailText
            .slice(bodyStartIndex)
            .replace(/^\s*\n+/g, "")
            .trim();
    }
    else {
        body = emailText.trim();
    }
    return {
        subject,
        body,
    };
};
