var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { createPreCallPrepTemplate, deletePreCallPrepTemplate, getAllPreCallPrepTemplates, } from "./common/endpoints";
import BaseModal from "./base_modal";
import BaseTable2 from "./base_table2";
import RightDrawer from "./right_drawer";
import classNames from "./class_names";
import EditPreCallTemplate from "./edit_pre_call_template";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CenterScreen } from "./settings/styled_components";
import WaitingSpinner from "./waiting_spinner";
const PreCallTemplateTable = () => {
    var _a, _b, _c, _d;
    const location = useLocation();
    const queryClient = useQueryClient();
    const { data: templates, isLoading: templatesLoading, isError: templatesError, } = useQuery(["pre_call_templates"], getAllPreCallPrepTemplates);
    const [addTemplateModalOpen, setAddTemplateModalOpen] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.templateId) !== null && _b !== void 0 ? _b : null);
    const templateCreate = useMutation({
        mutationFn: createPreCallPrepTemplate,
        onSuccess: (template) => {
            queryClient.setQueryData(["pre_call_templates"], (oldData) => {
                return oldData ? [...oldData, template] : [template];
            });
            setAddTemplateModalOpen(false);
            setSelectedTemplateId(template.id);
        },
    });
    const templateDelete = useMutation({
        mutationFn: deletePreCallPrepTemplate,
        onSuccess: () => {
            queryClient.setQueryData(["pre_call_templates"], (oldData) => {
                return oldData
                    ? oldData.filter((t) => t.id !== selectedTemplateId)
                    : [];
            });
            setSelectedTemplateId(null);
        },
    });
    if (templatesLoading || templatesError || !templates) {
        return (_jsxs(CenterScreen, { children: [_jsx(WaitingSpinner, { text: "Loading research sets..." }), ";"] }));
    }
    return (_jsxs("div", Object.assign({ className: classNames(selectedTemplateId ? "w-3/4" : "w-full") }, { children: [_jsx(BaseTable2, { title: "Research Sets", columns: [
                    {
                        header: "Name",
                        accessorKey: "name",
                    },
                    {
                        header: "Research Blocks",
                        accessorKey: "blocks_count",
                    },
                ], rows: templates, addCallback: () => {
                    setAddTemplateModalOpen(true);
                }, expandCallback: (row) => {
                    if (selectedTemplateId === row.id) {
                        setSelectedTemplateId(null);
                    }
                    else {
                        setSelectedTemplateId(row.id);
                    }
                } }), _jsx(BaseModal, { title: "Add a research set", description: "A research set is a collection of research blocks that you can use to prepare for a call.", fields: [
                    {
                        name: "Name",
                        type: "text",
                        value: "",
                        required: true,
                        placeholder: "Enter a name for the research set",
                    },
                ], open: addTemplateModalOpen, setOpen: setAddTemplateModalOpen, submitCallback: (fields) => __awaiter(void 0, void 0, void 0, function* () {
                    const name = fields[0].value;
                    yield templateCreate.mutateAsync(name);
                }), submitText: "Add research set", reactQuery: true }), selectedTemplateId && (_jsx(RightDrawer, Object.assign({ title: (_d = (_c = templates.find((t) => t.id === selectedTemplateId)) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "", isOpen: selectedTemplateId !== null, deleteCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                    yield templateDelete.mutateAsync(selectedTemplateId);
                }), onClose: () => setSelectedTemplateId(null) }, { children: _jsx(EditPreCallTemplate, { templateId: selectedTemplateId, refetch: () => __awaiter(void 0, void 0, void 0, function* () {
                        queryClient.invalidateQueries("pre_call_templates");
                    }) }) })))] })));
};
export default PreCallTemplateTable;
