export const convertSearchParamsToBackendFilter = (searchParams) => {
    if (!searchParams || !searchParams.get("filter")) {
        return undefined;
    }
    const filterString = searchParams.get("filter");
    return JSON.parse(filterString);
};
const isEmptyFilter = (backendFilter) => {
    if (backendFilter.where_condition.field ||
        backendFilter.where_condition.subquery) {
        return true;
    }
    if (!backendFilter.where_condition.subconditions ||
        !backendFilter.where_condition.subconditions.length) {
        return true;
    }
    return false;
};
export const convertBackendFilterToSearchParams = (backendFilter) => {
    if (isEmptyFilter(backendFilter)) {
        return undefined;
    }
    return JSON.stringify(backendFilter);
};
